import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const ChronicCystitisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Chronic Cystitis Diet Guide</h1>
      <p className="diet-description">Dietary changes can help reduce bladder irritation and manage symptoms of chronic cystitis by promoting bladder health and reducing inflammation.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">1. Stay Hydrated</h2>
        <p className="diet-text">Drinking plenty of water can help flush bacteria and irritants from the bladder. Aim for 8-10 cups of water a day to reduce symptoms and support bladder function.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">2. Avoid Bladder Irritants</h2>
        <p className="diet-text">Certain foods and drinks, such as caffeine, alcohol, spicy foods, citrus fruits, and artificial sweeteners, can irritate the bladder lining. Limiting or avoiding these items can help reduce bladder inflammation.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">3. Eat Anti-inflammatory Foods</h2>
        <p className="diet-text">Incorporating anti-inflammatory foods like leafy greens, berries, and omega-3-rich fish (e.g., salmon, sardines) into your diet can help reduce inflammation in the bladder and urinary tract.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">4. Increase Fiber Intake</h2>
        <p className="diet-text">A high-fiber diet can help prevent constipation, which can put pressure on the bladder and exacerbate symptoms of cystitis. Include whole grains, fruits, and vegetables in your meals.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">5. Limit Acidic Foods</h2>
        <p className="diet-text">Foods high in acid, such as tomatoes and citrus fruits, can irritate the bladder lining and should be consumed in moderation or avoided if they worsen your symptoms.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">6. Try Probiotic-Rich Foods</h2>
        <p className="diet-text">Probiotic foods, like yogurt, kefir, and fermented vegetables, help maintain a healthy balance of bacteria in the urinary tract, which can reduce bladder infections and inflammation.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">7. Avoid Excess Sugar and Processed Foods</h2>
        <p className="diet-text">Processed foods and sugary snacks can increase bladder irritation and inflammation. Focus on whole, unprocessed foods to support bladder health.</p>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal topped with blueberries and a drizzle of honey.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken salad with cucumbers, spinach, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A small probiotic yogurt or a handful of walnuts.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with steamed broccoli and quinoa.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water consistently throughout the day to support bladder function and flush out irritants.</li>
        </ul>
      </section>
    </div>
  );
};

export default ChronicCystitisDiet;
