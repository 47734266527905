import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {

  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/pedia4.webp')}alt="Urology Hero" />
        <h1>Autism spectrum disorder (ASD) Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Autism spectrum disorder (ASD)</h2>
          <img src={require('../images/child4.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy treats Autism Spectrum Disorder (ASD) by considering the individual holistically, focusing on physical, emotional, and psychological aspects. The goal is to identify and treat the underlying causes of the disorder, not just manage symptoms. This involves a comprehensive understanding of the patient’s unique characteristics, behaviors, and developmental history. Homeopathic remedies are selected based on the principle of “like cures like,” which means using substances that cause symptoms in a healthy person to treat similar symptoms in a sick person.
          </p>
          <h3>Causes</h3>
          <p>ASD is believed to arise from a complex interplay of factors, including:</p>
          <ul>
            <li><h5>Genetic Factors:</h5> Mutations or variations in certain genes can increase the risk of developing ASD.</li>
            <li><h5>Neurological Factors: </h5>Structural and functional differences in the brain, particularly in areas affecting communication and social interaction.</li>
            <li><h5>Environmental Factors:</h5> Prenatal exposure to toxins, infections, or medications may contribute to the development of ASD.</li>
            <li><h5>Developmental Factors:</h5> Issues during prenatal development, such as advanced parental age, low birth weight, or complications during birth. </li>
            <li><h5>Immune System Dysfunction:</h5> Abnormal immune responses and inflammation have been associated with ASD.</li>
          </ul>
          <h3>Investigations</h3>
          <p>Diagnosing ASD involves a thorough and multidisciplinary approach:</p>
          <ul>
            <li><h5>Comprehensive Medical History: </h5>Detailed examination of the patient’s developmental, medical, and family history.</li>
            <li><h5>Behavioral Assessments:</h5> Standardized questionnaires and direct observation to assess social, communication, and behavioral patterns.</li>
            <li><h5>Psychological Evaluations:</h5> Cognitive and emotional assessments to identify strengths and weaknesses.</li>
            <li><h5>Neurological Tests:</h5> Brain imaging and other tests to rule out other conditions and understand neurological differences. </li>
            <li><h5>Genetic Testing: </h5> To identify any genetic anomalies associated with ASD.</li>

          </ul>
          <h3>Complications and Risk Factors</h3>
          <p>ASD can lead to various complications, including:</p>
          <ul>
            <li><h5>Social Challenges:</h5> Difficulty in forming and maintaining relationships, leading to social isolation.</li>
            <li><h5>Communication Difficulties:</h5> Challenges in verbal and non-verbal communication, affecting social interactions and daily functioning.</li>
            <li><h5>Behavioral Issues:</h5> Repetitive behaviors, restricted interests, and resistance to change.</li>
            <li><h5>Sensory Sensitivities: </h5> Over or under-reactivity to sensory stimuli such as sounds, lights, textures, or tastes.</li>
            <li><h5>Mental Health Issues:</h5> Increased risk of anxiety, depression, and other coexisting mental health conditions. Risk factors include:</li>
            <li><h5>Behavioral Issues:</h5> Repetitive behaviors, restricted interests, and resistance to change.</li>
            <li><h5>Family History:</h5> Genetic predisposition to ASD or other developmental disorders.</li>
            <li><h5>Prenatal Factors:</h5> Maternal infections, exposure to toxins, or use of certain medications during pregnancy.</li>
            <li><h5>Gender:</h5> Boys are more likely to be diagnosed with ASD than girls.</li>
            <li><h5>Premature Birth: </h5>Babies born prematurely or with low birth weight.</li>
            <li><h5>Parental Age:</h5> Advanced parental age at the time of conception.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>Conventional treatment for ASD includes a combination of strategies:</p>
          <ul>
            <li><h5>Behavioral Therapy:</h5> Applied Behavior Analysis (ABA) and other techniques to improve social, communication, and learning skills.</li>
            <li><h5>Speech Therapy:</h5> To enhance communication skills, both verbal and non-verbal.</li>
            <li><h5>Occupational Therapy: </h5> To develop fine motor skills, daily living skills, and address sensory issues.</li>
            <li><h5>Medication:</h5> Used to manage coexisting conditions such as anxiety, depression, or attention issues, though there is no specific medication for ASD.</li>
            <li><h5>Educational Support:</h5>  Special education programs and individualized education plans (IEPs) to cater to the child’s learning needs.</li>
            <li><h5>Family Support:</h5> Training and counseling to help families understand and manage ASD.</li>
            </ul>
          <h3>Homeopathic Supportive Care</h3>
          <p>Homeopathy offers individualized remedies based on the patient’s unique symptoms and overall constitution:</p>
          <ul>
            <li><h5>Carcinosin:</h5> For children with obsessive behaviors, perfectionism, and a strong desire for control.</li>
            <li><h5>Stramonium:</h5> For intense fears, aggression, and extreme restlessness.</li>
            <li><h5>Baryta Carbonica: </h5> For developmental delays, shyness, and difficulty in social interactions.</li>
            <li><h5>Natrum Muriaticum: </h5>For children who are emotionally sensitive, introverted, and have difficulty expressing emotions.</li> 
            <li><h5>Silicea:</h5> For children who are timid, have difficulty in social situations, and are slow to develop.</li>
            <li><h5>Natrum Muriaticum:</h5>  For children who are emotionally sensitive, introverted, and have difficulty expressing emotions.</li>
            </ul>
            <p>Homeopathic remedies are prescribed in minute doses, tailored to the individual’s specific symptoms and overall health profile. A detailed consultation with a qualified homeopath is essential to determine the most appropriate remedy and dosage.</p>
          <h3>Conclusion</h3>
          <p>In our experience, a holistic approach combining homeopathic treatment, lifestyle modifications, and supportive therapies can lead to significant improvements in individuals with ASD. Patients often report better communication, improved social interactions, and enhanced overall well-being. Homeopathy, with its emphasis on individualized care, offers a gentle yet effective solution for managing ASD. Consulting with a qualified homeopath ensures that the treatment plan is tailored to the unique needs of each patient, promoting long-term health and balance. Integrating homeopathy into a comprehensive treatment strategy allows for a more nuanced and effective approach to managing ASD, addressing the disorder at its roots and providing lasting benefits for the patient’s mental and emotional health. Homeopathic treatments, combined with traditional therapies and family support, create a synergistic effect that fosters a more holistic and sustainable improvement in the quality of life for individuals with ASD and their families.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Autism spectrum disorder (ASD) Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Autism spectrum disorder (ASD) conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/pediatric/autism-spectrum-disorder/autism-spectrum-disorder-diet">Diet for Autism spectrum disorder (ASD)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Autism spectrum disorder (ASD)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Autism spectrum disorder (ASD)</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Autism spectrum disorder (ASD) Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/dA2h9fpJLqQ?si=lii3rMKfCuUM9bHm"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
