// import React from 'react';
// import '../css/Queries.css';

// const Queries = () => {
//   return (
//     <div className="queries-page">
//       <div className="query-hero-section">
//         <img src={require('../images/querieshero.jpg')} alt="Hero" className="query-hero-img" />
//         <div className="query-hero-overlay">
//           <h1 className="query-hero-title">Queries / Feedback / Follow Up</h1>
//           <p className="query-hero-text">Reach out to us for any queries, follow-ups, or feedback.</p>
//         </div>
//       </div>

//       <div className="queries-form-section">
//         <h2>Queries</h2>
//         <form className="queries-form">
//           <input type="text" placeholder="Full Name" required />
//           <input type="email" placeholder="Your Email" required />
//           <input type="tel" placeholder="Phone Number" required />

//           <textarea placeholder="Your Message" rows="4" required></textarea>
//           <button type="submit">Submit</button>
//         </form>
//       </div>
//     </div>
//   );
// };
// export default Queries;











import React, { useState } from 'react';
import '../css/Queries.css';

const Queries = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile_number: '',
    message: '',
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="queries-page">
      <div className="query-hero-section">
        <div className='queries-new'>
          <h1>Queries</h1>
          <h2>Reach out to us for any queries,</h2>
          <h2>follow-ups, or feedback</h2>
        </div>
        {/* <img src={require('../images/query_hero.jpg')} alt="Hero" className="query-hero-img" /> */}
      </div>

      <div className="queries-form-section">
        <h2>Queries</h2>
        <form 
          className="queries-form" 
          action="https://api.web3forms.com/submit" 
          method="POST"
          onSubmit={() => setResponseMessage("Your query has been submitted successfully!")}
        >
          {/* Web3Forms Access Key */}
          <input
            type="hidden"
            name="access_key"
            value="90277967-72b9-4089-93c9-5ec2b7137a38" // Replace with your actual Web3Forms access key
          />
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <input
            type="tel"
            name="mobile_number"
            placeholder="Phone Number"
            value={formData.mobile_number}
            onChange={handleInputChange}
            required
          />
          <textarea
            name="message"
            placeholder="Your Message"
            rows="4"
            value={formData.message}
            onChange={handleInputChange}
            required
          ></textarea>
          <button type="submit">Submit</button>
        </form>
        {responseMessage && <p>{responseMessage}</p>}
      </div>
    </div>
  );
};

export default Queries;



















// import React, { useState } from 'react';
// import '../css/Queries.css';

// const Queries = () => {
//   // State to manage form data
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     mobile_number: '',
//     message: '',
//   });

//   // State to manage response message
//   const [responseMessage, setResponseMessage] = useState('');

//   // Handle form input change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   // Handle form submit
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // API call using fetch
//     fetch('http://192.168.29.66:8000/niramayabackend/queries/', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(formData), // Sending form data as JSON
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.success) {
//           setResponseMessage(data.success);
//           setFormData({ name: '', email: '', mobile_number: '', message: '' }); // Clear form
//         } else {
//           setResponseMessage('Failed to submit query. Please try again.');
//         }
//       })
//       .catch((error) => {
//         setResponseMessage('An error occurred. Please try again.');
//         console.error('Error:', error);
//       });
//   };

//   return (
//     <div className="queries-page">
//       <div className="query-hero-section">
//       <div className='queries-new'>
//       <h1>Queries</h1>
//       <h2>Reach out to us for any queries,</h2>
//       <h2>follow-ups,or feedback</h2>
//       </div>
//         {/* <img src={require('../images/query_hero.jpg')} alt="Hero" className="query-hero-img" /> */}
       
//       </div>

//       <div className="queries-form-section">
//         <h2>Queries</h2>
//         <form className="queries-form" onSubmit={handleSubmit}>
//           <input
//             type="text"
//             name="name"
//             placeholder="Full Name"
//             value={formData.name}
//             onChange={handleInputChange}
//             required
//           />
//           <input
//             type="email"
//             name="email"
//             placeholder="Your Email"
//             value={formData.email}
//             onChange={handleInputChange}
//             required
//           />
//           <input
//             type="tel"
//             name="mobile_number"
//             placeholder="Phone Number"
//             value={formData.mobile_number}
//             onChange={handleInputChange}
//             required
//           />
//           <textarea
//             name="message"
//             placeholder="Your Message"
//             rows="4"
//             value={formData.message}
//             onChange={handleInputChange}
//             required
//           ></textarea>
//           <button type="submit">Submit</button>
//         </form>
//         {responseMessage && <p>{responseMessage}</p>}
//       </div>
//     </div>
//   );
// };

// export default Queries;
