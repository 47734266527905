import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const MaleInfertilityDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Male Infertility Diet Guide</h1>
      <p className="diet-description">A nutrient-dense diet can help improve male fertility by supporting hormone balance, sperm health, and overall reproductive function. Below are food suggestions to include and avoid to promote male fertility.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc is essential for testosterone production and sperm health. Foods like oysters, pumpkin seeds, lentils, and chickpeas can boost zinc levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Antioxidants like vitamin C, vitamin E, and selenium protect sperm from oxidative stress. Include citrus fruits, berries, almonds, and Brazil nuts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s in fatty fish, flaxseeds, and walnuts improve blood flow to the reproductive organs and support sperm quality.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Folate-Rich Foods</h3>
          <p className="diet-text">Folate is important for healthy sperm development. Include leafy greens, beans, and avocados in your diet for folate support.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. L-Arginine Sources</h3>
          <p className="diet-text">L-arginine, found in nuts, seeds, and whole grains, promotes nitric oxide production, supporting blood flow and sperm health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D is vital for hormone balance and sperm health. Include fortified dairy, fatty fish, and eggs in your diet.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed Meats</h3>
          <p className="diet-text">Processed meats contain preservatives and unhealthy fats that can harm sperm health. Stick to lean proteins like chicken, fish, and beans.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excessive sugar can lead to insulin resistance and hormonal imbalances, which can negatively impact sperm health. Limit sweets and sugary beverages.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats</h3>
          <p className="diet-text">Trans fats, found in fried and baked goods, can lower sperm count and quality. Opt for healthy fats like olive oil, avocados, and nuts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Excessive Alcohol</h3>
          <p className="diet-text">High alcohol intake can reduce testosterone levels and affect sperm quality. Limit alcohol to support reproductive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeine (in large amounts)</h3>
          <p className="diet-text">While moderate caffeine is generally fine, excessive caffeine intake can negatively affect sperm quality. Keep caffeine intake balanced.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Greek yogurt with berries, pumpkin seeds, and a handful of walnuts.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and a spinach salad.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or an apple with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and a side of leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water and herbal teas to support overall health and hydration.</li>
        </ul>
      </section>
    </div>
  );
};

export default MaleInfertilityDiet;
