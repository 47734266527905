import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const DUBDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Dysfunctional Uterine Bleeding (DUB) Diet Guide</h1>
      <p className="diet-description">A healthy diet can support the management of Dysfunctional Uterine Bleeding (DUB) by balancing hormones, reducing inflammation, and replenishing lost nutrients. Below are suggestions for foods to take and avoid.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Iron-Rich Foods</h3>
          <p className="diet-text">DUB can lead to heavy blood loss, which may result in iron deficiency or anemia. Include iron-rich foods such as spinach, lentils, quinoa, lean meats, and fortified cereals to replenish lost iron and support healthy blood production.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C helps with the absorption of iron and supports the immune system. Include foods like oranges, strawberries, bell peppers, and broccoli to enhance iron absorption and overall health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3 fatty acids, found in flaxseeds, walnuts, and fatty fish (salmon, sardines), help reduce inflammation and balance hormones, which is essential in managing DUB symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Calcium and Magnesium-Rich Foods</h3>
          <p className="diet-text">Foods rich in calcium and magnesium, such as dairy products, leafy greens, almonds, and seeds, support muscle relaxation and may help reduce uterine contractions and pain associated with DUB.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Whole Grains</h3>
          <p className="diet-text">Whole grains like brown rice, oats, and quinoa provide essential B-vitamins and fiber, which help regulate hormones and maintain healthy digestion, important for overall reproductive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas such as chamomile, ginger, and raspberry leaf tea can have soothing effects, reduce inflammation, and alleviate menstrual discomfort associated with DUB.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excessive sugar intake can cause hormonal imbalances and increase inflammation, worsening DUB symptoms. Limiting sugary snacks, sodas, and desserts is recommended.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Caffeine</h3>
          <p className="diet-text">Caffeine can increase uterine contractions and exacerbate bleeding and cramps. Reducing or eliminating coffee, energy drinks, and caffeinated sodas can help manage DUB symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed and Fried Foods</h3>
          <p className="diet-text">Processed and fried foods are often high in unhealthy fats and additives, which can increase inflammation and disrupt hormone balance. Stick to fresh, whole foods for better symptom management.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol consumption can affect hormone levels and exacerbate symptoms of DUB. It’s best to limit or avoid alcohol to support hormonal balance and reduce inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. High-Sodium Foods</h3>
          <p className="diet-text">Excess sodium can lead to bloating and water retention, worsening discomfort during heavy bleeding episodes. Limit processed foods, canned goods, and salty snacks to reduce these symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Red Meat</h3>
          <p className="diet-text">While red meat can be a source of iron, it is also high in saturated fats, which can promote inflammation. Consider plant-based or lean protein sources for iron intake to avoid worsening DUB symptoms.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal topped with chia seeds, strawberries, and a drizzle of honey, served with a glass of orange juice.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken salad with spinach, bell peppers, and avocado, dressed with olive oil and lemon juice.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a small yogurt with flaxseeds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and roasted broccoli, drizzled with olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include herbal teas like chamomile or ginger for soothing relief.</li>
        </ul>
      </section>
    </div>
  );
};

export default DUBDiet;
