import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {  
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/uti.webp')}alt="Urology Hero" />
        <h1>Urinary Tract Infection (UTI) Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Urinary Tract Infection (UTI)</h2>
          <img src={require('../images/kidney6.webp')}alt="Urology Hero" />
          <h3>Causes</h3>
          <ul>
            <li><h5>Bacterial Infection:</h5> The primary cause of UTIs is bacterial infection, typically due to bacteria entering the urinary tract through the urethra. The most common bacterium responsible for UTIs is Escherichia coli (E. coli), which normally resides in the gastrointestinal tract but can migrate to the urinary tract.</li>
            <li><h5>Anatomical Factors:</h5>Structural abnormalities or conditions that obstruct or impair the normal flow of urine can increase the risk of UTIs. Examples include kidney stones, vesicoureteral reflux (where urine flows backward from the bladder into the ureters), or anatomical abnormalities present from birth.</li>
            <li><h5>Impaired Immune System:</h5>Conditions or medications that weaken the immune system can make individuals more susceptible to UTIs. This includes conditions like HIV/AIDS, diabetes, or treatments such as chemotherapy that suppress immune function.</li>
            <li><h5>Urinary Catheter Use:</h5>Insertion of a urinary catheter, which is a tube used to drain urine from the bladder, can introduce bacteria directly into the urinary tract, increasing the risk of infection.</li>
            <li><h5>Menopause:</h5>Changes in hormonal levels, particularly decreased estrogen, during menopause can alter the urinary tract environment and increase susceptibility to UTIs in some women.</li>
          </ul>
          <h3>Symptoms</h3>
          <ul>
            <li><h5>Dysuria:</h5>Dysuria refers to pain, discomfort, or a burning sensation during urination. This symptom occurs because the inflamed lining of the urinary tract becomes irritated when urine passes through.</li>
            <li><h5>Frequency:</h5>Individuals with a UTI often feel the need to urinate more frequently than usual. This frequent urge to urinate is caused by irritation and inflammation of the bladder or urethra.</li>
            <li><h5>Urgency:</h5>There is a sudden and intense urge to urinate, which can be difficult to delay. This urgency is a result of the bladder’s irritation and its increased sensitivity to the presence of urine.</li>
            <li><h5>Lower Abdominal Discomfort:</h5>Many people with UTIs experience pain or pressure in the lower abdomen, specifically around the area of the bladder. This discomfort can range from mild to severe and may be accompanied by cramping.</li>
            <li><h5>Cloudy or Foul-Smelling Urine:</h5>Changes in the appearance or odor of urine are common symptoms of a UTI. Urine may appear cloudy due to the presence of bacteria, and it can have a stronger or more unpleasant odor than usual.</li>
            <li><h5>Hematuria:</h5>Hematuria refers to the presence of blood in the urine. This symptom can vary from a few red or pink spots in the urine to urine that appears visibly red or tea-colored. Blood in the urine may indicate that the infection has reached deeper into the urinary tract, such as the kidneys.</li>
            </ul>
          <h3>Investigations and Diagnosis</h3>
          <ul>
            <li><h5>Medical History:</h5>A comprehensive review of the patient’s medical history is conducted, focusing on symptoms related to a UTI such as dysuria (painful urination), frequency, urgency, lower abdominal discomfort, cloudy or foul-smelling urine, and hematuria (blood in urine). Additionally, discussing any risk factors such as recent urinary catheter use, sexual activity, or underlying conditions like diabetes is essential.</li>
            <li><h5>Physical Examination: </h5>A physical examination may reveal signs such as tenderness or pain in the lower abdomen, which can indicate inflammation of the bladder or kidneys. The healthcare provider may also assess for fever, which can suggest a more severe infection involving the kidneys (pyelonephritis).</li>
            <li><h5>Urinalysis:</h5> A urinalysis is a crucial initial test that involves examining a urine sample under a microscope and performing chemical tests to detect the presence of bacteria, white blood cells (indicative of infection), red blood cells (hematuria), and other abnormalities such as protein or nitrites (produced by bacteria like E. coli). This test helps confirm the presence of a UTI and provides information about its severity.</li>
            <li><h5>Urine Culture:</h5>A urine culture is typically performed if the urinalysis indicates a UTI. This test involves placing a urine sample in a culture medium to identify the specific bacteria causing the infection and determine which antibiotics are most effective for treatment. This information is crucial for guiding targeted antibiotic therapy and ensuring effective treatment of the UTI.</li>
          </ul>
          <h3>Complications</h3>
          <ul>
            <li><h5>Kidney Infections (Pyelonephritis):</h5>If a UTI spreads to the kidneys, it can lead to a more serious infection called pyelonephritis. Symptoms of pyelonephritis include fever, chills, nausea, vomiting, and severe back pain. This condition requires prompt medical treatment with antibiotics to prevent further complications.</li>
            <li><h5>Sepsis:</h5>In severe cases, untreated or inadequately treated UTIs, especially those involving the kidneys, can lead to sepsis. Sepsis is a life-threatening condition where the body’s immune response to infection causes widespread inflammation. It can lead to organ failure and requires urgent medical intervention.</li>
          </ul>
          <h3>Risk Factors</h3>
          <ul>
            <li><h5>Female Anatomy:</h5>Women are more prone to UTIs than men due to their shorter urethra, which allows bacteria easier access to the bladder. Additionally, the urethral opening is close to the anus, increasing the risk of bacterial contamination from the gastrointestinal tract.</li>
            <li><h5>Sexual Activity:</h5>Sexual intercourse can introduce bacteria into the urinary tract, increasing the likelihood of developing a UTI. Women who are sexually active or have new sexual partners are at higher risk.</li>
            <li><h5>Menopause:</h5>Changes in hormone levels during menopause can lead to alterations in the urinary tract environment, making women more susceptible to UTIs.</li>
            <li><h5>Urinary Tract Abnormalities:</h5>Structural abnormalities in the urinary tract, such as kidney stones, vesicoureteral reflux (urine flows backward from the bladder into the ureters), or congenital anomalies, can increase the risk of UTIs.</li>
            <li><h5>Conditions Affecting Immune Function:</h5>Conditions that weaken the immune system, such as diabetes, HIV/AIDS, or undergoing chemotherapy, can impair the body’s ability to fight off infections, increasing the risk of UTIs and their complications.</li>
          </ul>
          <h3>Traditional Treatment of UTI</h3>
          <ul>
            <li><h5>Antibiotics:</h5>Antibiotics are the primary treatment for UTIs. The choice of antibiotic depends on the type of bacteria identified through urine culture and sensitivity testing. Antibiotics are typically taken for a prescribed duration (usually 3 to 7 days) to completely eliminate the bacteria causing the infection. It’s important to complete the full course of antibiotics as directed by the healthcare provider, even if symptoms improve before finishing the medication.</li>
            <li><h5>Fluid Intake:</h5>Increasing fluid intake, particularly water, helps to flush out bacteria from the urinary tract and dilute urine, which can help alleviate symptoms and promote recovery. Drinking plenty of water is recommended during and after treatment for UTIs.</li>
            <li><h5>Pain Relief:</h5>Medications such as phenazopyridine (Pyridium) may be used to relieve pain, burning, and discomfort associated with UTI symptoms. This medication helps to numb the urinary tract and reduce symptoms while waiting for antibiotics to take effect. It should be used for a short duration (usually no more than 2 days) due to potential side effects and should not be used as a substitute for antibiotics.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <ul>
            <li><h5>Individualized Remedies:</h5>Homeopathy considers the unique symptoms and characteristics of each individual. A homeopathic practitioner will assess not only the physical symptoms related to the UTI (such as burning pain during urination, frequent urination, or cloudy urine) but also the person’s mental and emotional state, lifestyle factors, and overall health.</li>
            <li><h5>Selection of Remedies:</h5>Several homeopathic remedies may be considered for UTIs, depending on the individual’s symptoms and constitutional type. These remedies are selected based on a detailed assessment by a qualified homeopathic practitioner. The choice of remedy may also consider factors such as the person’s sensitivity to pain, temperature preferences, and aggravating or ameliorating factors.</li>
            <li><h5>Administration:</h5>Homeopathic remedies are typically administered in highly diluted doses (potencies) that stimulate the body’s self-healing mechanisms. Remedies can be given in various forms, including pellets, liquid dilutions, or as topical applications depending on the specific needs of the individual.</li>
            <li><h5>Holistic Approach:</h5> Homeopathy views health as a balance of physical, mental, and emotional aspects. Treatment aims to not only relieve symptoms of UTIs but also support overall health and well-being.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>Urinary tract infections are common bacterial infections affecting the urinary system, primarily caused by bacteria entering the urethra. Prompt diagnosis and treatment with antibiotics are crucial to prevent complications and recurrence. Homeopathic treatments offer alternative options tailored to individual symptoms and may complement conventional therapies in managing UTIs effectively.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Urinary Tract Infection (UTI) Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Urinary Tract Infection (UTI) conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/urology/uti/uti-diet">Diet for Urinary Tract Infection (UTI)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Urinary Tract Infection (UTI)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Urinary Tract Infection (UTI)</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on UTI Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/grrAqEybxz8?si=OsQqbtBq-0K79mEH"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
