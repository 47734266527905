import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const ChloasmaMelasmaDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Chloasma / Melasma Diet Guide</h1>
      <p className="diet-description">A nutrient-rich diet can help manage chloasma and melasma by promoting skin health, reducing pigmentation, and supporting overall skin tone. Below are suggestions for foods to take and avoid when dealing with these skin conditions.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are high in antioxidants, which help protect the skin from oxidative stress and reduce pigmentation. Antioxidants like vitamins C and E can brighten skin tone.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C helps reduce hyperpigmentation and promotes collagen production. Include citrus fruits, bell peppers, and strawberries to brighten skin and reduce dark patches.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in salmon, flaxseeds, and walnuts help reduce skin inflammation and promote an even skin tone. These healthy fats are essential for keeping skin hydrated and supple.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc is important for skin healing and reducing inflammation. Include foods like seeds, nuts, legumes, and whole grains to help reduce melasma-related discoloration.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Hydration is essential for healthy skin. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water throughout the day to maintain skin hydration and elasticity.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamin E-Rich Foods</h3>
          <p className="diet-text">Vitamin E is an important antioxidant for protecting skin cells and preventing further pigmentation. Almonds, sunflower seeds, and spinach are excellent sources of vitamin E.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can trigger inflammation and insulin resistance, both of which can worsen melasma. Avoid sugary snacks, sodas, and processed foods to support skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs like white bread and pasta can cause insulin spikes, which may trigger hormonal imbalances linked to melasma. Opt for whole grains instead to maintain balanced blood sugar levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and unhealthy fats can clog skin pores and increase inflammation, which can worsen pigmentation. Choose healthier fats from avocados, olive oil, and nuts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in additives and preservatives that can trigger inflammation and worsen melasma. Stick to whole, natural foods to support healthier skin.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol dehydrates the skin and can increase inflammation, both of which may worsen chloasma and melasma symptoms. Limiting alcohol intake can improve overall skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">Excess caffeine can dehydrate the body and lead to dull, uneven skin tone. Moderate caffeine intake and prioritize hydration to support brighter, healthier skin.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a vitamin C-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support skin hydration and brightness.</li>
        </ul>
      </section>
    </div>
  );
};

export default ChloasmaMelasmaDiet;
