import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const AllergicRhinitisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Allergic Rhinitis Diet Guide</h1>
      <p className="diet-description">A balanced diet can help reduce inflammation and manage the symptoms of allergic rhinitis. Below are suggestions for foods to take and avoid to minimize allergic reactions and improve overall health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, and fatty fish like salmon are rich in antioxidants and Omega-3s, which help reduce inflammation and soothe allergy symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C is a natural antihistamine that can help reduce allergy symptoms. Include citrus fruits, bell peppers, and broccoli to support your immune system.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Quercetin-Rich Foods</h3>
          <p className="diet-text">Quercetin, found in apples, onions, and broccoli, helps stabilize mast cells and prevents the release of histamines, reducing allergic reactions.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods help balance the gut microbiome, which can support the immune system and reduce allergic reactions.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Staying hydrated helps thin mucus and ease congestion. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Local Honey</h3>
          <p className="diet-text">Some people find that consuming local honey can help desensitize them to local pollen, reducing the severity of allergic rhinitis over time.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Dairy Products (for some)</h3>
          <p className="diet-text">For some individuals, dairy products can increase mucus production and worsen allergy symptoms. If you find dairy triggers your symptoms, consider reducing your intake.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can weaken the immune system and increase inflammation, potentially worsening allergy symptoms. Avoid sugary snacks, sodas, and processed foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain additives and preservatives that can trigger allergic reactions. Stick to whole, unprocessed foods for better immune support.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol, particularly red wine and beer, can increase histamine levels and worsen allergic rhinitis symptoms. Limiting alcohol intake may help reduce flare-ups.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can trigger or worsen nasal congestion in some individuals with allergic rhinitis. Reduce your intake of spicy dishes if they trigger symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">Excess caffeine can dehydrate the body and exacerbate allergy symptoms. Keep caffeine intake moderate and focus on staying hydrated to relieve congestion.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed spinach.</li>
          <li className="diet-list-item"><strong>Snack:</strong> An apple or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked chicken with brown rice and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support hydration and reduce allergy symptoms.</li>
        </ul>
      </section>
    </div>
  );
};

export default AllergicRhinitisDiet;
