import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const BrainTumorDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Brain Tumor Diet Guide</h1>
      <p className="diet-description">A nutrient-rich diet can support brain health and overall well-being during brain tumor treatment and recovery. Below are suggestions for foods to take and avoid when managing a brain tumor.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are high in antioxidants, which help fight oxidative stress and protect brain cells. These foods can support recovery and overall brain health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish like salmon, flaxseeds, and walnuts help reduce inflammation and support brain function, which is important for recovery from brain tumors.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Protein-Rich Foods</h3>
          <p className="diet-text">Protein helps support muscle maintenance and repair. Include lean meats, eggs, dairy, legumes, and plant-based proteins to ensure adequate protein intake during recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Hydrating Foods</h3>
          <p className="diet-text">Proper hydration is essential for overall health, especially during treatment. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water to stay hydrated.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D supports immune function and bone health. Include fortified dairy products, mushrooms, and fatty fish to boost Vitamin D levels during recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Fiber-Rich Foods</h3>
          <p className="diet-text">Fiber helps regulate digestion, which may be affected by treatment. Whole grains, fruits, and vegetables are excellent sources of fiber to support digestive health.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed and Sugary Foods</h3>
          <p className="diet-text">Processed foods and those high in sugar can contribute to inflammation and may interfere with treatment. Avoid sugary snacks, sodas, and processed meals to support better health outcomes.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Red and Processed Meats</h3>
          <p className="diet-text">Red and processed meats contain compounds that can promote inflammation. Opt for lean protein sources like chicken, turkey, or plant-based proteins to reduce inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Saturated and Trans Fats</h3>
          <p className="diet-text">Unhealthy fats found in fried and processed foods can increase inflammation and may negatively impact brain health. Choose healthier fats like olive oil, avocados, and nuts instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can interfere with treatment and impair brain function. Avoid alcohol during treatment and recovery to support brain health and overall well-being.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Artificial Sweeteners</h3>
          <p className="diet-text">Artificial sweeteners like aspartame have been linked to neurological issues. Avoid diet sodas and foods containing artificial sweeteners to reduce any risk of negative brain health effects.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. High-Sodium Foods</h3>
          <p className="diet-text">Foods high in sodium can increase blood pressure and may interfere with treatment. Limit processed snacks and canned foods that are high in salt.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a protein-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked chicken with brown rice and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support hydration and reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default BrainTumorDiet;
