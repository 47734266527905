import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/hh4.webp')}alt="Urology Hero" />
        <h1>Hypothyroidism</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Hypothyroidism</h2>
          <img src={require('../images/hormones5.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <ul>
            <li>Homeopathy considers hyperthyroidism as a disturbance in the body’s vital force, aiming to restore balance.</li>
            <li>Treatment focuses on individualized remedies tailored to the person’s specific symptoms and constitution.</li>
            <li>Remedies are chosen based on the principle of “like cures like,” addressing both physical and emotional symptoms.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li>Autoimmune conditions like Graves’ disease are common causes.</li>
            <li>Thyroid nodules or inflammation (thyroiditis) can also lead to hyperthyroidism.</li>
            <li>Emotional stress, genetic predisposition, and certain medications may contribute to its development.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li>Diagnostic tests include blood tests for thyroid hormones (T3, T4), thyroid-stimulating hormone (TSH), and possibly imaging studies (ultrasound, thyroid scan).</li>
            <li>Physical examination to assess symptoms like weight loss, palpitations, and nervousness.</li>
            <li>Homeopaths may also consider subtle signs such as thermal sensitivity and emotional state in their assessment.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li>Complications can include heart problems such as arrhythmias.</li>
            <li>Increased risk of osteoporosis due to elevated bone turnover.</li>
            <li>Thyroid storm, a severe and potentially life-threatening complication of untreated hyperthyroidism.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li>Conventional treatments include medications to reduce thyroid hormone production (antithyroid drugs).</li>
            <li>Radioactive iodine therapy to destroy thyroid tissue and surgical removal of the thyroid gland in severe cases.</li>
            <li>Monitoring of thyroid function and symptoms to adjust treatment as necessary.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <ul>
            <li>Individualized remedies such as Iodum, Lycopodium, Natrum muriaticum, and Thuja may be used based on specific symptoms.</li>
            <li>Supportive remedies aim to address underlying stress, emotional factors, and overall well-being.</li>
            <li>Emphasis on dietary adjustments and lifestyle modifications to support thyroid function.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathy offers a holistic approach to managing hyperthyroidism, complementing conventional treatments.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Hypothyroidism Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Hypothyroidism conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/hormonal-diseases/hypothyroidism/hypothyroidism-diet">Diet for Hypothyroidism</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Hypothyroidism</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Hypothyroidism Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Hypothyroidism Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/_KhFpHtkYjk?si=3i8T94Yys4uchVPC"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
