import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const CRFDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Chronic Renal Failure (CRF) Diet Guide</h1>
      <p className="diet-description">A well-planned diet can help manage chronic renal failure by supporting kidney function and reducing the workload on the kidneys.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">1. Control Protein Intake</h2>
        <p className="diet-text">Since excess protein can burden the kidneys, it's important to control your protein intake. Focus on high-quality protein sources such as lean meat, fish, eggs, and dairy in moderation, based on your doctor’s recommendation.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">2. Limit Sodium</h2>
        <p className="diet-text">Sodium control is crucial in managing fluid retention and blood pressure. Avoid high-sodium foods like processed snacks, canned goods, and fast food. Use herbs and spices for seasoning instead of salt.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">3. Manage Potassium Levels</h2>
        <p className="diet-text">Depending on your kidney function, you may need to limit foods high in potassium like bananas, oranges, potatoes, and spinach. Consult your healthcare provider to adjust your potassium intake.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">4. Limit Phosphorus</h2>
        <p className="diet-text">High phosphorus levels can lead to bone problems in patients with CRF. Avoid phosphorus-rich foods like dairy products, nuts, seeds, and colas. Opt for low-phosphorus alternatives such as almond milk or non-dairy creamers.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">5. Stay Hydrated but Monitor Fluid Intake</h2>
        <p className="diet-text">While staying hydrated is important, some CRF patients need to limit fluid intake to avoid overloading the kidneys. Drink water in small amounts throughout the day as per your doctor's advice.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">6. Focus on Calcium-Rich Foods</h2>
        <p className="diet-text">Calcium intake is essential for bone health, especially in CRF patients. However, this should be managed carefully due to phosphorus restrictions. Consult your doctor to choose safe calcium-rich foods.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">7. Balance Carbohydrates and Fats</h2>
        <p className="diet-text">Patients with CRF need to balance their calorie intake, ensuring they get enough energy without overloading the kidneys. Include healthy fats such as olive oil, and choose complex carbohydrates like whole grains and vegetables.</p>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Scrambled eggs with a slice of whole wheat toast and a small bowl of fresh berries.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken salad with olive oil dressing and low-potassium vegetables such as cucumbers and bell peppers.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A small serving of low-sodium cottage cheese or an apple with peanut butter (limited potassium).</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and steamed green beans, seasoned with herbs instead of salt.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Water intake should be monitored and consumed in small amounts throughout the day as per medical advice.</li>
        </ul>
      </section>
    </div>
  );
};

export default CRFDiet;
