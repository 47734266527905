import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const PersonalityDisorderDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Personality Disorder Diet Guide</h1>
      <p className="diet-description">A diet rich in brain-healthy nutrients can help manage symptoms of personality disorders by promoting neurotransmitter balance, reducing inflammation, and supporting emotional well-being. Here are dietary suggestions for foods to take and avoid to help support mental and emotional health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, flaxseeds, and walnuts, play an essential role in brain health and can help stabilize mood and support cognitive function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Complex Carbohydrates</h3>
          <p className="diet-text">Complex carbs, like those found in whole grains and vegetables, provide steady energy and support serotonin production, which is linked to mood regulation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Lean Protein</h3>
          <p className="diet-text">Protein-rich foods such as chicken, eggs, beans, and tofu provide amino acids that are precursors to neurotransmitters like dopamine and serotonin, essential for mood regulation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, dark chocolate, and leafy greens contain antioxidants that protect brain cells from oxidative stress and inflammation, supporting mental health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Fermented Foods and Probiotics</h3>
          <p className="diet-text">Probiotics in yogurt, kefir, and fermented vegetables support gut health, which is closely linked to brain health and mood regulation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. B Vitamins and Magnesium</h3>
          <p className="diet-text">Leafy greens, nuts, seeds, and whole grains contain B vitamins and magnesium, which are important for reducing anxiety, stress, and mood swings.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars and Processed Foods</h3>
          <p className="diet-text">Refined sugars and processed foods can lead to blood sugar spikes, mood swings, and inflammation. Opt for whole, nutrient-dense foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Trans Fats</h3>
          <p className="diet-text">Trans fats found in fried and packaged foods can increase inflammation and negatively affect brain function and mood stability.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Excessive Caffeine</h3>
          <p className="diet-text">Too much caffeine can increase anxiety and disrupt sleep, which can contribute to mood swings. Keep caffeine intake moderate.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can disrupt neurotransmitter balance and sleep patterns, both of which can exacerbate symptoms of personality disorders.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Artificial Additives and Preservatives</h3>
          <p className="diet-text">Artificial additives found in processed foods can have negative effects on mental health. Focus on whole foods for better mental clarity.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, berries, flaxseeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or salmon with quinoa and a mixed greens salad.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a handful of nuts or dark chocolate pieces.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and a side of leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water, and include herbal teas like chamomile to promote relaxation and reduce stress.</li>
        </ul>
      </section>
    </div>
  );
};

export default PersonalityDisorderDiet;
