import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/pedia2.webp')}alt="Urology Hero" />
        <h1>Behaviour disorder’s Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Behaviour disorder’s</h2>
          <img src={require('../images/child2.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy treats behavioral disorders by addressing the individual as a whole—considering physical, emotional, and environmental influences. The goal is to identify and treat the root causes, rather than just alleviating symptoms. This involves a detailed understanding of the patient’s personality, behavioral patterns, and life experiences. Remedies are selected based on the totality of symptoms, following the homeopathic principle of “like cures like.”
        </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Genetic Factors:</h5> A family history of behavioral or mental health issues can predispose individuals to similar conditions.</li>
            <li><h5>Neurological Issues:</h5> Imbalances in brain chemistry or structural abnormalities in the brain.</li>
            <li><h5>Environmental Factors: </h5> Exposure to traumatic events, abuse, neglect, or a chaotic home environment.</li>
            <li><h5>Developmental Factors:</h5> Complications during prenatal development, birth, or early childhood development.</li>
            <li><h5>Social Factors:</h5> Peer pressure, bullying, social isolation, or unstable relationships.</li>
            <li><h5>Psychological Factors:</h5> Underlying mental health issues such as anxiety, depression, or low self-esteem.</li>
          </ul>
          <h3>Investigations</h3>
          <p>Diagnosing behavioral disorders involves a multi-faceted approach:</p>
          <ul>
            <li><h5>Comprehensive Medical History:</h5> Detailed exploration of the patient’s development, family history, past traumas, and significant life events. </li>
            <li><h5>Behavioral Assessments:</h5> Standardized questionnaires and direct observation to identify specific behavior patterns.</li>
            <li><h5>Psychological Evaluations:</h5> Conducted by psychologists to assess cognitive and emotional functioning.</li>
            <li><h5>Neurological Tests:</h5> Brain imaging and other neurological tests to rule out underlying neurological conditions.</li>
            <li><h5>Laboratory Tests:</h5> Blood tests and other diagnostic measures to identify biochemical imbalances or deficiencies.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <p>Behavioral disorders can lead to various complications, such as:</p>
          <ul>
            <li><h5>Academic Difficulties:</h5> Poor school performance, difficulty maintaining employment, or frequent job changes.</li>
            <li><h5>Social Isolation:</h5> Challenges in forming and maintaining relationships, leading to loneliness and social withdrawal.</li>
            <li><h5>Legal Issues:</h5> Increased likelihood of engaging in risky, antisocial, or illegal behaviors.</li>
            <li><h5>Substance Abuse:</h5> Higher risk of alcohol or drug dependence as a coping mechanism.</li>
            <li><h5>Mental Health Issues:</h5> Greater susceptibility to anxiety, depression, and other mental health conditions. Risk factors include:</li>
            <li><h5>Family History: </h5> Genetic predisposition to behavioral or mental health disorders.</li>
            <li><h5>Trauma:</h5> History of physical, emotional, or sexual abuse.</li>
            <li><h5>Chronic Stress:</h5> Prolonged exposure to stressful environments, such as unstable home life or bullying.</li>
            <li><h5>Inconsistent Parenting: </h5> Lack of structure, inconsistent discipline, or lack of parental involvement and support.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>Conventional treatment for behavioral disorders typically involves:</p>
          <ul>
            <li><h5>Behavioral Therapy:</h5> Techniques like cognitive-behavioral therapy (CBT) to modify negative thought patterns and behaviors.</li>
            <li><h5>Medication:</h5> Use of antidepressants, antipsychotics, or stimulants to manage symptoms, depending on the specific disorder.</li>
            <li><h5>Counseling:</h5> Individual, group, or family therapy to address underlying issues and improve coping skills.</li>
            <li><h5>Educational Support: </h5>Implementation of special education programs and individualized education plans (IEPs) for children to support their learning needs.</li>
            <li><h5>Lifestyle Modifications:</h5> Encouraging regular physical activity, a healthy diet, and sufficient sleep to promote overall well-being.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <p>Homeopathy offers individualized remedies based on the patient’s unique symptoms and constitution:</p>
          <ul>
            <li><h5>Stramonium:</h5> For intense fears, violent behavior, and nightmares.</li>
            <li><h5>Hyoscyamus:</h5> For restlessness, jealousy, inappropriate behavior, and extreme talkativeness.</li>
            <li><h5>Tarentula Hispanica:</h5> For hyperactivity, impulsiveness, and constant desire for movement and activity.</li>
            <li><h5>Tuberculinum:</h5> For chronic restlessness, dissatisfaction, and a desire to travel or escape from routine.</li> 
            <li><h5>Natrum Muriaticum:</h5> For introverted behavior, sensitivity to criticism, and emotional suppression.</li>
            <li><h5>Ignatia:</h5> For mood swings, emotional sensitivity, and symptoms resulting from grief or disappointment.</li>
            </ul>
            <p>Homeopathic remedies are prescribed in minute doses and tailored to the individual’s specific symptoms and overall health profile. A detailed consultation with a qualified homeopath is essential to determine the most appropriate remedy and dosage.</p>
          <h3>Conclusion</h3>
          <p>Our experience has shown that a holistic approach, combining homeopathic treatment, lifestyle modifications, and supportive therapies, can lead to significant improvements in individuals with behavioral disorders. Patients often report enhanced emotional regulation, improved social interactions, and an overall sense of well-being. Homeopathy, with its focus on individualized care, offers a gentle yet effective solution for managing behavioral disorders. Consulting with a qualified homeopath ensures that the treatment plan is personalized to meet the unique needs of each patient, promoting long-term health and balance.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Behaviour disorder’s Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Behaviour disorder’s conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/pediatric/behaviour-disorder/behaviour-disorder-diet">Diet for Behaviour disorder’s</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Behaviour disorder’s</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Behaviour disorder’s</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Behaviour disorder’s Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/-6n14Jo4VUA?si=E4r93NygQAylMy40"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
