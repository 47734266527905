import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const ContactDermatitisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Contact Dermatitis Diet Guide</h1>
      <p className="diet-description">A balanced diet rich in anti-inflammatory and skin-supporting nutrients can help manage contact dermatitis by reducing skin irritation, promoting healing, and improving the immune response. Below are suggestions for foods to take and avoid when dealing with contact dermatitis.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, and fatty fish like salmon are rich in Omega-3 fatty acids and antioxidants, which help reduce inflammation and soothe skin irritation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods like sauerkraut support gut health, which is closely linked to immune function and skin inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc is important for skin healing and immune support. Include foods like seeds, nuts, legumes, and whole grains to promote recovery and reduce irritation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C helps reduce inflammation and promotes skin healing. Citrus fruits, bell peppers, strawberries, and broccoli are excellent sources of this vitamin for improving skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Keeping the skin hydrated is essential for managing contact dermatitis. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water to keep the skin moisturized.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamin E-Rich Foods</h3>
          <p className="diet-text">Vitamin E is an antioxidant that supports skin repair and reduces dryness. Almonds, sunflower seeds, and spinach are great sources of Vitamin E to promote healthy skin.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can increase inflammation and weaken the immune system, making contact dermatitis worse. Avoid sugary snacks, sodas, and processed foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in unhealthy fats, additives, and preservatives that can trigger inflammation and exacerbate skin conditions. Stick to whole, unprocessed foods for better skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and unhealthy fats can cause inflammation, which can worsen dermatitis symptoms. Choose healthy fats from avocados, olive oil, and nuts instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can dehydrate the skin and increase inflammation, making it harder for the skin to heal. Limiting alcohol intake can promote faster recovery and reduce flare-ups.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can sometimes trigger skin irritation or worsen dermatitis. Reducing or eliminating spicy dishes from your diet may help control symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Excessive Caffeine</h3>
          <p className="diet-text">Excess caffeine can dehydrate the skin and increase stress, which may lead to flare-ups. Moderate caffeine intake and focus on staying hydrated for better skin health.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and mixed greens.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support skin hydration and reduce irritation.</li>
        </ul>
      </section>
    </div>
  );
};

export default ContactDermatitisDiet;
