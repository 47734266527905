import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const DevelopmentalDisorderDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Developmental Disorder Diet Guide</h1>
      <p className="diet-description">A balanced diet rich in brain-boosting nutrients can help manage symptoms associated with developmental disorders. Here are dietary suggestions for foods to take and avoid, supporting cognitive function, immune health, and overall physical development.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, flaxseeds, and walnuts, are essential for brain health and can help support cognitive development and emotional stability.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Protein-Rich Foods</h3>
          <p className="diet-text">Protein is vital for growth and neurotransmitter function. Include foods like eggs, chicken, beans, and tofu to support brain and muscle development.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Whole Grains and Complex Carbohydrates</h3>
          <p className="diet-text">Whole grains, oats, and quinoa provide steady energy and help improve concentration by stabilizing blood sugar levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help protect brain cells from oxidative stress, supporting mental clarity and growth.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">Gut health is linked to overall well-being and brain function. Include foods like yogurt, kefir, and sauerkraut to support a healthy gut microbiome.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamins and Minerals</h3>
          <p className="diet-text">Foods rich in iron, zinc, magnesium, and vitamin B are essential for brain function, growth, and behavioral regulation. Include leafy greens, seeds, lean meats, and whole grains.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars</h3>
          <p className="diet-text">Refined sugars can lead to mood swings and energy crashes, which may impact behavior and concentration. Limit sweets, sugary drinks, and processed snacks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Artificial Additives</h3>
          <p className="diet-text">Artificial colors, flavors, and preservatives have been linked to hyperactivity in some children. Choose natural, fresh foods for better nutrient intake.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats and Processed Foods</h3>
          <p className="diet-text">Trans fats, found in fried and processed foods, may increase inflammation and have been linked to mood issues. Opt for healthy fats instead, like those in avocados and olive oil.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Dairy Products (if sensitive)</h3>
          <p className="diet-text">Some individuals may have sensitivities to casein in dairy, which could lead to digestive discomfort or behavioral issues. Consider dairy alternatives if needed.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeine</h3>
          <p className="diet-text">Caffeine can interfere with sleep and increase hyperactivity or anxiety, especially in children. It’s best to avoid caffeinated drinks entirely.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, banana, berries, and chia seeds, blended with almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu with quinoa and steamed vegetables.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a handful of nuts or an apple with peanut butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with brown rice and lean protein, such as fish or beans.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include calming herbal teas like chamomile for relaxation.</li>
        </ul>
      </section>
    </div>
  );
};

export default DevelopmentalDisorderDiet;
