import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const ReactiveArthritisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Reactive Arthritis (ReA) Diet Guide</h1>
      <p className="diet-description">
        Reactive arthritis (ReA) can be managed with an anti-inflammatory diet that helps reduce joint pain and inflammation. Below are dietary suggestions for foods to include and avoid to support immune health and minimize ReA symptoms.
      </p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">
            Omega-3s, found in fatty fish like salmon, sardines, and flaxseeds, have powerful anti-inflammatory properties that may help reduce joint pain and inflammation.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Probiotic-Rich Foods</h3>
          <p className="diet-text">
            Probiotics, found in yogurt, kefir, and fermented foods, support gut health, which can positively impact the immune system and help reduce inflammation in reactive arthritis.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Antioxidant-Rich Fruits and Vegetables</h3>
          <p className="diet-text">
            Berries, leafy greens, bell peppers, and citrus fruits are high in antioxidants, which help reduce oxidative stress and inflammation.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Whole Grains</h3>
          <p className="diet-text">
            Whole grains like oats, quinoa, and brown rice provide fiber and nutrients without the inflammatory effects associated with refined grains.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Vitamin D-Rich Foods</h3>
          <p className="diet-text">
            Vitamin D is important for immune health. Include fortified dairy, egg yolks, and fatty fish to maintain adequate vitamin D levels, supporting joint and bone health.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Ginger and Turmeric</h3>
          <p className="diet-text">
            Both ginger and turmeric have natural anti-inflammatory compounds. Use these spices in cooking to help reduce joint inflammation.
          </p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed Foods</h3>
          <p className="diet-text">
            Processed foods, often high in trans fats, additives, and preservatives, can increase inflammation. Choose whole, unprocessed foods for better health outcomes.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Sugars</h3>
          <p className="diet-text">
            Sugary snacks and drinks can cause blood sugar spikes and contribute to inflammation. Opt for natural sweeteners and limit sugary foods.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Red Meat</h3>
          <p className="diet-text">
            Red meat can be high in saturated fats, which may worsen inflammation. Choose lean proteins like chicken, fish, or plant-based options.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Fried and Fatty Foods</h3>
          <p className="diet-text">
            Fried foods and foods high in unhealthy fats can increase inflammation and should be limited. Opt for healthier fats like olive oil and avocado.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">
            Alcohol can interfere with the immune system and may increase inflammation in some people with reactive arthritis. Limit or avoid alcohol consumption.
          </p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, berries, and a tablespoon of ground flaxseeds.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Quinoa salad with mixed greens, grilled chicken, bell peppers, and a dressing made with olive oil and lemon juice.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A cup of probiotic-rich yogurt or a handful of almonds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Grilled salmon with steamed broccoli and sweet potatoes.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas with ginger or turmeric for added anti-inflammatory benefits.</li>
        </ul>
      </section>
    </div>
  );
};

export default ReactiveArthritisDiet;
