import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const KeloidDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Keloid Diet Guide</h1>
      <p className="diet-description">A nutrient-rich diet can help manage keloids by supporting skin healing, reducing inflammation, and promoting collagen balance. Below are suggestions for foods to take and avoid when dealing with keloids.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, and fatty fish like salmon are rich in Omega-3 fatty acids and antioxidants, which help reduce inflammation and promote skin healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C promotes collagen synthesis and supports wound healing. Citrus fruits, bell peppers, strawberries, and broccoli are excellent sources of Vitamin C.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc plays an essential role in skin healing and reducing scar tissue formation. Include foods like seeds, nuts, legumes, and whole grains to support the healing process.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Vitamin E-Rich Foods</h3>
          <p className="diet-text">Vitamin E is a powerful antioxidant that helps protect skin cells and improve elasticity. Almonds, sunflower seeds, and spinach are great sources of Vitamin E for promoting skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Proper hydration is key to maintaining healthy skin. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water throughout the day.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in foods like flaxseeds, walnuts, and fatty fish help reduce inflammation and prevent excessive scar formation.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can increase inflammation and interfere with collagen production, which may worsen keloid formation. Avoid sugary snacks, sodas, and processed foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs like white bread, pasta, and baked goods can spike blood sugar and inflammation, leading to poor skin healing. Opt for whole grains to stabilize blood sugar.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and unhealthy fats can block nutrient absorption and increase inflammation, making it harder for scars to heal. Choose healthier fats from avocados, olive oil, and nuts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">Processed foods are often lacking in essential nutrients and can increase oxidative stress, which may hinder skin healing. Stick to whole, nutrient-dense foods for better scar management.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol can dehydrate the skin and impair healing, making it harder for scars to fade. Limiting alcohol intake can support skin repair and reduce inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">While moderate caffeine intake is generally fine, excessive amounts can dehydrate the skin and interfere with nutrient absorption, slowing down the healing process. Keep caffeine intake balanced.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a zinc-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support skin hydration and healing.</li>
        </ul>
      </section>
    </div>
  );
};

export default KeloidDiet;
