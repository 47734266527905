import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh4.webp')}alt="Urology Hero" />
        <h1>Recurrent aphthous</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Recurrent aphthous</h2>
          <img src={require('../images/gastric4.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <ul>
            <li>Homeopathy views recurrent aphthous stomatitis as a manifestation of internal imbalance, often related to immune system dysfunction or digestive disturbances.</li>
            <li>Treatment aims to reduce inflammation, promote healing of oral mucosa, and address underlying causes through individualized remedies.</li>
            <li>Remedies are selected based on the specific characteristics of the aphthous ulcers, accompanying symptoms, and overall health of the person.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li>Causes of recurrent aphthous stomatitis can include immune system disorders, nutritional deficiencies (like vitamin B12, iron, or folic acid), hormonal changes, and stress.</li>
            <li>Allergic reactions, gastrointestinal disorders (such as celiac disease or inflammatory bowel disease), and genetic predisposition may also play a role.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li>Diagnostic evaluation typically involves a clinical examination of the oral cavity to identify the presence, size, and location of aphthous ulcers.</li>
            <li>Assessment of medical history, dietary habits, and lifestyle factors helps in understanding triggers and underlying conditions contributing to recurrent aphthous stomatitis.</li>
            <li>Homeopaths may also consider emotional stressors and overall vitality of the individual in their assessment.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li>Complications of recurrent aphthous stomatitis can include pain, discomfort during eating or speaking, and impaired quality of life.</li>
            <li>Risk factors include family history of canker sores, certain medical conditions affecting the immune system or gastrointestinal tract, and stress.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li>Conventional treatments include topical medications (like corticosteroids or numbing agents) to reduce pain and inflammation.</li>
            <li>Oral rinses or mouthwashes containing antimicrobial agents or corticosteroids may be prescribed for severe cases.</li>
            <li>Addressing underlying conditions such as nutritional deficiencies or immune system disorders is essential for long-term management.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <ul>
            <li>Individualized remedies such as Borax, Mercurius solubilis, Nitric acid, or Arsenicum album may be recommended based on symptomatology.</li>
            <li>Remedies aim to reduce inflammation, promote healing of aphthous ulcers, and address underlying immune or digestive disturbances.</li>
            <li>Emphasis on dietary recommendations (balanced nutrition, avoidance of trigger foods), stress reduction techniques, and lifestyle modifications to support oral health and overall well-being.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathy offers a holistic approach to managing recurrent aphthous stomatitis, focusing on reducing inflammation, promoting healing of oral mucosa, and addressing underlying causes. It can complement conventional treatments but should be supervised by a qualified homeopathic practitioner. Personalized treatment plans, including dietary and lifestyle recommendations, are crucial for preventing recurrence and improving quality of life for individuals with recurrent aphthous stomatitis. Regular monitoring and adjustments in treatment are important to address the specific needs and symptoms, promoting long-term relief and oral health.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Recurrent aphthous</h3>
            <p>We offer natural, safe, and effective treatment for Recurrent aphthous conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/recurrent-aphthous/recurrent-aphthous-diet">Diet for Recurrent aphthous</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Recurrent aphthous</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Recurrent aphthous</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Recurrent Aphthous Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/yx7CQI8VujI?si=XVEdo0Y4YGESyAfu"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
