import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const PolyarthritisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Polyarthritis Diet Guide</h1>
      <p className="diet-description">
        Managing polyarthritis can be supported by an anti-inflammatory diet, which helps reduce joint pain and stiffness. Below are dietary suggestions for foods to include and avoid to support joint health and minimize polyarthritis symptoms.
      </p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">
            Omega-3s found in salmon, sardines, flaxseeds, and chia seeds have powerful anti-inflammatory effects that may help reduce joint pain and stiffness.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Leafy Green Vegetables</h3>
          <p className="diet-text">
            Leafy greens like spinach, kale, and Swiss chard are high in antioxidants, which can reduce inflammation and support joint health.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Whole Grains</h3>
          <p className="diet-text">
            Whole grains like oats, quinoa, and brown rice can help reduce inflammation, in contrast to refined grains that may promote it.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Vitamin C-Rich Foods</h3>
          <p className="diet-text">
            Foods rich in vitamin C, such as oranges, bell peppers, and strawberries, support collagen formation, which is essential for joint health.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Nuts and Seeds</h3>
          <p className="diet-text">
            Almonds, walnuts, chia seeds, and flaxseeds contain healthy fats and antioxidants that reduce inflammation and promote joint health.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Extra Virgin Olive Oil</h3>
          <p className="diet-text">
            Olive oil contains anti-inflammatory compounds like oleocanthal that may help reduce joint pain. Use it as a salad dressing or cooking oil.
          </p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Carbohydrates</h3>
          <p className="diet-text">
            Foods like white bread, pastries, and sugary snacks can increase blood sugar and inflammation. Choose whole grains instead.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Meats</h3>
          <p className="diet-text">
            Processed meats like sausages, bacon, and deli meats contain high levels of saturated fats and additives that can increase inflammation.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried Foods</h3>
          <p className="diet-text">
            Fried foods often contain trans fats, which can worsen inflammation. Opt for baked, steamed, or grilled options instead.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Sugary Beverages</h3>
          <p className="diet-text">
            Sugary drinks like soda and sweetened juices can promote inflammation and increase joint pain. Stick to water, herbal teas, or unsweetened drinks.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Red Meat</h3>
          <p className="diet-text">
            Red meat, especially fatty cuts, can contribute to inflammation. Replace it with lean proteins like fish, chicken, or plant-based options.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Alcohol</h3>
          <p className="diet-text">
            Excessive alcohol intake can increase inflammation and may interact with medications used for polyarthritis. Limit or avoid alcohol consumption.
          </p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Quinoa salad with mixed greens, grilled chicken, cherry tomatoes, and a drizzle of olive oil.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or an apple with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with steamed vegetables (like broccoli and carrots) and brown rice.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day and include herbal teas to help reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default PolyarthritisDiet;
