import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const HormonalImbalanceDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Hormonal Imbalance Diet Guide</h1>
      <p className="diet-description">A balanced diet can help regulate hormones, reduce symptoms of hormonal imbalance, and support overall well-being. Below are suggestions for foods to take and avoid to restore hormonal balance.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Healthy Fats</h3>
          <p className="diet-text">Healthy fats from sources like avocados, olive oil, flaxseeds, and walnuts help produce and regulate hormones. Omega-3 fatty acids, in particular, reduce inflammation and support hormonal health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Fiber-Rich Foods</h3>
          <p className="diet-text">Whole grains, fruits, and vegetables are high in fiber, which helps regulate estrogen levels and supports digestive health. Fiber can also help prevent insulin spikes, which are linked to hormonal imbalances.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Cruciferous Vegetables</h3>
          <p className="diet-text">Cruciferous vegetables like broccoli, kale, and Brussels sprouts contain compounds that help detoxify the body and balance estrogen levels, reducing symptoms of hormonal imbalances.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods help maintain gut health, which is essential for hormone regulation. A healthy gut helps metabolize and eliminate excess hormones.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium supports the adrenal glands, which produce stress hormones. Foods like spinach, almonds, and bananas help reduce stress, which can lead to hormonal imbalances.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile, ginger, and peppermint can reduce inflammation, support digestion, and soothe stress, which plays a significant role in balancing hormones.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs, such as white bread, pastries, and sugary cereals, can cause insulin spikes, which lead to hormonal imbalances. Opt for complex carbohydrates like brown rice, oats, and quinoa instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excessive sugar intake can cause insulin resistance, which leads to hormonal imbalances. Reducing sugar from sweets, sodas, and processed foods can help regulate hormones.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain additives, unhealthy fats, and sugars that can disrupt hormones. Stick to fresh, whole foods for better hormonal health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Caffeine (in excess)</h3>
          <p className="diet-text">Excessive caffeine intake can raise cortisol levels, increasing stress and worsening hormonal imbalances. Reducing coffee, energy drinks, and caffeinated teas can help maintain better hormone regulation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol consumption can affect liver function, disrupting the body's ability to metabolize hormones and leading to imbalances. Limiting alcohol intake supports better hormone regulation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Trans Fats</h3>
          <p className="diet-text">Trans fats, found in fried foods, baked goods, and margarine, increase inflammation and disrupt hormone production. Replace unhealthy fats with healthier options like olive oil and avocado.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal topped with flaxseeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu salad with spinach, avocado, and a lemon-olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a smoothie with spinach, ginger, and turmeric.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and roasted vegetables (like broccoli and sweet potatoes).</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day and include herbal teas like chamomile or ginger to reduce inflammation and stress.</li>
        </ul>
      </section>
    </div>
  );
};

export default HormonalImbalanceDiet;
