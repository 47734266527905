import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const IVSDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Interventricular Septal (IVS) Disorder Diet Guide</h1>
      <p className="diet-description">A heart-healthy diet is essential for managing IVS disorders by promoting cardiovascular health, reducing inflammation, and maintaining a healthy weight. Below are suggestions for foods to take and avoid for better heart health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Foods rich in Omega-3 fatty acids, such as salmon, mackerel, chia seeds, and walnuts, are beneficial for heart health. They help reduce inflammation, lower blood pressure, and support cardiovascular function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Fiber-Rich Foods</h3>
          <p className="diet-text">Whole grains, fruits, vegetables, and legumes are rich in dietary fiber, which helps lower cholesterol levels, regulate blood pressure, and maintain healthy blood vessels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Antioxidants in berries, leafy greens, and nuts protect the heart by reducing oxidative stress, which contributes to cardiovascular disease. These foods also support overall health and well-being.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Lean Proteins</h3>
          <p className="diet-text">Opt for lean protein sources like chicken, turkey, tofu, and legumes, which provide necessary nutrients without the unhealthy fats that can contribute to heart problems.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Potassium-Rich Foods</h3>
          <p className="diet-text">Potassium helps regulate blood pressure and heart function. Foods like bananas, sweet potatoes, spinach, and avocados are excellent sources of potassium for heart health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Healthy Fats</h3>
          <p className="diet-text">Incorporate healthy fats from sources like olive oil, avocados, and flaxseeds. These fats help lower bad cholesterol and support heart function without contributing to artery-clogging plaque.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Trans Fats</h3>
          <p className="diet-text">Avoid trans fats, found in fried foods, baked goods, and processed snacks. These fats increase bad cholesterol and raise the risk of heart disease.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Saturated Fats</h3>
          <p className="diet-text">Limit saturated fats from red meat, full-fat dairy, and butter, as they can raise cholesterol levels and contribute to cardiovascular issues. Opt for leaner protein sources and plant-based alternatives.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. High-Sodium Foods</h3>
          <p className="diet-text">Excess sodium can raise blood pressure, which puts strain on the heart. Reduce intake of processed and packaged foods, which are often high in salt, and opt for fresh ingredients with minimal sodium.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Sugary Foods and Drinks</h3>
          <p className="diet-text">Sugary foods and beverages can contribute to weight gain, insulin resistance, and heart disease. Limit consumption of sweets, sodas, and sugary snacks to maintain a healthy heart.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in unhealthy fats, sodium, and preservatives, which can increase the risk of heart disease. Stick to whole, unprocessed foods for better heart health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Alcohol (in excess)</h3>
          <p className="diet-text">Excessive alcohol consumption can lead to high blood pressure, weight gain, and other heart-related issues. Limit alcohol intake to support heart health and overall well-being.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with flaxseeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu salad with spinach, avocado, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a green smoothie with spinach, banana, and chia seeds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and roasted sweet potatoes.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include herbal teas like chamomile or ginger to reduce inflammation and support heart health.</li>
        </ul>
      </section>
    </div>
  );
};

export default IVSDiet;
