import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const LumbarSpondylosisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Lumbar Spondylosis Diet Guide</h1>
      <p className="diet-description">A balanced diet rich in anti-inflammatory nutrients and bone-strengthening foods can help manage symptoms of lumbar spondylosis by reducing inflammation, strengthening bones, and supporting spinal health. Here are dietary suggestions for foods to include and avoid.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Calcium-Rich Foods</h3>
          <p className="diet-text">Calcium is essential for maintaining strong bones. Include dairy products, leafy greens, almonds, and fortified plant-based milks to support bone density.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D aids calcium absorption and is crucial for bone health. Include foods like eggs, fortified cereals, mushrooms, and fatty fish like salmon.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, chia seeds, and walnuts, have anti-inflammatory properties that can help reduce joint and spinal inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium supports muscle relaxation and bone health. Include nuts, seeds, bananas, and leafy greens to aid spinal muscle function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help reduce oxidative stress and inflammation, supporting spinal health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Protein-Rich Foods</h3>
          <p className="diet-text">Protein is crucial for muscle strength and repair. Include lean meats, eggs, tofu, and legumes to support muscles surrounding the spine.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Beverages</h3>
          <p className="diet-text">Sugary foods can lead to inflammation in the body. Limiting sweets, sugary drinks, and processed snacks can help manage pain and swelling.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain unhealthy fats and additives that may increase inflammation. Opt for whole, unprocessed foods for better results.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats and Fried Foods</h3>
          <p className="diet-text">Trans fats and fried foods can worsen inflammation. Choose healthy fats like olive oil, nuts, and avocados instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Excessive Caffeine</h3>
          <p className="diet-text">Too much caffeine may interfere with calcium absorption and cause muscle tension. Moderate intake and opt for herbal teas when possible.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol can weaken bones and dehydrate muscles. Reducing or avoiding alcohol can improve bone strength and reduce inflammation.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, chia seeds, almond milk, and a handful of berries.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon or tofu with quinoa and steamed vegetables like broccoli and carrots.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a handful of walnuts or a banana with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with lean protein (like chicken or chickpeas) and brown rice.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include anti-inflammatory herbal teas like ginger or turmeric for added benefits.</li>
        </ul>
      </section>
    </div>
  );
};

export default LumbarSpondylosisDiet;
