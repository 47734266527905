import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const BehaviorDisorderDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Behavioral Disorders Diet Guide</h1>
      <p className="diet-description">A diet rich in nutrients that support brain health and emotional stability can be beneficial for managing behavioral disorders. Here are dietary suggestions for foods to take and avoid to help with emotional regulation, focus, and overall well-being.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, flaxseeds, and walnuts, are essential for brain health and can help improve focus, reduce impulsivity, and stabilize mood.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Complex Carbohydrates</h3>
          <p className="diet-text">Complex carbs like whole grains, oats, and sweet potatoes provide steady energy and support serotonin production, helping to improve mood stability.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Lean Protein</h3>
          <p className="diet-text">Protein-rich foods such as chicken, eggs, tofu, and legumes contain amino acids, which are precursors to neurotransmitters that regulate mood and behavior.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are packed with antioxidants that help protect brain cells from oxidative stress, supporting mental clarity.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">Gut health is closely linked to brain health. Foods like yogurt, kefir, and fermented vegetables support a balanced gut, which can positively impact mood and behavior.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamin B-Rich Foods</h3>
          <p className="diet-text">B vitamins found in leafy greens, eggs, and whole grains play a crucial role in managing stress, energy production, and overall mental health.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars</h3>
          <p className="diet-text">Sugary foods can cause rapid spikes and drops in blood sugar, leading to irritability and impulsive behavior. Limit sweets, sodas, and processed snacks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain artificial additives and unhealthy fats that may negatively impact mood and focus. Choose whole foods for better mental clarity.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats</h3>
          <p className="diet-text">Trans fats found in fried and processed foods can increase inflammation and have been linked to mood and behavior issues. Opt for healthy fats instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Excessive Caffeine</h3>
          <p className="diet-text">While moderate caffeine can improve focus, excessive amounts may lead to anxiety and jitteriness, which can worsen behavioral symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Artificial Additives</h3>
          <p className="diet-text">Artificial sweeteners, colors, and preservatives in processed foods may impact mental clarity and behavior. Opt for fresh, whole foods for better results.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or salmon with quinoa and mixed greens.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a handful of nuts or an apple with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and a side of leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water and consider calming herbal teas like chamomile to support relaxation.</li>
        </ul>
      </section>
    </div>
  );
};

export default BehaviorDisorderDiet;
