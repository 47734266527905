import React  from 'react';
import '../css/Kidney.css'; 
const KidneyPage = () => {   
  return (
    <div className="kidney-page">
      <div className="kidney-hero-image">
      <img src={require('../images/kidney-hero.webp')}alt="Urology Hero" />
        <h1>Kidney Health & Treatment</h1>
      </div>
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Kidney Stones</h2>
          <img src={require('../images/kidney1.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy takes a holistic approach to kidney stones, focusing on individual symptoms, overall health, and constitutional characteristics. Remedies are selected based on the type of stone, location, severity of symptoms, and associated conditions. The goal is to alleviate pain, facilitate the passage of stones, prevent recurrence, and promote kidney health through natural healing processes.
          </p>
          <h3>Causes of Kidney Stones</h3>
          <ul>
            <li><h5>Mineral Imbalance:</h5>Disproportionate levels of calcium, oxalate, uric acid, or other minerals in the urine contribute to stone formation.</li>
            <li><h5>Dehydration:</h5>Insufficient fluid intake leads to concentrated urine, facilitating mineral crystallization.</li>
            <li><h5>Dietary Factors:</h5>High intake of oxalate-rich foods (e.g., spinach, nuts), sodium, or animal protein.</li>
            <li><h5>Medical Conditions:</h5> Such as hyperparathyroidism, gout, urinary tract infections, or genetic predisposition.</li>
            <li><h5>Obesity:</h5> Associated with increased urinary calcium and uric acid levels, contributing to stone formation.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>Assess symptoms, risk factors, and overall health status.</li>
            <li><h5>Imaging: </h5>X-ray, CT scan, or ultrasound to visualize stones, determine their size, location, and assess potential complications.</li>
            <li><h5>Urinalysis:</h5>Examination of urine composition to identify crystals and abnormalities.</li>
            <li><h5>Blood Tests:</h5> Evaluate kidney function, mineral levels, and detect underlying conditions.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Severe Pain:</h5>Intense pain in the back, sides, abdomen, or groin as stones move through the urinary tract.</li>
            <li><h5>Obstruction:</h5>Stones can block urine flow, causing acute pain, urinary tract infections, or kidney damage.</li>
            <li><h5>Recurrence:</h5>Previous stone formation increases the likelihood of future episodes.</li>
            <li><h5>Infection:</h5> Stones can harbor bacteria, leading to urinary tract infections.</li>
            <li><h5>Chronic Kidney Disease:</h5> Prolonged obstruction or recurrent stones may impair kidney function over time.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Pain Management:</h5>NSAIDs, opioids, or antispasmodics for pain relief.</li>
            <li><h5>Hydration:</h5>Encouraged fluid intake to facilitate stone passage.</li>
            <li><h5>Medical Expulsion Therapy:</h5>Medications to relax ureter muscles and aid stone passage.</li>
            <li><h5>Extracorporeal Shock Wave Lithotripsy (ESWL):</h5> Non-invasive procedure using shock waves to break stones into smaller fragments.</li>
            <li><h5>Ureteroscopy:</h5>Minimally invasive procedure to remove or fragment stones in the ureter or kidney.</li>
            <li><h5>Percutaneous Nephrolithotomy (PCNL):</h5> Surgical procedure to remove larger stones from the kidney.</li>
            <li><h5>Preventive Measures: </h5>Dietary modifications (e.g., reduced sodium, oxalate, protein intake), increased fluid intake, medications to manage underlying conditions.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <p>Homeopathy offers remedies based on stone type and individual symptoms:</p>
          <ul>
            <li><h5>Berberis Vulgaris: </h5> For renal colic with shooting pains radiating to the bladder, associated with burning urine we provide homeopathic medicine for kidney.</li>
            <li><h5>Lycopodium:</h5>Right-sided kidney stones, pain extending to the bladder, bloating, and digestive issues.</li>
            <li><h5>Cantharis:</h5>Intense burning pain, frequent, urgent urination.</li>
            <li><h5>Sarsaparilla:</h5> Gravel or sand-like sediment in urine, pain at the end of urination.</li>
            <li><h5>Belladonna:</h5> Sudden, intense pain with fever, flushed face.</li>
            <li><h5>Apis Mellifica:</h5> Stinging pain, swelling, burning sensation.</li>
            <li><h5>Pareira Brava:</h5>Bladder retention, painful urination, stones in the bladder.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathy provides a natural and comprehensive approach to managing kidney stones, focusing on symptom relief, facilitating stone passage, preventing recurrence, and promoting overall kidney health. Our clinical experience indicates that homeopathic medicine for kidney pain, aid in the passage of stones, and reduce the frequency of stone formation.</p>
          <h3>Key Strategies for Effective Management:</h3>
          <ul>
            <li><h5>Individualized Care: </h5>Tailoring treatment based on specific symptoms, stone type, and overall health.</li>
            <li><h5>Hydration and Dietary Guidance:</h5>Encouraging adequate fluid intake and dietary modifications to prevent stone recurrence.</li>
            <li><h5>Integrated Approach:</h5>Collaborating with conventional medical interventions when necessary for optimal patient care.</li>
            <li><h5>Lifestyle Adjustments:</h5>Emphasizing healthy habits, including diet, hydration, regular exercise, and stress management.</li>
            <li><h5>Monitoring and Follow-up:</h5>Regular evaluation to monitor stone status, kidney function, and overall well-being.</li>
          </ul>
          <h3>Holistic Care Model:</h3>
          <p>
          Homeopathy’s holistic approach not only addresses acute symptoms but also aims to improve kidney function, prevent future episodes, and enhance quality of life for individuals with kidney stones.we provide homeo medicines for kidney stones which help to remove the kidney stone. By focusing on natural healing and personalized treatment plans, homeopathy supports long-term kidney health and well-being.
          </p>
        </div>
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Kidney Treatment</h3>
            <p>We offer natural, safe, and effective homeo treatment for kidney stones and other kidney-related conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>
          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/urology/kidney-stone/diet-kidney-stone">Diet & homeopathic medicine for kidney stone pain</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Kidney Stones</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Kidney Health</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="kidney-video-container">
                <h3>Watch Our Video on Kidney Stone Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/ZA695b5mWfQ?si=LO2YzS2lFEsi1ddt"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
      
    </div>
  );
};

export default KidneyPage;


// import React, { useState } from 'react';
// import '../css/Kidney.css';

// const translations = {
//   en: {
//     heroTitle: "Kidney Health & Treatment",
//     leftContent: {
//       title: "Kidney Stones",
//       basicApproach: "Basic Homeopathic Approach",
//       basicApproachText: `Homeopathy takes a holistic approach to kidney stones, focusing on individual symptoms, overall health, and constitutional characteristics. Remedies are selected based on the type of stone, location, severity of symptoms, and associated conditions. The goal is to alleviate pain, facilitate the passage of stones, prevent recurrence, and promote kidney health through natural healing processes.`,
//       causesTitle: "Causes of Kidney Stones",
//       causes: [
//         { title: "Mineral Imbalance:", text: "Disproportionate levels of calcium, oxalate, uric acid, or other minerals in the urine contribute to stone formation." },
//         { title: "Dehydration:", text: "Insufficient fluid intake leads to concentrated urine, facilitating mineral crystallization." },
//         { title: "Dietary Factors:", text: "High intake of oxalate-rich foods (e.g., spinach, nuts), sodium, or animal protein." },
//         { title: "Medical Conditions:", text: "Such as hyperparathyroidism, gout, urinary tract infections, or genetic predisposition." },
//         { title: "Obesity:", text: "Associated with increased urinary calcium and uric acid levels, contributing to stone formation." },
//       ],
//       investigationsTitle: "Investigations",
//       investigations: [
//         { title: "Medical History and Physical Examination:", text: "Assess symptoms, risk factors, and overall health status." },
//         { title: "Imaging:", text: "X-ray, CT scan, or ultrasound to visualize stones, determine their size, location, and assess potential complications." },
//         { title: "Urinalysis:", text: "Examination of urine composition to identify crystals and abnormalities." },
//         { title: "Blood Tests:", text: "Evaluate kidney function, mineral levels, and detect underlying conditions." },
//       ],
//     },
//     rightSidebar: {
//       treatmentTitle: "Kidney Treatment",
//       treatmentText: "We offer natural, safe, and effective homeo treatment for kidney stones and other kidney-related conditions.",
//       bookAppointment: "Book Appointment",
//       relatedArticles: "Related Articles",
//       article1: "Diet & homeopathic medicine for kidney stone pain",
//       article2: "Prevention of Kidney Stones",
//       article3: "Homeopathy for Kidney Health",
//     },
//     videoSection: "Watch Our Video on Kidney Stone Treatment",
//   },
//   hi: {
//     heroTitle: "किडनी स्वास्थ्य और उपचार",
//     leftContent: {
//       title: "किडनी स्टोन",
//       basicApproach: "होम्योपैथिक दृष्टिकोण",
//       basicApproachText: `किडनी स्टोन के लिए होम्योपैथी एक समग्र दृष्टिकोण अपनाती है, जो व्यक्तिगत लक्षणों, समग्र स्वास्थ्य और संवैधानिक विशेषताओं पर ध्यान केंद्रित करती है। उपचार प्रकार, स्थान, लक्षणों की गंभीरता और संबंधित स्थितियों के आधार पर चुना जाता है। उद्देश्य दर्द को कम करना, पत्थरों के निकलने की सुविधा प्रदान करना, पुनरावृत्ति को रोकना और प्राकृतिक उपचार प्रक्रियाओं के माध्यम से किडनी के स्वास्थ्य को बढ़ावा देना है।`,
//       causesTitle: "किडनी स्टोन के कारण",
//       causes: [
//         { title: "खनिज असंतुलन:", text: "कैल्शियम, ऑक्सालेट, यूरिक एसिड या अन्य खनिजों के अनुपातहीन स्तर मूत्र में पत्थर के गठन में योगदान करते हैं।" },
//         { title: "पानी की कमी:", text: "अपर्याप्त तरल पदार्थ का सेवन सांद्रित मूत्र की ओर जाता है, जिससे खनिज क्रिस्टलीकरण की सुविधा मिलती है।" },
//         { title: "आहार कारक:", text: "ऑक्सालेट युक्त खाद्य पदार्थों का उच्च सेवन (जैसे पालक, नट्स), सोडियम या पशु प्रोटीन।" },
//         { title: "चिकित्सकीय स्थितियां:", text: "जैसे कि हाइपरपैराथायरायडिज्म, गाउट, मूत्र मार्ग में संक्रमण, या आनुवंशिक प्रवृत्ति।" },
//         { title: "मोटापा:", text: "मूत्र में कैल्शियम और यूरिक एसिड के बढ़े हुए स्तरों से जुड़ा हुआ है, जिससे पत्थर का निर्माण होता है।" },
//       ],
//       investigationsTitle: "जांच",
//       investigations: [
//         { title: "चिकित्सकीय इतिहास और शारीरिक परीक्षण:", text: "लक्षणों, जोखिम कारकों और समग्र स्वास्थ्य स्थिति का आकलन करें।" },
//         { title: "इमेजिंग:", text: "पत्थरों का चित्रण करने के लिए एक्स-रे, सीटी स्कैन या अल्ट्रासाउंड, उनका आकार, स्थान और संभावित जटिलताओं का आकलन।" },
//         { title: "मूत्र परीक्षण:", text: "क्रिस्टल और असामान्यताओं की पहचान करने के लिए मूत्र संरचना की परीक्षा।" },
//         { title: "रक्त परीक्षण:", text: "किडनी की कार्यक्षमता, खनिज स्तरों का मूल्यांकन करें और अंतर्निहित स्थितियों का पता लगाएं।" },
//       ],
//     },
//     rightSidebar: {
//       treatmentTitle: "किडनी उपचार",
//       treatmentText: "हम किडनी स्टोन और अन्य किडनी संबंधी स्थितियों के लिए प्राकृतिक, सुरक्षित और प्रभावी होम्योपैथिक उपचार प्रदान करते हैं।",
//       bookAppointment: "अपॉइंटमेंट बुक करें",
//       relatedArticles: "संबंधित लेख",
//       article1: "किडनी स्टोन दर्द के लिए डाइट और होम्योपैथिक दवा",
//       article2: "किडनी स्टोन की रोकथाम",
//       article3: "किडनी स्वास्थ्य के लिए होम्योपैथी",
//     },
//     videoSection: "किडनी स्टोन उपचार पर हमारा वीडियो देखें",
//   },
// };

// const KidneyPage = () => {
//   const [language, setLanguage] = useState("en");

//   const handleLanguageChange = (e) => {
//     setLanguage(e.target.value);
//   };

//   const t = translations[language];

//   return (
//     <div className="kidney-page">
//       {/* Language Selector */}
     

//       {/* Hero Image Section */}
//      <div className="kidney-hero-image">
//   <img src={require('../images/kidney-hero.jpg')} alt="Urology Hero" />
//   <h1>Kidney Health & Treatment</h1>

//   {/* Language Switcher in Hero Section */}
//   <div className="language-switcher-hero">
//     <label htmlFor="language">Language: </label>
//     <select id="language" value={language} onChange={handleLanguageChange}>
//       <option value="en">English</option>
//       <option value="hi">हिन्दी</option>
//     </select>
//   </div>
// </div>
//       {/* Main Content Section */}
//       <div className="kidney-content-container">
//         <div className="kidney-left-content">
//           <h2>{t.leftContent.title}</h2>
//           <h3>{t.leftContent.basicApproach}</h3>
//           <p>{t.leftContent.basicApproachText}</p>
//           <h3>{t.leftContent.causesTitle}</h3>
//           <ul>
//             {t.leftContent.causes.map((cause, index) => (
//               <li key={index}>
//                 <h5>{cause.title}</h5>
//                 <p>{cause.text}</p>
//               </li>
//             ))}
//           </ul>
//         </div>

//         {/* Right Sidebar */}
//         <div className="kidney-right-sidebar">
//           <div className="kidney-treatment-section">
//             <h3>{t.rightSidebar.treatmentTitle}</h3>
//             <p>{t.rightSidebar.treatmentText}</p>
//             <button className="appointments-btn">{t.rightSidebar.bookAppointment}</button>
//           </div>

//           <div className="related-articles">
//             <h3>{t.rightSidebar.relatedArticles}</h3>
//             <ul>
//               <li><a href="/treatments/urology/kidney-stone/diet-kidney-stone">{t.rightSidebar.article1}</a></li>
//               <li><a href="#" onClick={(e) => e.preventDefault()}>{t.rightSidebar.article2}</a></li>
//               <li><a href="#" onClick={(e) => e.preventDefault()}>{t.rightSidebar.article3}</a></li>
//             </ul>
//           </div>
//         </div>
//       </div>

//       {/* YouTube Video Section */}
//       <div className="kidney-video-container">
//         <h3>{t.videoSection}</h3>
//         <iframe
//           src="https://www.youtube.com/embed/ZA695b5mWfQ?si=LO2YzS2lFEsi1ddt"
//           title="Kidney Stone Treatment"
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//           allowFullScreen
//           style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
//         />
//       </div>
//     </div>
//   );
// };

// export default KidneyPage;
