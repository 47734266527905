import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const HypothyroidismDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Hypothyroidism Diet Guide</h1>
      <p className="diet-description">A nutrient-dense diet can help manage hypothyroidism by supporting thyroid hormone production, boosting metabolism, and reducing symptoms. Below are suggestions for foods to take and avoid when dealing with hypothyroidism.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Iodine-Rich Foods</h3>
          <p className="diet-text">Iodine is essential for thyroid hormone production. Seaweed, iodized salt, dairy products, and fish are good sources to support thyroid function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Selenium-Rich Foods</h3>
          <p className="diet-text">Selenium is important for the conversion of thyroid hormones. Include foods like Brazil nuts, sunflower seeds, and tuna to support thyroid hormone metabolism.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc helps regulate thyroid hormones. Foods like seeds, nuts, legumes, and whole grains provide essential zinc for thyroid health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish, flaxseeds, and walnuts help reduce inflammation and support overall thyroid function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Whole Grains and Fiber-Rich Foods</h3>
          <p className="diet-text">Fiber can help manage constipation, a common symptom of hypothyroidism. Include whole grains like oats, brown rice, and quinoa to support digestion.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D supports immune function and bone health. Include fortified dairy, mushrooms, and fatty fish to improve Vitamin D levels.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Goitrogenic Foods (in excess)</h3>
          <p className="diet-text">Cruciferous vegetables like cabbage, broccoli, and cauliflower can interfere with thyroid hormone production when consumed in large amounts. Eat them cooked or in moderation if you have hypothyroidism.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can slow metabolism and lead to weight gain, a common symptom of hypothyroidism. Avoid sugary snacks, sodas, and processed foods to stabilize energy levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain unhealthy fats, preservatives, and refined carbs, which can exacerbate symptoms. Stick to whole, unprocessed foods for better thyroid health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and unhealthy fats can interfere with thyroid medication absorption and slow metabolism. Choose healthier fats from sources like avocados and olive oil.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Gluten (for some individuals)</h3>
          <p className="diet-text">Gluten sensitivity or celiac disease may exacerbate thyroid conditions, particularly Hashimoto's disease. Opt for gluten-free grains like quinoa and brown rice if gluten is a concern.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Alcohol</h3>
          <p className="diet-text">Alcohol can interfere with thyroid hormone production and increase inflammation. Limiting alcohol intake can support thyroid health and overall well-being.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with chia seeds, berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with quinoa and steamed spinach.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a zinc-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with brown rice and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support hydration and overall health.</li>
        </ul>
      </section>
    </div>
  );
};

export default HypothyroidismDiet;
