import React from 'react';
import '../../css/CaseStudy.css';

const GeriatricCareCaseStudy = () => {
  return (
    <div className="case-study-page">
      <div className="case-study-hero">
        <h1>Improving Quality of Life in Old Age Through Safe Homeopathic Care</h1>
        {/* <img src={require('../images/case-study-hero.jpg')} alt="Geriatric Care" className="case-study-img" /> */}
      </div>

      <div className="case-study-content">
        <p>
          Mr. Sharma, an 82-year-old retiree from Pune, India, faced various health challenges common in old age, including joint pain, insomnia, and digestive issues. These ailments had gradually reduced his mobility and independence, impacting his quality of life. After trying different medications with limited success and experiencing some side effects, he decided to explore homeopathic care in late 2019, hoping for a gentle yet effective solution to manage his symptoms.
        </p>

        <h2>Starting with Homeopathy</h2>
        <p>
          In his first consultation, Mr. Sharma’s homeopathic doctor took a holistic approach, discussing his medical history, lifestyle, and current health concerns. The treatment plan was tailored to address his specific issues, focusing on improving joint mobility, enhancing sleep quality, and supporting digestive health without causing side effects.
        </p>

        <h2>A Supportive and Holistic Approach</h2>
        <p>
          Alongside homeopathic remedies, Mr. Sharma’s doctor recommended gentle exercises, dietary adjustments, and mindfulness practices to support his overall well-being. This integrated approach not only helped relieve his symptoms but also provided him with practical steps to maintain his health and independence.
        </p>

        <h2>Gradual Improvements in the First Few Months</h2>
        <p>
          Within the first few months, Mr. Sharma noticed a reduction in joint stiffness and improved sleep patterns. His energy levels were higher, and he felt less dependent on painkillers for joint pain. These early improvements motivated him to continue with the treatment and adhere to the recommended lifestyle adjustments.
        </p>

        <h2>Significant Progress Over the First Year</h2>
        <p>
          As he continued with homeopathic care, Mr. Sharma’s health improved steadily. His joint pain became manageable, allowing him to resume some of his favorite activities, like gardening and walking. Additionally, his digestive issues reduced significantly, and he felt more comfortable eating a balanced diet.
        </p>

        <h2>Enhanced Quality of Life in the Long Term</h2>
        <p>
          After a year and a half of consistent treatment, Mr. Sharma achieved a better quality of life. He reported minimal joint discomfort, better digestion, and restful sleep. His doctor reduced his remedy doses gradually, and he maintained his well-being without relying heavily on medications.
        </p>

        <h2>Enjoying Independence and Well-Being</h2>
        <p>
          Today, Mr. Sharma enjoys a more independent and active lifestyle. Homeopathy not only alleviated his symptoms but also improved his overall health, enabling him to lead a fulfilling life in his old age. His experience demonstrates how homeopathic care can support geriatric health by addressing both physical and emotional needs.
        </p>

        <h2>Final Thoughts</h2>
        <p>
          Mr. Sharma’s journey highlights the potential of homeopathy to improve quality of life for elderly individuals dealing with age-related health challenges. His story encourages others to consider holistic, gentle treatments that prioritize overall well-being and empower seniors to live with greater independence and vitality.
        </p>
      </div>
    </div>
  );
};

export default GeriatricCareCaseStudy;
