import React, { useState, useEffect } from "react";
import "../css/HomePage.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const HomePage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState("right"); // Track slide direction
  const [currentVideoSlide, setCurrentVideoSlide] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const slides = [
    {
      image: require("../images/hero1.webp"),
      heading: "Welcome to Niramaya Homeopathy",
      text: "Your Health, Our Priority",
    },
    {
      image: require("../images/hero2.webp"),
      heading: "Expert Homeopathy Treatments",
      text: "Personalized care for all your health needs",
    },
    {
      image: require("../images/hero3.webp"),
      heading: "Natural Healing, Effective Results",
      text: "Safe and gentle treatment methods",
    },
  ];
  const treatments = [
    {
      image: require("../images/treatment1.webp"),
      title: "Urology (Kidney & Bladder)",
      description:
        "Homeopathy offers natural relief for kidney and bladder issues, easing symptoms like pain and infections.",
      path: "/treatments/urology",
    },
    {
      image: require("../images/treatment2.webp"),
      title: "Female Troubles",
      description:
        "Homeopathy provides natural remedies menstrual irregularities, menopause symptoms, and hormonal imbalances.",
      path: "/treatments/female-troubles",
    },
    {
      image: require("../images/treatment4.webp"),
      title: "Gastric issues",
      description:
        "Homeopathy provides natural relief for gastric issues, including indigestion, acidity, and bloating.",
      path: "/treatments/gastric-issues",
    },
    {
      image: require("../images/treatment6.webp"),
      title: "Skin Care",
      description:
        "Homeopathy offers gentle, natural solutions for skin problems like acne, eczema, and psoriasis. ",
      path: "/treatments/skin",
    },
    {
      image: require("../images/treatment5.webp"),
      title: "Harmonal Disorder",
      description:
        "Homeopathy helps balance hormonal disorders thyroid problems, PCOS, and menstrual irregularities.",
      path: "/treatments/hormonal-diseases",
    },
    {
      image: require("../images/treatment3.webp"),
      title: "Migraines & Headache",
      description:
        "Homeopathy offers gentle relief for migraines, addressing symptoms like severe headaches, nausea, and sensitivity to light.",
      path: "/treatments/head",
    },
    {
      image: require("../images/treatment7.webp"),
      title: "ENTs",
      description:
        "Homeopathy provides effective treatment for ENT issues like sinusitis, ear infections, and throat problems. ",
      path: "/treatments/ent",
    },
    {
      image: require("../images/treatment8.webp"),
      title: "Male Genital Issues",
      description:
        "Homeopathy offers natural remedies for erectile dysfunction, prostatitis, and premature ejaculation.",
      path: "/treatments/male-genital",
    },
    {
      image: require("../images/treatment9.webp"),
      title: "Psychiatrist",
      description:
        "Homeopathy supports mental well-being by addressing psychiatric issues like anxiety, depression, and insomnia.",
      path: "/treatments/psychiatric",
    },
  ];
  const consultationSteps = [
    {
      step: "Step 1",
      description: "Contact to Reception & Book your Appointment.",
    },
    {
      step: "Step 2",
      description:
        "Complete the format that is send to you and attach your reports.",
    },
    {
      step: "Step 3",
      description:
        "Available Jr. Doctor will call you to complete the patient case history.",
    },
    {
      step: "Step 4",
      description:
        "After analysis of your case you will be join to the Sr. Doctor.",
    },
    {
      step: "Step 5",
      description:
        "After consultation your prescription will be sent to you & pharmacy.",
    },
    {
      step: "Step 6",
      description:
        "Pharmacy will send you medicine & collect the payment & create the next follow-up schedule.",
    },
  ];
  const experts = [
    {
      name: "Dr. Atul Singh",
      qualification: "BHMS, MD, PG London",
      image: require("../images/atulsir.webp"),
      words:
        "Treatment of any illness demands for dedication of doctor as well as devotion of patient for proper recovery. Our integrated approach include constitutional homeopathic treatment with diet management and most important yoga for lifestyle adaptation for proper recivery.",
    },
    {
      name: "Dr. Vandana Singh",
      qualification: "BHMS, PGDCC",
      image: require("../images/drvandana.webp"),
      words:
        "Real meaning of proper recovery is when a patient recovered mentally, physially and socieally. Natural constitutional medicines has capability to cure a patient in above all mentioned aspects",
    },
    {
      name: "Dr. Shalini Chaubey",
      qualification: "Yoga Therapist",
      image: require("../images/shalini.webp"),
      words:
        "YOGA heals you internally as well as externally. This is the only form of therapy which can change your all lifestyle disorders and have capability to increase efficacy of homeopathic treatment which you are taking.",
    },
  ];
  const patientVideos = [
    {
      title: "Homeopathic treatment for thyroid disease",
      videoUrl: "https://www.youtube.com/watch?v=hdVHvrxZVFY",
      thumbnail: require("../images/youtube1.webp"),
    },
    {
      title: "Homeopathic Treatment for Genetic Diseases",
      videoUrl: "https://www.youtube.com/watch?v=hdVHvrxZVFY",
      thumbnail: require("../images/youtube2.webp"),
    },
    {
      title: "Homeopathic Treatment for Cerebral Atrophy",
      videoUrl: "https://www.youtube.com/watch?v=hdVHvrxZVFY",
      thumbnail: require("../images/youtube3.webp"),
    },
    {
      title: "Homeopathic Treatment for Asthma",
      videoUrl: "https://www.youtube.com/watch?v=hdVHvrxZVFY",
      thumbnail: require("../images/youtube4.webp"),
    },
    {
      title: "Homeopathic Treatment for Arthritis",
      videoUrl: "https://www.youtube.com/watch?v=hdVHvrxZVFY",
      thumbnail: require("../images/youtube5.webp"),
    },
    {
      title: "Homeopathic Treatment for Diabetes",
      videoUrl: "https://www.youtube.com/watch?v=hdVHvrxZVFY",
      thumbnail: require("../images/youtube6.webp"),
    },
  ];
  const faqs = [
    {
      question: "What is homeopathy, and how does it work?",
      answer:
        "Homeopathy is a natural treatment that uses diluted substances to activate the body’s healing. It works by treating the whole person, focusing on both physical and emotional health, and is safe for various conditions.",
    },
    {
      question: "Is homeopathic treatment safe?",
      answer:
        "Yes, homeopathic treatment is considered safe. It uses natural, diluted remedies that have minimal to no side effects. Homeopathy is gentle enough for all age groups, including infants, pregnant women, and the elderly, making it a popular choice for those seeking non-invasive and holistic healthcare.",
    },
    {
      question:
        "How long will it take to see results from homeopathic treatment?",
      answer:
        "Results vary depending on the condition. Acute issues may improve within days, while chronic conditions may take weeks or months. Regular follow-ups ensure effective progress.",
    },
    {
      question: "Can homeopathy be taken alongside conventional medicine?",
      answer:
        "Yes, homeopathy can typically be taken alongside conventional medicine. It is safe and does not usually interfere with other treatments, but it's always best to consult your doctor or homeopath to ensure there are no conflicts.",
    },
  ];
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setDirection("right");
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 3000); // 3000ms = 3 seconds

    return () => clearInterval(slideInterval);
  }, [slides.length]);
  const nextSlide = () => {
    setDirection("right");
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setDirection("left");
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };
  const videosPerSlide = 3;
  const totalSlides = Math.ceil(patientVideos.length / videosPerSlide);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVideoSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, 3000);
    return () => clearInterval(interval);
  }, [totalSlides]);
  const goToVideoSlide = (index) => {
    setCurrentVideoSlide(index);
  };

  const getVideosForCurrentSlide = () => {
    const startIndex = currentVideoSlide * videosPerSlide;
    const endIndex = startIndex + videosPerSlide;
    return patientVideos.slice(startIndex, endIndex);
  };
  const toggleFAQ = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };
  return (
    <div>
      <div className="hero-slider">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`hero-slide ${index === currentSlide ? "active" : ""}`}
            style={{
              animation: `${
                index === currentSlide
                  ? direction === "right"
                    ? "slideIn"
                    : "slideOut"
                  : ""
              } 0.5s ease-in-out`,
            }}
          >
            <img
              src={slide.image}
              alt={`Hero Slide ${index + 1}`}
              className="hero-image"
            />
            <div className="hero-content ">
              <h1>{slide.heading}</h1>
              <p>{slide.text}</p>
              {/* Use Link instead of a tag */}
              <Link to="/patientbookappointment" className="book-btn">
                Book Appointment
              </Link>
            </div>
          </div>
        ))}
        <button className="prev" onClick={prevSlide}>
          ❮
        </button>
        <button className="next" onClick={nextSlide}>
          ❯
        </button>
      </div>

      <section className="about-niramaya">
        <div className="about-content">
          <div className="about-card">
            <img
              src={require("../images/niramaya.webp")}
              alt="About Niramaya"
              className="about-image"
            />
          </div>
          <div className="about-card">
            <h2>About Niramaya Homeopathy</h2>
            <p>
              Founded in 2010, Niramaya Homeopathy focuses on natural and
              complete healing. Right from the beginning, we have combined
              homoeopathic treatment with yoga and nutrition, creating a
              balanced approach that addresses both the body and mind. This
              unique integration supports overall well-being and offers deeper,
              long-lasting results. By using advanced software for detailed case
              history, analysis, and repertorization, we can find the
              best-matching homoeopathic remedies tailored to each patient.
              These tools help us select the most effective medicine for every
              individual, based on their unique health condition.
            </p>
          </div>
        </div>
      </section>
      <section className="treatments-section">
        <h2 className="section-heading">Our Treatments</h2>
        <div className="treatments-slider">
          {treatments.map((treatment, index) => (
            <Link
              key={index}
              to={treatment.path || "/"} // Fallback to home if no path is provided
              className="treatment-card"
            >
              <img
                src={treatment.image}
                alt={treatment.title}
                className="treatment-image"
              />
              <div className="treatment-info">
                <h3>{treatment.title}</h3>
                <p>{treatment.description}</p>
              </div>
            </Link>
          ))}
        </div>
      </section>
      <section className="statistics-section">
        <div className="statistics-container">
          <div className="stat-card">
            <img
              src={require("../images/trophy.webp")}
              alt="Patients Worldwide"
              className="stat-icon"
            />
            <h3>90,000+</h3>
            <p>Trusted by and Cured more than Patients Worldwide.</p>
          </div>
          <div className="stat-card">
            <img
              src={require("../images/experience.webp")}
              alt="Years of Experience"
              className="stat-icon"
            />
            <h3>15+</h3>
            <p>Years of Experience</p>
          </div>
          <div className="stat-card">
            <img
              src={require("../images/emergency.webp")}
              alt="Emergency case"
              className="stat-icon"
            />
            <h3>World Class</h3>
            <p>Homeopathic Clinic</p>
          </div>
        </div>
      </section>
      <section className="online-consultation-steps">
        <h2 className="sections-heading">
          Simple Steps To Book Online Consultation With Us
        </h2>
        <div className="steps-container">
          {consultationSteps.map((step, index) => (
            <div key={index} className={`step-card step-${index + 1}`}>
              <div className="step-number">{index + 1}</div>
              <h3>{step.step}</h3>
              <p>{step.description}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="appointment-section">
        <div className="appointment-content">
          <div className="why-homeopathy">
            <h2>Why Choose Niramaya Homeopathy?</h2>
            <p>
              Homeopathy offers a natural, holistic approach to healing that
              focuses on the individual’s overall well-being rather than just
              suppressing symptoms. Niramaya Homeopathy is best homeopathy
              clinic near you, it is gentle, non-invasive, and suitable for
              people of all ages. By treating the root cause of illness,
              homeopathy helps strengthen the immune system and promotes
              long-term health.
            </p>
            <p>
              It is a safe and effective system of medicine that can treat a
              wide variety of chronic and acute conditions, without causing any
              harmful side effects. Choose homeopathy for personalized care and
              lasting health benefits.
            </p>
          </div>
          <div className="book-appointment-card">
            <h3>Book an Appointment</h3>
            <form
              action="https://api.web3forms.com/submit"
              method="POST"
              onSubmit={() =>
                alert(
                  "Your appointment request has been submitted successfully!"
                )
              }
            >
              {/* Web3Forms Access Key */}
              <input
                type="hidden"
                name="access_key"
                value="90277967-72b9-4089-93c9-5ec2b7137a38" // Replace with your actual Web3Forms access key
              />

              <input type="text" name="name" placeholder="Name *" required />

              <input type="email" name="email" placeholder="Email" required />

              <input
                type="tel"
                name="phone"
                placeholder="Phone No *"
                required
              />

              <textarea
                name="message"
                placeholder="Message *"
                required
              ></textarea>

              <button type="submit">Send Message</button>
            </form>
          </div>
          {/* <div className="book-appointment-card">
            <h3>Book an Appointment</h3>
            <form>
              <input type="text" placeholder="Name *" required />
              <input type="email" placeholder="Email" required />
              <input type="tel" placeholder="Phone No *" required />
              <textarea placeholder="Message *" required></textarea>
              <button type="submit">Send Message</button>
            </form>
          </div> */}
        </div>
      </section>
      <section className="experts-section">
        <div className="slider">
          {experts.map((expert, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? "active" : ""}`}
            >
              <p className="expert-words">{expert.words}</p>
              <div className="expert-info">
                <img
                  src={expert.image}
                  alt={expert.name}
                  className="expert-image"
                />
                <div>
                  <h3>{expert.name}</h3>
                  <p>{expert.qualification}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Dots Indicator */}
        <div className="dots">
          {experts.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </section>
      <section className="patients-say-section">
        <h2>What do our patients say?</h2>
        <p>
          When we say we are the finest homeopathic clinic, we mean it. Here’s
          the proof.
        </p>
        <div className="video-slider">
          {getVideosForCurrentSlide().map((video, index) => (
            <div key={index} className="video-card">
              <a
                href={video.videoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={video.thumbnail}
                  alt={video.title}
                  className="video-thumbnail"
                />
              </a>
              <p>{video.title}</p>
            </div>
          ))}
        </div>

        {/* Slider Controls */}

        {/* Dots Indicator */}
        <div className="video-dots">
          {Array.from({ length: totalSlides }).map((_, index) => (
            <span
              key={index}
              className={`video-dot ${
                index === currentVideoSlide ? "active" : ""
              }`}
              onClick={() => goToVideoSlide(index)}
            ></span>
          ))}
        </div>
      </section>
      <section className="faqs-section">
        <div className="faqs-container">
          <div className="faq-text">
            <h2>Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <div className="faq-question" onClick={() => toggleFAQ(index)}>
                  {faq.question}
                  <span>{activeQuestion === index ? "−" : "+"}</span>
                </div>
                {activeQuestion === index && (
                  <div className="faq-answer">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="faq-image">
            <img src={require("../images/faq.webp")} alt="FAQ" />
          </div>
        </div>
      </section>
      <section className="contact-us-section">
        <div className="contact-card">
          <div className="contact-header">
            <h2>Connect With Us</h2>
            {/* <a
      href="https://wa.me/919236185711"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button"
    ></a> */}
            <a href="https://wa.me/919236185711">
              <FontAwesomeIcon icon={faWhatsapp} size="3x" color="white" />
            </a>{" "}
            {/* Phone icon */}
          </div>
          <p>
            Get personalized healthcare from our specialist homeopathy doctors
            with online consultations now available.
          </p>
        </div>

        <div className="contact-details">
          <div className="contact-item">
            <i className="fa fa-phone"></i>
            <p>
              <strong>Call Today</strong>
              <br />
              <a
                href="tel:+919236185711" // Make the phone number clickable
                className="contact-link"
                style={{ textDecoration: "none" }} // Removes the underline
              >
                +919236185711
              </a>
              <br />
            </p>
          </div>
          <div className="contact-item">
            <i className="fa fa-envelope"></i>
            <p>
              <strong>Email Us</strong>
              <br />
              <a
                href="mailto:niramayaforyou@gmail.com" // Opens email client on click
                className="contact-link"
                style={{ textDecoration: "none" }} // Removes the underline
              >
                niramayaforyou@gmail.com
              </a>
            </p>
          </div>
          <div className="contact-item">
            <i className="fa fa-map-marker"></i>
            <p>
              <strong>Address</strong>
              <br />
              0230 B Civil Lines 2 Bilandpur near DIG Bunglow, Gorakhpur, Uttar
              Pradesh, India, 273001
            </p>
          </div>
        </div>
      </section>
      <div className="floating-buttons">
        <a
          href="https://wa.me/919236185711"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <FontAwesomeIcon icon={faWhatsapp} size="2x" color="white" />
        </a>
        <a href="tel:+919236185711" className="call-button">
          <FontAwesomeIcon icon={faPhone} size="2x" color="white" />
        </a>
      </div>
    </div>
  );
};

export default HomePage;
