// import React from 'react';
// import '../css/BookAppointment.css';

// const BookAppointment = () => {
//   return (
//     <div className="book-appointment-page">
//       <div className="book-hero-section">
//       <div className='booking-new'>
//       <h1>Book Your Appointment</h1>
//       <h2>Get personalized care and treatment at</h2>
//       <h2>Niramaya Homeopathy</h2>
//       </div>
//         {/* <img src={require('../images/book_hero.jpg')} alt="Hero" className="book-hero-img" /> */}
        
//       </div>
//       <div className="appointment-form-section">
//         <h2>Book an Appointment</h2>
//         <form className="appointment-form">
//           <input type="text" placeholder="Your Name" required />
//           <input type="email" placeholder="Your Email" required />
//           <input type="tel" placeholder="Your Phone Number" required />
//           <textarea placeholder="Describe your Symptoms/Problems" rows="4" required></textarea>
//           <button type="submit">Book Appointment</button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default BookAppointment;






import React, { useState } from 'react';
import '../css/BookAppointment.css';

const BookAppointment = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    symptoms: '',
  });

  const [responseMessage, setResponseMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="book-appointment-page">
      <div className="book-hero-section">
        <div className='booking-new'>
          <h1>Book Your Appointment</h1>
          <h2>Get personalized care and treatment at</h2>
          <h2>Niramaya Homeopathy</h2>
        </div>
        {/* <img src={require('../images/book_hero.jpg')} alt="Hero" className="book-hero-img" /> */}
      </div>
      
      <div className="appointment-form-section">
        <h2>Book an Appointment</h2>
        <form 
          className="appointment-form" 
          action="https://api.web3forms.com/submit" 
          method="POST"
          onSubmit={() => setResponseMessage("Appointment booked successfully!")}
        >
          {/* Web3Forms Access Key */}
          <input
            type="hidden"
            name="access_key"
            value="90277967-72b9-4089-93c9-5ec2b7137a38" // Replace with your actual Web3Forms access key
          />
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Your Phone Number"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
          <textarea
            name="symptoms"
            placeholder="Describe your Symptoms/Problems"
            rows="4"
            value={formData.symptoms}
            onChange={handleInputChange}
            required
          ></textarea>
          <button type="submit">Book Appointment</button>
        </form>
        {responseMessage && <p>{responseMessage}</p>}
      </div>
    </div>
  );
};

export default BookAppointment;
