import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const AlopeciaDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Alopecia Diet Guide</h1>
      <p className="diet-description">A nutrient-dense diet can help manage alopecia by promoting hair growth, reducing inflammation, and supporting scalp health. Below are suggestions for foods to take and avoid when dealing with alopecia.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Protein-Rich Foods</h3>
          <p className="diet-text">Protein is essential for hair structure. Include protein-rich foods like eggs, lean meats, legumes, and lentils to support healthy hair growth.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Iron-Rich Foods</h3>
          <p className="diet-text">Iron deficiency is linked to hair loss. Include leafy greens, beans, lentils, and lean meats in your diet to improve iron levels and reduce hair thinning.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s in foods like salmon, flaxseeds, and walnuts help reduce inflammation and promote scalp health, essential for managing alopecia.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc supports hair follicle health. Include nuts, seeds, and whole grains to ensure adequate zinc intake, promoting hair growth and reducing hair thinning.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D deficiency has been linked to alopecia. Include foods like fortified dairy, mushrooms, and fatty fish to support healthy hair.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Biotin-Rich Foods</h3>
          <p className="diet-text">Biotin (Vitamin B7) promotes hair strength and growth. Include eggs, almonds, and sweet potatoes in your diet to prevent hair thinning.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can lead to hormonal imbalances and inflammation, which can worsen hair loss. Avoid sugary snacks, sodas, and processed foods to support healthy hair.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs like white bread and pasta can lead to insulin spikes, which may contribute to hair loss. Opt for whole grains instead to stabilize blood sugar levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and those high in unhealthy fats can clog hair follicles and hinder healthy hair growth. Choose healthier fats like avocados and olive oil.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Excessive alcohol consumption can dehydrate the body and weaken hair strands, making them more prone to breakage. Limit alcohol intake to support hair health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in preservatives, additives, and unhealthy fats that can disrupt hair health. Stick to whole, natural foods for better hair growth.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Excessive Caffeine</h3>
          <p className="diet-text">While moderate caffeine is generally fine, excessive amounts can dehydrate the scalp and weaken hair strands. Keep caffeine intake balanced.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon or tofu salad with quinoa and mixed greens.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a biotin-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked chicken with sweet potatoes and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support scalp hydration and hair growth.</li>
        </ul>
      </section>
    </div>
  );
};

export default AlopeciaDiet;
