import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const RecurrentPneumoniaDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Recurrent Pneumonia Diet Guide</h1>
      <p className="diet-description">A nutrient-rich diet that strengthens the immune system and supports lung health can help manage symptoms of recurrent pneumonia. Here are dietary suggestions for foods to take and avoid for better respiratory health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C boosts the immune system and helps the body fight infections. Include citrus fruits, strawberries, bell peppers, and broccoli in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D is essential for immune function and lung health. Include foods like eggs, mushrooms, fortified dairy products, and fatty fish like salmon.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Antioxidants protect lung tissue from damage. Berries, dark leafy greens, and nuts contain antioxidants that support respiratory health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s in fatty fish, chia seeds, and walnuts have anti-inflammatory properties, which can help reduce lung inflammation and improve breathing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics in yogurt, kefir, and fermented foods help support gut health, which plays a role in immune function and infection resistance.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Hydrating Foods and Fluids</h3>
          <p className="diet-text">Staying hydrated helps thin mucus, making it easier to clear the lungs. Include water-rich foods like cucumbers, soups, and herbal teas.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Beverages</h3>
          <p className="diet-text">Excessive sugar can weaken the immune system. Limit sweets, sugary drinks, and processed snacks to support immune resilience.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods and Trans Fats</h3>
          <p className="diet-text">Processed foods and trans fats can lead to inflammation. Avoid fried foods, packaged snacks, and choose whole foods instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Dairy (if mucus increases)</h3>
          <p className="diet-text">Dairy products can increase mucus in some individuals. If you experience more congestion with dairy, try lactose-free or plant-based alternatives.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can impair immune function and dehydrate the body. Avoid alcohol to support respiratory health and immune function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeine (in large amounts)</h3>
          <p className="diet-text">Excessive caffeine can lead to dehydration. Stick to moderate amounts, and opt for herbal teas that support respiratory health.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, orange, strawberries, and chia seeds.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a handful of blueberries or an apple with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and a side of mixed greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include herbal teas like ginger or chamomile for added immune support.</li>
        </ul>
      </section>
    </div>
  );
};

export default RecurrentPneumoniaDiet;
