import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const RecurrentAphthousDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Recurrent Aphthous Ulcers Diet Guide</h1>
      <p className="diet-description">A balanced diet can help reduce the frequency and severity of recurrent aphthous ulcers by supporting immune function, reducing inflammation, and promoting oral health. Below are suggestions for foods to take and avoid when dealing with canker sores.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Vitamin B12 and Folate-Rich Foods</h3>
          <p className="diet-text">Deficiencies in vitamin B12 and folate are often linked to recurrent aphthous ulcers. Foods like spinach, eggs, lentils, fortified cereals, and lean meats can help prevent nutrient deficiencies that may contribute to canker sores.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Iron-Rich Foods</h3>
          <p className="diet-text">Iron deficiency can also be associated with mouth ulcers. Include iron-rich foods such as spinach, beans, lentils, and lean meats to help maintain healthy blood and tissue function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods help maintain a healthy balance of bacteria in the mouth and digestive system, supporting immune function and reducing the risk of canker sores.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Anti-inflammatory Foods</h3>
          <p className="diet-text">Foods like berries, leafy greens, fatty fish (salmon, mackerel), and turmeric are rich in antioxidants and Omega-3s, which can help reduce inflammation and promote healing of mouth ulcers.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Proper hydration is crucial for oral health. Include hydrating foods like cucumbers, watermelon, and citrus fruits, along with drinking plenty of water, to keep the mouth moist and reduce irritation from canker sores.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc is important for immune function and healing. Include zinc-rich foods like pumpkin seeds, chickpeas, and whole grains to promote faster healing of mouth ulcers.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Acidic Foods</h3>
          <p className="diet-text">Citrus fruits, tomatoes, and other acidic foods can irritate the mouth and worsen canker sores. Limiting or avoiding acidic foods during outbreaks can reduce discomfort.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can cause irritation in the mouth and make canker sores more painful. Avoiding spicy dishes during an outbreak can help reduce pain and inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Crunchy or Hard Foods</h3>
          <p className="diet-text">Hard, crunchy foods like chips, crackers, and nuts can further irritate canker sores and delay healing. Stick to softer, soothing foods when experiencing ulcers.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Sugary Foods and Drinks</h3>
          <p className="diet-text">Sugary snacks and beverages can contribute to oral health issues and may exacerbate canker sores. Reducing sugar intake can support healing and prevent further irritation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeine (in excess)</h3>
          <p className="diet-text">Excessive caffeine can cause dehydration and irritate the mouth, worsening canker sores. Stick to moderate amounts and focus on hydration for better oral health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Alcohol</h3>
          <p className="diet-text">Alcohol can dry out the mouth and exacerbate canker sores. Reducing or avoiding alcohol, especially during outbreaks, can help promote faster healing and reduce pain.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie made with spinach, yogurt, banana, and chia seeds.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> A quinoa and avocado salad with grilled chicken and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Grilled salmon with steamed sweet potatoes and sautéed spinach.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include herbal teas like chamomile or ginger to soothe the mouth and reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default RecurrentAphthousDiet;
