import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh1.webp')}alt="Urology Hero" />
        <h1>Infertility</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Infertility</h2>
          <img src={require('../images/female1.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy treats infertility by considering the individual’s entire constitution, including physical, mental, and emotional aspects. The aim is to identify and address the root cause of infertility rather than just the symptoms. Homeopathic remedies are selected based on the specific symptoms and characteristics of the patient, aiming to restore overall balance and health, thereby enhancing the body’s natural ability to conceive.          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Hormonal Imbalances: </h5>Irregular levels of hormones such as estrogen, progesterone, FSH, and LH can disrupt ovulation and menstrual cycles.</li>
            <li><h5>Structural Problems:</h5>Issues such as blocked fallopian tubes, uterine fibroids, or polyps can impede the fertilization process.</li>
            <li><h5>Lifestyle Factors:</h5>Poor diet, obesity, excessive exercise, smoking, alcohol consumption, and high-stress levels can negatively impact fertility.</li>
            <li><h5>Medical Conditions:</h5>Conditions like Polycystic Ovary Syndrome (PCOS), endometriosis, thyroid disorders, and autoimmune diseases can affect fertility.</li>
            <li><h5>Male Factors:</h5>Low sperm count, poor sperm motility, and abnormalities in sperm shape can contribute to infertility in men.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Hormonal Assays:</h5>Blood tests to measure levels of FSH, LH, TSH, estrogen, and progesterone.</li>
            <li><h5>Ultrasound: </h5>Imaging to check the condition of the ovaries, uterus, and other reproductive organs.</li>
            <li><h5>Hysterosalpingography (HSG):</h5>An X-ray procedure to examine the inside of the uterus and fallopian tubes.</li>
            <li><h5>Semen Analysis:</h5>Evaluation of sperm count, motility, and morphology.</li>
            <li><h5>Laparoscopy:</h5>A minimally invasive surgical procedure to look at the reproductive organs and correct any abnormalities.</li>

          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Psychological Stress:</h5>The emotional toll of infertility can lead to anxiety, depression, and strained relationships.</li>
            <li><h5>Marital Issues:</h5>The stress and emotional burden of infertility can impact marital harmony.</li>
            <li><h5>Financial Burden:</h5>Treatments for infertility can be expensive, leading to financial strain.</li>
            <li><h5>Ectopic Pregnancy:</h5>Increased risk of a fertilized egg implanting outside the uterus.</li>
            <li><h5>Miscarriage:</h5> Higher incidence of early pregnancy loss.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Hormonal Therapies:</h5>Medications like Clomiphene Citrate to stimulate ovulation.</li>
            <li><h5>Assisted Reproductive Technologies (ART):</h5>Procedures such as In Vitro Fertilization (IVF) and Intrauterine Insemination (IUI).</li>
            <li><h5>Surgery:</h5>To correct structural abnormalities like blocked fallopian tubes or remove fibroids.</li>
            <li><h5>Lifestyle Modifications:</h5>Adopting a healthy diet, regular exercise, and reducing stress through yoga or meditation.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <ul>
            <li><h5>Sepia:</h5>For women experiencing hormonal imbalance, irregular periods, and emotional stress.</li>
            <li><h5>Pulsatilla:</h5>For those with irregular menstruation, mood swings, and a gentle, yielding disposition.</li>
            <li><h5>Lycopodium:</h5>Beneficial for men with low sperm count and issues related to self-confidence and digestive disturbances.            </li>
            <li><h5>Agnus Castus:</h5>For men and women with low libido, sexual dysfunction, and a history of suppressed sexual desire.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>In our experience, homeopathy can offer a supportive role in the treatment of infertility by addressing the whole person and not just the condition. Patients undergoing homeopathic treatment often report improvements in their overall health, including better menstrual regularity, enhanced emotional well-being, and reduced stress levels. These holistic benefits can create a more favorable environment for conception. While homeopathy may not replace conventional treatments, it can be an effective complementary approach, especially for those looking for a natural and less invasive option.</p>
          
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Infertility Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Infertility related conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/infertility/infertility-diet">Diet for Infertility</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Infertility</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Infertility</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Infertility Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/YMfLfqxD1z8?si=QjfR2UtF-2_HVYCA"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
     
    </div>
  );
};

export default KidneyPage;
