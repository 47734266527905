import Slider from 'react-slick';
import '../css/AboutUs.css'; 
import { Link } from 'react-router-dom';

const settings = {
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 2000,
  rtl: false,
  slidesToShow: 3, // Default value for larger screens
  slidesToScroll: 1, // Default value for larger screens
  responsive: [
    {
      breakpoint: 1024, // Tablet
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,   
      },
    },
    {
      breakpoint: 768, // Mobile devices
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const AboutUs = () => {
  return (
    <div className="about-us-page">
      <div className="about-hero-section">
      <div className='about-new'>
      <h1>Homeopathic Treatment</h1>
      <h2>Homeopathic is Supposed to Work Miracale.</h2>
      </div>
        {/* <img src={require('../images/t1.jpg')} alt="Treatment Hero" className="treatment-hero-img" /> */}
      </div>
      {/* <div className="heros-image">
        <img src={require('../images/about1.jpg')} alt="Hero" className="heros-img" />

       <div className="hero-overlay">
          <p></p>
         <Link to="/appointment" className="about-book-appointment-btn">Book Appointment</Link> 
        </div>
      </div> */}
      <section className="about-doctor-section">
        <div className="doctor-info-container">
          <div className="doctor-image">
            <img src={require('../images/atulsirbg.webp')} alt="Dr. Atul Kumar Singh" />
          </div>
          <div className="doctor-details">
            <h2>Dr. Atul Kumar Singh</h2>
            <p>
              Dr. Atul Kumar Singh is a renowned homeopathy specialist with over 15+ years of experience. 
              He has contributed immensely to the medical field, providing holistic treatment solutions to 
              thousands of patients worldwide.
            </p>
            <p>
              He is best homeopathy doctor near you known for his empathetic care and advanced treatment techniques,He has successfully 
              treated a range of complex diseases using homeopathy.
            </p>
            <p>
            His commitment to treating the root cause of health issues rather than just alleviating symptoms has earned him a reputation for excellence in patient care. Driven by a deep passion for healing, Dr. Atul strives to empower patients on their journey to better health. His approach ensures each patient receives attentive, personalized care to their unique needs.
            </p>
          </div>
        </div>
      </section>
      <section className="philosophy-section">
        <div className="philosophy-card">
          <h2>Approach of Integrated Homeopathic Treatment</h2>
          <p>
          When treating chronic illness, it's crucial to not only focus on healing but also on preventing recurrence. This can be achieved through lifestyle adjustments, including yoga, meditation, homeopathic medicine, and proper nutrition. The integrated homeopathic approach believes that, in addition to prescribing personalized homeopathic remedies, patients should be guided to make necessary lifestyle changes and adopt a balanced diet to support their recovery. This holistic strategy helps protect patients from the recurrence of chronic conditions, ensuring long-term health and well-being.
          </p>
          <p>
            Niramaya Homeopathy treatment approach focuses on understanding the patient's unique constitution, mental 
            and emotional states, and physical health to create a tailored remedy that stimulates the body’s 
            natural healing abilities. He has successfully treated chronic illnesses such as asthma, arthritis, 
            digestive disorders, and more.
          </p>
        </div>
      </section>
       <section className="why-and-treatments">
        <div className="why-niramaya">
          <h2>Why Niramaya Homeopathy?</h2>
          <p>
            Niramaya Homeopathy is committed to providing comprehensive healthcare solutions. Our doctors 
            combine years of experience with modern techniques to deliver high-quality treatments for various 
            disorders, including developmental, thyroid, female disorders, and allergies. At Niramaya, we focus 
            on treating the root cause of diseases rather than just addressing symptoms, ensuring long-term relief 
            and overall wellness for our patients.Whether you're dealing with chronic conditions or seeking preventative health, we are dedicated to guiding you toward optimal health with gentle, non-invasive treatments tailored to your needs.
          </p>
        </div>

        <div className="our-treatments">
          <h2>Our Treatments</h2>
          <div className="treatments-grid">
            <Link to="/treatments/urology" className="treatments-card">
              <img src={require('../images/treatment1.webp')} alt="Developmental Disorders" />
              <p>Urology(Kidney & Bladder)</p>
            </Link>
            <Link to="/treatments/female-troubles" className="treatments-card">
              <img src={require('../images/treatment2.webp')} alt="Children Diseases" />
              <p>Female Troubles</p>
            </Link>
            <Link to="/treatments/pediatric" className="treatments-card">
              <img src={require('../images/treatment10.webp')} alt="Thyroid Disorders" />
              <p>Child Care</p>
            </Link>
            <Link to="/treatments/skin" className="treatments-card">
              <img src={require('../images/treatment6.webp')} alt="Female Disorders" />
              <p>Skin Treatment</p>
            </Link>
            <Link to="/treatments/ent" className="treatments-card">
              <img src={require('../images/treatment7.webp')} alt="Allergies" />
              <p>ENTs</p>
            </Link>
            <Link to="/treatments/gastric-issues" className="treatments-card">
              <img src={require('../images/treatment4.webp')} alt="Skin Disorders" />
              <p>Gastric Issues</p>
            </Link>
          </div>
        </div>
      </section>
      <section className="why-choose-section">
      <h2>Why Choose Pure Integrated Homeopathy</h2>
      <div className="why-cards-container">
        <div className="why-card">
          <img src={require('../images/why1.webp')} alt="100% Organic" />
          <h3>100% Natural</h3>
          <p>100% natural integrated homeopathy uses pure, natural remedies to treat the root cause of health issues. It supports the body’s natural healing, ensuring safe and effective care for overall well-being.</p>
        </div>
        <div className="why-card">
          <img src={require('../images/why2.webp')} alt="Chemical Less" />
          <h3>Less Chemical</h3>
          <p>No chemical homeopathy uses only natural, chemical-free remedies to promote healing. It treats the root cause of health issues safely and effectively, supporting the body’s natural balance for overall wellness.</p>
        </div>
        <div className="why-card">
          <img src={require('../images/why3.webp')} alt="Tested Product" />
          <h3>Tested & Verified Product</h3>
          <p>Tested homeopathic products use safe, natural remedies to treat health issues at the root, supporting the body’s healing process.</p>
        </div>
        <div className="why-card">
          <img src={require('../images/why4.webp')} alt="Pure Ayurveda" />
          <h3>Pure Homeopathy</h3>
          <p>Pure homeopathy uses natural, chemical-free remedies to address the root cause of health issues, promoting safe and effective healing.</p>
        </div>
      </div>
    </section>
      <section className="clinic-overview-section">
        <div className="mission-vision-container">
          <div className="vision-card">
            <h2>Our Vision</h2>
            <p>
              To generate the awareness of homeopathy to each and every human being in this world. 
              To be recognized as pioneers in the homeopathy system and provide life-saving treatment to the sick of the disease.
            </p>
          </div>
          <div className="mission-card">
            <h2>Our Mission</h2>
           <p>
              To raise hope of life in patients who have been discouraged by diseased conditions.
             To develop faith in homeopathy.
              To reach every corner of the world with homeopathy and treat the needy.
            </p>
          </div>
        </div>
      </section>
      <div className="awards-recognition-section" style={{ margin: '0 256px' }}>
      <h2>Awards and Recognition</h2>
      <Slider {...settings}>
        <div className="award-card">
          <img src={require('../images/hero1.webp')} alt="Award 1" className="award-image" />
          <h3>Award Title 1</h3>
        </div>
        <div className="award-card">
          <img src={require('../images/hero2.webp')} alt="Award 2" className="award-image" />
          <h3>Award Title 2</h3>
        </div>
        <div className="award-card">
          <img src={require('../images/hero3.webp')} alt="Award 3" className="award-image" />
          <h3>Award Title 3</h3>
        </div>
        <div className="award-card">
          <img src={require('../images/hero1.webp')} alt="Award 4" className="award-image" />
          <h3>Award Title 4</h3>
        </div>
        <div className="award-card">
          <img src={require('../images/hero2.webp')} alt="Award 5" className="award-image" />
          <h3>Award Title 5</h3>
        </div>
        <div className="award-card">
          <img src={require('../images/hero3.webp')} alt="Award 6" className="award-image" />
          <h3>Award Title 6</h3>
        </div>
      </Slider>
    </div>
    <section className="faq-book-appointment-section">
  <div className="faq-book-container">
    <div className="faq-card">
      <h2>Frequently Asked Questions</h2>
      <ul>
        <li><strong>Q: How does homeopathy work?</strong>
          <p>A: Homeopathy works by stimulating the body's natural healing processes to treat the underlying cause of illness.</p>
        </li>
        <li><strong>Q: Is homeopathy safe for children?</strong>
          <p>A: Yes, homeopathic treatments are safe for people of all ages, including children and infants.</p>
        </li>
        <li><strong>Q: How long does it take to see results?</strong>
          <p>A: The duration varies depending on the individual's health condition and response to treatment. Chronic cases may take longer.</p>
        </li>
      </ul>
    </div>
    <div className="books-appointment-card">
  <h2>Contact Us</h2>
  <form 
    action="https://api.web3forms.com/submit" 
    method="POST"
    onSubmit={() => alert("Your contact request has been submitted successfully!")}
  >
    {/* Web3Forms Access Key */}
    <input
      type="hidden"
      name="access_key"
      value="90277967-72b9-4089-93c9-5ec2b7137a38" // Replace with your actual Web3Forms access key
    />
    
    <label htmlFor="name">Name:</label>
    <input 
      type="text" 
      id="name" 
      name="name" 
      required 
    />

    <label htmlFor="email">Email:</label>
    <input 
      type="email" 
      id="email" 
      name="email" 
      required 
    />

    <label htmlFor="phone">Phone:</label>
    <input 
      type="tel" 
      id="phone" 
      name="phone" 
      required 
    />

    <button type="submit" className="submit-book-appointment-btn">Submit</button>
  </form>
</div>














    {/* <div className="books-appointment-card">
      <h2>Contact Us</h2>
      <form>
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="phone">Phone:</label>
        <input type="tel" id="phone" name="phone" required />

        <button type="submit" className="submit-book-appointment-btn">Submit</button>
      </form>
    </div> */}
  </div>
</section>
    </div>
  );
};

export default AboutUs;