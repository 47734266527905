import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const LiverAbscessDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Liver Abscess Diet Guide</h1>
      <p className="diet-description">A proper diet can help manage liver abscess by supporting liver function, reducing inflammation, and aiding the body’s healing process. Below are suggestions for foods to take and avoid when dealing with liver abscess.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Fiber Foods</h3>
          <p className="diet-text">Fiber-rich foods such as whole grains, fruits, vegetables, and legumes help maintain liver health and aid in detoxification. They also support digestion and prevent strain on the liver.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Lean Proteins</h3>
          <p className="diet-text">Lean protein sources like chicken, turkey, tofu, and fish provide essential nutrients without putting excessive strain on the liver. These proteins are essential for tissue repair and recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Antioxidants found in berries, leafy greens, and citrus fruits help reduce inflammation and support the liver’s healing process. These foods help neutralize free radicals that could harm the liver.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Healthy Fats</h3>
          <p className="diet-text">Healthy fats from sources like avocados, olive oil, and nuts help reduce inflammation and promote liver health without contributing to fatty liver conditions.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Proper hydration is essential for detoxification and overall liver function. Drink plenty of water and include hydrating foods like cucumbers and watermelon to aid the liver's recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods help maintain gut health, which is closely linked to liver function. A healthy gut supports the body’s ability to fight infection and inflammation.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried and high-fat foods, such as fast food, red meat, and processed snacks, are hard to digest and can overload the liver, worsening inflammation and impairing recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined grains like white bread, pasta, and pastries can contribute to fatty liver conditions and hinder recovery from a liver abscess. Opt for whole grains to support liver health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Sugary Foods and Drinks</h3>
          <p className="diet-text">Sugary snacks and beverages like candy, soda, and baked goods increase liver fat and contribute to inflammation. Reducing sugar intake is essential for liver recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol is highly toxic to the liver, especially when it is already compromised by an abscess. Complete avoidance of alcohol is crucial for proper healing and liver recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in unhealthy fats, sodium, and additives that can strain the liver and increase inflammation. Stick to whole, unprocessed foods for better liver health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">While moderate caffeine intake can be safe, excessive caffeine can dehydrate the body and strain the liver. Limit caffeine intake and prioritize hydration.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with chia seeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu salad with spinach, avocado, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support liver function and detoxification.</li>
        </ul>
      </section>
    </div>
  );
};

export default LiverAbscessDiet;
