import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const FibroadenomaDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Fibroadenoma Diet Guide</h1>
      <p className="diet-description">A proper diet can help manage fibroadenoma by reducing inflammation, balancing hormones, and supporting overall breast health. Below are suggestions for foods to take and avoid when managing fibroadenoma.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-inflammatory Foods</h3>
          <p className="diet-text">Foods like berries, leafy greens, fatty fish (salmon, mackerel), and nuts are rich in Omega-3 fatty acids and antioxidants, which help reduce inflammation and may alleviate symptoms of fibroadenoma.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Fiber-Rich Foods</h3>
          <p className="diet-text">A diet high in fiber, such as whole grains, fruits, vegetables, and legumes, helps regulate estrogen levels, which may reduce the risk of fibroadenoma growth and improve overall breast health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Cruciferous Vegetables</h3>
          <p className="diet-text">Cruciferous vegetables like broccoli, kale, and cauliflower contain compounds that help balance estrogen levels, reducing the risk of benign breast conditions like fibroadenoma.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Healthy Fats</h3>
          <p className="diet-text">Incorporating healthy fats from sources like avocados, olive oil, flaxseeds, and walnuts can promote hormonal balance and support breast health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Green Tea</h3>
          <p className="diet-text">Green tea is rich in antioxidants and may have anti-estrogenic properties, which can help reduce the size or occurrence of fibroadenomas.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Probiotic-Rich Foods</h3>
          <p className="diet-text">Foods like yogurt, kefir, and fermented vegetables help maintain a healthy balance of bacteria in the body, which supports the immune system and hormonal health.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Fat Dairy Products</h3>
          <p className="diet-text">High-fat dairy products can increase estrogen levels, which may contribute to the growth of fibroadenomas. Choose low-fat or plant-based dairy alternatives to support hormonal balance.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed and Fried Foods</h3>
          <p className="diet-text">Processed and fried foods are often high in unhealthy fats and additives that promote inflammation and disrupt hormone balance. Avoid fast food and processed snacks for better breast health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbohydrates, such as white bread, pastries, and sugary cereals, can cause insulin spikes and disrupt hormone levels. Opt for whole grains and complex carbohydrates instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can cause insulin resistance and hormonal imbalances. Reducing sugar intake from sweets, sodas, and baked goods can help prevent fibroadenoma growth.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol consumption can increase estrogen levels and disrupt hormonal balance, potentially worsening fibroadenoma symptoms. It’s best to limit or avoid alcohol to support breast health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">Excessive caffeine intake may worsen breast pain and tenderness associated with fibroadenoma. Reducing coffee, tea, and energy drinks can help alleviate symptoms.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with chia seeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and a spinach salad with olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a smoothie with spinach, ginger, and flaxseeds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked tofu or chicken with roasted sweet potatoes and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include green tea for its antioxidant benefits.</li>
        </ul>
      </section>
    </div>
  );
};

export default FibroadenomaDiet;
