import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const TonsillitisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Tonsillitis Diet Guide</h1>
      <p className="diet-description">A soothing diet can help manage tonsillitis by reducing inflammation and discomfort. Below are suggestions for foods to take and avoid to ease the symptoms of tonsillitis and promote faster recovery.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Soft and Hydrating Foods</h3>
          <p className="diet-text">Smooth, soft foods like soups, smoothies, yogurt, and mashed potatoes can be easier to swallow and help soothe the throat during tonsillitis.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Warm Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile or ginger can provide soothing relief to the throat and help reduce inflammation. Add honey for extra soothing benefits.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Hydrating Foods and Fluids</h3>
          <p className="diet-text">Staying hydrated is important for recovery. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of fluids such as water, herbal teas, and clear broths.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Cold and Cooling Foods</h3>
          <p className="diet-text">Cool foods like ice cream, frozen yogurt, and cold smoothies can help numb the throat and reduce discomfort, providing temporary relief from pain.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Honey</h3>
          <p className="diet-text">Honey is known for its soothing and antibacterial properties. A spoonful of honey or adding it to warm drinks can help reduce throat irritation and support healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, and foods rich in Omega-3s, like salmon and flaxseeds, can help reduce inflammation and support the immune system during recovery.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Hard, Crunchy Foods</h3>
          <p className="diet-text">Hard foods like chips, crackers, and toast can scratch or irritate the throat. Avoid these to reduce discomfort and promote healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can cause further irritation to an already inflamed throat. Reduce or eliminate spicy dishes until the throat is fully healed.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Acidic Foods</h3>
          <p className="diet-text">Acidic foods like citrus fruits, tomatoes, and vinegar-based products can worsen throat irritation. Avoid these foods to prevent aggravation of symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can weaken the immune system and slow down recovery. Avoid sugary snacks, sodas, and desserts while recovering from tonsillitis.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Dairy Products (for some)</h3>
          <p className="diet-text">For some individuals, dairy products may increase mucus production, which can aggravate throat irritation. If dairy causes discomfort, consider reducing intake during recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeinated Beverages</h3>
          <p className="diet-text">Caffeine can dehydrate the body, worsening throat dryness and discomfort. Stick to water, herbal teas, and other hydrating fluids instead.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with bananas, yogurt, and honey.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Chicken soup with soft vegetables and a warm herbal tea with honey.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Mashed sweet potatoes or applesauce.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Soft, baked salmon with mashed potatoes and a side of steamed spinach.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas or clear broths to stay hydrated and soothe the throat.</li>
        </ul>
      </section>
    </div>
  );
};

export default TonsillitisDiet;
