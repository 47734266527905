import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/hh1.webp')}alt="Urology Hero" />
        <h1>Thyroid Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Thyroid</h2>
          <img src={require('../images/hormones1.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <ul>
            <li><h5>Individualized Remedies:</h5>Homeopathy treats each person uniquely, selecting remedies based on specific symptoms, emotional state, and overall constitution.</li>
            <li><h5>Miasmatic Analysis:</h5>Homeopaths may consider underlying miasms (inherited predispositions) that could influence thyroid dysfunction.</li>
            <li><h5>Potency and Dosage:</h5>Remedies are prescribed in varying potencies and dosages tailored to the individual’s response and sensitivity.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li><h5>Autoimmune Factors:</h5>Conditions like Hashimoto’s thyroiditis and Graves’ disease involve immune system dysfunction targeting the thyroid.</li>
            <li><h5>Environmental Factors:</h5> Exposure to radiation, certain medications (e.g., lithium), and iodine deficiency can contribute to thyroid disorders.</li>
            <li><h5>Genetic Predisposition: </h5>Family history of thyroid disease can increase the likelihood of developing thyroid disorders.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Thyroid Function Tests:  </h5>Includes TSH (thyroid-stimulating hormone), free T3 and T4 levels.</li>
            <li><h5>Imaging: </h5> Ultrasound for evaluating thyroid size and nodules.</li>
            <li><h5>Biopsy:</h5>Fine-needle aspiration (FNA) biopsy to assess thyroid nodules for malignancy or benignity.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Hypothyroidism:</h5>Potential complications include weight gain, fatigue, depression, and cardiovascular issues.</li>
            <li><h5>Hyperthyroidism:</h5> Complications may involve heat intolerance, palpitations, osteoporosis, and thyroid storm in severe cases.</li>
            <li><h5>Goiter and Nodules:</h5>Enlargement of the thyroid gland (goiter) and development of nodules, some of which may require further investigation for cancer.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Hypothyroidism:</h5> Levothyroxine supplementation to replace thyroid hormone.</li>
            <li><h5>Hyperthyroidism:</h5>Antithyroid medications (e.g., methimazole), radioactive iodine therapy, or surgical removal of the thyroid gland.</li>
            <li><h5>Monitoring and Adjustments: </h5>Regular thyroid function tests to adjust medication doses as needed.</li>
          </ul>
          <h3>Homeopathic Supportive Care:</h3>
          <p>Homeopathy offers remedies based on stone type and individual symptoms:</p>
          <ul>
            <li><h5>Remedy Selection:</h5>Examples like Calcarea carbonica for sluggish metabolism and Lycopodium for anxiety and digestive issues.</li>
            <li><h5>Symptom-Based Approach:</h5>Remedies like Sepia for hormonal imbalances and Thyroidinum for general thyroid support.</li>
            <li><h5>Long-Term Management:</h5>Continual assessment and adjustment of remedies based on changes in symptoms and overall health.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathic treatment for thyroid disorders aims to address underlying imbalances and support overall health. While it provides individualized care, severe cases or emergencies like thyroid storm require immediate conventional medical attention. Collaborative care with healthcare professionals ensures comprehensive management and monitoring of thyroid health.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Thyroid Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Thyroid conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/hormonal-diseases/thyroid/thyroid-diet">Diet for Thyroid</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Thyroid</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Thyroid</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Thyroid Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/OIdKrJ2IYK0?si=jhejRXbxuGGQP8u5"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
