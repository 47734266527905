import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const FemaleInfertilityDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Female Infertility Diet Guide</h1>
      <p className="diet-description">A balanced diet can support reproductive health by improving hormonal balance and overall well-being. Below are suggestions for foods to take and avoid when managing female infertility.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Leafy Greens and Vegetables</h3>
          <p className="diet-text">Leafy greens like spinach, kale, and broccoli are rich in folate, iron, and antioxidants, which are essential for reproductive health and hormone regulation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Healthy Fats</h3>
          <p className="diet-text">Incorporate healthy fats from sources like avocados, nuts, seeds, and olive oil. These fats help balance hormones and support egg quality.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Whole Grains</h3>
          <p className="diet-text">Whole grains such as quinoa, brown rice, and oats provide steady energy and help regulate insulin levels, which is crucial for fertility.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Protein from Plant-Based Sources</h3>
          <p className="diet-text">Plant-based protein from beans, lentils, and tofu is beneficial for fertility. Studies show that replacing some animal proteins with plant proteins can improve fertility.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3 fatty acids, found in fatty fish like salmon and flaxseeds, play a crucial role in regulating hormones and reducing inflammation, which can impact fertility.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Berries and Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries like blueberries and strawberries are packed with antioxidants that protect eggs from oxidative stress and improve reproductive health.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Trans Fats</h3>
          <p className="diet-text">Trans fats, found in processed foods like fried snacks, margarine, and fast food, can negatively impact ovulation and increase the risk of infertility.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. High-Sugar Foods</h3>
          <p className="diet-text">Sugary foods and drinks, such as soda and sweets, can cause insulin resistance and disrupt hormone levels, making it harder to conceive.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Excessive Caffeine</h3>
          <p className="diet-text">High amounts of caffeine, especially from coffee or energy drinks, have been linked to a lower chance of conception. Limit caffeine intake to one cup a day or less.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Carbohydrates</h3>
          <p className="diet-text">Refined carbohydrates, such as white bread, pastries, and sugary cereals, can spike insulin levels and affect fertility negatively. Choose whole grains instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Excessive alcohol consumption can interfere with ovulation and reduce fertility. It's best to avoid alcohol or limit it significantly when trying to conceive.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. High-Mercury Fish</h3>
          <p className="diet-text">Certain fish like swordfish, king mackerel, and shark are high in mercury, which can harm reproductive health. Opt for low-mercury fish like salmon or shrimp.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Avocado toast on whole grain bread with a poached egg and a side of mixed berries.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Quinoa salad with chickpeas, spinach, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or walnuts with an apple.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Grilled salmon with roasted sweet potatoes and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and limit caffeine and alcohol consumption.</li>
        </ul>
      </section>
    </div>
  );
};

export default FemaleInfertilityDiet;
