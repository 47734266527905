import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const BladderPolypsDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Bladder Polyps Diet Guide</h1>
      <p className="diet-description">A proper diet can help manage bladder polyps by supporting overall bladder health and reducing inflammation.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">1. Stay Hydrated</h2>
        <p className="diet-text">Drinking plenty of water helps flush toxins and irritants out of the bladder, potentially reducing inflammation and preventing complications. Aim for at least 8-10 cups of water daily.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">2. Avoid Irritating Foods</h2>
        <p className="diet-text">Foods and drinks that irritate the bladder, such as caffeine, alcohol, spicy foods, and artificial sweeteners, should be limited or avoided as they may worsen symptoms.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">3. Focus on Anti-inflammatory Foods</h2>
        <p className="diet-text">Include anti-inflammatory foods such as leafy greens, berries, fatty fish (like salmon), and nuts. These can help reduce inflammation and support bladder health.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">4. Limit Processed Foods</h2>
        <p className="diet-text">Processed foods, particularly those high in sodium, additives, and preservatives, can irritate the bladder. Opt for fresh, whole foods whenever possible.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">5. Increase Fiber Intake</h2>
        <p className="diet-text">A diet high in fiber can help prevent constipation, which can place extra pressure on the bladder. Whole grains, fruits, and vegetables are excellent sources of fiber.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">6. Avoid Acidic Foods</h2>
        <p className="diet-text">Citrus fruits, tomatoes, and other acidic foods can irritate the bladder lining and should be limited if they worsen symptoms.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">7. Eat Probiotic-Rich Foods</h2>
        <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods can help maintain a healthy balance of bacteria in the urinary tract, which may prevent infections that could worsen bladder polyps.</p>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Whole grain toast with avocado and a side of mixed berries.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with a quinoa salad and steamed spinach.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A probiotic-rich yogurt or a handful of almonds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with roasted sweet potatoes and a green salad.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day to stay hydrated.</li>
        </ul>
      </section>
    </div>
  );
};

export default BladderPolypsDiet;
