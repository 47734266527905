import React from 'react';
import '../../css/CaseStudy.css';

const LichenPlanusCaseStudy = () => {
  return (
    <div className="case-study-page">
      <div className="case-study-hero">
        <h1>Master Shashank’s Journey to Recovery from Lichen Planus with Homeopathy</h1>
        {/* <img src={require('../images/case-study-hero5.jpg')} alt="Lichen Planus Recovery" className="case-study-img" /> */}
      </div>

      <div className="case-study-content">
        <p>
          Master Shashank, a 9-year-old from Gurgaon, had been dealing with lichen planus for years. His skin was covered with dark, itchy spots, which caused him a lot of discomfort. His mother had tried allopathic and Ayurvedic treatments for six years, but nothing worked long-term. Worried and searching for another option, she found our clinic on Google and decided to try homeopathy, starting treatment on November 11, 2017.
        </p>

        <h2>A Fresh Start with Homeopathy</h2>
        <p>
          Shashank’s mother wasn’t sure if homeopathy would help, as nothing had worked in the past. However, during the initial consultation, our doctor discussed his symptoms and past treatments and created a customized plan for him. The doctor explained that lichen planus, a long-term skin issue, would take time to treat naturally and that follow-ups every month would help us track his progress closely.
        </p>

        <h2>Slow but Steady Progress</h2>
        <p>
          Shashank’s mother was asked to be patient and follow the treatment plan consistently. While homeopathy acts slowly, it strengthens the body’s healing. Alongside his homeopathic remedies, Shashank’s mother followed skincare and diet advice to support the process.
        </p>
        <p>
          In the first few months, changes were minor. Then, about six months in, Shashank’s mother noticed improvements—itching reduced, and his skin tone started evening out. Although some spots remained, these early signs gave her hope.
        </p>

        <h2>Major Improvements Over Time</h2>
        <p>
          With each monthly follow-up, Shashank’s condition steadily improved. His doctor adjusted his treatment plan as needed. After a year, most of the dark spots had lightened, and the itching was almost gone. After two years, Shashank’s skin was nearly clear, with no more dark patches or discomfort. By the end of three and a half years, his skin was completely normal, with no lesions or itching.
        </p>

        <h2>Life After Treatment</h2>
        <p>
          After three and a half years of homeopathic treatment, Shashank stopped the medication. Now, four years later, he remains free of symptoms. His skin is clear, and he feels great. Shashank’s mother, who had once been deeply worried, is thrilled with his recovery and grateful that homeopathy brought long-lasting relief.
        </p>

        <h2>Final Thoughts</h2>
        <p>
          Shashank’s journey shows the power of patience and consistency in long-term healing. His success with homeopathy offers hope to others dealing with chronic skin issues, showing that recovery is possible with the right guidance and commitment.
        </p>
      </div>
    </div>
  );
};

export default LichenPlanusCaseStudy;
