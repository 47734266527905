import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const NailsDisorderDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Nails Disorder Diet Guide</h1>
      <p className="diet-description">A nutrient-rich diet can help improve nail strength and health, reducing the risk of brittle nails, discoloration, or other nail disorders. Below are suggestions for foods to take and avoid when managing nail disorders.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Biotin-Rich Foods</h3>
          <p className="diet-text">Biotin is essential for nail strength and health. Include biotin-rich foods like eggs, almonds, walnuts, and sweet potatoes to support healthy nails.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Protein-Rich Foods</h3>
          <p className="diet-text">Keratin, the main structural protein in nails, requires adequate protein intake. Include lean meats, fish, tofu, and legumes to boost nail strength.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc plays an important role in cell growth and nail tissue repair. Include foods like nuts, seeds, legumes, and whole grains to maintain healthy nails.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Iron-Rich Foods</h3>
          <p className="diet-text">Iron deficiency can lead to brittle or ridged nails. Leafy greens, lentils, beans, and lean meats are excellent sources of iron for nail health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s help hydrate and nourish nails, preventing them from becoming dry and brittle. Fatty fish, flaxseeds, and walnuts are good sources.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamin E-Rich Foods</h3>
          <p className="diet-text">Vitamin E is important for maintaining moisture in nails and preventing dryness. Include almonds, sunflower seeds, and spinach for strong, healthy nails.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can weaken the immune system and reduce nutrient absorption, leading to brittle nails. Avoid sugary snacks, sodas, and processed foods to maintain nail health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs like white bread, pasta, and baked goods can spike blood sugar and interfere with the absorption of essential nutrients for nail health. Opt for whole grains instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and those high in unhealthy fats can block nutrient absorption and dehydrate nails. Choose healthier fats from sources like avocados, nuts, and olive oil.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">Processed foods are often lacking in essential nutrients and may contribute to poor nail health. Stick to whole, natural foods to support stronger nails.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Excessive alcohol consumption can dehydrate the body and weaken nails, making them more prone to breakage. Limit alcohol intake to support healthy nails.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Excessive Caffeine</h3>
          <p className="diet-text">While moderate caffeine intake is generally fine, excessive amounts can dehydrate the body and weaken nails. Keep caffeine consumption balanced.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon or tofu salad with quinoa and mixed greens.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a biotin-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked chicken with sweet potatoes and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support nail hydration and strength.</li>
        </ul>
      </section>
    </div>
  );
};

export default NailsDisorderDiet;
