import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const ClassicalHeadachesDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Classical Headaches Diet Guide</h1>
      <p className="diet-description">A balanced diet can help prevent and manage classical headaches (like tension headaches) by reducing triggers and supporting overall brain and nervous system health. Below are suggestions for foods to take and avoid when managing headaches.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium helps relax muscles and reduce tension, a common cause of headaches. Include leafy greens, seeds, almonds, and avocados in your diet to prevent headaches.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Hydrating Foods</h3>
          <p className="diet-text">Dehydration is a common headache trigger. Include water-rich foods like cucumbers, melons, and citrus fruits, and ensure you drink plenty of water throughout the day to stay hydrated.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish like salmon, flaxseeds, and walnuts help reduce inflammation, which can help prevent headaches caused by tension and stress.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Whole Grains</h3>
          <p className="diet-text">Whole grains like oats, quinoa, and brown rice provide sustained energy and help prevent blood sugar fluctuations, which are often linked to headaches.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help reduce inflammation and support brain health, potentially preventing headaches.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. B-Vitamin Rich Foods</h3>
          <p className="diet-text">B vitamins, particularly B2 (riboflavin) and B6, help reduce the frequency of headaches. Include eggs, dairy, spinach, and fortified cereals for B-vitamin support.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Caffeine (in excess)</h3>
          <p className="diet-text">While small amounts of caffeine can relieve headaches, excessive caffeine or withdrawal can trigger them. Keep caffeine intake moderate and consistent.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Meats</h3>
          <p className="diet-text">Processed meats like bacon, sausage, and deli meats contain nitrates and preservatives that can trigger headaches. Opt for fresh, unprocessed proteins instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can cause blood sugar spikes and crashes, leading to headaches. Avoid sugary snacks, sodas, and desserts to maintain stable energy levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Artificial Sweeteners</h3>
          <p className="diet-text">Aspartame and other artificial sweeteners have been linked to headaches. Avoid diet sodas and products containing artificial sweeteners to reduce headache risk.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol, especially red wine, can trigger headaches in some individuals. Limiting alcohol intake can help prevent headache episodes.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. MSG (Monosodium Glutamate)</h3>
          <p className="diet-text">MSG, commonly found in processed and restaurant foods, is a known headache trigger. Avoid foods with MSG to reduce the likelihood of headaches.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with chia seeds, berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with quinoa and steamed spinach.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a magnesium-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with brown rice and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support hydration and prevent headaches.</li>
        </ul>
      </section>
    </div>
  );
};

export default ClassicalHeadachesDiet;
