import React from "react";
import "../css/ForPatient.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebook, faTwitter, faInstagram, faLinkedin} from "@fortawesome/free-brands-svg-icons";


const ForPatient = () => {
  return (
    <div className="for-patient-page">
      <div className="patient-hero-section">
        <img
          src={require("../images/99.webp")}
          alt="For Patient Hero"
          className="patient-hero-img"
        />
        <div className="patient-hero-overlay">
  <h1 className="text-white">Book Your Online Consultation Now</h1>
  <h3 className="text-white">
    Get expert advice from the comfort of your home.
  </h3>
  {/* Use Link instead of a tag */}
  <Link to="/patientbookappointment" className="book-btn">
    Book Appointment
  </Link>
</div>

      </div>

      <div className="book-appointment-section">
        <div className="appointment-container">
          <div className="image-column">
            <h2 className="image-section-heading">
              Steps to Book Appointment{" "}
            </h2>

            <img
              src={require("../images/appointmentstep.webp")}
              alt="Steps for Appointment"
              className="steps-image"
            />
          </div>

          <div className="form-column">
            <h3>Ask any Query</h3>
            <form
              className="appointment-form"
              action="https://api.web3forms.com/submit"
              method="POST"
              onSubmit={() =>
                alert(
                  "Your appointment request has been submitted successfully!"
                )
              }
            >
              {/* Web3Forms Access Key */}
              <input
                type="hidden"
                name="access_key"
                value="90277967-72b9-4089-93c9-5ec2b7137a38"
              />

              <label>Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter your name *"
                required
              />

              <label>Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email *"
                required
              />

              <label>Phone Number</label>
              <input
                type="tel"
                name="phone"
                placeholder="Enter your phone number *"
                required
              />

              <label>Symptoms/Problems</label>
              <textarea
                name="message"
                placeholder="Describe your symptoms *"
                required
              ></textarea>

              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>
      <div className="safety-guidelines-section">
        <h2 className="safety-heading">Patient Safety Guidelines</h2>
        <ul className="safety-guidelines-list">
          <li>Wear a mask at all times within the clinic premises.</li>
          <li>Sanitize your hands before entering the clinic.</li>
          <li>Maintain a minimum distance of 6 feet from others.</li>
          <li>Avoid touching surfaces unnecessarily.</li>
          <li>
            If you have symptoms of COVID-19, please inform the staff before
            your appointment.
          </li>
          <li>
            Follow the instructions provided by the clinic staff for your
            safety.
          </li>
        </ul>
      </div>
      <div className="get-in-touch-section">
        <div className="patient-contact-info">
          <h1 className="heading">
            GET IN TOUCH <span className="highlight">WITH US</span>
          </h1>
          <p className="description">
            Thank you for visiting our website. Please feel free to contact us
            at our email. You may also submit a contact form. We would be glad
            to send you more information about our services.
          </p>

          <div className="email-info">
            <p>
              <strong>EMAIL US</strong>
            </p>
            <p>
              Support:{" "}
              <a href="tel:+919236185711" className="contact-link">
                +919236185711
              </a>
            </p>
          </div>

          <div className="call-info">
            <p>
              <strong>CALL US</strong>
            </p>
            <p>
              <a
                href="mailto:niramayaforyou@gmail.com"
                className="contact-link"
              >
                niramayaforyou@gmail.com
              </a>
            </p>
          </div>

          <div className="social-icns">
            <p>
              <strong>WE ARE SOCIAL</strong>
            </p>
            <div className="social-icns">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#3b5998", margin: "0 8px" }}
              >
                <FontAwesomeIcon icon={faFacebook} size="x" />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#00aced", margin: "0 8px" }}
              >
                <FontAwesomeIcon icon={faTwitter} size="x" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#bc2a8d", margin: "0 8px" }}
              >
                <FontAwesomeIcon icon={faInstagram} size="x" />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#007bb6", margin: "0 8px" }}
              >
                <FontAwesomeIcon icon={faLinkedin} size="x" />
              </a>
            </div>
          </div>
        </div>

        <div className="patient-contact-form">
          <form
            action="https://api.web3forms.com/submit"
            method="POST"
            onSubmit={() =>
              alert("Your message has been submitted successfully!")
            }
          >
            {/* Web3Forms Access Key */}
            <input
              type="hidden"
              name="access_key"
              value="90277967-72b9-4089-93c9-5ec2b7137a38"
            />

            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your name *"
              required
            />

            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email address *"
              required
            />

            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone number with country code *"
              required
            />

            <label htmlFor="country">Select Country</label>
            <select id="country" name="country" required>
              <option value="">Select Country *</option>
              <option value="India">India</option>
              <option value="USA">USA</option>
              <option value="UK">UK</option>
            </select>

            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Your message *"
              required
            ></textarea>

            <button type="submit" className="submit-btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ForPatient;
