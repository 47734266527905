import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/pedia1.webp')}alt="Urology Hero" />
        <h1>Recurrent cough and cold Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Recurrent cough and cold</h2>
          <img src={require('../images/child1.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy takes a holistic and individualized approach to treating recurrent cough and cold. It focuses on identifying and addressing the root cause of the illness rather than merely alleviating symptoms. This involves a thorough understanding of the patient’s overall constitution, susceptibility to illness, lifestyle, and specific symptom presentation. The guiding principle of homeopathy is “like cures like,” which means that a substance causing symptoms in a healthy person can be used to treat similar symptoms in a sick person.
          </p>
          <h3>Causes</h3>
          <p>Recurrent cough and cold can result from a variety of causes, including:</p>
          <ul>
            <li><h5>Viral Infections:</h5> The most common cause, often due to rhinoviruses.</li>
            <li><h5>Bacterial Infections:</h5> Secondary infections like sinusitis, bronchitis, or strep throat.</li>
            <li><h5>Allergies:</h5> Exposure to allergens such as pollen, dust mites, mold, and pet dander.</li>
            <li><h5>Environmental Factors:</h5> Exposure to pollutants, sudden changes in weather, and cold, dry air.</li>
            <li><h5>Weak Immune System:</h5> A compromised immune system due to chronic illness, poor nutrition, or lack of sleep.</li>
            <li><h5>Lifestyle Factors:</h5>  Stress, poor diet, inadequate sleep, and lack of physical activity can contribute to frequent illnesses.</li>
          </ul>
          <h3>Investigations</h3>
          <p>To diagnose the underlying cause of recurrent cough and cold, the following investigations are typically recommended:</p>
          <ul>
            <li><h5>Medical History: </h5>A detailed history to understand the frequency, duration, and potential triggers of symptoms.</li>
            <li><h5>Physical Examination: </h5>A thorough examination of the respiratory system and overall health.</li>
            <li><h5>Allergy Testing:</h5> To identify specific allergens responsible for symptoms.</li>
            <li><h5>Blood Tests:</h5> To check for signs of infection, immune deficiencies, or other underlying conditions.</li>
            <li><h5>Chest X-ray:</h5> To rule out serious conditions such as pneumonia or chronic respiratory disease.</li>
            <li><h5>Nasal Swabs: </h5>To detect bacterial or viral pathogens.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <p>Recurrent cough and cold can lead to several complications, including:</p>
          <ul>
            <li><h5>Sinusitis:</h5> Inflammation and infection of the sinuses.</li>
            <li><h5>Otitis Media:</h5>Middle ear infections, particularly common in children.</li>
            <li><h5>Bronchitis:</h5> Inflammation of the bronchial tubes, leading to persistent cough.</li>
            <li><h5>Asthma:</h5> Exacerbation of asthma symptoms, leading to increased frequency and severity of attacks.</li>
            <li><h5>Pneumonia:</h5> A serious lung infection requiring prompt medical attention. Risk factors include:</li>
            <li><h5>Age:  </h5> Young children and elderly individuals are more susceptible.</li>
            <li><h5>Existing Respiratory Conditions:</h5>Homeopathic medicine for lungs infectionhelp from conditions like asthma or chronic obstructive pulmonary disease (COPD).</li>
            <li><h5>Smoking:</h5> Increases susceptibility to respiratory infections and slows recovery.</li>
            <li><h5>Crowded Environments: </h5> Higher risk of exposure to infectious agents in schools, daycare centers, and public places.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>Conventional treatment focuses on relieving symptoms and may include:</p>
          <ul>
            <li><h5>Rest and Hydration:</h5> Essential for recovery and preventing dehydration.</li>
            <li><h5>Over-the-Counter Medications:</h5>  Decongestants, antihistamines, and cough suppressants to relieve symptoms.</li>
            <li><h5>Pain Relievers:</h5> Such as acetaminophen or ibuprofen to reduce fever and discomfort.</li>
            <li><h5>Antibiotics:</h5> Prescribed only if a bacterial infection is confirmed, as they are ineffective against viral infections.</li>
            <li><h5>Nasal Irrigation:</h5> Using saline solutions to clear nasal passages and reduce congestion.</li>
            <li><h5>Steam Inhalation:</h5>  Helps to ease nasal congestion and soothe irritated airways.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <p>Homeopathy offers a range of remedies tailored to the individual’s specific symptoms and overall constitution:</p>
          <ul>
            <li><h5>Allium Cepa: </h5>For colds with profuse, watery nasal discharge that irritates the nostrils and eyes.</li>
            <li><h5>Arsenicum Album: </h5> For colds with burning nasal discharge, restlessness, and anxiety.</li>
            <li><h5>Belladonna:</h5> For sudden onset of cold with high fever, red face, and throbbing headache.</li>
            <li><h5>Natrum Muriaticum: </h5>For colds with sneezing, watery nasal discharge, and symptoms triggered by emotional stress or grief.</li> 
            <li><h5>Pulsatilla:</h5> For colds with thick, yellow-green discharge, especially when symptoms change frequently and there is a desire for fresh air.</li>
            <li><h5>Euphrasia:</h5>  For colds with profuse, irritating nasal discharge and watery eyes.</li>
            </ul>
            <p>Homeopathic remedies are prescribed in minute doses, tailored to the patient’s symptom profile and overall constitution. A thorough consultation with a qualified homeopath is essential to determine the most appropriate remedy and dosage.</p>
          <h3>Conclusion</h3>
          <p>In our experience, a holistic approach that combines lifestyle modifications, immune system support, and individualized homeopathic treatment can significantly reduce the frequency and severity of recurrent cough and cold. Patients often report not only relief from acute symptoms but also a general improvement in their overall health and resilience against future infections. By addressing the root cause and enhancing the body’s natural defenses, homeopathy provides a comprehensive and sustainable solution for recurrent respiratory ailments. Consulting with a qualified homeopath ensures personalized care and optimal outcomes.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Recurrent cough and cold Treatment</h3>
            <p>We offer natural, safe, and effective treatment & homeopathic medicine for cough and cold in winter for Recurrent cough and cold conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/pediatric/recurrent-cough-cold/recurrent-cough-cold-diet">Diet for Recurrent cough and cold</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Recurrent cough and cold</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Recurrent cough and cold</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Recurrent cough and cold Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/FfAM9hWeCBk?si=zWBgN9cJOr3E05lm"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
