import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const MenstrualDisorderDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Menstrual Disorder Diet Guide</h1>
      <p className="diet-description">A balanced diet can help manage menstrual disorders by supporting hormone balance, reducing inflammation, and promoting overall well-being. Below are suggestions for foods to take and avoid.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Iron-Rich Foods</h3>
          <p className="diet-text">Heavy periods can cause or worsen anemia, so it's essential to include iron-rich foods such as spinach, lentils, quinoa, and lean meats to replenish lost iron and support healthy blood production.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in flaxseeds, chia seeds, and fatty fish (salmon, mackerel), help reduce menstrual pain and inflammation, improving overall reproductive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Whole Grains</h3>
          <p className="diet-text">Whole grains such as brown rice, oats, and quinoa provide essential nutrients like magnesium and B-vitamins, which help regulate menstrual cycles and reduce PMS symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Calcium and Vitamin D-Rich Foods</h3>
          <p className="diet-text">Foods rich in calcium and vitamin D, such as low-fat dairy, fortified plant milk, and leafy greens, help reduce menstrual cramps and support hormonal balance.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Fiber-Rich Foods</h3>
          <p className="diet-text">Eating fiber-rich foods such as fruits, vegetables, and legumes can help regulate estrogen levels, which is essential for maintaining regular menstrual cycles and reducing PMS symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile, ginger, and cinnamon have anti-inflammatory properties and may help relieve menstrual cramps and improve mood during the menstrual cycle.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excessive sugar intake can cause insulin spikes and worsen PMS symptoms such as mood swings and bloating. Limiting sweets, sodas, and sugary snacks is recommended.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Caffeine</h3>
          <p className="diet-text">Caffeine can increase anxiety, irritability, and breast tenderness during menstruation. Reducing coffee, energy drinks, and high-caffeine teas can help manage these symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed Foods</h3>
          <p className="diet-text">Processed foods high in salt and additives, such as chips, canned soups, and fast food, can cause bloating and water retention, worsening menstrual discomfort. Opt for fresh, whole foods instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can disrupt hormone levels and exacerbate menstrual symptoms like bloating, headaches, and mood swings. Limiting or avoiding alcohol during your menstrual cycle can help manage symptoms better.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. High-Fat and Fried Foods</h3>
          <p className="diet-text">High-fat and fried foods can increase inflammation and estrogen levels, potentially leading to irregular periods and worsening PMS symptoms. Avoid greasy, fried snacks and meals.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbohydrates, such as white bread, pasta, and sugary cereals, can spike insulin levels and lead to hormonal imbalances, negatively affecting menstrual health. Stick to whole grains instead.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with chia seeds, a handful of berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa, steamed broccoli, and a spinach salad with olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A small handful of almonds or a smoothie with kale, ginger, and an apple.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Lentil soup with sautéed greens and roasted sweet potatoes.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include herbal teas like chamomile and ginger to help reduce cramps.</li>
        </ul>
      </section>
    </div>
  );
};

export default MenstrualDisorderDiet;
