import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const UTIDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Urinary Tract Infection (UTI) Diet Guide</h1>
      <p className="diet-description">A proper diet can help prevent and manage urinary tract infections by supporting bladder health and reducing bacterial growth.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">1. Stay Hydrated</h2>
        <p className="diet-text">Drinking plenty of water is essential for flushing out bacteria from the urinary tract. Aim for at least 8-10 cups of water daily to help prevent and manage UTIs.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">2. Include Cranberries and Cranberry Juice</h2>
        <p className="diet-text">Cranberries contain compounds that prevent bacteria from adhering to the bladder wall, reducing the risk of infection. Unsweetened cranberry juice or cranberries can be a helpful addition to your diet.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">3. Eat Probiotic-Rich Foods</h2>
        <p className="diet-text">Probiotics, found in yogurt, kefir, and fermented foods, help maintain a healthy balance of bacteria in the urinary tract, which can help prevent infections.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">4. Avoid Irritants</h2>
        <p className="diet-text">Limit bladder irritants such as caffeine, alcohol, spicy foods, and artificial sweeteners, as they can exacerbate UTI symptoms and irritate the bladder.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">5. Focus on Vitamin C-Rich Foods</h2>
        <p className="diet-text">Vitamin C can help acidify the urine, making it less hospitable to bacteria. Include foods such as oranges, strawberries, bell peppers, and broccoli in your diet to boost your intake.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">6. Increase Fiber Intake</h2>
        <p className="diet-text">A high-fiber diet helps maintain regular bowel movements, reducing the risk of bacteria spreading from the digestive tract to the urinary tract. Whole grains, fruits, and vegetables are excellent sources of fiber.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">7. Include Garlic</h2>
        <p className="diet-text">Garlic has natural antibacterial properties that can help fight off infections, making it a beneficial addition to your diet when dealing with UTIs.</p>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal with strawberries and a glass of unsweetened cranberry juice.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with a quinoa salad and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A small probiotic yogurt or a handful of almonds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with garlic and brown rice, served with a side of mixed greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day and include unsweetened cranberry juice for added UTI prevention benefits.</li>
        </ul>
      </section>
    </div>
  );
};

export default UTIDiet;
