import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const OvarianCystsDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Ovarian Cysts Diet Guide</h1>
      <p className="diet-description">A well-balanced diet can help manage ovarian cysts by reducing inflammation, promoting hormonal balance, and supporting overall reproductive health. Below are suggestions for foods to take and avoid.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Fiber-Rich Foods</h3>
          <p className="diet-text">Fiber-rich foods, such as whole grains, fruits, and vegetables, help regulate hormones by improving digestion and reducing insulin levels. This can help prevent the growth of ovarian cysts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3 fatty acids found in flaxseeds, chia seeds, and fatty fish (salmon, mackerel) have anti-inflammatory properties that may help reduce the symptoms of ovarian cysts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Lean Proteins</h3>
          <p className="diet-text">Lean protein sources, such as chicken, turkey, tofu, and legumes, support healthy hormone production and overall reproductive health without adding unhealthy fats.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants that help reduce oxidative stress, which can support hormonal balance and prevent ovarian cyst development.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium helps relax muscles and reduce cramps. Foods like spinach, almonds, and bananas are rich in magnesium and can alleviate symptoms related to ovarian cysts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like ginger, chamomile, and raspberry leaf tea can reduce inflammation, relieve pain, and support hormonal balance, making them helpful for managing ovarian cysts.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs, such as white bread, pastries, and sugary cereals, can cause insulin spikes and hormone imbalances, which may worsen ovarian cysts. Opt for whole grains instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Sugary foods and drinks can lead to insulin resistance and hormonal imbalances. Reducing sugar intake is crucial for managing ovarian cysts and maintaining overall reproductive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Red Meat</h3>
          <p className="diet-text">Red meat can increase estrogen levels and contribute to the growth of ovarian cysts. Limiting or avoiding red meat can help balance hormones and reduce inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in trans fats and additives that can increase inflammation and hormone disruption. Stick to fresh, whole foods to support reproductive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeine</h3>
          <p className="diet-text">Excessive caffeine intake can disrupt hormone levels and exacerbate ovarian cyst symptoms. Limiting or avoiding caffeinated beverages like coffee and energy drinks is recommended.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Alcohol</h3>
          <p className="diet-text">Alcohol can disrupt the metabolism of hormones, leading to hormonal imbalances that may worsen ovarian cysts. Reducing or eliminating alcohol can help improve overall hormonal health.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal topped with flaxseeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu salad with spinach, avocado, and a lemon-olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a smoothie with spinach, ginger, and turmeric.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and roasted vegetables (like broccoli and sweet potatoes).</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day and include herbal teas like ginger or chamomile to reduce inflammation and pain.</li>
        </ul>
      </section>
    </div>
  );
};

export default OvarianCystsDiet;
