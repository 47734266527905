import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const GHDDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Growth Hormone Deficiency (GHD) Diet Guide</h1>
      <p className="diet-description">A well-balanced diet can help support healthy growth and promote the body's ability to produce and respond to growth hormone. Below are suggestions for foods to take and avoid to manage growth hormone deficiency.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Protein-Rich Foods</h3>
          <p className="diet-text">Protein is essential for growth and muscle development. Include lean meats, eggs, dairy, legumes, and plant-based proteins like tofu and beans to support growth hormone production.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish like salmon, flaxseeds, and walnuts help reduce inflammation and support healthy hormone levels, including growth hormone.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D helps regulate hormone levels and is crucial for bone development. Include fortified dairy, mushrooms, and fatty fish to boost Vitamin D intake.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc is important for growth and immune function. Foods like seeds, nuts, legumes, and whole grains provide essential zinc for better growth and development.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help reduce oxidative stress and promote overall growth and development.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Complex Carbohydrates</h3>
          <p className="diet-text">Complex carbs like whole grains, oats, and sweet potatoes provide sustained energy and support insulin regulation, which is linked to growth hormone production.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can cause insulin resistance and interfere with growth hormone production. Avoid sugary snacks, sodas, and processed foods to promote better hormone balance.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs like white bread, pasta, and baked goods can spike insulin levels and inhibit growth hormone production. Opt for whole grains and complex carbs instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats</h3>
          <p className="diet-text">Trans fats found in fried and processed foods can increase inflammation and disrupt hormone balance. Avoid foods like fast food, margarine, and packaged snacks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">Processed foods are often low in essential nutrients and high in unhealthy fats and additives, which can interfere with growth hormone production. Stick to whole, natural foods for better growth support.</p>
        </section>

        <section class="diet-subsection">
          <h3 class="diet-subsubheading">5. Caffeine (in excess)</h3>
          <p class="diet-text">Excessive caffeine can interfere with sleep and growth hormone production. Moderate caffeine intake and focus on restful sleep for better growth.</p>
        </section>
      </section>

      <section class="sample-diet">
        <h2 class="diet-sample-subheading">Sample Diet Plan</h2>
        <ul class="diet-list">
          <li class="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li class="diet-list-item"><strong>Lunch:</strong> Grilled chicken with quinoa and steamed broccoli.</li>
          <li class="diet-list-item"><strong>Snack:</strong> A handful of almonds or a high-protein yogurt.</li>
          <li class="diet-list-item"><strong>Dinner:</strong> Baked salmon with brown rice and roasted vegetables.</li>
          <li class="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support overall health and hormone balance.</li>
        </ul>
      </section>
    </div>
  );
};

export default GHDDiet;
