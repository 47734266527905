import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const OCDDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Obsessive-Compulsive Disorder (OCD) Diet Guide</h1>
      <p className="diet-description">A balanced diet with brain-healthy nutrients can help manage OCD symptoms by supporting neurotransmitter balance, reducing inflammation, and promoting overall mental well-being. Here are dietary suggestions for foods to take and avoid for better OCD management.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Tryptophan-Rich Foods</h3>
          <p className="diet-text">Tryptophan is an amino acid that helps produce serotonin, a neurotransmitter that promotes calmness. Include foods like turkey, eggs, tofu, and nuts in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s in fatty fish, flaxseeds, and walnuts support brain function, reduce inflammation, and help balance mood-regulating neurotransmitters.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Complex Carbohydrates</h3>
          <p className="diet-text">Complex carbs like whole grains, oats, and sweet potatoes provide steady energy and support serotonin production, which is essential for mental well-being.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and dark chocolate are rich in antioxidants, which protect brain cells from oxidative stress and may help reduce OCD symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">Gut health is closely linked to mental health. Include probiotic foods like yogurt, kefir, and fermented vegetables to support a healthy gut and mental well-being.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium helps relax muscles and nerves, supporting calmness. Include spinach, almonds, bananas, and avocados to help manage stress.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars and Simple Carbs</h3>
          <p className="diet-text">Refined sugars and simple carbs can cause blood sugar spikes and crashes, potentially worsening OCD symptoms. Limit sweets and sugary drinks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain additives and unhealthy fats, which can negatively affect brain health and exacerbate OCD symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Excessive Caffeine</h3>
          <p className="diet-text">Caffeine can increase anxiety and stress, which may worsen OCD symptoms. Try to moderate caffeine intake or choose calming herbal teas.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can interfere with neurotransmitter balance and disrupt sleep, making it harder to manage OCD symptoms. Moderation is essential.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Artificial Additives and Preservatives</h3>
          <p className="diet-text">Artificial additives and preservatives in processed foods may negatively impact mental health. Focus on whole, natural foods for better clarity.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with blueberries, flaxseeds, and a handful of walnuts.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or salmon with quinoa and a mixed greens salad.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a few pieces of dark chocolate or an apple with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water, and include calming herbal teas like chamomile or green tea to reduce stress.</li>
        </ul>
      </section>
    </div>
  );
};

export default OCDDiet;
