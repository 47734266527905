import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const LeukorrheaDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Leukorrhea Diet Guide</h1>
      <p className="diet-description">A balanced diet can help manage leukorrhea by strengthening the immune system, reducing inflammation, and promoting overall reproductive health. Below are suggestions for foods to take and avoid.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods like sauerkraut and kimchi help maintain a healthy balance of bacteria in the vaginal flora, which is essential for preventing infections and managing leukorrhea.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Fiber-Rich Foods</h3>
          <p className="diet-text">Whole grains, fruits, and vegetables are high in fiber, which helps eliminate toxins from the body and supports healthy digestion. Proper detoxification can help manage symptoms of leukorrhea.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Foods rich in vitamin C, such as citrus fruits, bell peppers, and strawberries, boost the immune system and help the body fight infections that can cause or worsen leukorrhea.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Garlic</h3>
          <p className="diet-text">Garlic has natural antibacterial and antifungal properties, making it a powerful food for fighting infections and managing vaginal discharge. Incorporating garlic into your meals can help manage leukorrhea.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Iron-Rich Foods</h3>
          <p className="diet-text">Leukorrhea can sometimes cause fatigue due to nutrient deficiencies. Including iron-rich foods like spinach, lentils, and lean meats helps prevent anemia and promotes overall health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Hydration</h3>
          <p className="diet-text">Staying well-hydrated is essential for flushing out toxins from the body. Drink plenty of water and include hydrating foods like cucumbers and watermelon to support overall reproductive health.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excessive sugar intake can disrupt the natural balance of bacteria in the vaginal flora, making it easier for infections to develop. Reducing sugar can help manage leukorrhea symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed and Junk Foods</h3>
          <p className="diet-text">Processed foods are often low in nutrients and high in unhealthy fats, which can increase inflammation and weaken the immune system. Avoid fast food, snacks, and pre-packaged meals for better symptom control.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can sometimes irritate the body and worsen vaginal discharge. Limiting or avoiding spicy meals can help manage leukorrhea more effectively.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Caffeine</h3>
          <p className="diet-text">Caffeine can dehydrate the body and exacerbate symptoms of leukorrhea. Limiting or avoiding caffeinated beverages like coffee, tea, and energy drinks can help maintain proper hydration and overall health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol consumption can weaken the immune system and disrupt hormone levels, which may worsen symptoms of leukorrhea. It is best to limit or avoid alcohol for better reproductive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. High-Sodium Foods</h3>
          <p className="diet-text">High-sodium foods can cause bloating and water retention, which may exacerbate discomfort associated with leukorrhea. Reducing salt intake can help alleviate symptoms.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of probiotic yogurt with chia seeds, fresh berries, and a drizzle of honey.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> A spinach and quinoa salad with avocado, grilled chicken, and lemon-olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a green smoothie with spinach, cucumber, and lemon.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with roasted sweet potatoes and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day and include herbal teas like chamomile or ginger to support the immune system and reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default LeukorrheaDiet;
