import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh8.webp')}alt="Urology Hero" />
        <h1>Leukorrhea</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Leukorrhea</h2>
          <img src={require('../images/female8.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy treats leukorrhea by considering the patient’s overall physical and emotional well-being. Remedies are chosen based on a comprehensive assessment of the patient’s symptoms, lifestyle, and mental state, aiming to stimulate the body’s natural healing processes. The treatment is highly individualized, addressing both the symptoms and underlying causes to restore balance and health.          </p>
          <h3>Causes</h3>
          <p>Leukorrhea, characterized by a white or yellowish vaginal discharge, can result from various factors:</p>
          <ul>
            <li><h5>Hormonal Imbalance:</h5>Fluctuations in estrogen and progesterone levels can affect vaginal secretions.</li>
            <li><h5>Infections:</h5>Bacterial vaginosis, fungal (yeast) infections like candidiasis, or protozoal infections such as trichomoniasis.</li>
            <li><h5>Sexually Transmitted Infections (STIs):</h5>Including chlamydia, gonorrhea, and trichomoniasis.</li>
            <li><h5>Poor Hygiene:</h5>Improper genital hygiene can lead to infections and abnormal discharge.</li>
            <li><h5>Diabetes:</h5>High blood sugar levels can contribute to yeast infections.</li>
            <li><h5>Pelvic Inflammatory Disease (PID):</h5> Infection of the female reproductive organs.</li>
            <li><h5>Cervical or Vaginal Polyps:</h5>Benign growths that can cause discharge.</li>
            <li><h5>Allergic Reactions:</h5>To soaps, detergents, spermicides, or contraceptives.</li>
            <li><h5>Stress:</h5>Can affect hormonal balance and immune response.</li>
            <li><h5>Foreign Bodies:</h5>Such as forgotten tampons or contraceptive devices.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>To identify potential underlying causes and assess overall health.</li>
            <li><h5>Vaginal Swab Test: </h5>To check for infections, including bacterial, fungal, and protozoal infections.</li>
            <li><h5>Pap Smear:</h5>To detect cervical changes, infections, or cancers.</li>
            <li><h5>Blood Tests:</h5>To check for diabetes, hormonal imbalances, and other systemic conditions.</li>
            <li><h5>Ultrasound:</h5>To visualize the reproductive organs and check for abnormalities like polyps, fibroids, or ovarian cysts.</li>
            <li><h5>Culture and Sensitivity Tests: </h5>To identify specific pathogens and their antibiotic sensitivity.</li>
            <li><h5>Colposcopy:</h5>Detailed examination of the cervix, vagina, and vulva if abnormal cells are suspected.</li>

          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Chronic Infections:</h5>Untreated infections can lead to recurrent or chronic issues.</li>
            <li><h5>Pelvic Inflammatory Disease (PID):</h5>Can cause chronic pelvic pain, infertility, and ectopic pregnancy.</li>
            <li><h5>Cervical Dysplasia:</h5>Abnormal changes in cervical cells that can be precancerous.</li>
            <li><h5>Emotional Distress:</h5> Persistent symptoms can cause stress, anxiety, and affect the quality of life.</li>
            <li><h5>Pregnancy Complications:</h5>Infections can lead to complications during pregnancy, including preterm labor.</li>
            <li><h5>Weakened Immune System:</h5>Conditions like diabetes and HIV can increase susceptibility to infections.</li>
            <li><h5>Sexual Activity:</h5>Unprotected sex and multiple partners increase the risk of STIs.</li>
            <li><h5>Poor Hygiene Practices: </h5>Contribute to infections and abnormal discharge.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5></li>
            <li><h5>Antibiotics:</h5>For bacterial infections such as bacterial vaginosis or STIs.</li>
            <li><h5>Antifungals:</h5> For yeast infections, available as creams, ointments, suppositories, or oral medications.</li>
            <li><h5>Antiprotozoals:</h5>For infections like trichomoniasis.</li>
            <li><h5>Good Hygiene Practices:</h5>Including proper genital hygiene, wearing breathable cotton underwear, and avoiding irritants like perfumed soaps and douches.</li>
            <li><h5>Diet and Lifestyle Changes:</h5>Reducing sugar intake for diabetic patients, maintaining a healthy diet, staying hydrated, and managing stress.</li>
            <li><h5>Probiotics</h5>To restore normal vaginal flora, particularly after antibiotic treatment.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <p>Homeopathy offers a range of remedies tailored to the individual’s specific symptoms and overall health:</p>
          <ul>
            <li><h5>Sepia: </h5> For yellowish or greenish discharge, often associated with a dragging sensation in the pelvis, irritability, and fatigue. Useful for women feeling overwhelmed, especially after childbirth or menopause.</li>
            <li><h5>Pulsatilla:</h5>For thick, creamy, white discharge, with accompanying mood swings, desire for fresh air, and aggravation from warmth. Ideal for gentle, mild-tempered individuals who feel better in open air.</li>
            <li><h5>Calcarea Carbonica:</h5>Suitable for milky white discharge, especially in women with a tendency to feel cold, have excessive sweating, and are easily fatigued. Often indicated for women with a sedentary lifestyle and those prone to obesity.</li>
            <li><h5>Borax:</h5>For white, albuminous discharge with a sensation of heat and itching in the vulva. Effective for women who experience painful intercourse and frequent vaginal infections.</li>
            <li><h5>Natrum Muriaticum:</h5> For clear, watery, or egg white-like discharge, often associated with emotional stress, introversion, and a history of grief or disappointment.</li>
            <li><h5>Kreosotum:</h5>  For offensive, acrid discharge causing itching and burning in the genital area. Helpful for women with a history of chronic vaginal infections and those who experience bleeding between periods.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathy provides a natural and individualized approach to managing leukorrhea, focusing on addressing the root causes and restoring overall health. Our clinical experience suggests that homeopathic treatment can significantly alleviate symptoms and improve the quality of life. Key to successful management is early intervention, a personalized selection of remedies, and regular follow-ups. Integrating homeopathic care with good hygiene practices, a healthy diet, and stress management enhances the effectiveness of the therapy and promotes long-term health. Regular monitoring through appropriate diagnostic tools ensures that progress is tracked and treatment plans are adjusted as needed, ensuring optimal outcomes for the patient. Homeopathy’s holistic approach not only aims to resolve the immediate symptoms but also seeks to strengthen the patient’s overall constitution, reducing the likelihood of recurrence and enhancing general well-being.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Leukorrhea</h3>
            <p>We offer natural, safe, and effective treatment for Leukorrhea conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/leukorrhea/leukorrhea-diet">Diet for Leukorrhea</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Leukorrhea</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Leukorrhea</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Leukorrhea Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/Spi2h_ztEh8?si=0Jty6mnI3abF1kdb"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
