import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const HyperthyroidismDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Hyperthyroidism Diet Guide</h1>
      <p className="diet-description">A balanced diet can help manage hyperthyroidism by supporting thyroid function and reducing excessive hormone production. Below are suggestions for foods to take and avoid when dealing with hyperthyroidism.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Calcium and Vitamin D-Rich Foods</h3>
          <p className="diet-text">Hyperthyroidism can weaken bones, so including foods rich in calcium and Vitamin D like dairy products, fortified plant-based milks, and leafy greens can help maintain bone health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help reduce oxidative stress caused by excess thyroid hormone production and support overall health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Cruciferous Vegetables</h3>
          <p className="diet-text">Cruciferous vegetables like broccoli, cauliflower, and Brussels sprouts can help reduce thyroid hormone production by interfering with iodine uptake, which may benefit individuals with hyperthyroidism.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish like salmon, flaxseeds, and walnuts help reduce inflammation and support cardiovascular health, which is important for those with hyperthyroidism.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Whole Grains</h3>
          <p className="diet-text">Whole grains like oats, quinoa, and brown rice provide sustained energy and fiber, helping to manage the metabolism boost associated with hyperthyroidism.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Protein-Rich Foods</h3>
          <p className="diet-text">Protein helps maintain muscle mass, which can be affected by hyperthyroidism. Include lean meats, eggs, dairy, and plant-based proteins like tofu and beans in your diet.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Iodine-Rich Foods</h3>
          <p className="diet-text">Avoid foods high in iodine like seaweed, shellfish, and iodized salt, as iodine can stimulate excess thyroid hormone production and worsen hyperthyroidism symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Caffeine</h3>
          <p className="diet-text">Caffeine can exacerbate symptoms like palpitations, anxiety, and tremors. Reduce or avoid coffee, tea, and caffeinated beverages to manage hyperthyroidism more effectively.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Sugary Foods and Drinks</h3>
          <p className="diet-text">Sugary foods can cause blood sugar fluctuations and increase metabolic stress. Avoid sugary snacks, sodas, and desserts to stabilize energy levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain additives and unhealthy fats that can exacerbate inflammation and interfere with thyroid function. Stick to whole, unprocessed foods for better thyroid health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol can interfere with thyroid function and worsen symptoms like anxiety, heart palpitations, and mood swings. Limiting alcohol intake can help manage hyperthyroidism.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a calcium-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with brown rice and roasted Brussels sprouts.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support hydration and overall health.</li>
        </ul>
      </section>
    </div>
  );
};

export default HyperthyroidismDiet;
