import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const CervicalSpondylitisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Cervical Spondylitis Diet Guide</h1>
      <p className="diet-description">A balanced diet with anti-inflammatory and bone-strengthening nutrients can help manage symptoms of cervical spondylitis by reducing inflammation and supporting joint and bone health. Here are dietary suggestions for foods to include and avoid.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Calcium-Rich Foods</h3>
          <p className="diet-text">Calcium is essential for bone health. Include dairy products, almonds, leafy greens like spinach, and fortified non-dairy alternatives to strengthen bones.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D is crucial for calcium absorption. Include foods like eggs, mushrooms, fortified dairy products, and fatty fish like salmon.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish, flaxseeds, and walnuts have anti-inflammatory properties that can help reduce joint pain and stiffness.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium supports muscle function and helps reduce muscle tension. Include nuts, seeds, bananas, and leafy greens.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, dark leafy greens, and nuts are high in antioxidants, which help reduce oxidative stress and inflammation, supporting joint health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Protein-Rich Foods</h3>
          <p className="diet-text">Protein is essential for muscle repair and bone strength. Include lean meats, legumes, tofu, and eggs to support muscle and joint health.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods</h3>
          <p className="diet-text">Sugary foods can increase inflammation in the body. Limit sweets, sugary drinks, and processed snacks to help reduce joint pain.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain additives and unhealthy fats that may worsen inflammation. Focus on whole, nutrient-dense foods instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats and Fried Foods</h3>
          <p className="diet-text">Trans fats and fried foods can contribute to inflammation. Choose healthy fats like those found in olive oil, avocados, and nuts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Excessive Caffeine</h3>
          <p className="diet-text">High caffeine intake may affect calcium absorption and increase muscle tension. Opt for moderate amounts or choose herbal teas.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol can dehydrate and weaken bones over time. Limiting or avoiding alcohol can support better bone health and reduce inflammation.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with berries, almonds, and a sprinkle of chia seeds.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon or tofu with quinoa and a side of steamed leafy greens like spinach or kale.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a handful of walnuts or a banana with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with lean protein (like chicken or chickpeas) and brown rice.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include anti-inflammatory herbal teas like ginger or chamomile for added benefits.</li>
        </ul>
      </section>
    </div>
  );
};

export default CervicalSpondylitisDiet;
