import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const GastricTroubleDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Gastric Trouble Diet Guide</h1>
      <p className="diet-description">A balanced diet with soothing, anti-inflammatory foods can help manage symptoms of gastric trouble by promoting digestion, reducing bloating, and easing stomach discomfort. Here are dietary suggestions for foods to take and avoid for optimal digestive health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Ginger and Peppermint</h3>
          <p className="diet-text">Ginger has natural anti-inflammatory properties and can help soothe nausea, while peppermint relaxes the digestive muscles, reducing gas and bloating.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics in yogurt, kefir, and fermented foods like kimchi support gut health, which can improve digestion and reduce bloating.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fiber-Rich Foods (in moderation)</h3>
          <p className="diet-text">Fiber aids digestion, but consuming too much can lead to gas. Include moderate amounts of fiber-rich foods like oats, bananas, and leafy greens.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Fennel Seeds</h3>
          <p className="diet-text">Fennel seeds can help relax the digestive tract and reduce gas. Chewing on fennel seeds after meals is a common remedy for bloating.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Water-Rich Fruits and Vegetables</h3>
          <p className="diet-text">Watermelon, cucumber, and celery are hydrating and help flush out toxins, which can improve digestion and reduce stomach discomfort.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Warm Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile, ginger, and peppermint help soothe the digestive system, reduce acidity, and ease bloating and gas.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Carbonated Beverages</h3>
          <p className="diet-text">Carbonated drinks can cause gas buildup in the stomach, leading to bloating. Opt for still water or herbal teas instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Spicy and Fried Foods</h3>
          <p className="diet-text">Spicy and fried foods can irritate the stomach lining and cause acid reflux. Choose baked or steamed foods for easier digestion.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. High-Fat Foods</h3>
          <p className="diet-text">High-fat foods slow down digestion and may cause bloating and gas. Limit foods high in fats, like processed meats and creamy sauces.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Dairy (if lactose intolerant)</h3>
          <p className="diet-text">Lactose can cause gas and bloating in those with lactose intolerance. Consider lactose-free dairy products or dairy alternatives like almond milk.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Artificial Sweeteners</h3>
          <p className="diet-text">Artificial sweeteners, like sorbitol, can cause bloating and gas, as they are difficult to digest. Limit sugar-free snacks and diet sodas.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with a sliced banana, a sprinkle of cinnamon, and a side of ginger tea.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu with quinoa and steamed vegetables (like carrots and zucchini).</li>
          <li className="diet-list-item"><strong>Snack:</strong> A small bowl of yogurt with a handful of berries or a few fennel seeds after meals.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with lean protein and brown rice, accompanied by peppermint tea.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and consider adding lemon to warm water to stimulate digestion.</li>
        </ul>
      </section>
    </div>
  );
};

export default GastricTroubleDiet;
