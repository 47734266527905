import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const AppendicitisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Appendicitis Diet Guide</h1>
      <p className="diet-description">A balanced diet can help manage appendicitis by reducing inflammation, supporting digestion, and promoting overall recovery. Below are suggestions for foods to take and avoid when dealing with appendicitis.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Fiber-Rich Foods (Post-Surgery)</h3>
          <p className="diet-text">Once your digestive system has recovered post-surgery, fiber-rich foods like whole grains, fruits, and vegetables help regulate digestion and prevent constipation. Avoid high fiber foods during an acute appendicitis attack.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Lean Proteins</h3>
          <p className="diet-text">Opt for lean proteins like chicken, turkey, tofu, and fish, which are easier to digest and help repair body tissues during recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Hydrating Foods and Fluids</h3>
          <p className="diet-text">Proper hydration is essential during and after appendicitis recovery. Drink plenty of water and include hydrating foods like cucumbers, watermelon, and soups to prevent dehydration.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Low-Fiber Foods (During Acute Episode)</h3>
          <p className="diet-text">During acute appendicitis, it's advisable to eat low-fiber foods like white bread, boiled potatoes, and rice to reduce strain on the digestive system.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods help maintain gut health and aid in restoring normal digestive functions after surgery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile and peppermint can help soothe the digestive system, reduce bloating, and promote healing after surgery.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can irritate the digestive system and should be avoided during and after an appendicitis episode to prevent discomfort and inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. High-Fiber Foods (During Acute Episode)</h3>
          <p className="diet-text">Avoid high-fiber foods like raw vegetables, beans, and whole grains during an acute appendicitis attack as they can strain the digestive system.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried and fatty foods are hard to digest and can slow the recovery process. Stick to lighter, healthier fats from sources like avocados and olive oil.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Carbonated Beverages</h3>
          <p className="diet-text">Carbonated drinks can cause bloating and gas, increasing discomfort during recovery. Avoid sodas and sparkling water during appendicitis and post-surgery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in unhealthy fats, sodium, and preservatives, which can strain the digestive system. Stick to fresh, whole foods during recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Sugary Snacks</h3>
          <p className="diet-text">Sugary foods and drinks, such as candy, soda, and baked goods, can contribute to inflammation and slow the healing process. Limiting sugar intake can aid recovery.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal (post-surgery) or boiled potatoes (during acute attack) with almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with steamed carrots and rice.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A probiotic-rich yogurt or a smoothie with banana and spinach.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with mashed potatoes and a light vegetable broth.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas like chamomile or peppermint to soothe digestion.</li>
        </ul>
      </section>
    </div>
  );
};

export default AppendicitisDiet;
