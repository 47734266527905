import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh10.webp')}alt="Urology Hero" />
        <h1>Contact Dermatitis</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Contact Dermatitis</h2>
          <img src={require('../images/skin10.webp')}alt="Urology Hero" />
          <p>
          Homoeopathy treats contact dermatitis by focusing on the individual’s overall health and constitution. Remedies are chosen based on specific symptoms, the nature of the skin reaction, and any underlying predispositions. The goal is to relieve itching, inflammation, and discomfort while supporting the skin’s natural healing process. Since contact dermatitis is often recurrent, homoeopathic treatment also aims to improve the body’s resilience against future reactions.          </p>
          <h3>Causesm</h3>
          <ul>
            <li><h5>Exposure to Irritants:</h5>Frequent exposure to soaps, detergents, solvents, and other harsh chemicals can irritate the skin and cause contact dermatitis.            </li>
            <li><h5>Allergic Reactions:</h5>Allergic contact dermatitis occurs when the immune system reacts to substances like plants, metals, fragrances, latex, or certain medications.            </li>
            <li><h5>Occupational Hazards:</h5>People who work in environments with frequent exposure to irritants, such as healthcare workers, hairdressers, and cleaners, are at higher risk.            </li>
            <li><h5>Pre-existing Skin Conditions:</h5>Individuals with conditions like eczema or dry skin are more prone to contact dermatitis.            </li>
            <li><h5>Environmental Factors:</h5>  </li>
          </ul>
          <h3>Symptoms </h3>
          <ul>
            <li><h5>Redness and Swelling:</h5>Affected areas may appear red, swollen, and inflamed, with a visible outline where the irritant or allergen came into contact with the skin.            </li>
            <li><h5>Itching and Burning:</h5>Intense itching, burning, and stinging sensations are common, particularly during the acute phase of the reaction.            </li>
            <li><h5>Blisters and Cracking:</h5>Severe cases may result in blisters, oozing, and crusting, and, over time, the skin can become dry and cracked.            </li>
            <li><h5>Pain and Tenderness:</h5>The skin may become painful, especially if blisters break open or if the area is scratched excessively.            </li>
            <li><h5>Dry, Scaly Skin:</h5>After the initial inflammation subsides, the affected areas can become dry, rough, and scaly.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Secondary Infections:</h5>Open blisters and cracked skin can lead to bacterial infections, particularly if the area is scratched or exposed to contaminants.            </li>
            <li><h5>Chronic Dermatitis:</h5>Repeated exposure to irritants or allergens can lead to chronic contact dermatitis, with persistent symptoms and changes in skin texture.            </li>
            <li><h5>Emotional Impact:</h5>Visible or uncomfortable skin reactions can affect self-esteem, leading to stress or anxiety, particularly if the condition is recurrent.            </li>
            <li><h5>Increased Sensitivity:</h5>Individuals with contact dermatitis may become more sensitive to other irritants or allergens, potentially triggering reactions in other areas of the skin.            </li>
         </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Avoidance of Irritants and Allergens:</h5>Identifying and avoiding the specific triggers is essential to prevent contact dermatitis from recurring.            </li>
            <li><h5>Topical Corticosteroids:</h5>These are commonly prescribed to reduce inflammation, itching, and redness. They are used in mild to moderate cases.            </li>
            <li><h5>Moisturisers and Emollients:</h5>Keeping the skin moisturised with fragrance-free, hypoallergenic products helps reduce dryness and soothe irritated skin.            </li>
            <li><h5>Oral Antihistamines:</h5>Antihistamines can help relieve itching and provide relief, especially at night to aid sleep.            </li>
            <li><h5>Wet Dressings and Compresses:</h5>Applying cool, wet compresses can help reduce itching, swelling, and discomfort, particularly during flare-ups.            </li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Rhus Toxicodendron:</h5>Effective for contact dermatitis with intense itching, burning, and swelling, especially when blisters or small vesicles form. The symptoms often worsen with cold and improve with warmth.            </li>
            <li><h5>Apis Mellifica:</h5>Suitable for individuals with red, swollen, and itchy skin that feels hot and stings. This remedy is ideal for skin reactions that worsen with heat and are relieved by cold applications.            </li>
            <li><h5>Natrum Muriaticum:</h5>Effective for individuals with dry, inflamed skin and blisters. This remedy is particularly suitable for those with a tendency toward dry skin and emotional triggers, especially if the dermatitis is linked to stress.            </li>
            <li><h5>Graphites:</h5>Recommended for dry, cracked, and oozing skin, particularly when thick yellowish crusts form. It is beneficial for individuals with contact dermatitis around folds of the skin, such as behind the ears or on the hands.            </li>
            <li><h5>Sulphur:</h5>Known for treating chronic, itchy, and inflamed skin conditions. This remedy is particularly useful for individuals with burning, red skin that worsens with heat and bathing. Sulphur is suitable for those with recurring skin issues.            </li>
          </ul>
         <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy offers a gentle and natural approach to managing contact dermatitis by addressing both the symptoms and underlying causes. Many individuals report relief from itching, inflammation, and improved skin resilience with consistent homoeopathic treatment. Homoeopathy can also help reduce recurrence by strengthening the body’s natural defenses and promoting overall skin health. For optimal results, it’s essential to combine homoeopathic remedies with lifestyle adjustments, such as avoiding known triggers, practising good skincare, and maintaining a balanced diet. Consulting a qualified healthcare provider is crucial for managing contact dermatitis effectively and ensuring comprehensive care.
          </p>
          </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Contact Dermatitis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Contact Dermatitis.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/contact-dermatitis/contact-dermatitis-diet">Diet for Contact Dermatitis prevention</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Contact Dermatitis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Contact Dermatitis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Contact Dermatitis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/n9dl2rAQXzY?si=JZ9VO6qGsFm07CBE"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
