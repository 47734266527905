import React from 'react';
import '../../css/KidneyStoneDiet.css';  
import kidneyStoneImage from '../../images/diet1.jpg';
 
 
const KidneyStoneDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
            <div id="google_translate_element" style={{ position: 'absolute', top: '10px', right: '10px' }}></div>
 
       <h1 className="diet-title">Kidney Stone Diet Guide</h1>
       <a
        href={kidneyStoneImage}
        download="KidneyStoneDietImage.jpg"
        className="download-button" >
        Download Image
      </a>
      <img
        src={kidneyStoneImage}
        alt="Kidney Stone Diet"
        className="diet-image"
      />
      <p className="diet-description">Follow this diet to help prevent kidney stones and promote kidney health.</p>
 
      <section className="diet-section">
        <h2 className="diet-subheading">1. Stay Hydrated</h2>
        <p className="diet-text">Drink plenty of water to help flush out toxins and reduce the risk of stone formation. Aim for 8-12 cups (2-3 liters) of water daily. Adding lemon or lime to your water can increase citrate levels, helping to prevent stones.</p>
      </section>
 
      <section className="diet-section">
        <h2 className="diet-subheading">2. Limit Sodium Intake</h2>
        <p className="diet-text">Reduce salt consumption, as high sodium can increase calcium in your urine, leading to stone formation. Aim for less than 2,300 mg of sodium per day.</p>
      </section>
 
      <section className="diet-section">
        <h2 className="diet-subheading">3. Control Protein Intake</h2>
        <p className="diet-text">Limit animal proteins such as red meat, poultry, and seafood, as they can increase uric acid levels. Choose plant-based proteins like beans and lentils.</p>
      </section>
 
      <section className="diet-section">
        <h2 className="diet-subheading">4. Eat Calcium-Rich Foods</h2>
        <p className="diet-text">Calcium from food can help prevent stones by binding to oxalates. Include dairy, almonds, and green vegetables in your diet.</p>
      </section>
 
      <section className="diet-section">
        <h2 className="diet-subheading">5. Limit Oxalate-Rich Foods</h2>
        <p className="diet-text">Oxalate-rich foods like spinach, beets, and nuts can contribute to kidney stones. Pair them with calcium-rich foods to reduce oxalate absorption.</p>
      </section>
 
      <section className="diet-section">
        <h2 className="diet-subheading">6. Reduce Sugar and Sugary Drinks</h2>
        <p className="diet-text">Limit sugary drinks and foods, as they increase calcium excretion. Opt for water or unsweetened beverages.</p>
      </section>
 
      <section className="diet-section">
        <h2 className="diet-subheading">7. Increase Fruits and Vegetables</h2>
        <p className="diet-text">Citrus fruits like oranges and lemons can reduce the risk of stones due to their citrate content. Include plenty of fiber-rich vegetables in your meals.</p>
      </section>
 
      <section className="diet-section">
        <h2 className="diet-subheading">8. Moderate Alcohol and Caffeine</h2>
        <p className="diet-text">Limit alcohol and caffeine intake, as they can lead to dehydration and increase the risk of stones.</p>
      </section>
 
      <section className="diet-section">
        <h2 className="diet-subheading">9. Avoid Vitamin C Supplements</h2>
        <p className="diet-text">Excessive vitamin C can lead to oxalate production. Avoid high-dose vitamin C supplements.</p>
      </section>
 
      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with almond milk and blueberries, lemon water.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with broccoli and quinoa.</li>
          <li className="diet-list-item"><strong>Snack:</strong> An apple or a handful of almonds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon, sweet potatoes, and mixed vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, including lemon or lime water.</li>
        </ul>
      </section>
    </div>
  );
};
 
export default KidneyStoneDiet;