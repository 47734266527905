import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const EndometriosisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Endometriosis Diet Guide</h1>
      <p className="diet-description">A proper diet can help manage the symptoms of endometriosis by reducing inflammation, balancing hormones, and supporting overall well-being. Below are suggestions for foods to take and avoid.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-inflammatory Foods</h3>
          <p className="diet-text">Foods like berries, leafy greens, tomatoes, and fatty fish (salmon, sardines) are rich in antioxidants and Omega-3 fatty acids, which help reduce inflammation and manage the pain associated with endometriosis.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Fiber-Rich Foods</h3>
          <p className="diet-text">Whole grains, fruits, vegetables, and legumes are high in fiber, which helps regulate estrogen levels and improve digestion. A high-fiber diet supports hormonal balance, which is key in managing endometriosis.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in flaxseeds, chia seeds, and walnuts help reduce inflammation and promote hormone regulation, alleviating endometriosis symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Lean Proteins</h3>
          <p className="diet-text">Lean proteins like chicken, turkey, tofu, and plant-based options provide the body with necessary nutrients while reducing the intake of saturated fats, which can worsen inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium helps with muscle relaxation and may reduce cramps. Foods rich in magnesium, such as spinach, almonds, and bananas, can help manage endometriosis pain.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like ginger, chamomile, and turmeric can help reduce inflammation, relieve pain, and support hormonal balance in women with endometriosis.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Red Meat</h3>
          <p className="diet-text">Red meat is high in saturated fats and may increase estrogen levels, which can worsen endometriosis symptoms. It's best to limit or avoid red meat and opt for lean protein sources instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Dairy Products (in excess)</h3>
          <p className="diet-text">Dairy can increase inflammation and disrupt hormone levels. Limiting dairy intake or opting for plant-based alternatives like almond milk can help manage symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Refined Sugars</h3>
          <p className="diet-text">Refined sugars, found in sweets, baked goods, and sodas, can increase inflammation and worsen hormonal imbalances. Reduce sugar intake to help manage endometriosis.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Caffeine</h3>
          <p className="diet-text">Caffeine can increase estrogen levels and worsen endometriosis symptoms like pain and cramping. Limit or avoid caffeinated beverages like coffee, energy drinks, and tea.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol can affect liver function and disrupt the metabolism of hormones, leading to worsened endometriosis symptoms. Limiting or avoiding alcohol is recommended for better hormonal balance.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Processed Foods</h3>
          <p className="diet-text">Processed foods, high in trans fats and additives, can increase inflammation and contribute to hormonal imbalances. Stick to fresh, whole foods to manage symptoms better.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with flaxseeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu salad with spinach, avocado, and a lemon-olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a smoothie with spinach, ginger, and turmeric.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and roasted vegetables (like broccoli and sweet potatoes).</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day and include herbal teas like ginger or chamomile to reduce inflammation and pain.</li>
        </ul>
      </section>
    </div>
  );
};

export default EndometriosisDiet;
