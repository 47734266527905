import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const GastritisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Gastritis Diet Guide</h1>
      <p className="diet-description">A proper diet can help manage gastritis by reducing stomach inflammation, promoting healing, and preventing irritation. Below are suggestions for foods to take and avoid when dealing with gastritis.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Low-Acid Fruits</h3>
          <p className="diet-text">Fruits like bananas, melons, and pears are gentle on the stomach and can help reduce irritation. They are also rich in vitamins and fiber, which support digestion.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Non-Citrus Vegetables</h3>
          <p className="diet-text">Vegetables like leafy greens, carrots, and squash are non-acidic and easy to digest. These vegetables provide essential nutrients and help reduce inflammation in the stomach lining.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Lean Proteins</h3>
          <p className="diet-text">Lean proteins such as chicken, turkey, tofu, and fish are easier to digest and can help repair stomach lining. Avoid frying and opt for baked, grilled, or steamed preparations.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Whole Grains</h3>
          <p className="diet-text">Whole grains like brown rice, oats, and quinoa are rich in fiber and gentle on the stomach. They can help regulate digestion and reduce symptoms of gastritis.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods like sauerkraut can help maintain a healthy balance of stomach bacteria, which may aid in reducing inflammation and promoting healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile, ginger, and licorice root can help soothe the stomach, reduce nausea, and promote healing of the stomach lining.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can irritate the stomach lining and worsen symptoms of gastritis. Reducing or avoiding spicy meals can help prevent further inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Acidic Foods and Drinks</h3>
          <p className="diet-text">Citrus fruits, tomatoes, and caffeinated beverages like coffee can increase stomach acid and exacerbate gastritis symptoms. Avoid acidic foods to reduce irritation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried and fatty foods can slow digestion and increase stomach acid production, leading to more irritation. Opt for low-fat, baked, or grilled options instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Carbonated Beverages</h3>
          <p className="diet-text">Carbonated drinks can cause bloating and increase stomach pressure, which may worsen gastritis symptoms. Avoid sodas and sparkling water to prevent discomfort.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol irritates the stomach lining and can delay healing. Reducing or avoiding alcohol is essential for managing gastritis symptoms and promoting recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in additives, preservatives, and unhealthy fats, which can aggravate gastritis symptoms. Stick to fresh, whole foods to support stomach health.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with sliced bananas and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with steamed broccoli and brown rice.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A probiotic-rich yogurt or a handful of almonds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and roasted carrots.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include herbal teas like chamomile or ginger to soothe the stomach.</li>
        </ul>
      </section>
    </div>
  );
};

export default GastritisDiet;
