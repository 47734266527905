import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const GallstonesDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Gallstones Diet Guide</h1>
      <p className="diet-description">A proper diet can help manage gallstones by reducing cholesterol levels, promoting healthy digestion, and preventing the formation of new stones. Below are suggestions for foods to take and avoid when dealing with gallstones.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Fiber Foods</h3>
          <p className="diet-text">Fiber-rich foods like whole grains, fruits, vegetables, and legumes help regulate digestion and lower cholesterol levels, reducing the risk of gallstone formation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Healthy Fats</h3>
          <p className="diet-text">Opt for healthy fats from sources like avocados, olive oil, and nuts. These fats can improve bile flow and prevent the buildup of cholesterol in the gallbladder.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Lean Proteins</h3>
          <p className="diet-text">Include lean protein sources such as chicken, turkey, tofu, and fish in your diet to support overall health without contributing to cholesterol-related issues.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Low-Fat Dairy</h3>
          <p className="diet-text">Low-fat or fat-free dairy products like yogurt, milk, and cheese are easier to digest and can help manage gallstones without increasing cholesterol levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Foods rich in antioxidants, such as berries, leafy greens, and citrus fruits, help reduce inflammation and support overall liver and gallbladder health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Hydrating Foods</h3>
          <p className="diet-text">Proper hydration is key to keeping bile fluid and reducing the risk of gallstone formation. Drink plenty of water and include hydrating foods like cucumbers and melons.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Saturated and Trans Fats</h3>
          <p className="diet-text">Avoid high-fat and fried foods, such as red meat, butter, and processed snacks, as these can increase cholesterol levels and lead to gallstone formation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined grains like white bread, pasta, and pastries can spike blood sugar levels and lead to the production of gallstones. Opt for whole grains instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Sugary Foods and Drinks</h3>
          <p className="diet-text">Sugary snacks and beverages like candy, soda, and baked goods can increase cholesterol and contribute to gallstone formation. Reducing sugar intake is essential for gallbladder health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. High-Fat Dairy Products</h3>
          <p className="diet-text">Full-fat dairy products, such as whole milk, cheese, and butter, are harder to digest and can lead to gallbladder problems. Choose low-fat or fat-free options instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol (in excess)</h3>
          <p className="diet-text">Excessive alcohol consumption can affect liver and gallbladder function, increasing the risk of gallstones. Limit alcohol intake to support overall digestive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in unhealthy fats and additives, which can trigger gallstone formation. Stick to fresh, whole foods for better gallbladder health.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with chia seeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu salad with spinach, avocado, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day and include herbal teas to support digestion.</li>
        </ul>
      </section>
    </div>
  );
};

export default GallstonesDiet;
