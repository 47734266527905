import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const LichenPlanusDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Lichen Planus Diet Guide</h1>
      <p className="diet-description">A diet rich in anti-inflammatory foods can help manage lichen planus by reducing inflammation, promoting skin health, and boosting the immune system. Below are suggestions for foods to take and avoid when dealing with lichen planus.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Anti-inflammatory foods like berries, leafy greens, and fatty fish (such as salmon and mackerel) can help reduce inflammation and promote skin healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods help maintain a healthy gut, which is closely linked to immune health and skin inflammation control.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C is essential for collagen production and skin repair. Citrus fruits, bell peppers, strawberries, and broccoli are great sources of vitamin C that support skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s in foods like chia seeds, flaxseeds, walnuts, and fatty fish have anti-inflammatory properties that can help manage lichen planus symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Keeping your skin hydrated is crucial. Include water-rich foods like cucumbers, melons, and citrus fruits, along with drinking plenty of water to support skin hydration.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Garlic and Turmeric</h3>
          <p className="diet-text">Garlic and turmeric have strong anti-inflammatory and antioxidant properties that may help reduce skin irritation and inflammation in lichen planus.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can trigger inflammation and exacerbate skin conditions like lichen planus. Avoid sugary snacks, sodas, and processed foods to help control flare-ups.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can irritate the skin and worsen lichen planus symptoms. Reducing or eliminating spicy dishes from your diet can help manage discomfort.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain unhealthy fats, preservatives, and additives that can increase inflammation. Stick to fresh, whole foods for better skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and those high in trans fats can increase inflammation and hinder skin healing. Opt for healthy fats from sources like avocados, nuts, and olive oil.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol can weaken the immune system and cause dehydration, worsening lichen planus symptoms. Limiting or avoiding alcohol can improve overall skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Excessive Caffeine</h3>
          <p className="diet-text">While moderate caffeine is generally fine, excessive amounts can dehydrate the skin and aggravate skin conditions. Keep caffeine intake in check to support healthy skin.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A probiotic-rich yogurt or a handful of walnuts.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas like chamomile to reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default LichenPlanusDiet;
