import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const PhobiaDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Phobia Diet Guide</h1>
      <p className="diet-description">A balanced diet with calming and brain-supportive nutrients can help manage symptoms associated with phobias by supporting neurotransmitter balance, reducing inflammation, and promoting mental well-being. Below are dietary suggestions for foods to take and avoid to help alleviate phobia-related anxiety.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium has a calming effect on the nervous system, which can help reduce anxiety. Include foods like leafy greens, almonds, bananas, and avocados.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, flaxseeds, and walnuts, are essential for brain health and help reduce inflammation, which can stabilize mood and reduce anxiety.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Complex Carbohydrates</h3>
          <p className="diet-text">Complex carbs like whole grains, oats, and sweet potatoes provide steady energy and promote serotonin production, a neurotransmitter that supports calmness.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Tryptophan-Rich Foods</h3>
          <p className="diet-text">Tryptophan, an amino acid found in turkey, eggs, and seeds, helps produce serotonin and can support mood stability, which may ease phobia-related anxiety.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">A healthy gut is linked to improved mood. Probiotic foods like yogurt, kefir, and sauerkraut can support gut health, which may positively impact mental resilience.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile, lavender, and green tea have calming properties that may help alleviate stress and promote relaxation.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars</h3>
          <p className="diet-text">High-sugar foods can cause blood sugar spikes and crashes, which may worsen anxiety. Limit sweets, sugary drinks, and processed snacks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods contain artificial additives and trans fats, which can negatively impact mental health. Stick to whole, nutrient-dense foods instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Excessive Caffeine</h3>
          <p className="diet-text">Too much caffeine can increase heart rate and trigger anxiety, making phobia symptoms worse. Choose calming herbal teas over caffeinated drinks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can interfere with neurotransmitter balance and impair sleep, both of which may aggravate anxiety symptoms. Moderation is essential for mental clarity.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Artificial Additives</h3>
          <p className="diet-text">Artificial additives, preservatives, and food colorings in processed foods can negatively impact mental health. Opt for natural, whole foods for better emotional stability.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with blueberries, chia seeds, and a handful of walnuts.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or salmon with quinoa and steamed vegetables.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a few pieces of dark chocolate or a banana with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water, and include calming herbal teas like chamomile or lavender tea to support relaxation.</li>
        </ul>
      </section>
    </div>
  );
};

export default PhobiaDiet;
