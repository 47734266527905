import React from 'react';
import '../../css/CaseStudy.css';

const AlopeciaCaseStudy = () => {
  return (
    <div className="case-study-page">
      <div className="case-study-hero">
        <h1>Snigdha Srivastava’s Journey to Overcoming Alopecia Areata with Homeopathy</h1>
        {/* <img src={require('../images/case-study-hero3.jpg')} alt="Alopecia Recovery" className="case-study-img" /> */}
      </div>

      <div className="case-study-content">
        <p>
          Snigdha Srivastava had struggled with alopecia areata for seven long years. She watched helplessly as bald patches formed on her scalp, with no treatment providing any lasting relief. She had tried everything—modern medicine, Ayurveda, and even various homeopathic approaches—but nothing seemed to help. Each failed attempt left her feeling more discouraged, but she still held onto hope.
        </p>

        <h2>Discovering a New Treatment Approach</h2>
        <p>
          One day, while searching online, Snigdha discovered our clinic and decided to give it a try. During her first consultation, we explored her medical history, lifestyle, stress levels, and diet to understand the unique factors affecting her condition. Alopecia can often be worsened by stress and other underlying issues, so we created a homeopathic treatment plan tailored to her needs. We explained to Snigdha that healing would take time, as her condition was chronic, and she agreed to commit to the journey.
        </p>

        <h2>Subtle Improvements Begin</h2>
        <p>
          The first few weeks brought subtle changes; Snigdha noticed she wasn’t getting as many new bald patches, and her stress levels began to ease. These small improvements encouraged her to keep going. By the third month, she was thrilled to see tiny new hairs growing in places that had been bare for years. We adjusted her treatment as she progressed, making sure it stayed effective.
        </p>

        <h2>Continued Progress and Regrowth</h2>
        <p>
          Over the months, her scalp continued to fill in, and her hair grew back thicker and stronger. By the seventh month, the bald spots were nearly gone. At nine months, her hair was fully restored, returning to its former volume and health. This recovery brought back not just her hair, but also her confidence and peace of mind.
        </p>

        <h2>Reflecting on the Journey</h2>
        <p>
          Looking back, Snigdha realized how finding our clinic online had been a true turning point. After seven years of struggling, a nine-month journey helped her not only recover her hair but also regain her sense of well-being. Her journey is an inspiring example of how commitment to a personalized, holistic treatment plan can yield transformative results.
        </p>
      </div>
    </div>
  );
};

export default AlopeciaCaseStudy;
