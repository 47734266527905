import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const ASDDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Autism Spectrum Disorder (ASD) Diet Guide</h1>
      <p className="diet-description">A balanced diet rich in nutrients that support brain health and digestion can help manage symptoms associated with Autism Spectrum Disorder. Here are dietary suggestions for foods to include and avoid for overall mental and physical well-being.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, flaxseeds, and walnuts, support brain function and reduce inflammation, which may help with cognitive function and mood stability.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Probiotic-Rich Foods</h3>
          <p className="diet-text">Gut health is closely linked to brain health. Include probiotic foods like yogurt, kefir, and sauerkraut to support a healthy gut, which can benefit behavior and mood.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Vitamin B6 and Magnesium</h3>
          <p className="diet-text">B6 and magnesium can help reduce hyperactivity and improve focus. Include foods like leafy greens, bananas, avocados, and nuts in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help protect brain cells from oxidative stress and support overall brain health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Fiber-Rich Foods</h3>
          <p className="diet-text">High-fiber foods like whole grains, fruits, and vegetables support digestive health, which is especially important for individuals with ASD who may have gastrointestinal issues.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Zinc and Iron-Rich Foods</h3>
          <p className="diet-text">Zinc and iron are essential for brain function. Include foods like lean meats, beans, seeds, and spinach to support cognitive development and reduce irritability.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars</h3>
          <p className="diet-text">Refined sugars can cause energy spikes and crashes, which may worsen hyperactivity. Limit sweets, sugary drinks, and processed snacks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Artificial Additives</h3>
          <p className="diet-text">Artificial colors, flavors, and preservatives have been linked to behavioral changes in some individuals with ASD. Opt for fresh, whole foods whenever possible.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Dairy Products (for some)</h3>
          <p className="diet-text">Some individuals with ASD are sensitive to casein, a protein in dairy. If dairy worsens symptoms, consider alternatives like almond or oat milk.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Gluten (for some)</h3>
          <p className="diet-text">Some children and adults with ASD have gluten sensitivities. Gluten-free grains like rice, quinoa, and oats may be helpful in these cases.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain additives and preservatives that can negatively impact mood and behavior. Choose natural, nutrient-dense foods for better results.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or salmon with quinoa and steamed vegetables.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt (if dairy-tolerant) with a handful of nuts, or an apple with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu or lean meat, brown rice, and leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas for added antioxidants.</li>
        </ul>
      </section>
    </div>
  );
};

export default ASDDiet;
