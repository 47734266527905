import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const PanicDisorderDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Panic Disorder Diet Guide</h1>
      <p className="diet-description">A balanced diet rich in calming nutrients can help manage panic disorder by supporting neurotransmitter balance, stabilizing blood sugar, and reducing stress. Here are dietary recommendations for managing panic symptoms and promoting mental well-being.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium is known for its calming effect on the nervous system. Include foods like spinach, almonds, avocado, and bananas to help relax muscles and reduce anxiety.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish, flaxseeds, and walnuts support brain health and reduce inflammation, which can help stabilize mood and reduce panic symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Complex Carbohydrates</h3>
          <p className="diet-text">Complex carbs, like whole grains, oats, and sweet potatoes, provide steady energy and support serotonin production, promoting a sense of calmness.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, dark chocolate, and leafy greens contain antioxidants that help reduce oxidative stress, which is linked to anxiety and panic disorders.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Tryptophan-Rich Foods</h3>
          <p className="diet-text">Tryptophan is an amino acid that supports serotonin production. Include foods like turkey, eggs, and tofu, which may help improve mood stability.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile, lavender, and green tea contain natural calming compounds that may help reduce stress and promote relaxation.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars</h3>
          <p className="diet-text">Refined sugars can cause blood sugar spikes and crashes, which may trigger or worsen panic symptoms. Limit sweets and sugary drinks for more stable energy levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain artificial additives and trans fats, which may negatively impact mental health. Focus on whole, nutrient-dense foods instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Caffeine (in large amounts)</h3>
          <p className="diet-text">Excessive caffeine can increase heart rate and anxiety, potentially worsening panic symptoms. Opt for calming herbal teas over caffeinated beverages.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can disrupt neurotransmitter balance and impact sleep, making it harder to manage panic symptoms. Moderation is essential for stability.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Artificial Additives</h3>
          <p className="diet-text">Artificial sweeteners, preservatives, and food colorings in processed foods can negatively impact mental health. Focus on fresh, natural foods for better clarity.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with blueberries, chia seeds, and a handful of almonds.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a few pieces of dark chocolate or a banana with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and a side of leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include calming herbal teas like chamomile or green tea to reduce stress.</li>
        </ul>
      </section>
    </div>
  );
};

export default PanicDisorderDiet;
