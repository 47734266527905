import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const VaricoceleDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Varicocele Diet Guide</h1>
      <p className="diet-description">A balanced diet with anti-inflammatory and antioxidant-rich foods can help manage varicocele symptoms by supporting vascular health and reducing oxidative stress. Below are suggestions for foods to take and avoid when dealing with varicocele.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Fiber Foods</h3>
          <p className="diet-text">Fiber-rich foods like whole grains, fruits, and vegetables can help prevent constipation, reducing abdominal pressure and improving blood flow in the veins.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Antioxidants found in berries, nuts, and leafy greens help combat oxidative stress, supporting vein health and reducing inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s in fish, flaxseeds, and walnuts support vascular health, reducing inflammation and supporting blood flow.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C in citrus fruits, strawberries, and bell peppers supports collagen production, strengthening vein walls.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Water-rich fruits and vegetables, like cucumbers and melons, help maintain hydration, supporting healthy circulation.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain high levels of sodium, which can lead to water retention and increased pressure on veins.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Saturated and Trans Fats</h3>
          <p className="diet-text">These fats, found in fried and fatty foods, can worsen blood flow and contribute to inflammation. Opt for healthier fats like those in avocados and olive oil.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excessive sugar can lead to inflammation and blood flow issues, so limit sweets and sugary beverages.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Excessive Caffeine and Alcohol</h3>
          <p className="diet-text">Too much caffeine and alcohol can dehydrate the body and impact circulation. Moderate these to maintain healthy blood flow.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with berries, chia seeds, and a handful of almonds.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and a mixed greens salad.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or an apple with nut butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water and include herbal teas to maintain hydration and support circulation.</li>
        </ul>
      </section>
    </div>
  );
};

export default VaricoceleDiet;
