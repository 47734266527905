import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/mgh4.webp')}alt="Urology Hero" />
        <h1>Genital Cyst Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Genital Cyst</h2>
          <img src={require('../images/male4.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy treats genital cysts by considering the individual’s entire health profile, including physical, emotional, and lifestyle factors. The aim is to stimulate the body’s natural healing mechanisms to reduce cyst size, alleviate discomfort, and prevent recurrence. Remedies are selected based on a comprehensive assessment of the patient’s symptoms and overall constitution.          </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Blocked Glands:</h5>Cysts often develop when ducts or glands, such as the Bartholin’s glands, become blocked, causing fluid to accumulate.</li>
            <li><h5>Infections:</h5>Bacterial or viral infections can lead to inflammation and cyst formation.</li>
            <li><h5>Hormonal Imbalances:</h5>Fluctuations in hormone levels, especially during menstruation or menopause, can contribute to cyst development.</li>
            <li><h5>Genetic Factors:  </h5>A hereditary predisposition to cysts may increase the likelihood of their occurrence.</li>
            <li><h5>Injuries or Trauma: </h5>Physical trauma or irritation in the genital area can result in cyst formation.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5> Physical Examination: </h5>A thorough examination of the genital area to assess the cyst’s size, location, and characteristics.</li>
            <li><h5>Ultrasound: </h5>Imaging to visualize the cyst and determine its structure, ensuring it is not a more serious condition.</li>
            <li><h5>Biopsy:</h5>In some cases, a tissue sample may be taken to rule out malignancy.</li>
            <li><h5>Cultures:</h5>Testing for bacterial or viral infections if the cyst is suspected to be infected.</li>
            <li><h5>Blood Tests:</h5>To check for underlying conditions or infections that may be contributing to cyst formation.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Infection:</h5>Cysts can become infected, leading to abscess formation and requiring antibiotic treatment or drainage.</li>
            <li><h5>Pain and Discomfort:</h5>Large or infected cysts can cause significant pain and discomfort, affecting daily activities and quality of life.</li>
            <li><h5>Recurrence:</h5>Even after treatment, cysts can recur, necessitating ongoing management.</li>
            <li><h5>Emotional Stress:</h5>Persistent or painful cysts can cause anxiety, stress, and impact emotional well-being.</li>
            <li><h5>Sexual Dysfunction:</h5>Pain and discomfort from cysts can interfere with sexual activity and intimacy.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Warm Compresses:</h5>Applying warmth to the cyst to reduce swelling and promote natural drainage.</li>
            <li><h5>Antibiotics:</h5> Prescribed to treat infected cysts and prevent further infection.</li>
            <li><h5>Surgical Removal:</h5>Large, painful, or recurrent cysts may require surgical drainage or removal.</li>
            <li><h5>Marsupialization:</h5>A surgical procedure to create a permanent opening for continuous drainage and to prevent recurrence.</li>
            <li><h5>Pain Relief:</h5>Over-the-counter or prescription pain medications to manage discomfort associated with cysts.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <ul>
            <li><h5>Silicea: </h5>For cysts that are slow to heal and prone to infection, promoting expulsion of the cyst’s contents.</li>
            <li><h5>Hepar Sulphuris:</h5>Effective for painful, pus-filled cysts, especially in individuals sensitive to cold.</li>
            <li><h5>Calcarea Fluorica: </h5>For hard, stony cysts, particularly useful when there is a tendency for recurrence.</li>
            <li><h5>Thuja: </h5>Used for cysts associated with warts or other skin growths, promoting overall skin health.</li> 
            <li><h5>Apis Mellifica:</h5>For cysts that are red, swollen, and accompanied by burning or stinging pain, reducing inflammation and discomfort.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>Homeopathy offers a holistic and individualized approach to treating genital cysts, focusing on stimulating the body’s natural healing processes. In our experience, patients often experience significant reductions in cyst size, relief from pain, and lower recurrence rates. Homeopathic remedies, tailored to the individual’s specific symptoms and overall health, provide a non-invasive and natural alternative to conventional treatments. This approach not only addresses the cysts but also enhances overall well-being, contributing to improved quality of life.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Genital Cyst Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Genital Cyst conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/male-genital/genital-cyst/genital-cyst-diet">Diet for Genital Cyst</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Genital Cyst</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Genital Cyst</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Genital Cyst Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/0nfBzwlvdbM?si=GA_CeT9DtJ3390bm"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
