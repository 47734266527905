import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const GenitalCystDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Genital Cyst Diet Guide</h1>
      <p className="diet-description">A diet rich in anti-inflammatory and immune-boosting foods can help manage genital cysts by reducing inflammation, supporting healing, and promoting hormonal balance. Below are dietary suggestions for foods to include and avoid for genital cyst management.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Foods like turmeric, ginger, and leafy greens can help reduce inflammation, supporting healing of cysts. Include these in your daily meals.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fish, chia seeds, and walnuts have anti-inflammatory properties that may help reduce cyst inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fiber-Rich Foods</h3>
          <p className="diet-text">A high-fiber diet can help maintain hormonal balance. Foods like whole grains, fruits, and vegetables promote detoxification and support hormone regulation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics in yogurt, kefir, and fermented foods support a healthy gut, which plays a role in reducing inflammation and maintaining hormonal health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C in citrus fruits, strawberries, and bell peppers boosts immunity, supporting healing and reducing the risk of infection in cysts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc is important for skin and tissue healing. Include foods like pumpkin seeds, lentils, and chickpeas to support the body’s repair processes.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed and Sugary Foods</h3>
          <p className="diet-text">Processed and sugary foods can promote inflammation and disrupt hormone balance. Limit sweets, sugary drinks, and packaged snacks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Dairy Products (if sensitive)</h3>
          <p className="diet-text">For some, dairy can increase mucus production and inflammation. If you notice symptoms worsen with dairy, consider alternatives like almond or oat milk.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans and Saturated Fats</h3>
          <p className="diet-text">Trans fats, found in fried foods and baked goods, can increase inflammation. Opt for healthier fats like olive oil, nuts, and seeds.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Caffeine (in large amounts)</h3>
          <p className="diet-text">Excessive caffeine can dehydrate and stress the body, potentially worsening cysts. Moderate your intake for better results.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol can contribute to hormonal imbalance and liver strain, which can exacerbate cysts. Limit alcohol for better hormonal health.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and a mixed greens salad.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or an apple with nut butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and a side of leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water, and include herbal teas like chamomile to reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default GenitalCystDiet;
