import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/treat4.webp')}alt="Urology Hero" />
        {/* <h1>Gouty Arthritis Treatment</h1> */}
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Gouty Arthritis</h2>
          <img src={require('../images/joint2.webp')}alt="Urology Hero" />
       
          <h3>Causes</h3>
          <ul>
            <li><h5>Uric Acid Buildup:</h5>The culprit is excess uric acid, a waste product from purines (substances found in the body and some foods). Normally, uric acid dissolves in blood and is excreted by the kidneys. When levels become too high, sharp crystals form in joints, causing inflammation and intense pain.</li>
          </ul>
          <h3>Symptoms </h3>
          <p>Gout attacks come on suddenly, often at night, affecting one or more joints, most commonly the big toe. Symptoms include:</p>
          <ul>
            <li><h5>Intense Pain:</h5>Described as sharp, throbbing, or excruciating.</li>
            <li><h5>Joint Swelling: </h5>The affected joint becomes red, swollen, and tender.</li>
            <li><h5>Warmth and Redness:</h5>The area may feel hot and appear red.</li>
            <li><h5>Stiffness:</h5>The joint may be difficult to move.</li>

          </ul>
          <h3>Investigations </h3>
          <ul>
            <li><h5>Physical Examination: </h5>A doctor will examine your joints for swelling, tenderness, and warmth.</li>
            <li><h5>Blood tests:  </h5>To measure uric acid levels and rule out other conditions mimicking gout.</li>
            <li><h5>Joint Fluid Analysis:</h5> Extracting a small amount of fluid from the affected joint and examining it for urate crystals.</li>
            <li><h5>Imaging tests:</h5>X-rays, ultrasounds, or MRIs may be used in some cases to assess joint damage or rule out other causes of joint pain.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Frequent Attacks:</h5>Untreated gout can lead to more frequent and severe attacks.</li>
            <li><h5>Joint Damage: </h5>Urate crystal deposits can erode cartilage and bone over time, leading to joint deformity and disability.</li>
            <li><h5>Tophi:</h5>Hard lumps of urate crystals can form under the skin around joints, especially in advanced cases.</li>
            <li><h5>Kidney Stones:</h5>High uric acid levels can increase the risk of developing kidney stones.</li>
            <li><h5>Kidney Disease:</h5>Chronic gout can contribute to or worsen kidney disease.</li>

          </ul>
          <p>Risk factors for polyarthritis vary depending on the underlying cause. Some general risk factors include:</p>
          <ul>
            <li><h5>Diet:</h5>Consuming excessive purine-rich foods (red meat, organ meats, seafood) and sugary drinks can increase uric acid levels.</li>
            <li><h5>Alcohol Consumption:</h5>Heavy alcohol intake can interfere with uric acid excretion and raise uric acid levels.</li>
            <li><h5>Obesity:</h5> Being overweight or obese increases the risk of gout.</li>
            <li><h5>Medical Conditions:</h5>High blood pressure, diabetes, and some kidney diseases can increase the risk of gout.</li>
            <li><h5>Medications:</h5>Injuries can increase susceptibility to arthritis later in life.</li>
            <li><h5>Genetics:</h5>Having a family history of gout increases your risk.</li>
            <li><h5>Medications:</h5>Men are more prone to gout before menopause, but women’s risk increases after menopause.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>Treatment for gouty arthritis focuses on:</p>
          <ul>
            <li><h5>Managing gout attacks:</h5>Reducing inflammation and pain during an attack with medications like nonsteroidal anti-inflammatory drugs (NSAIDs), corticosteroids, or colchicine.</li>
            <li><h5>Preventing future attacks: </h5>Medications like allopurinol or febuxostat can help lower uric acid levels and prevent future gout attacks.</li>
            <li><h5>Lifestyle modifications:</h5> Maintaining a healthy weight, following a gout-friendly diet, limiting alcohol intake, and staying well-hydrated can all help manage gout.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>Gouty arthritis can be painful, but with proper diagnosis and treatment, you can manage symptoms and prevent future attacks. If you experience sudden and severe joint pain, especially in the big toe, consult a doctor for diagnosis and discuss treatment options. Remember, I cannot recommend homeopathic treatments as they lack scientific backing.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Gouty Arthritis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Gouty Arthritis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/joint-bone/gouty-arthritis/gouty-arthritis-diet">Diet for Gouty Arthritis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Gouty Arthritis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Gouty Arthritis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Gouty Arthritis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/Mbrzxp5hZJU?si=roDJt9T_htWAZqav"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
