import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh7.webp')}alt="Urology Hero" />
        <h1>Vitiligo</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Vitiligo</h2>
          <img src={require('../images/skin7.webp')}alt="Urology Hero" />
          <p>
          Homoeopathy treats vitiligo by focusing on the person’s overall health and individual constitution. Remedies are selected based on specific symptoms, emotional well-being, and the individual’s unique response to vitiligo. The goal is to address underlying causes, support the immune system, and stimulate melanin production. While vitiligo is a chronic condition, homoeopathic treatment aims to slow its progression, reduce depigmentation, and promote natural healing.
          </p>
          <h3>Causes of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Autoimmune Reaction:</h5>The immune system mistakenly attacks and destroys melanocytes, leading to depigmentation.            </li>
            <li><h5>Genetic Predisposition:</h5>A family history of vitiligo or other autoimmune diseases may increase the risk of developing vitiligo.            </li>
            <li><h5>Environmental Triggers:</h5>Sunburn, stress, or exposure to certain chemicals can trigger or worsen vitiligo in individuals with a predisposition.            </li>
            <li><h5>Hormonal Changes:</h5>Hormonal changes during puberty, pregnancy, or menopause may trigger vitiligo or accelerate its progression.            </li>
            <li><h5>Oxidative Stress:</h5>Imbalance in free radicals and antioxidants can damage melanocytes and contribute to the development of vitiligo.            </li>
          </ul>
          <h3>Symptoms of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Pale or White Patches on the Skin:</h5>Depigmented patches that are more noticeable on darker skin. They may start small and grow over time.            </li>
            <li><h5>Symmetrical Patterns:</h5>Patches often appear symmetrically on both sides of the body, though they can also be localised.            </li>
            <li><h5>Loss of Color in the Hair, Beard, or Eyelashes:</h5>Vitiligo can affect hair, causing white or grey strands on the scalp, eyebrows, or other areas.            </li>
            <li><h5>Mucous Membrane Discoloration:</h5>In some cases, vitiligo affects areas inside the mouth, nose, or even the eyes, leading to loss of pigment in these areas.            </li>
            <li><h5>Sun Sensitivity:</h5>The depigmented patches are more sensitive to sunburn, as they lack melanin for natural sun protection.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Psychological Impact:</h5>Vitiligo can lead to feelings of self-consciousness, anxiety, and depression, particularly if it affects visible areas of the body.            </li>
            <li><h5>Sunburn and Skin Cancer:</h5>Depigmented areas are more susceptible to sunburn, which increases the risk of skin damage and potentially skin cancer.            </li>
            <li><h5>Hearing Loss:</h5>Some studies suggest an association between vitiligo and hearing loss due to melanin-related cells in the inner ear.            </li>
            <li><h5>Eye Problems:</h5>There may be an increased risk of inflammation in the eyes, as melanin is also present in the retina.            </li>
         </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Topical Corticosteroids:</h5>Steroid creams may be applied to the skin to help restore pigmentation, especially in early stages.            </li>
            <li><h5>Topical Immunomodulators:</h5>Medications such as tacrolimus or pimecrolimus are sometimes prescribed to suppress the immune response in affected areas.            </li>
            <li><h5>Phototherapy:</h5>Ultraviolet light therapy (PUVA or UVB) may be used to stimulate melanocytes and encourage pigment production.            </li>
            <li><h5>Depigmentation Therapy:</h5>In cases of widespread vitiligo, depigmentation therapy can be used to lighten unaffected areas to match the white patches.            </li>
            <li><h5>Surgical Options:</h5>Procedures like skin grafting or melanocyte transplant may be considered for localised areas of vitiligo.            </li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Arsenicum Album:</h5>Recommended for individuals with dry, rough skin and itching around the depigmented areas. This remedy is often used for people who are anxious and fastidious by nature.            </li>
            <li><h5>Silicea:</h5>Suitable for those with weak immune systems and a tendency toward chronic skin issues. It is beneficial for individuals with pale skin, low energy, and sensitivity to cold.            </li>
            <li><h5>Natrum Muriaticum:</h5>Effective for vitiligo associated with emotional stress, grief, or suppressed emotions. This remedy is especially useful for individuals who are introverted, sensitive, and emotionally reserved.            </li>
            <li><h5>Sepia:</h5>Recommended for hormonal imbalances that contribute to vitiligo, particularly in women. It is helpful for individuals with irregular menstrual cycles, mood swings, and a feeling of indifference.            </li>
            <li><h5>Sulphur:</h5>Known for its action on the skin, sulphur is ideal for individuals with dry, scaly skin and intense itching. This remedy is useful for people who often feel warm and experience skin problems that worsen with heat.            </li>
          </ul>
         <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy can provide supportive treatment for individuals with vitiligo by addressing both physical symptoms and emotional well-being. Many people report improvements in skin tone, a reduction in new white patches, and enhanced emotional resilience with homoeopathic care. While homoeopathy may not cure vitiligo, it can help slow its progression and improve overall quality of life. As vitiligo is a chronic condition, long-term commitment to homoeopathic treatment, lifestyle changes, and good sun protection are essential for the best outcomes. Consulting a qualified healthcare provider for a comprehensive treatment plan is crucial for managing vitiligo effectively.          </p>
</div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Vitiligo Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Vitiligo.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/vitiligo/vitiligo-diet">Diet for Vitiligo prevention</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Vitiligo</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Vitiligo</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Vitiligo Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/ifIuUTEJutM?si=AvKkBqtFkomUNJHb"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
