import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const AnxietyDisorderDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Anxiety Disorder Diet Guide</h1>
      <p className="diet-description">A balanced diet with calming nutrients and steady energy sources can help manage anxiety by promoting neurotransmitter balance, reducing inflammation, and supporting mental well-being. Here are some dietary suggestions for reducing anxiety symptoms.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium helps relax muscles and nerves, supporting calmness. Include spinach, almonds, avocado, and bananas in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish, chia seeds, and flaxseeds support brain health and reduce inflammation, which may help alleviate anxiety symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Complex Carbohydrates</h3>
          <p className="diet-text">Complex carbs, such as those in oats, sweet potatoes, and whole grains, provide steady energy and support serotonin production, promoting a sense of calm.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics in yogurt, kefir, and sauerkraut support gut health, which is closely linked to mood and anxiety regulation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, dark chocolate, and leafy greens are rich in antioxidants that help protect the brain from oxidative stress, supporting mental health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile, lavender, and green tea contain calming compounds that may help reduce stress and promote relaxation.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars</h3>
          <p className="diet-text">Sugary foods cause blood sugar spikes and crashes, which can contribute to mood swings and anxiety. Limit candies, sodas, and sweetened snacks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain artificial additives and unhealthy fats that may negatively impact mental health. Focus on whole foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Caffeine (in excessive amounts)</h3>
          <p className="diet-text">Excessive caffeine can increase heart rate and anxiety. Limit intake or choose calming herbal teas instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol may initially reduce anxiety, but it often worsens symptoms in the long run. Moderation is key for mental health stability.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Artificial Additives</h3>
          <p className="diet-text">Artificial sweeteners and preservatives can impact mental well-being. Opt for fresh, whole foods for better mental clarity.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with blueberries, chia seeds, and a handful of almonds.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed vegetables.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a few pieces of dark chocolate.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water, and include calming herbal teas like chamomile or lavender tea to reduce stress.</li>
        </ul>
      </section>
    </div>
  );
};

export default AnxietyDisorderDiet;
