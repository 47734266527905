import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/overactive.webp')}alt="Urology Hero" />
        <h1>Overactive Bladder Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Overactive Bladder</h2>
          <img src={require('../images/kidney5.webp')}alt="Urology Hero" />
          <h3>Causes</h3>
          <ul>
            <li><h5>Neurological Factors:</h5>Conditions like Parkinson’s disease, stroke, or spinal cord injuries can disrupt nerve signals controlling bladder function, leading to overactivity.</li>
            <li><h5>Frequency:</h5>People with overactive bladder often urinate more frequently than normal, typically more than eight times in a 24-hour period. This frequent need to urinate can disrupt daily activities and sleep patterns.</li>
            <li><h5>Medications:</h5>Certain drugs, like diuretics or medications for hypertension, can stimulate bladder activity or interfere with nerve signals, exacerbating overactive bladder symptoms.</li>
            <li><h5>Lifestyle Factors:</h5> Excessive consumption of caffeine or alcohol can irritate the bladder, increasing urgency and frequency of urination.</li>
            <li><h5>Infections:</h5>Urinary tract infections (UTIs) can inflame the bladder, causing sudden urges to urinate and contributing to overactive bladder symptom</li>
          </ul>
          <h3>Symptoms</h3>
          <ul>
            <li><h5>Urgency:</h5>Overactive bladder is characterized by a sudden and intense urge to urinate that is difficult to postpone. This urge can arise abruptly and may be triggered by factors such as running water or the sight of a restroom.</li>
            <li><h5>Bladder Abnormalities:</h5>Structural issues such as bladder stones or tumors can impair the bladder’s ability to store and release urine properly, causing urgency and incontinence.</li>
            <li><h5>Nocturia:</h5>Nocturia refers to the need to wake up from sleep to urinate. Individuals with overactive bladder may experience this symptom multiple times during the night, which can lead to sleep disturbances and fatigue.</li>
            <li><h5>Urge Incontinence:</h5>Urge incontinence involves involuntary urine leakage following a sudden urge to urinate. This leakage can occur before reaching the restroom in response to the urgency sensation, and it may vary in amount from a few drops to a larger volume.</li>
            <li><h5>Impact on Quality of Life:</h5>Beyond the physical symptoms, overactive bladder can significantly impact quality of life. It may lead to anxiety, embarrassment, and social withdrawal due to the fear of leakage and the constant need to be near restroom facilities.</li>
          </ul>
          <h3>Investigations and Diagnosis</h3>
          <ul>
            <li><h5>Medical History: </h5>A thorough review of the patient’s medical history is essential. This includes discussing symptoms such as urgency, frequency, nocturia, and urge incontinence. Details about any underlying conditions (like neurological disorders or previous surgeries) and medications are also important as they may contribute to bladder dysfunction.</li>
            <li><h5>Physical Examination:</h5>A physical exam, particularly focusing on pelvic health, can provide valuable insights. The healthcare provider may assess pelvic floor muscle tone, evaluate for signs of prolapse (when organs like the bladder drop from their normal position), and check neurological function related to bladder control.</li>
            <li><h5>Urinalysis:</h5>A urinalysis is performed to detect signs of urinary tract infections (UTIs) or other urinary abnormalities such as blood or protein in the urine. UTIs can mimic symptoms of overactive bladder and treating them can resolve symptoms in some cases.</li>
            <li><h5>Bladder Diary:</h5> Keeping a bladder diary involves recording fluid intake, voiding frequency, urgency episodes, and any leakage over several days. This diary helps establish patterns and triggers for symptoms, providing valuable information for diagnosis and treatment planning.</li>
            <li><h5>Urodynamic Testing:</h5> Urodynamic testing involves a series of tests that evaluate how well the bladder, sphincters, and urethra are storing and releasing urine. </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Quality of Life:</h5>Overactive bladder can significantly impact an individual’s quality of life. The constant urge to urinate and potential for urge incontinence can lead to anxiety, embarrassment, and frustration. Activities such as traveling, socializing, and participating in physical activities may be limited due to the need to be near restroom facilities.</li>
            <li><h5>Health Risks in Older Adults:</h5>Older adults with overactive bladder are at increased risk of health complications such as falls and fractures. The urgency to urinate may lead to rushing to the restroom, increasing the likelihood of falls, especially at night. Additionally, frequent trips to the restroom can disrupt sleep patterns, contributing to fatigue and increasing the risk of accidents.</li>
            <li><h5>Skin Infections: </h5>For individuals experiencing urge incontinence, skin irritation and infections can occur due to prolonged exposure to moisture from urine. This can lead to conditions such as dermatitis or urinary tract infections (UTIs), further impacting overall health and comfort.</li>
            <li><h5>Psychosocial Impact:</h5>Overactive bladder can have a profound psychosocial impact. Individuals may experience embarrassment and social withdrawal due to fear of leakage or accidents in public settings. This can affect relationships, self-esteem, and overall emotional well-being.</li>
            <li><h5>Risk Factors:</h5>Several factors can increase the risk of developing overactive bladder, including:
                <li><h6>Age:</h6>Bladder function tends to change with age, increasing the risk of overactive bladder in older adults.</li>
                <li><h6>Gender:</h6>Women are more likely than men to experience overactive bladder, possibly due to anatomical differences and hormonal changes.</li>
                <li><h6>Obesity:</h6>Excess weight can put pressure on the bladder and pelvic muscles, contributing to bladder control issues.</li>
                <li><h6>Neurological Conditions:</h6>Disorders such as Parkinson’s disease, stroke, or multiple sclerosis can affect nerve signals to the bladder, leading to overactive bladder symptoms.</li>
                <li><h6>Medications:</h6>Certain medications, such as diuretics, antihistamines, or medications for high blood pressure, can contribute to overactive bladder symptoms.
                </li>
            </li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <h4>Behavioral Therapies:</h4>
          <ul>
            <li><h5>Bladder Training:</h5>Involves gradually increasing the time between bathroom trips to help control the urge to urinate. This technique aims to retrain the bladder to hold urine for longer periods, reducing the frequency of urges.</li>
            <li><h5>Scheduled Voiding:</h5>Establishing a regular schedule for urination can help manage overactive bladder symptoms. This involves urinating at set times throughout the day, regardless of whether there is a strong urge.</li>
          </ul>
          <h4>Medications:</h4>
          <ul>
            <li><h5>Anticholinergics:</h5>These medications block the neurotransmitter acetylcholine, which helps relax bladder muscles and reduce the urgency and frequency of urination.</li>
            <li><h5>Beta-3 Agonists: </h5>These medications work by relaxing the bladder muscle, allowing it to hold more urine and reducing the urgency to urinate.</li>
          </ul>
          <h4>Pelvic Floor Exercises:</h4>
          <ul>
            <li><h5>Kegel Exercises:</h5>These exercises strengthen the pelvic floor muscles, which support the bladder and help control urination. Strengthening these muscles can improve bladder control and reduce urgency and leakage episodes.</li>
          </ul>
          <h3>Homeopathic Approaches to Treatment</h3>
          <ul>
            <li><h5>Personalized Remedies:</h5>Based on individual symptoms and constitutional assessment.</li>
            <li><h5>Common Remedies:</h5>Such as Causticum, Sepia, or Lycopodium for urinary symptoms.</li>
          </ul>
          <h3>Conclusion on Overactive Bladder</h3>
          <p>Overactive bladder presents with urgency, frequency, and potentially urge incontinence, affecting daily life significantly. While conventional treatments emphasize medications and behavioral therapies, homeopathic approaches provide personalized alternatives. Effective management often integrates lifestyle adjustments, medications, and potentially homeopathic remedies to alleviate symptoms and enhance overall quality of life.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Overactive Bladder Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Overactive Bladder and other Overactive Bladder conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/urology/overactive-bladder/overactive-bladder-diet">Diet for Overactive Bladder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Overactive Bladder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Overactive Bladder Treatment</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Overactive Bladder Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/nO1MPhb5elA?si=KMgJmTZWFpD7UPsG"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
      
    </div>
  );
};

export default KidneyPage;
