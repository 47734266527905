import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const AcneDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Acne Diet Guide</h1>
      <p className="diet-description">A diet rich in anti-inflammatory and low-glycemic foods can help manage acne by reducing inflammation, regulating hormones, and promoting healthy skin. Below are suggestions for foods to take and avoid when dealing with acne.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Low-Glycemic Foods</h3>
          <p className="diet-text">Low-glycemic foods like whole grains, legumes, and vegetables help stabilize blood sugar levels and reduce insulin spikes, which are often linked to acne flare-ups.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in salmon, flaxseeds, and walnuts have anti-inflammatory properties that help reduce skin inflammation and promote clearer skin.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc plays a crucial role in skin healing and inflammation reduction. Include foods like pumpkin seeds, lentils, and chickpeas to support skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are packed with antioxidants, which help protect the skin from oxidative stress and promote a healthy complexion.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods support gut health, which is closely linked to skin health. A balanced gut can reduce acne breakouts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamin A-Rich Foods</h3>
          <p className="diet-text">Vitamin A is important for skin regeneration and repair. Include foods like sweet potatoes, carrots, and spinach to promote clear, healthy skin.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Glycemic Foods</h3>
          <p className="diet-text">Foods with a high glycemic index, such as white bread, sugary snacks, and sodas, can spike blood sugar and insulin levels, leading to acne flare-ups. Opt for low-glycemic alternatives.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Dairy Products (in some cases)</h3>
          <p className="diet-text">Dairy products, especially milk, may increase insulin levels and stimulate acne in some individuals. Reducing or eliminating dairy could improve skin clarity for those sensitive to it.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can increase insulin resistance and inflammation, both of which can contribute to acne breakouts. Limit sweets, sugary beverages, and processed foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain unhealthy fats, additives, and sugars that can exacerbate acne. Stick to whole, unprocessed foods for healthier skin.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and those high in unhealthy fats can increase sebum production, leading to clogged pores and more frequent breakouts. Choose healthy fats like avocados and olive oil.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Excessive Caffeine</h3>
          <p className="diet-text">While moderate caffeine is fine, excessive amounts can dehydrate the skin and increase stress levels, both of which can lead to acne breakouts. Keep caffeine intake balanced.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, flaxseeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and mixed greens.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support skin hydration and reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default AcneDiet;
