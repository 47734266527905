import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
   
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh6.webp')}alt="Endometriosis" />
        <h1>Endometriosis</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Endometriosis</h2>
          <img src={require('../images/female6.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy offers a holistic approach to treating endometriosis by addressing the individual’s overall health, including physical symptoms and emotional well-being. Treatment focuses on stimulating the body’s self-healing mechanisms, reducing inflammation, managing pain, and restoring hormonal balance through personalized remedies.          </p>
          <h3>Causes</h3>
          <p>Endometriosis involves the growth of endometrial-like tissue outside the uterus, influenced by several factors:</p>
          <ul>
            <li><h5>Retrograde Menstruation: </h5>Menstrual blood flows backward through the fallopian tubes.</li>
            <li><h5>Genetic Predisposition:</h5>Family history increases susceptibility.</li>
            <li><h5>Immune System Dysfunction:</h5>Impaired immune response fails to eliminate misplaced endometrial tissue.</li>
            <li><h5>Hormonal Imbalances:</h5>Estrogen dominance promotes tissue growth.</li>
            <li><h5>Environmental Factors: </h5>Exposure to toxins affecting hormone levels.</li>
            <li><h5>Metaplasia: </h5> Transformation of other cells into endometrial-like tissue.</li>        
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History and Physical Examination: </h5>Assess symptoms, menstrual history, and overall health.</li>
            <li><h5>Pelvic Exam: </h5>Detect abnormalities such as cysts or nodules.</li>
            <li><h5>Ultrasound:</h5>Identify endometriomas (ovarian cysts) and evaluate pelvic structures.</li>
            <li><h5>MRI:</h5> Provides detailed imaging for precise diagnosis.</li>
            <li><h5>Laparoscopy:</h5>Gold standard for confirming endometriosis through direct visualization and biopsy.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Chronic Pelvic Pain:</h5>Debilitating pain during menstruation and throughout the cycle.</li>
            <li><h5>Infertility:</h5>Adhesions and scarring affect fertility by blocking fallopian tubes or altering pelvic anatomy.</li>
            <li><h5>Ovarian Cysts (Endometriomas):</h5>Painful cysts can form on ovaries.</li>
            <li><h5>Adhesions:</h5>Scar tissue binds organs together, causing pain and dysfunction.</li>
            <li><h5>Emotional Impact:</h5>Stress, depression, and anxiety due to chronic pain and infertility.</li>
            <li><h5>Increased Cancer Risk:</h5>Slightly higher risk of certain cancers like ovarian cancer.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5></li>
            <li><h5>Pain Management:</h5>NSAIDs for pain relief.</li>
            <li><h5>Hormonal Therapies:</h5>Birth control pills, progestins, GnRH agonists to suppress estrogen and manage symptoms.</li>
            <li><h5>Surgical Options:</h5></li>
            <li><h5>Laparoscopic Surgery:</h5>To remove endometrial tissue, cysts, and adhesions.</li>
            <li><h5>Hysterectomy:</h5>Last resort for severe cases not responding to other treatments.</li>
            <li><h5>Lifestyle Modifications: </h5>Diet changes, exercise, stress reduction techniques.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <p>Homeopathy offers individualized remedies based on symptoms and overall health:</p>
          <ul>
            <li><h5>Sepia: </h5>For women with dragging pelvic pain, irritability, fatigue exacerbated by hormonal changes.</li>
            <li><h5>Pulsatilla:</h5>Shifting pain, mood swings, symptoms relieved by fresh air.</li>
            <li><h5>Calcarea Carbonica:</h5>Heavy periods, cold intolerance, fatigue.</li>
            <li><h5>Lachesis:</h5> Left-sided pain, ovarian cysts, worsened premenstrually.</li>
            <li><h5>Belladonna:</h5>Sudden, intense pain, throbbing sensation.</li>
            <li><h5>Magnesia Phosphorica:</h5>Menstrual cramps, spasmodic pain relieved by warmth, pressure.</li>
            <li><h5>Cimicifuga: </h5>Intense pelvic pain, radiating to thighs, mood swings.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathy provides a natural approach to managing endometriosis, focusing on symptom relief, improving quality of life, and addressing the underlying causes. Early intervention, personalized treatment plans, and integration with conventional care enhance effectiveness. Holistic management includes lifestyle adjustments, regular monitoring, and comprehensive support to optimize outcomes and promote long-term health and well-being.</p>          
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Endometriosis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Endometriosis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/endometriosis/endometriosis-diet">Diet for Endometriosis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Endometriosis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Endometriosis </a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Endometriosis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/7T6QW2SSfis?si=btxa5TWkGGJxXhQd"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
