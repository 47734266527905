import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const OveractiveBladderDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Overactive Bladder Diet Guide</h1>
      <p className="diet-description">Managing overactive bladder through dietary changes can help reduce symptoms and improve overall bladder health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">1. Stay Hydrated</h2>
        <p className="diet-text">Drinking enough water is essential, but be mindful not to overdo it. Excessive fluid intake can exacerbate symptoms. Aim for balanced hydration by drinking water consistently throughout the day, around 6-8 cups daily.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">2. Limit Caffeine and Alcohol</h2>
        <p className="diet-text">Caffeine and alcohol are diuretics that can irritate the bladder and increase the urgency to urinate. Reduce or eliminate these drinks to help manage symptoms.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">3. Avoid Spicy and Acidic Foods</h2>
        <p className="diet-text">Spicy foods, citrus fruits, and tomatoes can irritate the bladder lining and increase urgency. Avoid these foods if they worsen your symptoms.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">4. Eat Fiber-Rich Foods</h2>
        <p className="diet-text">Constipation can put pressure on the bladder and worsen overactive bladder symptoms. Eating a diet rich in fiber, including whole grains, fruits, and vegetables, helps prevent constipation.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">5. Manage Fluid Timing</h2>
        <p className="diet-text">Spread out your fluid intake throughout the day and limit drinking large amounts of water before bed to prevent frequent nighttime urination (nocturia).</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">6. Focus on Anti-inflammatory Foods</h2>
        <p className="diet-text">Incorporating anti-inflammatory foods, such as berries, leafy greens, and fatty fish, into your diet can help reduce bladder irritation.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">7. Try Probiotic-Rich Foods</h2>
        <p className="diet-text">Probiotics, found in foods like yogurt, kefir, and sauerkraut, can help promote a healthy balance of bacteria in the urinary tract and reduce bladder infections.</p>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with blueberries and flaxseeds.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled turkey sandwich on whole grain bread with a side of cucumber slices.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A small bowl of plain yogurt with a handful of almonds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked chicken breast with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water in small amounts throughout the day, avoiding large amounts before bed.</li>
        </ul>
      </section>
    </div>
  );
};

export default OveractiveBladderDiet;
