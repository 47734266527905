import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const PsoriasisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Psoriasis Diet Guide</h1>
      <p className="diet-description">A diet rich in anti-inflammatory foods can help manage psoriasis by reducing skin inflammation, promoting immune health, and supporting overall skin health. Below are suggestions for foods to take and avoid when dealing with psoriasis.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, fatty fish (like salmon and mackerel), and nuts are rich in Omega-3 fatty acids and antioxidants, which help reduce skin inflammation and promote healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods like sauerkraut support gut health and balance the immune system, which is crucial for managing autoimmune conditions like psoriasis.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D helps modulate the immune response and reduce psoriasis symptoms. Fatty fish, eggs, and fortified dairy products are good sources of Vitamin D.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Whole Grains</h3>
          <p className="diet-text">Whole grains like brown rice, quinoa, and oats provide fiber and nutrients that reduce inflammation and support overall skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Keeping the body hydrated is essential for managing psoriasis. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Turmeric and Ginger</h3>
          <p className="diet-text">Both turmeric and ginger have strong anti-inflammatory properties that can help reduce flare-ups and soothe skin irritation in psoriasis.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can trigger inflammation, worsening psoriasis symptoms. Avoid sugary snacks, sodas, and processed foods to help control flare-ups.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods, often high in unhealthy fats, additives, and preservatives, can contribute to inflammation. Stick to fresh, whole foods for better skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and those high in unhealthy fats can increase inflammation, which may worsen psoriasis. Choose healthy fats from sources like avocados, olive oil, and nuts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can weaken the immune system and lead to dehydration, both of which can trigger psoriasis flare-ups. Limiting or avoiding alcohol is recommended for better skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Gluten (for some individuals)</h3>
          <p className="diet-text">For some people, gluten sensitivity may trigger psoriasis flare-ups. If gluten sensitivity is a concern, opt for gluten-free grains like quinoa and brown rice.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Dairy Products (for some individuals)</h3>
          <p className="diet-text">Dairy products can be inflammatory for some individuals with psoriasis. Reducing or eliminating dairy may help control symptoms, depending on sensitivity.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, flaxseeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A probiotic-rich yogurt or a handful of walnuts.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default PsoriasisDiet;
