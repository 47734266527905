import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh3.webp')}alt="Urology Hero" />
        <h1>Hair fall</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Hair fall</h2>
          <img src={require('../images/skin3.webp')}alt="Urology Hero" />
          <p>
          Homeopathic medicine for hair treatments hair fall by addressing the underlying causes, including physical, mental, and emotional factors. Remedies are selected based on the individual’s specific symptoms and constitution, aiming to strengthen hair follicles, improve scalp health, and stimulate hair growth. Since hair fall can be linked to various factors, homoeopathic treatment focuses on holistic healing, helping to restore balance in the body and promote long-term hair health.
          </p>
          <h3>Causes of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Genetics:</h5>Androgenetic alopecia, also known as male-pattern or female-pattern baldness, is the most common cause of hair loss and is hereditary.            </li>
            <li><h5>Hormonal Changes:</h5>Hormonal imbalances during pregnancy, childbirth, menopause, or due to thyroid problems can lead to temporary or permanent hair loss.            </li>
            <li><h5>Nutritional Deficiencies:</h5>Lack of essential nutrients such as iron, protein, vitamins (especially B vitamins and vitamin D), and minerals can contribute to hair fall.            </li>
            <li><h5>Stress and Emotional Factors:</h5>Chronic stress, anxiety, and trauma can disrupt the hair growth cycle, leading to increased hair shedding.            </li>
            <li><h5>Medical Conditions:</h5>Conditions such as alopecia areata, scalp infections, and autoimmune diseases can cause hair loss.            </li>
            <li><h5>Medications and Treatments:</h5>Certain medications, including chemotherapy drugs, antidepressants, and blood thinners, can lead to hair loss as a side effect.            </li>
            <li><h5>Poor Hair Care Practices:</h5>Excessive styling, heat treatments, chemical processing, and tight hairstyles can weaken hair and contribute to hair fall.            </li>
            </ul>
          <h3>Symptoms of Molluscum Contagiosum</h3>
          <ul>
            <li><h5>Gradual Thinning:</h5>Hair becomes progressively thinner, particularly on the top of the head or crown.            </li>
            <li><h5>Patchy or Circular Bald Spots:</h5>Hair falls out in small, coin-sized patches on the scalp, beard, or eyebrows.            </li>
            <li><h5>Sudden Loosening of Hair:</h5>Hair may loosen and fall out suddenly due to physical or emotional shock.            </li>
            <li><h5>Full-Body Hair Loss:</h5>Hair loss occurs across the entire body, often as a result of medical treatments like chemotherapy.            </li>
            <li><h5>Scaling or Inflammation on the Scalp:</h5>This may be a sign of an underlying scalp infection, such as ringworm, leading to hair fall.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Psychological Impact:</h5>Hair loss can lead to decreased self-esteem, anxiety, and depression, affecting overall well-being.            </li>
            <li><h5>Permanent Hair Loss:</h5>In some cases, untreated hair fall conditions like androgenetic alopecia can lead to permanent baldness.            </li>
            <li><h5>Increased Sensitivity:</h5>Hair loss can expose the scalp, making it more sensitive to sunburn, weather changes, and external irritants.            </li>
            <li><h5>Scalp Infections:</h5>Open areas on the scalp may become prone to infections, particularly if the skin barrier is compromised.            </li>
         </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Medications:</h5>Minoxidil and finasteride are commonly prescribed for male and female-pattern hair loss.            </li>
            <li><h5>Hormone Therapy: </h5>Hormonal treatments may be used for hair loss related to hormonal imbalances, particularly in women.            </li>
            <li><h5>Nutritional Supplements:</h5>Biotin, vitamin D, iron, and other essential vitamins and minerals may be recommended to improve hair health.            </li>
            <li><h5>Hair Transplant Surgery:</h5>For cases of permanent hair loss, hair transplant procedures can help restore hair density.            </li>
            <li><h5>Lifestyle Modifications:</h5>Reducing stress, avoiding harsh treatments, and following a balanced diet can support overall hair health.            </li>
          </ul>
          <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Silicea:</h5>Effective for individuals with weak, brittle hair that falls out easily. Suitable for people with a tendency towards dry skin and a history of other skin issues.            </li>
            <li><h5>Natrum Muriaticum:</h5>Recommended for hair loss associated with emotional stress or grief. Beneficial for those experiencing dandruff or greasy scalp with hair thinning, especially on the crown area.            </li>
            <li><h5>Phosphorus:</h5>Ideal for individuals with premature greying and hair loss in patches. Often used for people who experience scalp sensitivity, itching, and a burning sensation.            </li>
            <li><h5>Lycopodium:</h5>Suitable for hair loss due to hormonal imbalances, particularly in women after childbirth. Helpful for individuals with digestive issues, especially bloating and gas, along with hair fall.            </li>
            <li><h5>Kali Sulphuricum:</h5>Known for treating hair loss with dandruff, itching, and a scaly scalp. This remedy is often used for people experiencing hair fall along with yellowish dandruff.            </li>
          </ul>
         <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy offers a holistic approach for managing hair fall by addressing both physical and emotional factors. Many individuals report improved hair health, reduced shedding, and less scalp irritation with consistent homoeopathic treatment. While homoeopathy may not completely reverse genetic or permanent hair loss, it serves as a valuable complementary approach for those seeking natural options to improve overall hair and scalp health. Homoeopathy is particularly beneficial for those dealing with hair loss related to stress, hormonal changes, or nutritional deficiencies, as it supports long-term healing and balance in the body.
          </p>
</div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Hair fall Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Hair fall.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/hair-fall/hair-fall-diet">Diet for Hair fall prevention</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Hair fall</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Hair fall</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Hair Fall Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/NVyjnM5QjZE?si=tIgh7euMql22xdAV"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
