import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/treat4.webp')}alt="Urology Hero" />
        {/* <h1>Polyarthritis Treatment</h1> */}
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Polyarthritis</h2>
          <img src={require('../images/joint4.webp')}alt="Urology Hero" />
       
          <h3>Causes</h3>
          <p>Polyarthritis itself isn’t a specific disease, but rather a term describing inflammation in five or more joints. Several conditions can cause it, including:</p>
          <ul>
            <li><h5>Autoimmune diseases:</h5>Rheumatoid arthritis, lupus, psoriatic arthritis, and others where the body attacks its own tissues.</li>
            <li><h5>Infections:</h5>Bacterial or viral infections like chikungunya, parvovirus, or reactive arthritis triggered by a prior infection.</li>
            <li><h5>Crystal deposition diseases: </h5>Gout, caused by uric acid crystals building up in joints.</li>
            <li><h5>Other conditions: </h5>Sarcoidosis, Whipple’s disease, and some cancers.</li>
          </ul>
          <h3>Symptoms of Polyarthritis:</h3>
          <ul>
            <li>Joint pain, stiffness, and swelling.</li>
            <li>Reduced range of motion in affected joints.</li>
            <li>Fever, fatigue, and malaise (depending on the cause).</li>
            <li>Rash or redness around the joints (in some cases).</li>
          </ul>
          <h3>Investigations of Polyarthritis:</h3>
          <ul>
            <li><h5>Physical Examination: </h5>A doctor will assess your joints for swelling, tenderness, and warmth.</li>
            <li><h5>Blood tests:  </h5>To check for inflammation markers, rheumatoid factor (in some cases), and rule out infections.</li>
            <li><h5>Imaging tests:</h5> X-rays, ultrasounds, or MRIs to visualize the joints and assess for damage.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Joint damage and deformity:</h5>If left untreated, chronic inflammation can erode cartilage and bone, leading to joint deformity and disability.</li>
            <li><h5>Increased risk of heart disease:</h5>Inflammation can damage blood vessels and increase the risk of heart attack and stroke.</li>
            <li><h5>Osteoporosis:</h5>Chronic inflammation can weaken bones and increase the risk of fractures.</li>
          </ul>
          <p>Risk factors for polyarthritis vary depending on the underlying cause. Some general risk factors include:</p>
          <ul>
            <li><h5>Age:</h5>Risk increases with age for many types of arthritis.</li>
            <li><h5>Sex:</h5>Women are more prone to rheumatoid arthritis, while gout is more common in men.</li>
            <li><h5>Genetics:</h5> Having a family history of certain conditions like rheumatoid arthritis increases risk.</li>
            <li><h5>Obesity:</h5>Excess weight puts stress on joints, increasing the risk of arthritis.</li>
            <li><h5>Previous joint injuries:</h5>Injuries can increase susceptibility to arthritis later in life.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>Treatment focuses on managing symptoms, reducing inflammation, and preventing joint damage. It may involve:</p>
          <ul>
            <li><h5>Medications:</h5>Pain relievers, anti-inflammatory drugs, disease-modifying antirheumatic drugs (DMARDs) for autoimmune diseases, and corticosteroids.</li>
            <li><h5>Physical therapy:</h5>Exercises to improve joint flexibility, strength, and range of motion.</li>
            <li><h5>Occupational therapy:</h5>Learning techniques to perform daily activities with less joint strain.</li>
            <li><h5>Lifestyle modifications:</h5>Maintaining a healthy weight, eating a balanced diet, and getting enough rest.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>Polyarthritis can significantly impact quality of life. Early diagnosis and proper treatment can help manage symptoms, prevent joint damage, and maintain a good quality of life. If you experience pain or swelling in five or more joints, consult a doctor for proper diagnosis and treatment.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Polyarthritis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Polyarthritis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/joint-bone/polyarthritis/polyarthritis-diet">Diet for Polyarthritis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Polyarthritis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Polyarthritis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Polyarthritis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/3X9qtbX2XeU?si=cbPolhqAES4_Oabn"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
