import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const ThyroidDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Thyroid Diet Guide</h1>
      <p className="diet-description">A nutrient-dense diet can help support thyroid health by regulating hormones, reducing inflammation, and promoting overall well-being. Below are suggestions for foods to take and avoid when managing thyroid disorders.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Iodine-Rich Foods</h3>
          <p className="diet-text">Iodine is essential for thyroid hormone production. Seaweed, iodized salt, and dairy products are good sources of iodine to support thyroid function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Selenium-Rich Foods</h3>
          <p className="diet-text">Selenium plays a crucial role in thyroid hormone metabolism. Include Brazil nuts, sunflower seeds, and tuna to ensure adequate selenium intake.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc helps regulate thyroid hormone levels. Include foods like seeds, nuts, legumes, and whole grains to maintain healthy thyroid function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish, flaxseeds, and walnuts help reduce inflammation and support thyroid health by regulating immune responses.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D deficiency is common in people with thyroid disorders. Include fortified dairy products, mushrooms, and fatty fish to boost Vitamin D levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help reduce oxidative stress and promote overall thyroid health.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Goitrogenic Foods (in excess)</h3>
          <p className="diet-text">Cruciferous vegetables like cabbage, broccoli, and cauliflower can interfere with thyroid hormone production in large amounts. Consume them cooked or in moderation if you have hypothyroidism.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain unhealthy fats, additives, and preservatives that can disrupt thyroid function. Stick to whole, unprocessed foods for better thyroid health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can lead to inflammation and weight gain, worsening thyroid symptoms. Avoid sugary snacks, sodas, and desserts to support balanced thyroid function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and unhealthy fats can interfere with thyroid medication absorption and slow metabolism. Choose healthier fats from sources like avocados and olive oil.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Gluten (for some individuals)</h3>
          <p className="diet-text">Gluten sensitivity or celiac disease may exacerbate thyroid conditions, particularly Hashimoto's disease. Opt for gluten-free grains like quinoa and brown rice if gluten is a concern.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Alcohol</h3>
          <p className="diet-text">Alcohol can interfere with thyroid hormone production and increase inflammation. Limiting alcohol intake can support thyroid health and overall well-being.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a zinc-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support thyroid health and hydration.</li>
        </ul>
      </section>
    </div>
  );
};

export default ThyroidDiet;
