import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const PCODInfertilityDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">PCOD Diet Guide</h1>
      <p className="diet-description">A healthy diet plays an important role in managing PCOD and improving fertility. Below is a guide to foods that can help regulate hormones and what to avoid to reduce symptoms.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Fiber Foods</h3>
          <p className="diet-text">Fiber-rich foods like whole grains, legumes, fruits, and vegetables can help manage insulin levels, which are often imbalanced in women with PCOD. They can also support healthy digestion and hormonal balance.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Lean Proteins</h3>
          <p className="diet-text">Choose lean proteins such as chicken, fish, and plant-based sources like lentils and tofu. These help support healthy ovulation and reduce inflammation in the body.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Foods high in Omega-3s, like flaxseeds, chia seeds, and fatty fish (salmon, sardines), help reduce inflammation and regulate hormone levels, which is key in managing PCOD-related infertility.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Low-Glycemic Index (GI) Foods</h3>
          <p className="diet-text">Low-GI foods such as whole grains, legumes, and most fruits help control insulin levels, which is essential in managing PCOD symptoms and improving fertility.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, nuts, and seeds are rich in antioxidants that protect the body from oxidative stress, improving egg quality and overall reproductive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Healthy Fats</h3>
          <p className="diet-text">Incorporate healthy fats from avocados, olive oil, and nuts. These fats support hormone production and improve insulin sensitivity, reducing PCOD symptoms.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs, such as white bread, pastries, and sugary cereals, can cause insulin spikes, worsening PCOD symptoms and leading to hormonal imbalances. Stick to whole grains and low-GI options.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Sugary snacks, soft drinks, and sweets can disrupt insulin levels and exacerbate PCOD symptoms. It’s important to reduce sugar intake for better hormone regulation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed and Junk Foods</h3>
          <p className="diet-text">Processed foods are often high in unhealthy fats and additives that can increase inflammation and worsen hormonal imbalances. Avoid fast food and pre-packaged meals.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Saturated and Trans Fats</h3>
          <p className="diet-text">Foods high in trans fats and saturated fats, such as fried snacks, baked goods, and margarine, should be avoided. These can increase inflammation and disrupt hormone levels, further complicating PCOD.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Dairy Products (in excess)</h3>
          <p className="diet-text">Excess dairy can sometimes increase androgen levels, which may worsen PCOD symptoms. Limit dairy intake or choose alternatives like almond or coconut milk.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Excessive Caffeine</h3>
          <p className="diet-text">Too much caffeine can impact stress hormones and affect the reproductive system. It’s best to limit caffeine to one cup of coffee per day or switch to herbal teas.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with chia seeds, fresh berries, and a drizzle of honey.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken salad with avocado, spinach, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or walnuts with an apple.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and steamed broccoli, drizzled with olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and limit sugary drinks and excessive caffeine.</li>
        </ul>
      </section>
    </div>
  );
};

export default PCODInfertilityDiet;
