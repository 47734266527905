import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const HemorrhoidsDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Hemorrhoids Diet Guide</h1>
      <p className="diet-description">A proper diet can help prevent and manage hemorrhoids by softening stools, reducing strain during bowel movements, and promoting overall digestive health. Below are suggestions for foods to take and avoid when dealing with hemorrhoids.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Fiber Foods</h3>
          <p className="diet-text">Fiber-rich foods like whole grains, fruits, vegetables, and legumes help soften stools, making it easier to pass bowel movements without straining. Aim for at least 25-30 grams of fiber daily.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Hydrating Foods</h3>
          <p className="diet-text">Proper hydration is crucial for softening stools and preventing constipation. Drink plenty of water and include hydrating foods like cucumbers, watermelon, and oranges in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods like sauerkraut support gut health by balancing the bacteria in the digestive system, improving bowel movements, and reducing the risk of constipation-related hemorrhoids.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Healthy Fats</h3>
          <p className="diet-text">Healthy fats from sources like avocados, olive oil, and nuts can help lubricate the digestive system, making bowel movements smoother and reducing irritation from hemorrhoids.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium is important for muscle relaxation and can help prevent constipation. Foods like spinach, almonds, and bananas are rich in magnesium and can aid in bowel regularity.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like ginger, chamomile, and peppermint can soothe the digestive system, reduce inflammation, and promote smoother bowel movements.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed Foods</h3>
          <p className="diet-text">Processed foods, such as fast food, chips, and pre-packaged snacks, are often low in fiber and can contribute to constipation, increasing the risk of hemorrhoids. Opt for whole, unprocessed foods instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Grains</h3>
          <p className="diet-text">Refined grains, like white bread, pasta, and pastries, are stripped of their fiber content and can lead to constipation. Choose whole grains such as brown rice, oats, and quinoa for better digestion.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can irritate the digestive system and worsen hemorrhoid symptoms, such as itching and discomfort. Reducing spicy food intake may help manage symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Red Meat</h3>
          <p className="diet-text">Red meat is low in fiber and takes longer to digest, which can contribute to constipation. Reducing red meat consumption and incorporating more plant-based proteins can help prevent hemorrhoids.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Sugary Snacks and Drinks</h3>
          <p className="diet-text">Sugary snacks and beverages, such as candy, soda, and baked goods, can disrupt digestion and lead to constipation. Limiting sugar intake can improve bowel regularity and reduce hemorrhoid symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">While small amounts of caffeine can stimulate bowel movements, excessive caffeine can dehydrate the body and worsen constipation, which may aggravate hemorrhoids. Stick to moderate amounts of caffeine and stay well-hydrated.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal with chia seeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> A quinoa and spinach salad with avocado, grilled chicken, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a smoothie with prunes, spinach, and banana.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Grilled salmon with roasted sweet potatoes and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day and include herbal teas like ginger or chamomile to reduce inflammation and promote smoother bowel movements.</li>
        </ul>
      </section>
    </div>
  );
};

export default HemorrhoidsDiet;
