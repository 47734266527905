import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const OsteoarthritisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Osteoarthritis Diet Guide</h1>
      <p className="diet-description">
        A diet rich in anti-inflammatory and bone-supporting nutrients can help manage osteoarthritis by reducing joint inflammation, improving cartilage health, and enhancing bone strength. Below are dietary recommendations for foods to include and avoid to support joint health and reduce osteoarthritis symptoms.
      </p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">
            Omega-3s found in salmon, sardines, and flaxseeds have anti-inflammatory properties that may help reduce joint pain and stiffness in osteoarthritis.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Leafy Green Vegetables</h3>
          <p className="diet-text">
            Dark leafy greens, such as spinach, kale, and Swiss chard, are rich in antioxidants, vitamins, and minerals that support bone health and reduce inflammation.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Vitamin D-Rich Foods</h3>
          <p className="diet-text">
            Foods like fortified dairy, egg yolks, and mushrooms provide vitamin D, which is crucial for bone health and may help reduce osteoarthritis progression.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Calcium-Rich Foods</h3>
          <p className="diet-text">
            Calcium is essential for bone health. Include low-fat dairy products, leafy greens, and fortified plant-based milks to support joint strength.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">
            Berries, oranges, and bell peppers are high in antioxidants like vitamin C, which helps protect cartilage and reduce inflammation.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Extra Virgin Olive Oil</h3>
          <p className="diet-text">
            Olive oil contains oleocanthal, a compound with anti-inflammatory effects similar to NSAIDs, which may help reduce osteoarthritis pain.
          </p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars</h3>
          <p className="diet-text">
            Excess sugar can promote inflammation and contribute to weight gain, putting additional stress on joints. Limit sugary snacks and drinks.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">
            Processed foods often contain additives and unhealthy fats that increase inflammation. Stick to whole, unprocessed foods.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Saturated and Trans Fats</h3>
          <p className="diet-text">
            Saturated fats found in fried foods, fatty meats, and pastries can worsen inflammation. Replace with healthy fats like olive oil and avocado.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Red Meat</h3>
          <p className="diet-text">
            Red meat can be high in saturated fats and advanced glycation end products (AGEs), which contribute to inflammation. Opt for lean proteins like fish and poultry.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Excessive Salt</h3>
          <p className="diet-text">
            High salt intake can cause water retention, leading to increased joint swelling. Limit salt in your diet to reduce joint discomfort.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Alcohol</h3>
          <p className="diet-text">
            Excessive alcohol can interfere with medication and increase inflammation, worsening osteoarthritis symptoms. Limit or avoid alcohol consumption.
          </p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Greek yogurt with chia seeds, berries, and a sprinkle of walnuts.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with steamed broccoli and quinoa, drizzled with olive oil.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Carrot sticks with hummus or a handful of almonds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried tofu with mixed vegetables (bell peppers, spinach, zucchini) and brown rice.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day and consider green tea for added antioxidants.</li>
        </ul>
      </section>
    </div>
  );
};

export default OsteoarthritisDiet;
