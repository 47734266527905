import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/meh5.webp')}alt="Urology Hero" />
        <h1>Anxiety disorder Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Anxiety disorder</h2>
          <img src={require('../images/mental5.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
         <ul>
            <li>Homeopathy approaches Anxiety Disorders as complex conditions involving heightened emotional responses and mental distress. It emphasizes a holistic and individualized treatment aimed at addressing the underlying causes of anxiety, reducing symptoms, and promoting overall well-being. </li>
            <li>Remedies are selected based on a thorough assessment of the patient’s specific anxiety symptoms, triggers, personality traits, and overall health. The goal is to restore balance in the nervous system, improve emotional resilience, and support mental clarity.            </li>
            </ul>
          <h3>Causes</h3>
          <ul>
            <li>Anxiety Disorders can arise from a variety of factors, including genetic predisposition, neurotransmitter imbalances (such as serotonin and dopamine), hormonal fluctuations (e.g., thyroid disorders), chronic stress, trauma, and significant life changes.</li>
            <li>Environmental factors, such as upbringing, early life experiences, ongoing stressors, and social factors, can also contribute to the development or exacerbation of anxiety disorders.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li>Homeopathic diagnosis of Anxiety Disorders involves a comprehensive evaluation of the patient’s anxiety symptoms, psychological profile, medical history, and potential triggers.</li>
            <li>Clinical assessments, including detailed discussions about anxiety triggers, frequency, intensity, and impact on daily life, help in understanding the individual’s specific anxiety patterns and tailoring treatment.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li>Complications associated with Anxiety Disorders include impaired daily functioning, avoidance behaviors, social isolation, depression, substance abuse, and physical health issues (such as cardiovascular problems due to chronic stress).</li>
            <li>Risk factors include a family history of anxiety or other mental health disorders, traumatic experiences, chronic medical conditions, substance abuse, and high-stress environments.            </li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li>Conventional treatments for Anxiety Disorders typically include psychotherapy (such as cognitive-behavioral therapy [CBT], exposure therapy, or mindfulness-based therapies) and medications (such as selective serotonin reuptake inhibitors [SSRIs], benzodiazepines, or beta-blockers) to manage symptoms.</li>
            <li>Lifestyle modifications, stress management techniques, and support groups may complement traditional treatments to improve coping strategies and overall well-being.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <ul>
            <li>Homeopathic remedies for Anxiety Disorders are selected based on the individual’s specific anxiety symptoms, personality traits, and underlying emotional state.</li>
            <li>Examples of commonly used homeopathic remedies include:</li>
            <li>Aconite: for sudden, intense anxiety with fear of death or heart palpitations, often triggered by shock or trauma.</li>
            <li>Gelsemium: for anticipatory anxiety, trembling, weakness, and avoidance behaviors before stressful events or performance anxiety.</li>
            <li>Arsenicum album: for anxiety associated with perfectionism, restlessness, and fear of illness or death, worsened at night or in cold environments.</li>
            <li>Remedies are chosen to address the root causes of anxiety, restore emotional balance, and support the body’s natural healing mechanisms.</li>
            </ul>
          <h3>Conclusion</h3>
          <ul>
            <li>Homeopathy offers a gentle and holistic approach to treating Anxiety Disorders, focusing on individualized care, emotional support, and promoting overall well-being.</li>
            <li>Effectiveness of homeopathic treatment can vary, with many patients experiencing reduced anxiety symptoms, improved resilience to stress, and enhanced quality of life. </li>
            <li>Integrating homeopathy with conventional treatments and lifestyle modifications can provide comprehensive support for managing Anxiety Disorders, addressing both the psychological and physiological aspects of anxiety.            </li>
          </ul>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Anxiety disorder Treatment</h3>
            <p>We offer natural, safe, and effective treatment & homeopathic medicine for mental disorders & Anxiety disorder conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/psychiatric/anxiety-disorder/anxiety-disorder-diet">Diet for Anxiety disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Anxiety disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Anxiety disorder</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Anxiety disorder Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/eZTU2sXsu9Q?si=eaUVZsZnxBLNt1SM"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
