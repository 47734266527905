import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const EarPerforationDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Ear Perforation Diet Guide</h1>
      <p className="diet-description">A nutrient-rich and anti-inflammatory diet can support recovery from ear perforation by reducing inflammation and promoting tissue healing. Below are suggestions for foods to take and avoid to aid the healing process.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Protein-Rich Foods</h3>
          <p className="diet-text">Protein is essential for tissue repair and recovery. Include lean meats, eggs, fish, legumes, and plant-based proteins like tofu to support healing from ear perforation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C boosts collagen production, which helps repair damaged tissues. Include citrus fruits, bell peppers, strawberries, and broccoli in your diet to promote healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc plays a crucial role in tissue repair and immune function. Foods like seeds, nuts, whole grains, and legumes provide essential zinc to aid recovery from ear perforation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish, flaxseeds, and walnuts help reduce inflammation, which is important for promoting tissue recovery and minimizing discomfort.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods and Fluids</h3>
          <p className="diet-text">Staying hydrated helps maintain proper fluid balance and supports overall healing. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are high in antioxidants, which help reduce oxidative stress and promote overall healing of the ear.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed and Sugary Foods</h3>
          <p className="diet-text">Processed foods and excess sugar can slow the healing process by promoting inflammation. Avoid sugary snacks, sodas, and heavily processed foods to support recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. High-Sodium Foods</h3>
          <p className="diet-text">Sodium can cause fluid retention, which may worsen ear discomfort. Reduce intake of processed snacks, canned foods, and salty meals.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and unhealthy fats can increase inflammation and interfere with healing. Opt for healthier fats from sources like olive oil, avocados, and nuts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can dehydrate the body and weaken the immune system, potentially slowing recovery. Avoid alcohol to promote better healing and reduce inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeinated Beverages (in excess)</h3>
          <p className="diet-text">Excess caffeine can dehydrate the body and delay tissue repair. Limit caffeine intake and prioritize hydrating fluids like water and herbal teas.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with quinoa and steamed spinach.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a zinc-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with brown rice and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to stay hydrated and support healing.</li>
        </ul>
      </section>
    </div>
  );
};

export default EarPerforationDiet;
