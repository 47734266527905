import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const PolypsDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Diet Guide for Managing Polyps (Female Reproductive Health)</h1>
      <p className="diet-description">A balanced diet can help manage polyps by reducing inflammation, supporting hormonal balance, and improving overall reproductive health. Below are suggestions for foods to take and avoid.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-inflammatory Foods</h3>
          <p className="diet-text">Anti-inflammatory foods such as berries, leafy greens, turmeric, and fatty fish (salmon, sardines) help reduce inflammation, which can alleviate symptoms and support overall reproductive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Fiber-Rich Foods</h3>
          <p className="diet-text">High-fiber foods such as whole grains, fruits, vegetables, and legumes help regulate estrogen levels and improve digestion, which can prevent the growth of polyps and support hormone balance.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in flaxseeds, chia seeds, and walnuts, help reduce inflammation and promote hormonal balance, which is essential for managing polyps related to female reproductive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Iron-Rich Foods</h3>
          <p className="diet-text">Polyps can cause irregular and heavy bleeding, leading to anemia. Incorporating iron-rich foods like spinach, lentils, and lean meats can help prevent anemia and promote overall health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium is important for muscle relaxation and may help reduce cramps associated with polyps. Include foods like spinach, almonds, and bananas in your diet to alleviate symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics, found in yogurt, kefir, and fermented foods, help maintain a healthy balance of bacteria in the gut and reproductive system, which can improve immune function and reduce inflammation.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs such as white bread, pastries, and sugary cereals can cause insulin spikes and hormonal imbalances, potentially worsening polyp symptoms. Opt for whole grains instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excessive sugar intake can disrupt hormone levels and promote inflammation, which may contribute to the growth of polyps. Reducing sugar intake is important for managing symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Red Meat</h3>
          <p className="diet-text">Red meat, particularly processed meats, is high in saturated fats and can increase estrogen levels and inflammation. Reducing or avoiding red meat can help balance hormones and alleviate polyp symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">Processed foods, which are often high in unhealthy fats and additives, can increase inflammation and disrupt hormone balance. Avoid fast food, processed snacks, and pre-packaged meals.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeine</h3>
          <p className="diet-text">Excessive caffeine consumption can affect hormone levels and worsen symptoms like cramping and irregular bleeding. Limiting or avoiding caffeinated beverages like coffee and energy drinks is recommended.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Alcohol</h3>
          <p className="diet-text">Alcohol can disrupt hormone metabolism and promote inflammation, which can worsen the symptoms of polyps. Reducing or avoiding alcohol is advised for better hormone regulation.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal with flaxseeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu salad with spinach, avocado, and a lemon-olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a smoothie with spinach, ginger, and turmeric.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and roasted vegetables (like broccoli and sweet potatoes).</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day and include herbal teas like ginger or chamomile to reduce inflammation and pain.</li>
        </ul>
      </section>
    </div>
  );
};

export default PolypsDiet;
