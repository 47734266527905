import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/mih2.webp')}alt="Urology Hero" />
        <h1>Classical headaches</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Classical headaches</h2>
          <img src={require('../images/head2.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy approaches classical headaches by considering the individual’s specific symptoms, triggers, and overall health. The treatment aims to alleviate pain, address underlying causes, and prevent recurrence through personalized remedies that stimulate the body’s self-healing mechanisms.
          </p>
        <h3>Causes</h3>
          <ul>
            <li><h5>Stress:</h5>Emotional stress or tension can lead to muscle tension and headaches.</li>
            <li><h5>Poor Posture:</h5>Incorrect posture, especially while sitting or standing for long periods, can strain muscles and trigger headaches.</li>
            <li><h5>Eye Strain:</h5>Prolonged use of digital screens or reading in poor light conditions can strain the eyes and cause headaches.</li>
            <li><h5>Dehydration:</h5>Insufficient intake of fluids can lead to dehydration, which is a common trigger for headaches.</li>
            <li><h5>Dietary Factors:</h5>Certain foods and beverages, such as alcohol, caffeine, and processed foods, can trigger headaches in susceptible individuals.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History:</h5>Detailed inquiry into the frequency, duration, and characteristics of headaches.</li>
            <li><h5>Physical Examination: </h5>To check for signs of tension in the neck and shoulders, and assess overall health.</li>
            <li><h5>Triggers and Patterns:</h5> Identifying specific triggers like stress, posture, or dietary factors that precede headaches.</li>
            <li><h5>Diagnostic Tests:</h5>Occasionally, imaging studies or other tests may be ordered to rule out underlying conditions if headaches are severe or persistent.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Chronic Pain:</h5>Frequent or persistent headaches can lead to chronic pain and affect quality of life.</li>
            <li><h5>Impact on Daily Activities:</h5>Headaches can interfere with work, school, and daily responsibilities.</li>
            <li><h5>Medication Overuse:</h5>Over-reliance on pain medications can lead to rebound headaches and worsen the condition.</li>
            <li><h5>Emotional and Mental Health:</h5> Headaches may contribute to stress, anxiety, and depression in some individuals.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Pain Relief Medications:</h5>Over-the-counter pain relievers (NSAIDs like ibuprofen or acetaminophen) for immediate relief during a headache.</li>
            <li><h5>Rest and Relaxation:</h5>Taking breaks, practicing relaxation techniques, and ensuring sufficient sleep to alleviate tension and reduce headaches.</li>
            <li><h5>Lifestyle Modifications:</h5>Stress management techniques, proper posture, adequate hydration, and dietary adjustments to avoid triggers.</li>
            <li><h5>Alternative Therapies:</h5>Massage therapy, acupuncture, yoga, and biofeedback to promote relaxation and reduce stress-related headaches.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <ul>
            <li><h5>Bryonia: </h5>For headaches worsened by movement, typically with a bursting or splitting sensation. The person may prefer to lie still in a dark, quiet room.</li>
            <li><h5>Gelsemium:</h5>Indicated for dull, heavy headaches, often associated with dizziness, blurred vision, and heaviness of the eyelids.</li>
            <li><h5>Natrum Muriaticum:</h5> Helpful for headaches triggered by grief, stress, or emotional upset, with a sensation of a tight band around the head.</li>
            <li><h5>Belladonna:</h5>For sudden, intense headaches that come on rapidly, often with throbbing pain and sensitivity to light and noise.</li>
            <li><h5>Nux Vomica:</h5>Used for headaches due to overindulgence in food, alcohol, or stimulants, with irritability and sensitivity to noise and light.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>Homeopathy provides a tailored approach to managing classical headaches, focusing on individual symptoms and triggers to provide effective relief and prevent recurrence. Our experience shows that homeopathic remedies can effectively alleviate headache symptoms, improve overall well-being, and offer a natural alternative for individuals seeking to manage headaches without frequent use of conventional medications. By addressing the underlying causes and promoting the body’s natural healing abilities, homeopathy aims to restore balance and reduce the frequency and intensity of headaches in a gentle and holistic manner.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Classical headaches Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Classical headaches conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/head/classical-headaches/headaches-diet">Diet for Classical headaches</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Classical headaches</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Classical headaches </a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Classical headaches Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/EcpBRXBBgiY?si=T-oF0B4m4gHrZ6rO"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
