import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const UrticariaDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Urticaria Diet Guide</h1>
      <p className="diet-description">A proper diet can help manage urticaria by reducing inflammation, calming the immune system, and avoiding potential triggers. Below are suggestions for foods to take and avoid when managing urticaria.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, and fatty fish like salmon are rich in Omega-3 fatty acids and antioxidants, which help reduce inflammation and soothe the immune response associated with hives.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Low-Histamine Foods</h3>
          <p className="diet-text">Focus on fresh foods like fruits (except strawberries), vegetables, and lean proteins. Low-histamine foods can prevent triggering hives in those sensitive to histamines.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods like sauerkraut help balance gut health, which is closely linked to immune function and inflammation control.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C helps reduce inflammation and may lower histamine levels. Citrus fruits, bell peppers, and strawberries (if tolerated) are great sources of this vitamin.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Keeping the body hydrated is important for managing urticaria. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water throughout the day.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Quercetin-Rich Foods</h3>
          <p className="diet-text">Quercetin, found in apples, onions, and broccoli, acts as a natural antihistamine and may help control the symptoms of urticaria by reducing histamine levels in the body.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Histamine Foods</h3>
          <p className="diet-text">Fermented foods like aged cheese, sauerkraut, smoked meats, and alcoholic beverages contain high levels of histamines and should be avoided if sensitive to histamine triggers.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Sugar can increase inflammation and weaken the immune system, worsening urticaria symptoms. Avoid sugary snacks, sodas, and processed foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain preservatives, additives, and unhealthy fats, which can trigger or exacerbate urticaria. Stick to fresh, whole foods to minimize triggers.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol, especially red wine and beer, is high in histamines and can trigger hives in some individuals. Limiting alcohol intake can reduce flare-ups and improve overall health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can sometimes trigger histamine release or worsen skin inflammation, leading to flare-ups. Reducing or eliminating spicy dishes may help manage urticaria symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">Excessive caffeine can stimulate histamine release and worsen urticaria in some people. Moderating caffeine intake may help prevent symptoms.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, apples, and almond milk (or dairy-free alternatives).</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu salad with quinoa and leafy greens.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a low-histamine yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with steamed broccoli and brown rice.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to soothe inflammation and promote hydration.</li>
        </ul>
      </section>
    </div>
  );
};

export default UrticariaDiet;
