import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const MoodDisorderDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Mood Disorder Diet Guide</h1>
      <p className="diet-description">A balanced diet with nutrient-rich foods can support mental health and help manage mood disorders by stabilizing blood sugar, boosting neurotransmitter production, and promoting overall brain health. Below are dietary suggestions for foods to take and avoid for better mood management.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, chia seeds, and walnuts, are essential for brain health and can help stabilize mood by supporting neurotransmitter function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Complex Carbohydrates</h3>
          <p className="diet-text">Complex carbs like whole grains, oats, and sweet potatoes promote steady blood sugar levels, reducing mood swings and supporting serotonin production.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Lean Protein Sources</h3>
          <p className="diet-text">Protein-rich foods like lean meat, eggs, and legumes contain amino acids that are precursors to mood-stabilizing neurotransmitters like serotonin and dopamine.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics in yogurt, kefir, and fermented foods support gut health, which has been linked to mental health and mood regulation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Antioxidants in berries, dark chocolate, and leafy greens help reduce oxidative stress and inflammation, both of which are linked to mood disorders.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamin B-Rich Foods</h3>
          <p className="diet-text">B vitamins, especially folate and B12, play a vital role in brain health and mood regulation. Include leafy greens, eggs, and fortified cereals.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars and Simple Carbs</h3>
          <p className="diet-text">Foods high in refined sugars, like candies and sodas, can cause rapid blood sugar spikes and crashes, leading to mood swings.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often lack essential nutrients and contain additives that may impact mental health negatively. Focus on whole, unprocessed foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats and Fried Foods</h3>
          <p className="diet-text">Trans fats found in fried and processed foods can increase inflammation and may contribute to mood disorders. Choose healthy fats instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Caffeine (in excessive amounts)</h3>
          <p className="diet-text">While moderate caffeine can enhance mood, excessive amounts can increase anxiety and disrupt sleep, both of which affect mood stability.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol can alter brain chemistry and disrupt sleep, both of which can worsen mood disorders. Moderation is essential for mental health.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with berries, chia seeds, and a handful of walnuts.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or salmon with quinoa and mixed vegetables.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or yogurt with a few pieces of dark chocolate.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried tofu with brown rice and leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day and include calming herbal teas like chamomile or green tea to support relaxation.</li>
        </ul>
      </section>
    </div>
  );
};

export default MoodDisorderDiet;
