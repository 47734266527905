import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const MigraineDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Migraine Diet Guide</h1>
      <p className="diet-description">A balanced diet can help manage migraines by reducing triggers and promoting overall brain health. Below are suggestions for foods to take and avoid when managing migraines.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium helps prevent migraines by calming the nervous system. Include leafy greens, seeds, almonds, and avocados in your diet for magnesium support.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish like salmon, flaxseeds, and walnuts help reduce inflammation, which can play a role in migraine prevention.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Hydrating Foods</h3>
          <p className="diet-text">Dehydration is a common migraine trigger. Include water-rich foods like cucumbers, melons, and citrus fruits, and ensure you drink plenty of water throughout the day.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Whole Grains</h3>
          <p className="diet-text">Whole grains like oats, quinoa, and brown rice provide sustained energy and help stabilize blood sugar, which is important for avoiding migraine triggers.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Riboflavin (Vitamin B2) Foods</h3>
          <p className="diet-text">Vitamin B2 has been shown to reduce migraine frequency. Include foods like eggs, dairy, and spinach to increase your intake of riboflavin.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help reduce inflammation and support brain health, potentially preventing migraines.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Caffeine (in excess)</h3>
          <p className="diet-text">While small amounts of caffeine can relieve migraines, excessive caffeine or withdrawal can trigger them. Keep caffeine intake moderate and consistent.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Alcohol</h3>
          <p className="diet-text">Alcohol, especially red wine, is a common migraine trigger. Limiting alcohol intake can help prevent migraine episodes.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed Meats</h3>
          <p className="diet-text">Processed meats like deli meat and bacon contain nitrates and preservatives that can trigger migraines. Avoid processed meats and opt for fresh protein sources.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Artificial Sweeteners</h3>
          <p className="diet-text">Aspartame and other artificial sweeteners have been linked to migraines. Avoid diet sodas and products containing artificial sweeteners to reduce the risk of headaches.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Aged Cheese and Fermented Foods</h3>
          <p className="diet-text">Aged cheese, sauerkraut, and other fermented foods contain tyramine, which can trigger migraines in some individuals. Limit or avoid these foods if they trigger your migraines.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. MSG (Monosodium Glutamate)</h3>
          <p className="diet-text">MSG, often found in processed foods and restaurant meals, is a known migraine trigger. Avoid foods with MSG to reduce the risk of migraine attacks.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with chia seeds, berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed spinach.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a magnesium-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked chicken with brown rice and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support hydration and reduce migraine triggers.</li>
        </ul>
      </section>
    </div>
  );
};

export default MigraineDiet;
