import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const UterineFibroidDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Uterine Fibroid Diet Guide</h1>
      <p className="diet-description">A balanced diet can help manage uterine fibroids by reducing estrogen levels and supporting overall reproductive health. Below are suggestions for foods to take and avoid when managing uterine fibroids.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Fiber Foods</h3>
          <p className="diet-text">Fiber-rich foods such as whole grains, fruits, and vegetables help reduce estrogen levels and promote healthy digestion. A high-fiber diet can also support weight management, which is important for fibroid management.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Cruciferous Vegetables</h3>
          <p className="diet-text">Cruciferous vegetables like broccoli, kale, and cauliflower contain compounds that help balance estrogen levels, reducing the risk of fibroid growth.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Foods rich in Omega-3s, like flaxseeds, walnuts, and fatty fish (salmon, mackerel), help reduce inflammation, which may lower the risk of fibroid growth and alleviate symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Iron-Rich Foods</h3>
          <p className="diet-text">Uterine fibroids can cause heavy menstrual bleeding, leading to anemia. Iron-rich foods such as spinach, beans, lentils, and lean meats can help replenish lost iron and prevent anemia.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Anti-inflammatory Foods</h3>
          <p className="diet-text">Incorporate anti-inflammatory foods like turmeric, ginger, leafy greens, and berries to reduce inflammation and support hormonal balance.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Certain herbal teas, such as green tea and chamomile, may have anti-estrogenic properties and can help manage fibroid symptoms. Green tea contains antioxidants that may slow fibroid growth.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed and Refined Foods</h3>
          <p className="diet-text">Refined grains and processed foods, such as white bread, pasta, and sugary snacks, can increase inflammation and exacerbate fibroid symptoms. Stick to whole grains and unprocessed foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. High-Sodium Foods</h3>
          <p className="diet-text">Excessive sodium can lead to fluid retention and bloating, worsening fibroid symptoms. Reduce intake of salty snacks, canned soups, and processed foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Red Meat</h3>
          <p className="diet-text">Red meat, particularly processed meats like sausages and bacon, can increase inflammation and estrogen levels, potentially promoting fibroid growth. Opt for lean protein sources instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol consumption can increase estrogen levels, which may contribute to fibroid growth. Limit or avoid alcohol to help balance hormone levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. High-Fat Dairy Products</h3>
          <p className="diet-text">Dairy products high in fat, such as full-fat milk, cream, and cheese, can increase estrogen levels in the body, contributing to fibroid development. Choose low-fat or plant-based dairy alternatives.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">Excessive caffeine consumption, particularly from coffee, may increase fibroid symptoms by affecting hormone levels. Try to limit caffeine intake or switch to herbal teas.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with flaxseeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with a quinoa and broccoli salad, drizzled with olive oil and lemon juice.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a smoothie with spinach and ginger.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Lentil soup with sautéed kale and a small side of roasted sweet potatoes.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water and include herbal teas like green tea throughout the day.</li>
        </ul>
      </section>
    </div>
  );
};

export default UterineFibroidDiet;
