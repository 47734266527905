import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/eh7.webp')}alt="Urology Hero" />
        <h1>AOM/CSOM Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
            <h3>Acute otitis media (AOM)/chronic suppurative otitis media (CSOM)</h3>
         <h2>Acute Otitis Media (AOM)</h2>
          <img src={require('../images/ent7.webp')}alt="Urology Hero" />
          <h3>Causes</h3>
          <p>AOM is typically caused by bacterial or viral infections of the middle ear. It often follows upper respiratory tract infections or allergies that cause inflammation and blockage of the Eustachian tube, leading to fluid buildup in the middle ear.</p>
          <h3>Symptoms:</h3>
          <ul>
            <li>Sudden onset of ear pain, especially in young children</li>
            <li>Earache (otalgia)</li>
            <li>Fever</li>
            <li>Fluid drainage from the ear (if the eardrum ruptures)</li>
            <li>Hearing loss or difficulty hearing</li>
            <li>Irritability or fussiness in infants and children</li>
          </ul>
          <h3>Investigations:</h3>
          <p>Diagnosis involves otoscopic examination to visualize the tympanic membrane and assess for signs of inflammation or fluid. Tympanometry and audiometry may be used to evaluate middle ear function and hearing loss.</p>
          <h3>Complications:</h3>
          <ul>
            <li>Perforation of the eardrum (which may lead to temporary hearing loss).</li>
            <li>Spread of infection to nearby structures (e.g., mastoiditis).</li>
            <li>Recurrent infections.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <ul>
            <li>Antibiotics (such as amoxicillin) if the infection is bacterial.</li>
            <li>Pain relievers (e.g., acetaminophen or ibuprofen).</li>
            <li>Ear drops to relieve pain and inflammation.</li>
            <li>In some cases, tympanostomy tubes (ear tubes) may be surgically inserted to aid drainage and prevent recurrent infections.</li> 
            </ul>
          <h3>Homeopathic Perspective:</h3>
          <p>Homeopathy offers remedies like Belladonna, Pulsatilla, Chamomilla, Hepar sulph, and Silicea. These are chosen based on individual symptoms such as sudden onset of pain, fever, irritability, and sensitivity to touch.</p>
         <h2>Chronic Suppurative Otitis Media (CSOM)</h2>
          <h3>Causes</h3>
          <p>CSOM involves chronic infection and discharge from the middle ear, often due to untreated or recurrent AOM, trauma to the eardrum, chronic allergies, or immune system weaknesses.</p>
          <h3>Symptoms:</h3>
          <ul>
            <li>Persistent ear discharge (otorrhea), which may be foul-smelling.</li>
            <li>Hearing loss.</li>
            <li>Earache or discomfort.</li>
            <li>Tinnitus (ringing in the ears).</li>
            <li>Vertigo or dizziness (in severe cases).</li>
          </ul>
          <h3>Investigations:</h3>
          <p>Diagnosis includes otoscopic examination to observe chronic perforation and discharge, as well as assessment of hearing loss and possible complications like cholesteatoma (abnormal skin growth in the middle ear).</p>
          <h3>Complications:</h3>
          <ul>
            <li>Hearing loss (conductive or mixed).</li>
            <li>Cholesteatoma (which can erode surrounding bone and lead to further complications).</li>
            <li>Mastoiditis (infection of the mastoid bone).</li>
          </ul>
          <h3>Traditional Treatment:</h3>
          <ul>
            <li>Antibiotics (oral or ear drops) to control infection.</li>
            <li>Ear cleaning to remove discharge and debris.</li>
            <li>Surgery to repair the perforation, remove cholesteatoma, or address complications like mastoiditis.</li>
            </ul>
          <h3>Homeopathic Perspective:</h3>
          <p>Homeopathic remedies such as Silicea, Calcarea sulph, Kali muriaticum, Hepar sulphuris, and Mercurius solubilis are used based on symptoms like chronic discharge, foul-smelling discharge, and associated symptoms like headache or dizziness.</p>
        <h3>Conclusion</h3>
        <p>Both AOM and CSOM can significantly impact ear health and require appropriate management to prevent complications and promote healing. Homeopathy provides a holistic approach by addressing underlying causes, enhancing immune response, and supporting overall ear health.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>ASOM/CSOM Treatment</h3>
            <p>We offer natural, safe, and effective treatment for ASOM/CSOM conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/ent/asom-csom/asom-csom-diet">Diet for ASOM/CSOM</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of ASOM/CSOM</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for ASOM/CSOM</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on ASOM/CSOM Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/xG4c1BGzaKM?si=0OHm6656FKEqr97y"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
