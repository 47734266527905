import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const DementiaDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Dementia Diet Guide</h1>
      <p className="diet-description">A diet rich in brain-supportive nutrients can help manage dementia symptoms and support cognitive health by reducing inflammation, protecting brain cells, and promoting overall well-being. Below are dietary recommendations for foods to take and avoid to help maintain brain health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, flaxseeds, and walnuts, are essential for brain cell health and may help slow cognitive decline by reducing inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, dark chocolate, and leafy greens are high in antioxidants, which help protect brain cells from oxidative stress and damage.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Leafy Greens and Vegetables</h3>
          <p className="diet-text">Leafy greens like spinach, kale, and broccoli are rich in vitamins and minerals, especially folate and vitamin K, which are important for brain health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Whole Grains</h3>
          <p className="diet-text">Whole grains like brown rice, oats, and quinoa provide steady energy and support cognitive function by promoting blood flow to the brain.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. B Vitamins</h3>
          <p className="diet-text">B vitamins, especially B6, B12, and folate, are important for brain health and may help reduce homocysteine levels, a marker linked to dementia.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Hydrating Foods</h3>
          <p className="diet-text">Dehydration can worsen cognitive symptoms, so include water-rich foods like cucumbers, melons, and citrus fruits to maintain hydration.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars</h3>
          <p className="diet-text">High-sugar foods can contribute to insulin resistance and inflammation, both of which may negatively impact brain health. Limit sweets and sugary drinks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain unhealthy fats and additives, which can worsen inflammation and oxidative stress. Focus on whole, nutrient-rich foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans and Saturated Fats</h3>
          <p className="diet-text">Trans and saturated fats found in fried foods and red meat can increase inflammation and may contribute to cognitive decline. Opt for healthy fats instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Excessive Alcohol</h3>
          <p className="diet-text">Excessive alcohol consumption can harm brain cells and impair memory. Moderate alcohol intake to protect cognitive function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Artificial Additives</h3>
          <p className="diet-text">Additives like artificial sweeteners and preservatives may negatively impact brain health. Choose whole foods for better mental clarity.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with blueberries, walnuts, and a sprinkle of flaxseed.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon or mackerel with quinoa and a mixed greens salad.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or an apple with nut butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and a side of steamed spinach.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water, and include herbal teas like green tea, which is rich in antioxidants.</li>
        </ul>
      </section>
    </div>
  );
};

export default DementiaDiet;
