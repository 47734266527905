import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const DNSDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Deviated Nasal Septum (DNS) Diet Guide</h1>
      <p className="diet-description">While diet alone cannot correct a deviated nasal septum, certain foods can help manage symptoms like congestion and inflammation. Below are suggestions for foods to take and avoid to improve respiratory health and reduce discomfort caused by DNS.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, and fatty fish like salmon are rich in Omega-3s and antioxidants, which help reduce inflammation and ease nasal congestion.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C supports immune function and helps reduce inflammation. Citrus fruits, bell peppers, and broccoli can help keep respiratory issues at bay.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods help support a healthy immune system and may reduce inflammation that aggravates nasal issues.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Hydrating Foods</h3>
          <p className="diet-text">Staying hydrated can help thin mucus and improve breathing. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water throughout the day.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Garlic and Ginger</h3>
          <p className="diet-text">Garlic and ginger have anti-inflammatory properties and can help reduce swelling in the nasal passages, making it easier to breathe.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in foods like flaxseeds, chia seeds, and walnuts, help reduce inflammation and may improve symptoms of DNS-related congestion.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Dairy Products (for some)</h3>
          <p className="diet-text">For some individuals, dairy products can increase mucus production and worsen nasal congestion. Consider reducing dairy intake if it aggravates your symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can weaken the immune system and increase inflammation, potentially worsening nasal congestion. Avoid sugary snacks, sodas, and processed foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain preservatives and additives that can trigger inflammation. Stick to whole, unprocessed foods to reduce nasal irritation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can dehydrate the body and increase nasal congestion. Limiting alcohol intake may help improve symptoms of DNS-related breathing issues.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can sometimes trigger nasal irritation and worsen congestion. Avoid excessive spiciness if it leads to discomfort or breathing difficulty.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. High-Sodium Foods</h3>
          <p className="diet-text">Sodium can cause fluid retention and worsen swelling in the nasal passages. Reduce intake of processed snacks, canned foods, and salty meals.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with quinoa and steamed spinach.</li>
          <li className="diet-list-item"><strong>Snack:</strong> An apple or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with brown rice and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support hydration and reduce nasal congestion.</li>
        </ul>
      </section>
    </div>
  );
};

export default DNSDiet;
