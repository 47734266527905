import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/mgh6.webp')}alt="Urology Hero" />
        <h1>Mild Hydrocele Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Mild Hydrocele</h2>
          <img src={require('../images/male6.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
          <p>
          Homeopathy offers a natural and non-invasive approach to treating mild hydrocele by focusing on reducing fluid accumulation, alleviating discomfort, and improving the overall health of the affected individual. Remedies are selected based on the symptoms, duration, and patient’s constitution, with the goal of addressing the underlying cause and supporting the body’s ability to reabsorb the fluid.          </p>
          <h3>Causes</h3>
          <p>A hydrocele is an accumulation of fluid around the testicle, and while often painless, it can cause discomfort or swelling. Some common causes include:</p>
          <ul>
            <li><h5>Congenital Hydrocele:</h5>Present at birth due to the incomplete closure of the processus vaginalis, allowing fluid to accumulate in the scrotum.</li>
            <li><h5>Injury or Trauma:</h5> Direct trauma to the scrotum or testicles can lead to inflammation and fluid buildup.</li>
            <li><h5>Inflammation: </h5>General inflammation of the testicles or surrounding tissues due to various health issues can cause fluid accumulation.</li>
            <li><h5>Imbalance of Fluid Drainage: </h5>When the balance of fluid production and absorption is disrupted, a hydrocele may form.</li>
            <li><h5>Infections: </h5>Conditions like epididymitis or orchitis may cause inflammation, leading to the formation of hydrocele.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Medical History: </h5>Visual inspection and palpation of the scrotum to detect swelling, fluid buildup, and tenderness.</li>
            <li><h5>Transillumination: </h5>Shining a light through the scrotum to confirm the presence of fluid.</li>
            <li><h5>Ultrasound:</h5>Used to confirm the diagnosis, evaluate the size of the hydrocele, and rule out other conditions like hernias or tumors.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Discomfort:</h5>As fluid accumulates, the scrotum may become heavy, causing discomfort during physical activity or sitting.</li>
            <li><h5>Infection:</h5>In rare cases, a hydrocele can become infected, leading to more serious complications.</li>
            <li><h5>Testicular Atrophy:</h5>Prolonged compression due to the hydrocele can cause a decrease in testicular size over time.</li>
            <li><h5>Hernia:</h5>Sometimes, a hydrocele may be associated with an inguinal hernia, which may require additional medical attention.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Watchful Waiting:</h5>In mild or asymptomatic cases, hydroceles may resolve on their own, particularly in infants.</li>
            <li><h5>Surgical Removal (Hydrocelectomy):</h5>If the hydrocele is large, persistent, or causing discomfort, surgery may be recommended to remove the fluid and close off the sac.</li>
            <li><h5>Aspiration:</h5>Fluid may be drained using a needle, although this is often a temporary solution as fluid may reaccumulate.</li>
            <li><h5>Sclerotherapy:</h5>Injecting a solution after aspiration to prevent fluid reaccumulation.</li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <p>Homeopathy offers remedies to address the root cause of hydrocele, reduce fluid accumulation, and promote reabsorption. These remedies are chosen based on the individual’s constitution, symptoms, and overall health:</p>
          <ul>
            <li><h5>Rhododendron: </h5>For swelling of the scrotum due to hydrocele, especially if associated with pain that worsens in cold weather. Useful when there is sensitivity and discomfort.</li>
            <li><h5>Clematis Erecta:</h5>For hydrocele with hard, indurated testicles, and where the person experiences pain radiating from the abdomen to the scrotum.</li>
            <li><h5>Apis Mellifica: </h5>Useful when there is fluid accumulation and swelling, especially if there is associated redness or a stinging sensation.</li>
            <li><h5>Pulsatilla: </h5>For mild hydrocele with swelling and heaviness in the scrotum, often accompanied by general discomfort. This remedy is also useful when hydrocele develops after an infection or trauma.</li> 
            <li><h5>Graphites:</h5>Effective in cases of chronic hydrocele, especially if there is a history of skin eruptions or delayed healing.</li>
            <li><h5>Silicea:</h5> For individuals prone to fluid retention or slow reabsorption of fluids, particularly useful in treating long-standing or recurrent hydrocele.</li>
            <li><h5>Calcarea Carbonica:</h5> Indicated for individuals with a tendency toward water retention, weight gain, and sluggish metabolism, often useful when hydrocele is associated with overall weakness.</li>
            </ul>
          <h3>Conclusion</h3>
          <p>Homeopathic treatment for mild hydrocele focuses on reducing fluid accumulation naturally, addressing the underlying cause, and promoting the body’s natural ability to absorb the excess fluid. Based on clinical experience, homeopathy can effectively alleviate discomfort, reduce swelling, and help prevent recurrence of hydrocele in mild cases, avoiding the need for surgical intervention.</p>
          <h3>Key Strategies for Effective Management</h3>
          <ul>
            <li><h5>Individualized Care: </h5>Homeopathic remedies are selected based on the specific symptoms and overall health of the patient, offering personalized treatment.</li>
            <li><h5>Non-invasive Approach:</h5>Homeopathy provides a gentle, non-surgical alternative for managing hydrocele, particularly in mild cases.</li>
            <li><h5>Holistic Healing:</h5>Homeopathic treatment addresses not only the physical symptoms of hydrocele but also focuses on the patient’s constitution and overall health.</li>
            <li><h5>Lifestyle Adjustments: </h5> Supporting the patient with dietary advice, encouraging hydration, and avoiding activities that may aggravate the condition.</li> 
            <li><h5>Long-term Monitoring:</h5>Regular follow-ups to monitor the reduction of fluid accumulation and ensure proper healing.</li>
            <li><h5>Preventive Care:</h5>Homeopathy may help prevent the recurrence of hydrocele by promoting better fluid balance and reducing the likelihood of inflammation.</li>
            </ul>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Mild Hydrocele Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Mild Hydrocele conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/male-genital/mild-hydrocele/mild-hydrocele-diet">Diet for Mild Hydrocele</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Mild Hydrocele</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Mild Hydrocele</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Mild Hydrocele Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/85QHSjbX6uE?si=Zb8C0Uv0Kdkc8ehq"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
