import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const ImpotenceDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Impotence Diet Guide</h1>
      <p className="diet-description">A diet rich in nutrients that support blood flow, cardiovascular health, and hormone regulation can help manage impotence. Here are suggestions for foods to include and avoid to support sexual health and overall vitality.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Leafy Greens</h3>
          <p className="diet-text">Leafy greens like spinach, kale, and arugula are rich in nitrates, which help improve blood flow and support erectile function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fish like salmon, flaxseeds, and walnuts, promote cardiovascular health and improve circulation, which is crucial for sexual health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc is essential for testosterone production and overall reproductive health. Include pumpkin seeds, chickpeas, and almonds in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Flavonoid-Rich Foods</h3>
          <p className="diet-text">Fruits like blueberries, strawberries, and oranges contain flavonoids, which support blood flow and may help reduce erectile dysfunction.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. L-Arginine Sources</h3>
          <p className="diet-text">L-arginine is an amino acid that supports nitric oxide production, improving blood flow. Nuts, seeds, and beans are good sources of L-arginine.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D is crucial for hormonal balance and cardiovascular health. Include fortified foods, eggs, and fatty fish in your diet.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed Meats and Foods</h3>
          <p className="diet-text">Processed meats and foods contain high amounts of sodium and unhealthy fats, which can impair blood flow and affect overall health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can lead to insulin resistance and inflammation, both of which negatively impact sexual health. Limit sweets and sugary drinks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Excessive Alcohol</h3>
          <p className="diet-text">While moderate alcohol consumption is usually fine, excessive intake can impair testosterone production and decrease sexual performance.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Trans and Saturated Fats</h3>
          <p className="diet-text">These fats, found in fried foods and baked goods, can harm heart health and circulation. Opt for healthy fats like olive oil and avocados.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeine (in large amounts)</h3>
          <p className="diet-text">While moderate caffeine can improve energy, excessive intake may cause stress and blood pressure issues, affecting sexual function.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with berries, walnuts, and a sprinkle of flaxseed.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and a mixed greens salad.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of pumpkin seeds or a banana.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and a drizzle of olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water, and include herbal teas like green tea to support circulation.</li>
        </ul>
      </section>
    </div>
  );
};

export default ImpotenceDiet;
