import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const GoutyArthritisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Gouty Arthritis Diet Guide</h1>
      <p className="diet-description">
        Managing gout involves a diet low in purines, which helps prevent uric acid buildup and reduces the risk of painful flare-ups. Below are dietary suggestions for foods to take and avoid to support joint health and manage gout symptoms.
      </p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Low-Purine Foods</h3>
          <p className="diet-text">
            Low-purine foods like whole grains, fruits, and vegetables help reduce uric acid levels. Choose foods such as cucumbers, potatoes, and leafy greens.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Cherries and Berries</h3>
          <p className="diet-text">
            Cherries and berries contain antioxidants and have been shown to reduce uric acid levels and decrease inflammation, helping to prevent gout attacks.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Vitamin C-Rich Foods</h3>
          <p className="diet-text">
            Citrus fruits, bell peppers, and strawberries are rich in vitamin C, which helps lower uric acid levels and reduce gout symptoms.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Low-Fat Dairy Products</h3>
          <p className="diet-text">
            Low-fat milk, yogurt, and other dairy products may reduce uric acid levels and lower the risk of gout attacks.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Water</h3>
          <p className="diet-text">
            Staying well-hydrated helps flush out uric acid from the body. Aim to drink plenty of water throughout the day.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Coffee (in moderation)</h3>
          <p className="diet-text">
            Some studies suggest that moderate coffee intake may lower uric acid levels. However, consult your doctor to determine if coffee is suitable for you.
          </p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Purine Foods</h3>
          <p className="diet-text">
            Foods high in purines, such as red meat, organ meats (like liver), and certain seafood (such as anchovies, sardines, and scallops), can increase uric acid levels. Avoid or limit these foods.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Drinks</h3>
          <p className="diet-text">
            Sugary drinks, especially those with high fructose corn syrup, can trigger gout attacks. Opt for water or unsweetened beverages instead.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Alcohol (especially beer)</h3>
          <p className="diet-text">
            Beer and other alcoholic drinks can increase uric acid levels and trigger flare-ups. Limit alcohol intake, especially during a gout attack.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">
            Processed foods often contain additives and unhealthy fats that can worsen inflammation. Choose fresh, whole foods for better joint health.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Red Meat and Organ Meats</h3>
          <p className="diet-text">
            Red meats and organ meats are high in purines, which can elevate uric acid levels. Replace them with lean protein sources like chicken or legumes.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Fried Foods</h3>
          <p className="diet-text">
            Fried foods contain unhealthy fats that can exacerbate inflammation. Choose baked or steamed options instead.
          </p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with berries and a glass of low-fat milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with a side of steamed vegetables and brown rice.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of cherries or a low-fat yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Vegetable stir-fry with tofu and quinoa.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day and include herbal teas to support hydration and reduce uric acid buildup.</li>
        </ul>
      </section>
    </div>
  );
};

export default GoutyArthritisDiet;
