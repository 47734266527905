import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const MenopausalSyndromeDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Menopausal Syndrome Diet Guide</h1>
      <p className="diet-description">A balanced diet can help manage the symptoms of menopause by regulating hormones, reducing inflammation, and promoting overall well-being. Below are suggestions for foods to take and avoid to manage menopausal syndrome effectively.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Phytoestrogen-Rich Foods</h3>
          <p className="diet-text">Phytoestrogens, found in soy products (tofu, edamame, and soy milk), flaxseeds, and lentils, mimic estrogen in the body and help alleviate symptoms such as hot flashes and night sweats during menopause.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish (salmon, sardines), flaxseeds, and chia seeds help reduce inflammation and promote heart health, which is important during menopause.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Calcium and Vitamin D-Rich Foods</h3>
          <p className="diet-text">Foods rich in calcium and vitamin D, such as dairy, leafy greens, and fortified plant milks, help support bone health and reduce the risk of osteoporosis, a common concern during menopause.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Whole Grains</h3>
          <p className="diet-text">Whole grains like oats, brown rice, and quinoa provide fiber, B-vitamins, and essential nutrients to regulate metabolism, support digestion, and improve mood during menopause.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium, found in spinach, almonds, and bananas, can help relieve symptoms such as anxiety, sleep disturbances, and muscle cramps associated with menopause.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile, red clover, and peppermint help reduce stress, support digestion, and alleviate hot flashes and mood swings during menopause.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">High sugar intake can cause insulin spikes and worsen menopausal symptoms such as mood swings and fatigue. Reducing sugar from sweets, sodas, and processed foods can help manage symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs, such as white bread, pastries, and sugary cereals, can lead to weight gain and insulin resistance, both of which can exacerbate menopausal symptoms. Choose whole grains instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in sodium, unhealthy fats, and additives that can cause bloating, water retention, and mood swings. Stick to fresh, whole foods for better symptom management.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can worsen symptoms like hot flashes and sleep disturbances, and it may contribute to mood swings. Limiting or avoiding alcohol can help manage menopausal symptoms more effectively.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeine (in excess)</h3>
          <p className="diet-text">Excessive caffeine can cause anxiety, insomnia, and worsen hot flashes. Reducing coffee, energy drinks, and caffeinated teas can help improve sleep and reduce menopausal symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Trans Fats</h3>
          <p className="diet-text">Trans fats, found in fried foods, baked goods, and margarine, increase inflammation and disrupt hormone balance, potentially worsening menopausal symptoms. Opt for healthier fats like olive oil and avocados.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal with flaxseeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> A spinach and quinoa salad with grilled salmon, avocado, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a green smoothie with spinach, banana, and chia seeds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked tofu or chicken with roasted sweet potatoes and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and include herbal teas like red clover or chamomile to reduce stress and hot flashes.</li>
        </ul>
      </section>
    </div>
  );
};

export default MenopausalSyndromeDiet;
