import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const FistulaDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Fistula Diet Guide</h1>
      <p className="diet-description">A balanced diet can help manage fistulas by promoting healthy digestion, reducing inflammation, and supporting overall gut health. Below are suggestions for foods to take and avoid when managing fistulas.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Fiber Foods</h3>
          <p className="diet-text">Fiber-rich foods such as whole grains, fruits, vegetables, and legumes help soften stools and reduce strain during bowel movements, which is essential for preventing irritation and managing fistulas.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Hydrating Foods</h3>
          <p className="diet-text">Proper hydration is important for keeping stools soft and preventing constipation. Drink plenty of water and include hydrating foods like cucumbers, watermelon, and citrus fruits to aid digestion.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods like kimchi and sauerkraut help maintain a healthy balance of gut bacteria, which is crucial for healing and preventing further infections in fistulas.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Healthy Fats</h3>
          <p className="diet-text">Healthy fats from sources like avocados, olive oil, and nuts can help reduce inflammation and promote healing in the body. These fats also help lubricate the digestive system, making bowel movements easier.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3 fatty acids found in fatty fish like salmon and flaxseeds have strong anti-inflammatory properties, which can help reduce inflammation around the fistula and promote healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like chamomile, peppermint, and ginger can help soothe the digestive system, reduce inflammation, and support overall gut health, aiding in fistula management.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed Foods</h3>
          <p className="diet-text">Processed foods, such as fast food, chips, and pre-packaged snacks, are often low in fiber and high in unhealthy fats, which can contribute to constipation and worsen fistula symptoms. Choose whole, unprocessed foods instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can irritate the digestive tract and worsen inflammation around fistulas, leading to discomfort and prolonged healing. Reducing spicy food intake can help manage symptoms more effectively.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Red Meat</h3>
          <p className="diet-text">Red meat is low in fiber and takes longer to digest, which can lead to constipation and worsen fistula symptoms. Reducing red meat consumption and opting for plant-based proteins can help prevent irritation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Sugary Snacks and Drinks</h3>
          <p className="diet-text">Sugary foods and beverages like candy, soda, and baked goods can disrupt digestion and increase inflammation. Limiting sugar intake can promote better bowel movements and reduce inflammation around the fistula.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeine (in excess)</h3>
          <p className="diet-text">While small amounts of caffeine can stimulate bowel movements, excessive caffeine can lead to dehydration and worsen constipation, which may aggravate fistulas. Stick to moderate amounts and ensure proper hydration.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Alcohol</h3>
          <p className="diet-text">Alcohol can disrupt gut health and worsen inflammation, which may slow down the healing process for fistulas. Reducing or avoiding alcohol is recommended for better gut and immune health.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal with chia seeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> A spinach and quinoa salad with avocado, grilled chicken, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a green smoothie with spinach, ginger, and banana.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Grilled salmon with roasted sweet potatoes and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day and include herbal teas like chamomile or ginger to soothe digestion and reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default FistulaDiet;
