import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/fh4.webp')}alt="Urology Hero" />
        <h1>Menstural Disorder Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Menstural Disorder</h2>
          <img src={require('../images/female4.webp')}alt="Urology Hero" />
         <h3>Basic Homoeopathic Approach</h3>
         <p>Homoeopathy addresses menstrual disorders by considering the individual's overall constitution, including physical, mental, and emotional aspects. The goal is to identify and treat the underlying cause of menstrual irregularities rather than merely addressing the symptoms. Homoeopathic remedies are selected based on the patient’s unique symptoms and characteristics, aiming to restore hormonal balance and enhance the body’s natural rhythms.</p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Hormonal Imbalances:</h5>Fluctuations in oestrogen, progesterone, and other hormones can lead to irregular cycles, heavy bleeding, and other menstrual symptoms.</li>
            <li><h5>Stress:</h5>High-stress levels can affect the hypothalamus, the part of the brain that controls the hormones regulating menstruation.</li>
            <li><h5>Lifestyle Factors:</h5>Poor diet, lack of exercise, obesity, and substance abuse (smoking, alcohol) can influence menstrual health.</li>
            <li><h5>Medical Conditions:</h5>Disorders like Polycystic Ovary Syndrome (PCOS), endometriosis, and thyroid issues can cause irregular or painful menstruation.            </li>
            <li><h5>Structural Issues:</h5>Conditions like fibroids, polyps, and pelvic inflammatory disease can affect menstrual flow and cause pain.            </li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li><h5>Hormonal Assays:</h5>Blood tests to evaluate levels of hormones like FSH, LH, TSH, and prolactin to check for imbalances.            </li>
            <li><h5>Ultrasound:</h5>Imaging to examine the uterus, ovaries, and other reproductive organs for any abnormalities.</li>
            <li><h5>Endometrial Biopsy:</h5>A procedure to examine the lining of the uterus for conditions like endometrial hyperplasia.            </li>
            <li><h5>Pelvic Exam:</h5>A physical examination to detect any abnormalities in the reproductive organs.            </li>
            <li><h5>Pap Smear:</h5>A test to check for infections, inflammation, and abnormal cell changes in the cervix.            </li>
          </ul>
          <h3>Complications and Risk Factors:</h3>
          <ul>
            <li><h5>Anaemia:</h5>Heavy menstrual bleeding can lead to low iron levels, causing fatigue, weakness, and other symptoms.</li>
            <li><h5>Infertility:</h5>Some menstrual disorders, like PCOS or endometriosis, can impact fertility if left untreated.</li>
            <li><h5>Chronic Pain:</h5>Severe menstrual cramps (dysmenorrhea) can lead to persistent pelvic pain that affects daily activities.            </li>
            <li><h5>Emotional Stress:</h5>Menstrual irregularities and associated symptoms can lead to anxiety, depression, and strained personal relationships.            </li>
          </ul>
          <h3>Homeopathic Treatment</h3>
          <ul>
            <li><h5>Pulsatilla:</h5>Suitable for women with delayed, scanty, or changeable menstrual cycles, often accompanied by mood swings.</li>
            <li><h5>Sepia:</h5>Effective for women experiencing irregular periods, fatigue, and a general feeling of apathy or irritability.</li>
            <li><h5>Cimicifuga:</h5>For severe menstrual cramps, headaches, and emotional distress linked to menstruation.</li>
            <li><h5>Belladonna:</h5>Helps with sudden, intense pain and heavy bleeding during periods, especially when accompanied by throbbing sensations.</li>
            <li><h5>Calcarea Carbonica:</h5>Suitable for women with profuse, prolonged bleeding and other symptoms like fatigue, sweating, and cold extremities.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>In our experience, homoeopathy can be beneficial for women dealing with menstrual disorders by focusing on the whole person, not just the symptoms. Many patients undergoing homoeopathic treatment report improved menstrual regularity, reduced pain, and enhanced emotional well-being. While homoeopathy may not replace conventional treatments, it serves as an effective complementary approach, particularly for those seeking natural and less invasive options for managing menstrual health.          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Menstrual disorder Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Menstrual disorder conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/female-troubles/menstural-disorder/menstural-disorder-diet">Diet for relief of Menstrual disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Menstrual disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Menstrual disorder Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Menstural Disorder Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/38-oEbAsA18?si=sND5YZSrqIN-BejD"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
      </div>
  );
};

export default KidneyPage;
