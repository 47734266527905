import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const MildHydroceleDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Mild Hydrocele Diet Guide</h1>
      <p className="diet-description">A diet rich in anti-inflammatory and immune-boosting foods can help manage mild hydrocele by reducing inflammation, promoting lymphatic drainage, and supporting healing. Below are dietary suggestions to help manage hydrocele symptoms effectively.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Foods like turmeric, ginger, and green leafy vegetables can help reduce inflammation and may alleviate discomfort associated with hydrocele.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish, flaxseeds, and walnuts have anti-inflammatory properties that support lymphatic health and reduce fluid retention.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fiber-Rich Foods</h3>
          <p className="diet-text">Fiber helps maintain digestive health and reduces pressure on the abdomen. Include whole grains, fruits, and vegetables to promote regularity.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Hydrating Foods</h3>
          <p className="diet-text">Hydrating foods like cucumbers, watermelon, and citrus fruits aid in lymphatic drainage and support fluid balance in the body.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics in yogurt, kefir, and other fermented foods help maintain gut health, which plays a role in overall immune function and healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C helps boost immunity and supports tissue healing. Include foods like bell peppers, strawberries, and oranges.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Salty Foods</h3>
          <p className="diet-text">Excessive salt can lead to water retention and may increase swelling. Try to limit processed foods and added salt in meals.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed and Sugary Foods</h3>
          <p className="diet-text">Processed foods and sugary snacks can promote inflammation. Focus on whole, unprocessed foods to support healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats</h3>
          <p className="diet-text">Trans fats in fried and baked goods can worsen inflammation. Opt for healthy fats like olive oil, avocados, and nuts instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Excessive Alcohol and Caffeine</h3>
          <p className="diet-text">High amounts of alcohol and caffeine can strain the body and impact fluid balance. Moderate these to support lymphatic health.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, berries, ginger, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and a mixed greens salad.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and a side of leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water, and include herbal teas like dandelion or chamomile to reduce inflammation and support fluid balance.</li>
        </ul>
      </section>
    </div>
  );
};

export default MildHydroceleDiet;
