import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const DepressionDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Depression Diet Guide</h1>
      <p className="diet-description">A nutrient-rich diet can help support mental health by promoting neurotransmitter balance, reducing inflammation, and stabilizing blood sugar. Here are some dietary suggestions for managing depression symptoms and promoting overall well-being.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, flaxseeds, and walnuts, are essential for brain health and may help reduce symptoms of depression by supporting neurotransmitter function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Whole Grains</h3>
          <p className="diet-text">Whole grains like oats, quinoa, and brown rice provide steady energy and support serotonin production, a neurotransmitter that promotes feelings of well-being.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Protein-Rich Foods</h3>
          <p className="diet-text">Lean proteins such as chicken, turkey, eggs, and legumes contain amino acids, which are precursors to mood-regulating neurotransmitters like serotonin and dopamine.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Leafy Greens and Vegetables</h3>
          <p className="diet-text">Leafy greens and vegetables are rich in folate and magnesium, both of which are linked to improved mood and reduced symptoms of depression.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics in yogurt, kefir, and fermented foods support gut health, which is closely connected to mood and mental health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Antioxidants in berries, dark chocolate, and nuts help reduce oxidative stress and inflammation, which may positively impact mood.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars</h3>
          <p className="diet-text">High-sugar foods like candies and sodas cause blood sugar spikes and crashes, which can lead to mood instability.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often lack essential nutrients and contain additives that can negatively impact mental health. Focus on whole foods for better mental clarity.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Alcohol</h3>
          <p className="diet-text">Alcohol can disrupt sleep and alter mood, making depression symptoms worse. Limiting alcohol can help stabilize mood.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Excessive Caffeine</h3>
          <p className="diet-text">While moderate caffeine can improve focus, excessive amounts can increase anxiety and interfere with sleep, impacting mood negatively.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Trans and Saturated Fats</h3>
          <p className="diet-text">Trans fats, found in fried and processed foods, can promote inflammation, which has been linked to worsened symptoms of depression. Choose healthy fats instead.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with berries, flaxseeds, and a handful of walnuts.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or salmon with quinoa and steamed vegetables.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a few pieces of dark chocolate.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried tofu with brown rice and a mixed greens salad.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water, and include calming herbal teas like chamomile or green tea to support relaxation and hydration.</li>
        </ul>
      </section>
    </div>
  );
};

export default DepressionDiet;
