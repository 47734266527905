import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh7.webp')}alt="Urology Hero" />
        <h1>IBS</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>IBS</h2>
          <img src={require('../images/gastric7.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <p>
        In homoeopathy, the treatment for an Interventricular Septal (IVS) defect, also known as a Ventricular Septal Defect (VSD), focuses on the individual’s overall constitution. Homoeopathic remedies aim to improve cardiac function, alleviate symptoms, and support the body’s natural healing mechanisms. While VSD is often managed with conventional treatments, homoeopathy can serve as a supportive approach to improve general health and reduce associated symptoms.
        </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Congenital:</h5>Most cases of VSD are congenital, meaning they develop during fetal growth. The exact cause is often unknown but may involve genetic and environmental factors.            </li>
            <li><h5>Genetic Factors:</h5>Family history and certain genetic conditions, like Down syndrome, are associated with a higher risk of VSD.            </li>
            <li><h5>Maternal Health Issues:</h5>Conditions such as diabetes or infections during pregnancy can increase the risk of congenital heart defects.            </li>
            <li><h5>Environmental Factors:</h5>Exposure to harmful substances (e.g., alcohol, tobacco, or certain medications) during pregnancy may contribute to congenital heart defects            .            </li>
          </ul>
          <h3>Symptoms of Anal Fissures</h3>
          <ul>
            <li><h5>Heart Murmur:</h5>Often detected in infancy, a heart murmur is a whooshing or swishing sound heard through a stethoscope, indicating turbulent blood flow.            </li>
            <li><h5>Shortness of Breath:</h5>Difficulty breathing, especially during feeding or physical activities, is common in moderate to large VSDs.            </li>
            <li><h5>Frequent Respiratory Infections:</h5>VSD can increase susceptibility to lung infections due to compromised lung function.            </li>
            <li><h5>Poor Growth and Weight Gain:</h5>Infants with moderate to severe VSD may have difficulty gaining weight or growing at a normal rate.            </li>
            <li><h5>Fatigue:</h5>Children with VSD often tire easily, especially during physical activity, as the heart works harder to supply the body with oxygen.            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li><h5>Heart Failure:</h5>Large, untreated VSDs may strain the heart, eventually leading to heart failure as the heart becomes less effective at pumping blood.            </li>
            <li><h5>Pulmonary Hypertension:</h5>Increased blood flow to the lungs can raise pressure in the pulmonary arteries, causing pulmonary hypertension.            </li>
            <li><h5>Endocarditis:</h5>VSD increases the risk of infective endocarditis, a bacterial infection of the heart’s inner lining.            </li>
            <li><h5>Arrhythmias:</h5>VSD may lead to irregular heartbeats due to the heart's overwork and structural strain.            </li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li><h5>Monitoring:</h5>Small VSDs may close on their own and require only regular monitoring with echocardiograms and physical exams.            </li>
            <li><h5>Medications:</h5>Diuretics, ACE inhibitors, and beta-blockers may be prescribed to reduce symptoms by managing blood pressure, fluid retention, and heart strain.            </li>
            <li><h5>Surgical Repair:</h5>Moderate to large VSDs often require surgical intervention to close the hole, typically through open-heart surgery or catheter procedures.        Patients may be advised to follow a heart-healthy diet, avoid smoking, and monitor exercise levels as part of their long-term management.            In severe cases, a procedure such as a lateral internal sphincterotomy may be performed to reduce muscle spasms and allow healing.            </li>
            <li><h5>Prophylactic Antibiotics:</h5>Used before certain procedures to prevent endocarditis in individuals with a history of VSD.            </li>
            </ul>
            <h3>Homoeopathic Treatment</h3>
          <ul>
            <li><h5>Calcarea Carbonica:</h5>Recommended for individuals with fatigue, breathlessness, and poor weight gain. It is suitable for those who are chilly and have a history of delayed growth and development.            </li>
            <li><h5>Baryta Carb:</h5>Suitable for young children with VSD who experience developmental delays and frequent infections. It helps in building immunity and improving growth.            </li>
            <li><h5>Crataegus:</h5>Known as a heart tonic in homoeopathy, Crataegus helps strengthen heart function, particularly in cases of heart strain and fatigue.            </li>         
            <li><h5>Digitalis:</h5>Effective for symptoms of arrhythmias, breathlessness, and fatigue. It is helpful in cases where the heart is weak and beating irregularly.            </li>
            <li><h5>Aurum Metallicum:</h5>Often recommended for individuals with a family history of heart issues and those experiencing anxiety or depressive symptoms due to their condition.            </li>
            </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>
          In our experience, homoeopathy can serve as a supportive approach for individuals with VSD by enhancing general health, managing symptoms, and improving quality of life. While homoeopathy does not replace conventional treatments for moderate to severe VSD, it can be a helpful complementary option, especially for those seeking natural support. Homeopathy focuses on the whole person, addressing not just the physical symptoms but also emotional and mental well-being, and is particularly beneficial for those with small VSDs or post-surgical recovery needs.
          </p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>IBS Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Anal Fissures.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/irf/irf-diet">Diet for preventing IBS</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from IBS</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for relief of IBS</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on IBS Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/L3T3LDFvyTI?si=-YfRiNH2tavkwPnI"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
