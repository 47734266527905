import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const DiabetesMellitusDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Diabetes Mellitus Diet Guide</h1>
      <p className="diet-description">A well-balanced diet is essential for managing blood sugar levels in people with diabetes mellitus (DM). Below are suggestions for foods to take and avoid to maintain healthy blood sugar levels.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Low-Glycemic Foods</h3>
          <p className="diet-text">Focus on foods with a low glycemic index (GI) like whole grains, legumes, and vegetables. These help prevent rapid spikes in blood sugar and provide sustained energy.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Fiber-Rich Foods</h3>
          <p className="diet-text">Fiber slows the absorption of sugar and improves blood sugar control. Include high-fiber foods like oats, barley, beans, and vegetables in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Lean Proteins</h3>
          <p className="diet-text">Proteins like chicken, turkey, tofu, and fish help stabilize blood sugar levels without raising them. They also support muscle maintenance and overall health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Healthy Fats</h3>
          <p className="diet-text">Unsaturated fats found in olive oil, avocados, nuts, and seeds can improve heart health and keep blood sugar levels steady.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s in fatty fish like salmon, mackerel, and flaxseeds help reduce inflammation and support cardiovascular health, important for those with diabetes.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Hydrating Foods</h3>
          <p className="diet-text">Staying hydrated is important for blood sugar regulation. Include water-rich foods like cucumbers, leafy greens, and citrus fruits, and drink plenty of water throughout the day.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Sugary foods like candies, sodas, and desserts cause rapid spikes in blood sugar. Avoid or minimize these foods to maintain better blood sugar control.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs like white bread, pasta, and baked goods are quickly converted into sugar, leading to blood sugar spikes. Opt for whole grains and high-fiber carbs instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats</h3>
          <p className="diet-text">Trans fats, found in fried and processed foods, can increase insulin resistance and contribute to heart disease. Avoid foods like fast food, margarine, and packaged snacks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain hidden sugars, unhealthy fats, and refined carbs. Stick to whole, unprocessed foods for better blood sugar management.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol can cause fluctuations in blood sugar, especially when consumed in excess. Limit alcohol intake and avoid sugary mixers to keep blood sugar stable.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">Excessive caffeine can cause spikes in blood sugar and insulin levels. Keep caffeine intake moderate and prioritize hydration for better blood sugar control.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with chia seeds, berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with quinoa and steamed vegetables.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a high-fiber fruit like apple or pear.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with brown rice and roasted Brussels sprouts.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support hydration and metabolism.</li>
        </ul>
      </section>
    </div>
  );
};

export default DiabetesMellitusDiet;
