import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const BPHProstateDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">BPH (Prostate) Diet Guide</h1>
      <p className="diet-description">Following a healthy diet can help manage symptoms of Benign Prostatic Hyperplasia (BPH) and promote prostate health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">1. Eat More Vegetables and Fruits</h2>
        <p className="diet-text">A diet rich in vegetables and fruits, especially those high in antioxidants, can support prostate health. Include plenty of leafy greens, tomatoes, and berries, which are known to benefit prostate function.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">2. Include Healthy Fats</h2>
        <p className="diet-text">Swap saturated fats found in red meat and dairy products with healthier fats like those found in nuts, seeds, and fatty fish. Omega-3 fatty acids, in particular, are known to support prostate health.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">3. Choose Whole Grains</h2>
        <p className="diet-text">Whole grains like oats, brown rice, and barley provide essential nutrients and fiber, which may reduce inflammation and improve urinary health.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">4. Reduce Red Meat Consumption</h2>
        <p className="diet-text">High consumption of red meat has been linked to prostate issues. Substitute red meat with leaner protein sources such as poultry, fish, and plant-based proteins like legumes.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">5. Limit Alcohol and Caffeine</h2>
        <p className="diet-text">Both alcohol and caffeine can irritate the bladder and prostate, worsening urinary symptoms. Reduce consumption of coffee, tea, soda, and alcohol to minimize irritation.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">6. Stay Hydrated</h2>
        <p className="diet-text">Drinking enough water helps flush toxins from the body and promotes urinary health. However, avoid drinking too much in the evening to reduce nighttime trips to the bathroom.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">7. Include Lycopene-Rich Foods</h2>
        <p className="diet-text">Tomatoes are a great source of lycopene, an antioxidant that may reduce prostate enlargement and support prostate health. Cooked tomatoes, in particular, are easier to absorb and more beneficial.</p>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Whole grain oatmeal with berries and a glass of water.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with a side of quinoa and steamed spinach.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a piece of fruit like an apple.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Turkey breast with roasted vegetables and brown rice.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day but limit fluid intake before bedtime.</li>
        </ul>
      </section>
    </div>
  );
};

export default BPHProstateDiet;
