import React from 'react';
import '../../css/CaseStudy.css';

const KidneyStoneCaseStudy = () => {
  return (
    <div className="case-study-page">
      <div className="case-study-hero">
        <h1>Deepak Kumar’s Pain-Free Kidney Stone Recovery with Homeopathy</h1>
        {/* <img src={require('../images/case-study-hero.jpg')} alt="Kidney Stone Recovery" className="case-study-img" /> */}
      </div>

      <div className="case-study-content">
        <p>
          Deepak Kumar, a 32-year-old from Mumbai, was diagnosed with a 13.8mm kidney stone in July 2015. This large stone worried him, as he expected intense pain and discomfort. However, his recovery turned out to be smooth, thanks to online homeopathic treatment.
        </p>

        <h2>Choosing a New Treatment Option</h2>
        <p>
          On July 16, 2015, Deepak decided to try homeopathy instead of surgery. His doctor recommended a natural treatment plan to dissolve the kidney stone gradually, allowing it to pass through his urine painlessly. Follow-ups were scheduled every 15 days, with specific lifestyle tips to support the treatment.
        </p>

        <h2>Making Lifestyle Changes</h2>
        <p>
          Deepak was advised to make three major changes:
        </p>
        <ul>
          <li><strong>Stay Hydrated:</strong> Drinking plenty of water would help flush out the stone.</li>
          <li><strong>Reduce Salt:</strong> Salt can worsen kidney stones, so he cut down on salty foods.</li>
          <li><strong>Limit Protein:</strong> He lowered his intake of meat and added more plant-based proteins to his diet.</li>
        </ul>
        <p>
          These adjustments were challenging initially, but he quickly adapted, especially as he noticed reduced discomfort.
        </p>

        <h2>First Follow-Up</h2>
        <p>
          Two weeks later, Deepak had his first follow-up. His doctor asked about any pain or changes, and surprisingly, Deepak reported no severe pain. The doctor encouraged him to continue with the treatment and dietary changes, assuring him it would speed up the recovery.
        </p>

        <h2>Second Follow-Up – Progressing Well</h2>
        <p>
          Fifteen days after the first follow-up, Deepak noticed even more improvement. He mentioned seeing tiny fragments in his urine, a clear sign that the stone was breaking down. This progress was a relief, and he continued his hydration and diet plan diligently.
        </p>

        <h2>The Breakthrough Moment</h2>
        <p>
          After about a week, Deepak noticed small particles passing in his urine, marking a significant milestone. These fragments indicated the stone was dissolving and being expelled naturally. His doctor praised his efforts and encouraged him to stick with the plan.
        </p>

        <h2>A Pain-Free Recovery</h2>
        <p>
          After two months, a check-up revealed the stone was entirely gone. Deepak had managed to clear the kidney stone naturally, without surgery or pain. The regular hydration, salt reduction, and limited protein intake had supported his homeopathic treatment, resulting in a smooth recovery.
        </p>

        <h2>Looking Back</h2>
        <p>
          Deepak’s experience showed him the effectiveness of combining homeopathy with lifestyle changes. He continues to drink plenty of water, limit salt, and maintain a balanced diet to prevent kidney stones from returning. His story has inspired friends and family, proving that natural treatments, guided by a doctor, can be successful.
        </p>

        <p>
          Today, Deepak lives a normal, healthy life and encourages others to consider natural options when possible. His journey is a great example of how simple changes and professional support can lead to remarkable healing.
        </p>
      </div>
    </div>
  );
};

export default KidneyStoneCaseStudy;
