import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const AnkylosingSpondylitisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Ankylosing Spondylitis Diet Guide</h1>
      <p className="diet-description">A nutrient-rich diet that reduces inflammation and supports joint and bone health can be beneficial for managing symptoms of ankylosing spondylitis. Here are dietary suggestions for foods to take and avoid for improved joint mobility and overall health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, chia seeds, and walnuts, are anti-inflammatory and can help reduce joint pain and stiffness.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Calcium-Rich Foods</h3>
          <p className="diet-text">Calcium is crucial for bone health, which is important for people with ankylosing spondylitis. Include dairy, leafy greens, almonds, and fortified non-dairy milks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Vitamin D-Rich Foods</h3>
          <p className="diet-text">Vitamin D supports calcium absorption and bone health. Include fatty fish, mushrooms, eggs, and fortified dairy products.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium aids muscle relaxation and supports bone health. Include nuts, seeds, bananas, and dark leafy greens in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts contain antioxidants that help reduce inflammation and protect joint and bone tissues from damage.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Turmeric and Ginger</h3>
          <p className="diet-text">Both turmeric and ginger have strong anti-inflammatory properties. Adding these to meals or teas can help manage inflammation.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods</h3>
          <p className="diet-text">Excessive sugar can increase inflammation. Limit sweets, sugary drinks, and processed snacks to reduce joint pain.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain trans fats and additives that worsen inflammation. Choose fresh, whole foods instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. High-Sodium Foods</h3>
          <p className="diet-text">Excess sodium can cause water retention and increase joint stiffness. Limit salty snacks, canned foods, and processed meals.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can interfere with medication efficacy and increase inflammation. Limiting alcohol intake supports better joint health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Red Meat (in moderation)</h3>
          <p className="diet-text">Red meat contains arachidonic acid, which may increase inflammation. Opt for lean proteins like fish, chicken, or plant-based sources.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon or tofu with quinoa and steamed leafy greens like kale.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a handful of walnuts or an apple with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with turmeric-seasoned chicken or chickpeas, served with brown rice.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include anti-inflammatory herbal teas like ginger or turmeric tea.</li>
        </ul>
      </section>
    </div>
  );
};

export default AnkylosingSpondylitisDiet;
