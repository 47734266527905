import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const ADHDDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">ADHD Diet Guide</h1>
      <p className="diet-description">A nutrient-rich diet can support focus, brain health, and emotional stability, which can be beneficial for managing ADHD symptoms. Here are dietary suggestions for foods to take and avoid to help with attention, impulsivity, and overall well-being.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s, found in fatty fish, flaxseeds, and walnuts, are essential for brain health and can help improve focus, reduce impulsivity, and stabilize mood.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Protein-Rich Foods</h3>
          <p className="diet-text">Protein-rich foods such as eggs, chicken, tofu, and beans provide amino acids that are important for neurotransmitter function, supporting focus and concentration.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Complex Carbohydrates</h3>
          <p className="diet-text">Complex carbs like whole grains, oats, and sweet potatoes provide steady energy and help stabilize blood sugar, reducing irritability and mood swings.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Iron and Zinc-Rich Foods</h3>
          <p className="diet-text">Iron and zinc are important for brain function and behavior regulation. Include foods like lean meats, pumpkin seeds, and spinach to support cognitive health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help reduce oxidative stress in the brain, promoting better mental clarity.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium, found in leafy greens, almonds, and bananas, helps reduce hyperactivity and supports relaxation by calming the nervous system.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Refined Sugars</h3>
          <p className="diet-text">Sugary foods can lead to blood sugar spikes and crashes, which may worsen hyperactivity and inattention. Limit sweets, sodas, and sugary snacks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods and Additives</h3>
          <p className="diet-text">Processed foods may contain artificial colors, preservatives, and flavors that have been linked to worsening ADHD symptoms in some individuals. Choose whole foods.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats</h3>
          <p className="diet-text">Trans fats, found in fried and processed foods, can impair brain health and worsen behavior issues. Opt for healthy fats like olive oil and avocado.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Caffeine</h3>
          <p className="diet-text">While some adults with ADHD may benefit from small amounts of caffeine, excessive intake can increase anxiety and disrupt sleep, impacting focus and mood.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Artificial Sweeteners</h3>
          <p className="diet-text">Artificial sweeteners in diet sodas and processed snacks may impact neurotransmitter function. Choose natural sweeteners or whole fruits instead.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Scrambled eggs with spinach, a slice of whole-grain toast, and a handful of berries.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu with quinoa and mixed greens.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a handful of almonds or an apple with peanut butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with sweet potato and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, and consider calming herbal teas like chamomile to promote relaxation.</li>
        </ul>
      </section>
    </div>
  );
};

export default ADHDDiet;
