import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const MolluscumContagiosumDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Molluscum Contagiosum Diet Guide</h1>
      <p className="diet-description">A healthy, immune-boosting diet can help manage molluscum contagiosum by supporting the body's natural defense mechanisms, promoting skin health, and reducing inflammation. Below are suggestions for foods to take and avoid when managing molluscum contagiosum.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Immune-Boosting Foods</h3>
          <p className="diet-text">Foods rich in vitamins C, D, and E, as well as zinc and selenium, can strengthen the immune system and help the body fight off viral infections. Include citrus fruits, bell peppers, spinach, almonds, and seeds in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help protect the body from oxidative stress and support skin health. These foods promote faster healing of skin lesions caused by molluscum contagiosum.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods support gut health and boost the immune system. A healthy gut plays a significant role in fighting viral infections and maintaining skin integrity.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Healthy Fats</h3>
          <p className="diet-text">Omega-3 fatty acids found in fatty fish like salmon, flaxseeds, and chia seeds have anti-inflammatory properties that can help reduce skin inflammation and support overall skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Hydration is important for skin health. Drink plenty of water and include hydrating foods like cucumbers, melons, and citrus fruits to maintain skin moisture and aid healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Garlic and Ginger</h3>
          <p className="diet-text">Both garlic and ginger have antiviral and anti-inflammatory properties, which may help boost immunity and support the body’s defense against molluscum contagiosum.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can weaken the immune system and exacerbate inflammation. Avoid sugary snacks, sodas, and processed foods to support your body’s ability to fight the infection.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain unhealthy fats, preservatives, and additives that can increase inflammation in the body and weaken the immune response. Stick to whole, unprocessed foods for better health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and those high in trans fats can increase inflammation, which may slow down healing. Opt for healthier fats like olive oil, avocado, and nuts instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can weaken the immune system and dry out the skin, making it harder for the body to recover from molluscum contagiosum. Limit or avoid alcohol to support faster healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Excessive Caffeine</h3>
          <p className="diet-text">While moderate caffeine intake is generally fine, excessive caffeine can dehydrate the skin and interfere with the immune system’s ability to fight infections. Keep caffeine intake in check.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, flaxseeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A probiotic-rich yogurt or a handful of almonds.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support immunity.</li>
        </ul>
      </section>
    </div>
  );
};

export default MolluscumContagiosumDiet;
