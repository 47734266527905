import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const AdenoidsDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Adenoids Diet Guide</h1>
      <p className="diet-description">A soothing and anti-inflammatory diet can help manage symptoms associated with enlarged adenoids, such as breathing difficulty and nasal congestion. Below are suggestions for foods to take and avoid to support overall respiratory health and reduce inflammation.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Soft and Hydrating Foods</h3>
          <p className="diet-text">Smooth, soft foods like soups, smoothies, mashed potatoes, and yogurt are easier to swallow and help soothe the throat, reducing discomfort caused by enlarged adenoids.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, and Omega-3-rich foods like salmon help reduce inflammation, potentially easing symptoms related to swollen adenoids.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Hydrating Foods and Fluids</h3>
          <p className="diet-text">Staying hydrated can thin mucus and improve breathing. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water, herbal teas, and clear broths.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Honey</h3>
          <p className="diet-text">Honey has natural antibacterial and soothing properties that can help reduce throat irritation and support healing. Add honey to warm water or herbal teas for relief.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods can help support immune function and reduce the inflammation that may contribute to adenoid swelling.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Garlic and Ginger</h3>
          <p className="diet-text">Both garlic and ginger have anti-inflammatory and antibacterial properties that may help reduce swelling and promote faster recovery from respiratory issues.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Dairy Products (for some)</h3>
          <p className="diet-text">For some individuals, dairy products can increase mucus production and worsen nasal congestion. If dairy exacerbates your symptoms, consider reducing or eliminating it from your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can weaken the immune system and increase inflammation, potentially worsening symptoms. Avoid sugary snacks, sodas, and processed foods to support healing.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can irritate the throat and nasal passages, worsening symptoms. Avoid excessively spicy dishes while managing enlarged adenoids.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Processed Foods</h3>
          <p className="diet-text">Processed foods are often loaded with additives and preservatives that can trigger inflammation and weaken the immune system. Stick to whole, unprocessed foods for better overall health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">Alcohol can dehydrate the body and exacerbate throat and nasal irritation. Limiting alcohol intake may help improve respiratory symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. High-Sodium Foods</h3>
          <p className="diet-text">Sodium can contribute to fluid retention, worsening swelling in the nasal passages. Reduce intake of processed snacks, canned foods, and salty meals.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with bananas, yogurt, and honey.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Chicken soup with soft vegetables and warm herbal tea with honey.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Mashed sweet potatoes or applesauce.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with brown rice and a side of steamed spinach.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas or clear broths to stay hydrated and reduce throat discomfort.</li>
        </ul>
      </section>
    </div>
  );
};

export default AdenoidsDiet;
