import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/treat4.webp')}alt="Urology Hero" />
        {/* <h1>Osteoarthritis Treatment</h1> */}
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Osteoarthritis</h2>
          <img src={require('../images/joint3.webp')}alt="Urology Hero" />
       <p>Osteoarthritis (OA), often referred to as “wear and tear” arthritis, is the most common form of arthritis. It affects millions of people worldwide and can significantly impact daily life. Here’s a breakdown to help you understand it better:</p>
          <h3>Causes</h3>
          <p>Osteoarthritis results from the gradual breakdown of cartilage, the smooth, cushioning tissue that protects the ends of bones in your joints. This breakdown can occur due to several factors:</p>
          <ul>
            <li><h5>Age:</h5>Cartilage naturally wears down with age, making older adults more susceptible.</li>
            <li><h5>Previous Injuries:</h5>Joint injuries, including fractures, ligament tears, or meniscus tears, can increase the risk of OA later in life.</li>
            <li><h5>Overuse:</h5>Repetitive stress on certain joints, especially from sports or occupations, can accelerate cartilage breakdown.</li>
            <li><h5>Obesity:</h5>Excess weight puts extra stress on weight-bearing joints like knees and hips, increasing the risk of OA.</li>
            <li><h5>Genetics:</h5>Some people have a genetic predisposition to weaker cartilage, making them more susceptible to OA.</li>
          </ul>
          <h3>Symptoms </h3>
          <p>The most common symptoms of osteoarthritis include:</p>
          <ul>
            <li><h5>Joint Pain:</h5>This is often worse with activity and improves with rest.</li>
            <li><h5>Stiffness: </h5>Joints may feel stiff, especially in the morning or after periods of inactivity.</li>
            <li><h5>Reduced Range of Motion:</h5>The ability to move a joint through its full range of motion may become limited.</li>
            <li><h5>Joint Creaking or Grating:</h5>A crackling or grinding sensation may be felt or heard during movement.</li>
            <li><h5>Swelling:</h5> In some cases, the affected joint may become swollen due to inflammation.</li>
          </ul>
          <h3>Investigations </h3>
          <ul>
            <li><h5>Physical Examination: </h5>A doctor will assess your joints for tenderness, swelling, range of motion, and signs of instability.</li>
            <li><h5>X-rays:</h5>These images can reveal narrowing of the joint space, bone spurs (osteophytes), and other signs of OA damage.</li>
            <li>In some cases, additional tests like MRIs or blood tests may be used to rule out other conditions that mimic OA.</li>
          </ul>
          <h3>Complications</h3>
          <p>While not life-threatening, OA can lead to complications like:</p>
          <ul>
            <li><h5>Joint Deformity:</h5>Over time, cartilage loss can cause bones to rub against each other, leading to joint deformity.</li>
            <li><h5>Disability: </h5>Severe OA can significantly impair mobility and make it difficult to perform daily activities.</li>
            <li><h5>Reduced Quality of Life:</h5>Pain and limitations caused by OA can negatively impact a person’s quality of life.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <p>There is no cure for osteoarthritis, but treatment focuses on managing symptoms, slowing progression, and improving function. It may involve:</p>
          <ul>
            <li><h5>Pain Management: </h5>Medications like pain relievers (NSAIDs) or acetaminophen can help manage pain.</li>
            <li><h5>Physical Therapy:</h5>Exercises to strengthen muscles around the joint, improve flexibility, and maintain range of motion.</li>
            <li><h5>Weight Management:</h5> Losing excess weight can significantly reduce stress on weight-bearing joints.</li>
            <li><h5>Assistive Devices: </h5>Using canes, walkers, or braces can help improve mobility and reduce pain during activities.</li>
            <li><h5>Joint Injections:</h5>Corticosteroid injections can provide temporary pain relief in some cases.</li>
            <li><h5>Surgery:</h5>In severe cases, joint replacement surgery may be an option to replace a damaged joint with an artificial one.</li>
          </ul>
          <h3>Conclusion</h3>
          <p>Osteoarthritis is a chronic condition, but with proper management, you can control symptoms and maintain an active lifestyle. If you experience persistent joint pain, stiffness, or swelling, consult a doctor for diagnosis and discuss treatment options. Remember, I cannot recommend homeopathic treatments as they lack scientific backing.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Osteoarthritis Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Osteoarthritis conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/joint-bone/osteoarthritis/osteoarthritis-diet">Diet for Osteoarthritis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Osteoarthritis</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Osteoarthritis</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Osteoarthritis Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/JEuUNnghy1U?si=sQH3V4sXJivtV5WU"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
