import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const FattyLiverDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Fatty Liver Diet Guide</h1>
      <p className="diet-description">A balanced diet can help manage fatty liver disease by promoting liver health, reducing inflammation, and preventing fat buildup. Below are suggestions for foods to take and avoid when dealing with fatty liver.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Fiber-Rich Foods</h3>
          <p className="diet-text">Fiber-rich foods such as whole grains, fruits, vegetables, and legumes help regulate digestion and support liver health by reducing fat buildup in the liver.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Lean Proteins</h3>
          <p className="diet-text">Opt for lean protein sources like chicken, turkey, tofu, and fish. These proteins support liver repair and help maintain muscle mass without adding excess fat.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Foods rich in Omega-3s, such as salmon, flaxseeds, and walnuts, can reduce liver fat, improve lipid levels, and reduce inflammation, which is essential for managing fatty liver disease.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Antioxidants in berries, leafy greens, and citrus fruits help protect the liver by reducing oxidative stress and promoting detoxification.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Healthy Fats</h3>
          <p className="diet-text">Healthy fats from avocados, olive oil, and nuts can improve liver function and support overall health without contributing to fat buildup in the liver.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Hydrating Foods</h3>
          <p className="diet-text">Proper hydration helps detoxify the liver and promote healthy digestion. Drink plenty of water and include hydrating foods like cucumbers and watermelon.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Saturated and Trans Fats</h3>
          <p className="diet-text">Avoid foods high in saturated and trans fats, such as fried foods, red meat, and processed snacks. These fats can increase fat buildup in the liver and worsen fatty liver disease.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined grains like white bread, pasta, and pastries can raise blood sugar levels and contribute to fat accumulation in the liver. Opt for whole grains instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Sugary Foods and Drinks</h3>
          <p className="diet-text">Sugary snacks and beverages like soda, candy, and baked goods contribute to insulin resistance and liver fat. Reducing sugar intake is essential for managing fatty liver.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol is toxic to the liver and can worsen fatty liver disease. Avoiding alcohol is crucial for protecting liver function and promoting recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in unhealthy fats, sodium, and additives, which can strain the liver. Stick to whole, unprocessed foods for better liver health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Excessive Caffeine</h3>
          <p className="diet-text">While moderate caffeine can have health benefits, excessive consumption can strain the liver. Limit caffeine intake to support liver health and hydration.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with chia seeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken or tofu salad with spinach, avocado, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a green smoothie with spinach, ginger, and banana.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with quinoa and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support liver function and detoxification.</li>
        </ul>
      </section>
    </div>
  );
};

export default FattyLiverDiet;
