import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const AcuteRenalFailureDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Acute Renal Failure (ARF) Diet Guide</h1>
      <p className="diet-description">A carefully planned diet can support recovery from acute renal failure by managing fluid and nutrient intake to reduce stress on the kidneys.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">1. Control Protein Intake</h2>
        <p className="diet-text">To reduce the buildup of waste in the blood, limit protein intake. Focus on high-quality proteins like lean meats, eggs, and fish, as advised by your healthcare provider. The amount will depend on the severity of kidney failure.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">2. Monitor Sodium Intake</h2>
        <p className="diet-text">Limiting sodium is important to manage fluid retention and high blood pressure. Avoid processed foods, canned soups, and salty snacks. Opt for fresh, unprocessed foods and use herbs and spices instead of salt for flavor.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">3. Control Potassium Levels</h2>
        <p className="diet-text">In ARF, potassium levels may become dangerously high. Avoid potassium-rich foods such as bananas, potatoes, spinach, and oranges. Follow your healthcare provider’s guidelines on safe potassium intake.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">4. Limit Phosphorus</h2>
        <p className="diet-text">High phosphorus levels can harm bones and lead to complications in ARF. Limit dairy products, nuts, seeds, and colas. Choose phosphorus-free alternatives such as almond milk or non-dairy creamers.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">5. Fluid Management</h2>
        <p className="diet-text">Your doctor may advise you to limit fluid intake to prevent fluid overload. It is important to follow your healthcare provider’s recommendations on how much water and other fluids to drink each day.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">6. Focus on Low-Potassium Fruits and Vegetables</h2>
        <p className="diet-text">Choose low-potassium fruits and vegetables such as apples, berries, cauliflower, and green beans. These can provide essential vitamins and minerals without raising potassium levels.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">7. Monitor Phosphorus and Calcium</h2>
        <p className="diet-text">Your doctor may recommend controlling both phosphorus and calcium intake. Too much phosphorus and too little calcium can lead to bone issues. Choose calcium-rich, low-phosphorus foods after consulting your dietician.</p>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Scrambled egg whites with a slice of low-sodium toast and an apple.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken breast with steamed green beans and a small quinoa salad.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of blueberries or a small serving of low-sodium crackers.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked fish (tilapia or cod) with a side of cauliflower and rice.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Follow your doctor’s recommendations on how much water to drink, avoiding overhydration.</li>
        </ul>
      </section>
    </div>
  );
};

export default AcuteRenalFailureDiet;
