import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const NasalPolypsDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Nasal Polyps Diet Guide</h1>
      <p className="diet-description">A balanced diet can help reduce inflammation and manage the symptoms of nasal polyps. Below are suggestions for foods to take and avoid to minimize the risk of nasal polyps and improve overall respiratory health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, leafy greens, and fatty fish like salmon are rich in Omega-3s and antioxidants, which help reduce inflammation and improve respiratory health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C is a natural antihistamine and immune booster. Include citrus fruits, bell peppers, and broccoli to help reduce inflammation and improve sinus health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Quercetin-Rich Foods</h3>
          <p className="diet-text">Quercetin, found in apples, onions, and broccoli, helps stabilize mast cells and reduce histamine release, potentially minimizing the development of nasal polyps.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods help balance gut bacteria, which supports the immune system and reduces inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Hydrating Foods</h3>
          <p className="diet-text">Staying hydrated helps thin mucus and improve respiratory function. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of water throughout the day.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Ginger and Turmeric</h3>
          <p className="diet-text">Ginger and turmeric are known for their anti-inflammatory properties and can help reduce swelling in the nasal passages. Add these spices to your diet to support sinus health.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Dairy Products (for some)</h3>
          <p className="diet-text">For some individuals, dairy products can increase mucus production and worsen nasal congestion. If dairy triggers your symptoms, consider reducing or eliminating it from your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can contribute to inflammation and weaken the immune system. Avoid sugary snacks, sodas, and processed foods to help reduce inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain additives and preservatives that can trigger allergic reactions and worsen inflammation. Stick to whole, unprocessed foods for better immune support.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol, especially beer and wine, can increase inflammation and worsen nasal congestion. Limiting alcohol intake may help reduce nasal polyps flare-ups.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Spicy Foods</h3>
          <p className="diet-text">Spicy foods can trigger nasal irritation and worsen symptoms of nasal polyps. Avoid excessively spicy dishes to reduce sinus congestion.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Gluten (for some)</h3>
          <p className="diet-text">Some individuals with sensitivities may find that gluten worsens inflammation and allergy symptoms. Consider reducing gluten intake if you notice a correlation with your symptoms.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> An apple or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked chicken with brown rice and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support hydration and reduce nasal congestion.</li>
        </ul>
      </section>
    </div>
  );
};

export default NasalPolypsDiet;
