import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const UrethralStrictureDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Urethral Stricture Diet Guide</h1>
      <p className="diet-description">A healthy diet can support the management of urethral stricture by preventing complications and promoting healing.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">1. Stay Hydrated</h2>
        <p className="diet-text">Drinking plenty of water is essential to keeping your urinary tract healthy and reducing the risk of infections. Aim for 8-10 cups of water daily to keep your urine dilute.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">2. Increase Fiber Intake</h2>
        <p className="diet-text">Eating a diet high in fiber helps prevent constipation, which can put pressure on the urinary tract and worsen symptoms of urethral stricture. Include whole grains, fruits, vegetables, and legumes in your diet.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">3. Limit Spicy and Acidic Foods</h2>
        <p className="diet-text">Spicy and acidic foods, such as citrus fruits, tomatoes, and hot peppers, can irritate the urinary tract and worsen symptoms. Opt for milder foods to reduce discomfort.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">4. Avoid Caffeine and Alcohol</h2>
        <p className="diet-text">Caffeine and alcohol can irritate the bladder and increase urinary urgency. Try to limit or avoid coffee, tea, sodas, and alcoholic beverages.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">5. Include Probiotics</h2>
        <p className="diet-text">Probiotics, found in yogurt and fermented foods, promote healthy gut and urinary tract bacteria. They may help reduce infections and inflammation, supporting the recovery of the urinary tract.</p>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">6. Eat Anti-inflammatory Foods</h2>
        <p className="diet-text">Include foods rich in antioxidants and anti-inflammatory properties, such as berries, leafy greens, nuts, and fatty fish (salmon, sardines). These can help reduce inflammation in the urinary tract.</p>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with fresh berries and a glass of water.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with a side of quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of mixed nuts or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with sweet potatoes and spinach.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day, aiming for 8-10 cups.</li>
        </ul>
      </section>
    </div>
  );
};

export default UrethralStrictureDiet;
