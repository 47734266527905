import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/hh3.webp')}alt="Urology Hero" />
        <h1>Growth hormone deficiency (GHD)</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Growth hormone deficiency (GHD)</h2>
          <img src={require('../images/hormones3.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <ul>
            <li>Homeopathy considers growth hormone disorders as disruptions in the body’s vital force affecting growth and development.</li>
            <li>Treatment aims to stimulate the body’s self-regulatory mechanisms to restore balance and support optimal growth.</li>
            <li>Remedies are chosen based on individual symptoms, emotional state, and overall constitution.</li>
          </ul>
          <h3>Causes</h3>
          <ul>
            <li>Growth hormone disorders can be congenital (present at birth) or acquired later in life.</li>
            <li>Congenital causes may include genetic mutations affecting growth hormone production or signaling.</li>
            <li>Acquired causes can involve tumors affecting the pituitary gland, trauma, or infections impacting hormone secretion.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li>Diagnostic tests include growth hormone stimulation tests, insulin-like growth factor (IGF-1) levels, and imaging studies (MRI or CT scans) to assess the pituitary gland.</li>
            <li>Comprehensive assessment of growth patterns, physical examination, and medical history are crucial for diagnosis.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li>Complications may include short stature, delayed puberty, and metabolic disturbances.</li>
            <li>Risk factors include genetic predisposition, certain medical conditions affecting the pituitary gland, and environmental factors.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li>Conventional treatments may involve synthetic growth hormone injections to stimulate growth.</li>
            <li>Treatment plans are tailored based on the underlying cause and severity of the growth hormone disorder.</li>
            <li>Regular monitoring of growth parameters and hormone levels to adjust treatment as necessary.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <ul>
            <li>Individualized remedies such as Calcarea carbonica, Silicea, or Baryta carbonica may be prescribed based on symptomatology.</li>
            <li>Support for enhancing growth hormone production, improving overall health, and addressing associated symptoms.</li>
            <li>Emphasis on nutritional support, exercise, and lifestyle modifications to support optimal growth and development.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathy offers a complementary approach to managing growth hormone disorders, focusing on stimulating the body’s innate healing mechanisms and supporting overall health. It can be integrated with conventional treatments under the guidance of a qualified homeopathic practitioner and in coordination with regular medical monitoring. Personalized treatment plans and ongoing assessment are essential for addressing the unique needs of individuals with growth hormone disorders and optimizing their growth potential.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Growth hormone deficiency (GHD) Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Growth hormone deficiency (GHD) conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/hormonal-diseases/growth-hormone-deficiency/growth-hormone-deficiency-diet">Diet for Growth hormone deficiency (GHD)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Growth hormone deficiency (GHD)</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Growth hormone deficiency (GHD) Health</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Growth hormone deficiency Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/_KhFpHtkYjk?si=3i8T94Yys4uchVPC"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
