import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const VitiligoDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Vitiligo Diet Guide</h1>
      <p className="diet-description">A balanced diet can help manage vitiligo by supporting immune health, promoting skin regeneration, and reducing oxidative stress. Below are suggestions for foods to take and avoid when dealing with vitiligo.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help neutralize free radicals and reduce oxidative stress, promoting healthy skin regeneration.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc plays an important role in immune function and skin health. Include foods like nuts, seeds, legumes, and whole grains to boost zinc levels and promote skin recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C helps promote collagen production and protect the skin from free radical damage. Citrus fruits, bell peppers, strawberries, and broccoli are excellent sources.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Folate-Rich Foods</h3>
          <p className="diet-text">Folate supports skin regeneration and overall health. Include leafy greens, lentils, and avocados to ensure an adequate intake of folate, which can help reduce skin depigmentation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Beta-Carotene-Rich Foods</h3>
          <p className="diet-text">Beta-carotene, found in carrots, sweet potatoes, and spinach, helps support skin health by promoting skin cell regeneration and protecting against UV damage.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Copper-Rich Foods</h3>
          <p className="diet-text">Copper is crucial for melanin production, which may help manage vitiligo symptoms. Include foods like almonds, sunflower seeds, and shellfish to support healthy skin pigmentation.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in additives and preservatives that can weaken the immune system and increase oxidative stress. Stick to whole, unprocessed foods to support skin health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can cause inflammation and may interfere with skin health. Avoid sugary snacks, sodas, and desserts to reduce the risk of worsening vitiligo symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Red Meat</h3>
          <p className="diet-text">Red meat can increase inflammation and oxidative stress in the body, which may worsen vitiligo. Opt for lean protein sources like chicken, fish, and plant-based options instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Gluten (for some individuals)</h3>
          <p className="diet-text">For individuals sensitive to gluten, eliminating gluten from the diet may help reduce autoimmune responses and manage vitiligo symptoms. Consider gluten-free grains like quinoa and brown rice.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Spicy Foods</h3>
          <p className="diet-text">Spicy foods may trigger inflammation and skin irritation in some individuals with vitiligo. Reducing or eliminating spicy dishes can help manage flare-ups.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Alcohol</h3>
          <p className="diet-text">Alcohol can weaken the immune system and impair skin healing. Limiting alcohol intake is recommended to promote better skin health and reduce flare-ups.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, flaxseeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and olive oil.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support skin hydration and immune function.</li>
        </ul>
      </section>
    </div>
  );
};

export default VitiligoDiet;
