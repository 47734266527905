import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/gh2.webp')}alt="Urology Hero" />
        <h1>Hemorrhoids</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Hemorrhoids</h2>
          <img src={require('../images/gastric2.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
        <ul>
            <li>Homeopathy views hemorrhoids as a manifestation of imbalance in the body’s vital force, often related to venous congestion and poor circulation.</li>
            <li>Treatment aims to address symptoms, reduce inflammation, and improve venous tone through individualized remedies.</li>
            <li>Remedies are chosen based on the specific symptoms, severity, and overall health of the person.</li>
        </ul>
          <h3>Causes</h3>
          <ul>
            <li>Causes of hemorrhoids can include straining during bowel movements, chronic constipation or diarrhea, prolonged sitting or standing, and pregnancy.</li>
            <li>Obesity, low fiber diet, and genetic predisposition can also contribute to their development.</li>
          </ul>
          <h3>Investigations</h3>
          <ul>
            <li>Diagnostic evaluation typically involves a physical examination of the rectal area to assess for swollen veins (hemorrhoids).</li>
            <li>Symptoms such as pain, itching, bleeding, and discomfort during bowel movements are considered in diagnosis.</li>
            <li>Homeopaths may also inquire about bowel habits, dietary habits, and lifestyle factors.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <ul>
            <li>Complications can include thrombosis (blood clots within hemorrhoids), prolapse (protrusion of hemorrhoids outside the anus), and persistent bleeding.</li>
            <li>Risk factors include advanced age, pregnancy, chronic constipation or diarrhea, and a family history of hemorrhoids.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
            <li>Conventional treatments include dietary modifications (increased fiber intake), topical ointments or suppositories to reduce inflammation and discomfort,homeopathic medicine for loose motion and procedures such as rubber band ligation or surgery for severe cases.</li>
            <li>Lifestyle changes such as avoiding straining during bowel movements and maintaining proper hygiene are also recommended.</li>
          </ul>
          <h3>Homeopathic Supportive Care </h3>
          <ul>
            <li>Individualized remedies such as Aesculus hippocastanum, Hamamelis virginiana, Collinsonia canadensis, or Nux vomica may be recommended based on symptomatology.</li>
            <li>Remedies aim to reduce swelling, relieve pain and itching, and improve venous circulation.</li>
            <li>Emphasis on dietary recommendations (increased fiber and fluid intake), lifestyle modifications (regular exercise, avoiding prolonged sitting), and proper anal hygiene.</li>
          </ul>
          <h3>Conclusion / Our Experience</h3>
          <p>Homeopathy offers a holistic approach to managing hemorrhoids, aiming to alleviate symptoms, reduce inflammation, and improve overall venous circulation. It can be used alongside conventional treatments but should be supervised by a qualified homeopathic</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Hemorrhoids Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Hemorrhoids conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/gastric-issues/hemorrhoids/hemorrhoids-diet">Diet for Hemorrhoids</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Hemorrhoids</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Hemorrhoids</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Hemorrhoids Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/7FMjbOygX8Y?si=l3mCIOthaNMs1iHc"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
