import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/meh7.webp')}alt="Urology Hero" />
        <h1>Panic disorder Treatment</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Panic disorder</h2>
          <img src={require('../images/mental7.webp')}alt="Urology Hero" />
        <h3> Basic Homeopathic Approach</h3>
         <p>Homeopathy views panic disorder as a manifestation of an underlying imbalance in the individual’s vital force. The treatment is holistic, taking into account the patient’s physical, emotional, and mental states. Homeopaths select remedies based on the principle of “like cures like,” aiming to stimulate the body’s self-healing abilities. Each treatment plan is personalized, focusing on the unique way the patient experiences their symptoms.
         </p>
          <h3>Causes</h3>
          <ul>
            <li><h5>Genetic Factors:</h5>A predisposition to panic disorder can run in families, indicating a genetic component.</li>
            <li><h5>Biological Factors:</h5> Imbalances in neurotransmitters such as serotonin, norepinephrine, and gamma-aminobutyric acid (GABA) play a role in the development of panic disorder.</li>
            <li><h5>Psychological Factors:</h5> Traumatic events, chronic stress, and major life changes (e.g., loss of a loved one, divorce) can trigger panic attacks.</li>
            <li><h5>Environmental Factors:</h5> High-stress environments, substance abuse (including caffeine and nicotine), and a lack of social support are significant contributors.</li>
            <li><h5>Medical Conditions:</h5> Certain medical conditions, such as hyperthyroidism, mitral valve prolapse, and hypoglycemia, can mimic or exacerbate symptoms of panic disorder.            </li>
          </ul>
          <h3>Investigations</h3>
          <ul>
          <li><h5>Clinical Evaluation:</h5> A thorough medical history and physical examination to rule out other conditions.</li>
          <li><h5>Psychiatric Assessment:</h5> Evaluation by a mental health professional to confirm the diagnosis and assess the severity of the disorder.</li>
          <li><h5>Diagnostic Criteria:</h5> Use of DSM-5 criteria, which include recurrent, unexpected panic attacks and persistent concern about having more attacks.</li>
          <li><h5>Laboratory Tests:</h5> Blood tests to check for thyroid function, glucose levels, and other potential medical causes.</li>
          <li><h5>Imaging Studies:</h5> MRI or CT scans if there’s a suspicion of neurological causes.</li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <h5>Complications</h5>
          <ul>
          <li><h5>Agoraphobia:</h5>  Avoidance of places where escape might be difficult or help unavailable during a panic attack.</li>
          <li><h5>Other Anxiety Disorders:</h5> Generalized anxiety disorder, social anxiety disorder, and specific phobias.</li>
          <li><h5>Depression:</h5> Persistent low mood and loss of interest in activities.</li>
          <li><h5>Substance Abuse:</h5> Use of alcohol or drugs to self-medicate.</li>
          <li><h5>Reduced Quality of Life: </h5>Impact on personal relationships, work, and overall well-being.</li>
          </ul>
          <h5>Risk</h5>
          <ul>
          <li><h5>Family History:</h5> Relatives with panic disorder or other anxiety disorders.</li>
          <li><h5>Personality Traits:</h5> High sensitivity to stress or negative emotions.</li>
          <li><h5>Stressful Life Events:</h5>  Divorce, loss of a job, or major transitions.</li>
          <li><h5>Traumatic Experiences: </h5>History of physical or sexual abuse.</li>
          <li><h5>Medical Conditions:</h5> Heart disease, respiratory disorders, and chronic pain.</li>
          </ul>
          <h3>Traditional Basic Treatment</h3>
          <ul>
          <li><h5>Cognitive Behavioral Therapy (CBT):</h5> This therapy helps patients identify and change negative thought patterns and behaviors that trigger panic attacks.</li>
          <li><h5>Selective Serotonin Reuptake Inhibitors (SSRIs): </h5>Such as sertraline, fluoxetine, and paroxetine.</li>
          <li><h5>Serotonin-Norepinephrine Reuptake Inhibitors (SNRIs): </h5> Such as venlafaxine.</li>
          <li><h5>Benzodiazepines:</h5>  Such as alprazolam and clonazepam, used for short-term relief.</li>
          <li><h5>Regular Exercise:</h5> Helps reduce anxiety and improve mood. </li>
          <li><h5>Healthy Diet:</h5> Balanced nutrition to support overall health.</li>
          <li><h5>Avoidance of Stimulants:</h5> Reducing caffeine and nicotine intake. </li>
          <li><h5>Relaxation Techniques:</h5> Yoga, meditation, and deep-breathing exercises.</li>
          <li><h5>Support Groups:</h5> Sharing experiences and strategies in a supportive community.</li>
          </ul>
          <h3>Homeopathic Supportive Care</h3>
          <p>Homeopathy offers a range of remedies tailored to the specific symptoms and constitutional type of the individual. The remedies are selected based on a comprehensive assessment of the patient’s physical, emotional, and mental state. Some commonly used remedies include:</p>
          <ul>
          <li><h5>Aconitum napellus:</h5> For sudden, intense panic attacks with fear of death and restlessness.</li>
          <li><h5>Arsenicum album:</h5> For anxiety with extreme restlessness, fear of being alone, and perfectionism.</li>
          <li><h5>Argentum nitricum:</h5>  For anxiety with anticipation, impulsiveness, and digestive issues.</li>
          <li><h5>Gelsemium:</h5> For performance anxiety with weakness, trembling, and drowsiness.</li>
          <li><h5>Kali arsenicosum:</h5> For chronic anxiety with nervousness, restlessness, and sensitivity to cold.</li>
          <li><h5>Phosphorus:</h5> For anxiety with sensitivity, fear of thunderstorms, and desire for company.</li>
          </ul>
          <h3>Conclusion</h3>
         <p>In our experience, homeopathic treatment for panic disorder can be highly effective, particularly when it is tailored to the individual’s specific symptoms and overall constitution. Patients often report a significant reduction in the frequency and intensity of panic attacks, as well as improvements in general well-being and emotional resilience. Homeopathy not only addresses the immediate symptoms but also aims to enhance the patient’s overall health, reducing susceptibility to future episodes. Combining homeopathic treatment with lifestyle modifications and supportive therapies, such as counseling or CBT, can further enhance the therapeutic outcome. Regular follow-ups and adjustments to the treatment plan ensure that the remedy remains effective as the patient’s condition evolves. Overall, homeopathy provides a holistic and patient-centered approach to managing panic disorder, supporting both acute relief and long-term healing.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Panic disorder Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Panic disorder conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/psychiatric/panic-disorder/panic-disorder-diet">Diet for Panic disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Panic disorder</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Panic disorder</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Panic disorder Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/9FV-FDmc-g0?si=PEz9-dK9FKK79BpD"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
