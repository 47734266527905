import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const ConstipationDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Constipation Diet Guide</h1>
      <p className="diet-description">A well-balanced diet can help manage constipation by improving digestion, increasing fiber intake, and promoting overall gut health. Below are suggestions for foods to take and avoid when dealing with constipation.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Fiber Foods</h3>
          <p className="diet-text">Fiber-rich foods such as whole grains, fruits, vegetables, and legumes help bulk up stools and promote regular bowel movements. Aim for at least 25-30 grams of fiber per day.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Hydrating Foods</h3>
          <p className="diet-text">Staying hydrated is crucial for preventing constipation. Drink plenty of water and include hydrating foods like cucumbers, watermelon, and oranges to keep stools soft and easier to pass.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Prunes and Dried Fruits</h3>
          <p className="diet-text">Prunes, dried apricots, and figs are high in fiber and natural laxatives like sorbitol, which can help promote bowel movements and relieve constipation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods like sauerkraut and kimchi support gut health by maintaining a healthy balance of bacteria, which can improve digestion and relieve constipation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Healthy Fats</h3>
          <p className="diet-text">Healthy fats from sources like avocados, olive oil, nuts, and seeds can help lubricate the digestive tract and promote smoother bowel movements.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Herbal Teas</h3>
          <p className="diet-text">Herbal teas like ginger, peppermint, and chamomile can soothe the digestive system, relieve bloating, and promote regular bowel movements.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed Foods</h3>
          <p className="diet-text">Processed foods such as chips, fast food, and pre-packaged snacks are often low in fiber and high in unhealthy fats, which can contribute to constipation. Choose whole, unprocessed foods instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Grains</h3>
          <p className="diet-text">Refined grains, such as white bread, pasta, and pastries, are low in fiber and can slow down digestion. Opt for whole grains like brown rice, oats, and quinoa for better digestion.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Dairy Products (in excess)</h3>
          <p className="diet-text">Dairy products, particularly in large amounts, can cause constipation for some people. Consider reducing dairy intake or choosing lactose-free alternatives if you experience digestive issues.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Red Meat</h3>
          <p className="diet-text">Red meat is low in fiber and takes longer to digest, which can contribute to constipation. Consider reducing red meat consumption and incorporating more plant-based proteins into your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Sugary Snacks and Drinks</h3>
          <p className="diet-text">Sugary snacks and beverages like candy, soda, and pastries can disrupt the digestive system and contribute to constipation. Limiting sugar intake can help improve bowel regularity.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">While small amounts of caffeine can stimulate bowel movements, excessive caffeine can dehydrate the body and worsen constipation. Stick to moderate amounts and ensure proper hydration.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A bowl of oatmeal with chia seeds, fresh berries, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> A spinach and quinoa salad with avocado, chickpeas, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a smoothie with prunes, spinach, and banana.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Grilled salmon with roasted sweet potatoes and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day and include herbal teas like peppermint or ginger to soothe digestion.</li>
        </ul>
      </section>
    </div>
  );
};

export default ConstipationDiet;
