import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation files
const resources = {
  en: {
    translation: {
      welcome: "Welcome to Niramaya Homeopathy",
      yourHealth: "Your Health, Our Priority",
      treatments: "Our Treatments",
      // Add more translations here
      bookAppointment: "Book Appointment",
      aboutNiramaya: "About Niramaya Homeopathy",
      // Add other sections and translations here...
    },
  },
  hi: {
    translation: {
      welcome: "निरामया होम्योपैथी में आपका स्वागत है",
      yourHealth: "आपका स्वास्थ्य, हमारी प्राथमिकता",
      treatments: "हमारे उपचार",
      // Add more translations here
      bookAppointment: "अपॉइंटमेंट बुक करें",
      aboutNiramaya: "निरामया होम्योपैथी के बारे में",
      // Add other sections and translations here...
    },
  },
};

// Initialize i18n
i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
