import React from 'react';
import '../../css/CaseStudy.css';

const RheumatoidArthritisCaseStudy = () => {
  return (
    <div className="case-study-page">
      <div className="case-study-hero">
        <h1>Shubhankar Das’s Journey to Overcoming Rheumatoid Arthritis with Homeopathy</h1>
        {/* <img src={require('../images/case-study-hero4.jpg')} alt="Rheumatoid Arthritis Recovery" className="case-study-img" /> */}
      </div>

      <div className="case-study-content">
        <p>
          Shubhankar Das, a 44-year-old from Jalpaiguri, had been suffering from rheumatoid arthritis (RA) for 14 years. The pain, stiffness, and swelling in his joints made daily tasks difficult. He tried various treatments over the years—steroids, Ayurvedic remedies, and even some homeopathic options—but nothing brought lasting relief. His RA factor and CRP levels stayed high, signaling ongoing inflammation, and he was losing hope.
        </p>

        <h2>Discovering a New Approach During the Pandemic</h2>
        <p>
          In early 2020, during the COVID-19 pandemic, Shubhankar came across our YouTube channel, where he saw others sharing their experiences of finding relief through homeopathy. Intrigued, he scheduled his first consultation with us in February. During that meeting, we reviewed his medical history, current symptoms, and lifestyle. We explained that homeopathic treatment would be gradual but designed to address his condition’s root cause, not just the symptoms. With this plan in place, Shubhankar decided to give it a try.
        </p>

        <h2>Combining Homeopathy with Lifestyle Support</h2>
        <p>
          Our approach combined homeopathic remedies and lifestyle changes. We also connected him with our yoga therapist, Shalini, who guided him through gentle, online yoga sessions to support his joints and improve his flexibility.
        </p>

        <h2>Early Signs of Improvement</h2>
        <p>
          In the first few months, Shubhankar noticed small improvements. His joint pain became less intense, and he found himself relying on painkillers less often. Over the following year, the inflammation decreased, and his mobility improved, allowing him to move with much more comfort. His blood tests showed promising results as his RA factor and CRP levels began to drop.
        </p>

        <h2>A Remarkable Recovery</h2>
        <p>
          After about two years, Shubhankar's symptoms had dramatically improved. His joint pain was nearly gone, and his blood levels were close to normal. By two and a half years, his progress was complete, and he was able to stop all medications. Now free from the constant pain, Shubhankar felt like he had regained his life.
        </p>

        <h2>Maintaining Wellness Through Yoga and Lifestyle Changes</h2>
        <p>
          Today, he continues to do regular yoga sessions with Shalini, not just to manage pain but to maintain his flexibility and overall well-being. Looking back, he feels grateful for finding our YouTube channel, as it led him to a treatment path that finally gave him relief after years of struggle. His story is a testament to the power of a patient, holistic approach and the benefits of combining treatment with lifestyle support.
        </p>
      </div>
    </div>
  );
};

export default RheumatoidArthritisCaseStudy;
