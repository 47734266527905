import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const CoughColdDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Diet Guide for Recurrent Cough and Cold</h1>
      <p className="diet-description">A diet rich in immune-boosting nutrients can help manage recurrent cough and cold symptoms by supporting respiratory health, reducing inflammation, and promoting overall immunity. Here are dietary suggestions for managing these symptoms effectively.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C is essential for immune support. Include citrus fruits, strawberries, bell peppers, and broccoli to boost your body's defenses.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Ginger and Turmeric</h3>
          <p className="diet-text">Both ginger and turmeric have strong anti-inflammatory and antiviral properties. Add them to teas, soups, or meals to soothe the respiratory tract.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Garlic and Onions</h3>
          <p className="diet-text">Garlic and onions contain allicin, a compound that has antibacterial and immune-boosting effects. Adding these to meals can help combat infections.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods help maintain gut health, which is closely linked to immune function.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Honey</h3>
          <p className="diet-text">Honey has natural soothing and antibacterial properties. A teaspoon in warm water or tea can help relieve sore throats and cough.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Warm Herbal Teas</h3>
          <p className="diet-text">Herbal teas, such as chamomile, peppermint, and thyme, provide warmth and hydration, easing congestion and soothing the throat.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Beverages</h3>
          <p className="diet-text">Excess sugar can suppress the immune system. Limit sweets, sugary drinks, and processed snacks for better immune resilience.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Dairy Products (if sensitive)</h3>
          <p className="diet-text">For some, dairy can increase mucus production. If you notice increased congestion after consuming dairy, try alternatives like almond or oat milk.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Greasy Foods</h3>
          <p className="diet-text">Greasy foods can lead to inflammation and make it harder for the body to fight infections. Opt for lighter, easier-to-digest meals instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Cold Beverages</h3>
          <p className="diet-text">Cold drinks can irritate the throat and increase congestion. Stick to warm or room-temperature beverages to ease symptoms.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Warm oatmeal topped with berries, a drizzle of honey, and a sprinkle of cinnamon.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Chicken or vegetable soup with garlic, ginger, and turmeric for added immune support.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Greek yogurt with a handful of mixed nuts or an apple with almond butter.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu or lean protein, garlic, and a side of brown rice.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink warm water throughout the day, and include herbal teas like ginger or peppermint to relieve symptoms.</li>
        </ul>
      </section>
    </div>
  );
};

export default CoughColdDiet;
