import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const OsteoporosisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Osteoporosis Diet Guide</h1>
      <p className="diet-description">
        Managing osteoporosis involves a diet rich in bone-supporting nutrients, such as calcium, vitamin D, and magnesium, which help maintain bone density and prevent fractures. Below are dietary suggestions for foods to include and avoid to support bone health and reduce the risk of osteoporosis progression.
      </p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Calcium-Rich Foods</h3>
          <p className="diet-text">
            Calcium is essential for strong bones. Include dairy products like milk, yogurt, and cheese, as well as leafy greens, almonds, and fortified plant-based milks in your diet.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin D-Rich Foods</h3>
          <p className="diet-text">
            Vitamin D enhances calcium absorption. Include fatty fish (such as salmon and mackerel), egg yolks, and fortified foods to ensure adequate vitamin D intake.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Magnesium-Rich Foods</h3>
          <p className="diet-text">
            Magnesium helps convert vitamin D into its active form for better calcium absorption. Foods like nuts, seeds, whole grains, and dark leafy greens are good sources of magnesium.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Protein-Rich Foods</h3>
          <p className="diet-text">
            Protein is important for bone structure. Include lean meats, fish, beans, and legumes in your diet to maintain muscle mass and support bone health.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Vitamin K-Rich Foods</h3>
          <p className="diet-text">
            Vitamin K is essential for bone metabolism and health. Leafy greens, such as kale, spinach, and broccoli, provide a rich source of vitamin K.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Potassium-Rich Foods</h3>
          <p className="diet-text">
            Potassium helps reduce calcium loss from bones. Bananas, oranges, potatoes, and tomatoes are excellent sources of potassium to include in your diet.
          </p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Excess Salt</h3>
          <p className="diet-text">
            High salt intake can increase calcium loss through urine, weakening bones over time. Limit processed foods and avoid adding too much salt to your meals.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">
            Excessive sugar can interfere with calcium absorption. Limit sugary snacks and beverages to promote better bone health.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Caffeine</h3>
          <p className="diet-text">
            High caffeine intake can reduce calcium absorption. Limit coffee, tea, and sodas, and consider decaffeinated options when possible.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">
            Excessive alcohol can weaken bones and reduce calcium absorption. Limit alcohol intake to support bone health.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Soft Drinks</h3>
          <p className="diet-text">
            Soft drinks contain phosphates, which can interfere with calcium absorption and negatively impact bone density. Replace them with water or herbal teas.
          </p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Fortified cereal with low-fat milk and a banana.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken salad with spinach, almonds, and olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> Low-fat yogurt with a handful of berries.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with a side of steamed broccoli and quinoa.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink water throughout the day and consider adding calcium-fortified orange juice.</li>
        </ul>
      </section>
    </div>
  );
};

export default OsteoporosisDiet;
