import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const AOMCSOMDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">AOM/CSOM Diet Guide</h1>
      <p className="diet-description">A nutrient-rich, anti-inflammatory diet can help manage symptoms and support recovery from AOM (Acute Otitis Media) and CSOM (Chronic Suppurative Otitis Media). Below are suggestions for foods to take and avoid to promote healing and reduce ear inflammation.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s found in fatty fish, flaxseeds, and walnuts help reduce inflammation, which is key to managing the ear infections associated with AOM and CSOM.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C supports immune function and helps fight off infections. Include citrus fruits, bell peppers, strawberries, and broccoli to boost your Vitamin C intake.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc is crucial for immune function and tissue repair. Include seeds, nuts, whole grains, and legumes to ensure sufficient zinc intake to support recovery from ear infections.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and fermented foods can help support immune function and may prevent the recurrence of infections by maintaining a healthy gut microbiome.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Antioxidant-Rich Foods</h3>
          <p className="diet-text">Berries, leafy greens, and nuts are rich in antioxidants, which help reduce oxidative stress and inflammation, promoting overall ear health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Hydrating Foods and Fluids</h3>
          <p className="diet-text">Staying hydrated supports immune function and helps thin mucus, which may relieve ear pressure. Include water-rich foods like cucumbers, melons, and citrus fruits, and drink plenty of fluids.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed and Sugary Foods</h3>
          <p className="diet-text">Processed foods and excess sugar can promote inflammation and weaken the immune system. Avoid sugary snacks, sodas, and heavily processed foods to support ear health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. High-Sodium Foods</h3>
          <p className="diet-text">Sodium can contribute to fluid retention, which may worsen ear discomfort and swelling. Reduce intake of processed snacks, canned foods, and salty meals.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried foods and unhealthy fats can increase inflammation and interfere with immune function. Opt for healthier fats from sources like olive oil, avocados, and nuts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Alcohol can dehydrate the body and weaken the immune system, potentially worsening symptoms. Avoid alcohol to promote better recovery and reduce inflammation.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Caffeinated Beverages (in excess)</h3>
          <p class="diet-text">Excess caffeine can dehydrate the body and delay recovery. Limit caffeine intake and prioritize hydrating fluids like water and herbal teas.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with quinoa and steamed spinach.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a zinc-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with brown rice and roasted vegetables.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support hydration and reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default AOMCSOMDiet;
