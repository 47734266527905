import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const RheumatoidArthritisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Rheumatoid Arthritis Diet Guide</h1>
      <p className="diet-description">
        A diet rich in anti-inflammatory foods can help manage rheumatoid arthritis (RA) symptoms by reducing joint inflammation, pain, and stiffness. Below are dietary suggestions for foods to take and avoid to support joint health and reduce RA symptoms.
      </p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Omega-3 Fatty Acids</h3>
          <p className="diet-text">
            Omega-3s found in fish like salmon, mackerel, and sardines have powerful anti-inflammatory properties that may help reduce joint pain and stiffness.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Antioxidant-Rich Foods</h3>
          <p className="diet-text">
            Foods like berries, oranges, spinach, and bell peppers are high in antioxidants, which help reduce oxidative stress and inflammation associated with RA.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fiber-Rich Foods</h3>
          <p className="diet-text">
            Whole grains, beans, and vegetables provide fiber that supports gut health, which is linked to reduced inflammation levels.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Vitamin D-Rich Foods</h3>
          <p className="diet-text">
            Vitamin D is essential for bone health and immune function. Include fortified dairy, egg yolks, and mushrooms to support joint health.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Probiotic Foods</h3>
          <p className="diet-text">
            Yogurt, kefir, and other fermented foods support gut health, which can help reduce inflammation linked to RA symptoms.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Olive Oil</h3>
          <p className="diet-text">
            Olive oil contains oleocanthal, a compound with anti-inflammatory effects similar to ibuprofen. Use it as a cooking oil or salad dressing.
          </p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed Foods</h3>
          <p className="diet-text">
            Processed foods, often high in unhealthy fats and additives, can increase inflammation in the body. Opt for whole, unprocessed foods.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Sugars</h3>
          <p className="diet-text">
            Excess sugar can worsen inflammation and contribute to weight gain, which adds pressure on joints. Limit sugary snacks, sodas, and desserts.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Red Meat</h3>
          <p className="diet-text">
            High in saturated fats, red meat can increase inflammation in the body. Choose lean proteins like fish and poultry instead.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Fried and Fatty Foods</h3>
          <p className="diet-text">
            Fried foods contain trans fats and can lead to increased inflammation. Replace with healthy fats from nuts, seeds, and avocados.
          </p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Alcohol</h3>
          <p className="diet-text">
            Excessive alcohol can interfere with medications and increase inflammation. Limit or avoid alcohol to reduce joint stress.
          </p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal topped with berries, chia seeds, and a drizzle of honey.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa, steamed broccoli, and a side of mixed greens with olive oil dressing.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or walnuts.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried tofu with colorful vegetables (bell peppers, carrots, spinach) and brown rice.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day and include herbal teas to help reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default RheumatoidArthritisDiet;
