import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const MenieresDiseaseDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Meniere’s Disease Diet Guide</h1>
      <p className="diet-description">A proper diet can help manage the symptoms of Meniere’s disease, such as vertigo and fluid retention in the inner ear. Below are suggestions for foods to take and avoid to help reduce symptoms and improve overall balance and ear health.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Low-Sodium Foods</h3>
          <p className="diet-text">A low-sodium diet is essential to reduce fluid retention in the inner ear. Include fresh fruits, vegetables, and lean proteins that are naturally low in sodium.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Hydrating Foods and Fluids</h3>
          <p className="diet-text">Staying hydrated helps balance fluid levels in the body and supports overall ear health. Drink plenty of water, and include water-rich foods like cucumbers, melons, and citrus fruits.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Potassium-Rich Foods</h3>
          <p className="diet-text">Potassium helps regulate fluid balance in the body, which is important for managing Meniere’s disease. Include bananas, spinach, sweet potatoes, and avocados in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Magnesium-Rich Foods</h3>
          <p className="diet-text">Magnesium is essential for nerve function and can help reduce dizziness. Foods like leafy greens, nuts, seeds, and whole grains are good sources of magnesium.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Whole Grains and Fiber-Rich Foods</h3>
          <p className="diet-text">Whole grains help maintain stable blood sugar levels, which can reduce symptoms of dizziness. Include oats, quinoa, and brown rice in your meals.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Berries, fatty fish (like salmon), and leafy greens are rich in antioxidants and Omega-3s, which help reduce inflammation and support overall ear health.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. High-Sodium Foods</h3>
          <p className="diet-text">Excess sodium can cause fluid retention, which may worsen symptoms of Meniere’s disease. Avoid processed foods, canned soups, salty snacks, and fast food.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Caffeine</h3>
          <p className="diet-text">Caffeine can increase ringing in the ears (tinnitus) and stimulate the nervous system. Limit or avoid coffee, tea, soda, and other caffeinated beverages.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Alcohol</h3>
          <p className="diet-text">Alcohol can alter the fluid balance in the inner ear and worsen vertigo symptoms. Limit alcohol intake to reduce the frequency of attacks.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can cause blood sugar fluctuations, which may trigger dizziness. Avoid sugary snacks, desserts, and sugary drinks like sodas.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Processed Foods</h3>
          <p className="diet-text">Processed foods often contain additives and preservatives that can exacerbate symptoms. Stick to whole, unprocessed foods for better control of Meniere’s disease.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. MSG (Monosodium Glutamate)</h3>
          <p className="diet-text">MSG can trigger headaches and dizziness in some individuals. Avoid foods containing MSG to prevent worsening of Meniere’s symptoms.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Oatmeal with bananas, chia seeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled chicken with quinoa and steamed spinach.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of almonds or a potassium-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked salmon with sweet potatoes and a green salad.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to stay hydrated and reduce dizziness.</li>
        </ul>
      </section>
    </div>
  );
};

export default MenieresDiseaseDiet;
