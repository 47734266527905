import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const HairFallDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Hair Fall Diet Guide</h1>
      <p className="diet-description">A diet rich in essential nutrients can help prevent hair fall by strengthening hair follicles, promoting scalp health, and boosting overall hair growth. Below are suggestions for foods to take and avoid to manage hair fall.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Protein-Rich Foods</h3>
          <p className="diet-text">Hair is made of keratin, a type of protein. Include protein-rich foods like eggs, lean meat, legumes, and lentils in your diet to strengthen hair and prevent hair fall.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Iron-Rich Foods</h3>
          <p className="diet-text">Iron deficiency is one of the major causes of hair fall. Leafy greens like spinach, lentils, and lean meats are excellent sources of iron, promoting better oxygen delivery to hair follicles.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Foods rich in Omega-3s, such as salmon, walnuts, and flaxseeds, nourish the scalp, improve hair elasticity, and promote stronger hair growth.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Zinc and Selenium-Rich Foods</h3>
          <p className="diet-text">Zinc and selenium are essential for maintaining a healthy scalp and hair growth. Include nuts, seeds, and whole grains in your diet to prevent hair thinning.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C supports collagen production and helps absorb iron, both of which are crucial for healthy hair. Include citrus fruits, bell peppers, and strawberries for better hair growth.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Biotin-Rich Foods</h3>
          <p className="diet-text">Biotin, also known as Vitamin B7, is important for hair health. Eggs, nuts, seeds, and sweet potatoes are excellent sources of biotin to prevent hair thinning and strengthen hair strands.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>
        
        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excessive sugar consumption can lead to hormonal imbalances, which can trigger hair fall. Avoid sugary snacks, sodas, and processed sweets to support healthy hair growth.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Refined Carbohydrates</h3>
          <p className="diet-text">Refined carbs like white bread, pasta, and baked goods can cause insulin spikes, contributing to hair thinning. Opt for whole grains instead to stabilize blood sugar levels.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Fried and Fatty Foods</h3>
          <p className="diet-text">Fried and high-fat foods can block hair follicles and increase sebum production on the scalp, leading to hair fall. Choose healthier fats like avocados, nuts, and olive oil.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Alcohol</h3>
          <p className="diet-text">Excessive alcohol consumption can dehydrate the body and weaken hair strands, making them more prone to breakage. Limit alcohol intake to support hair health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Processed Foods</h3>
          <p className="diet-text">Processed foods are often high in unhealthy fats, preservatives, and additives, which can interfere with hair growth and overall health. Stick to whole, natural foods for better hair health.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Caffeine (in excess)</h3>
          <p className="diet-text">While moderate caffeine intake is generally fine, excessive caffeine can interfere with nutrient absorption and disrupt healthy hair growth. Keep caffeine intake balanced.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> A smoothie with spinach, berries, flaxseeds, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon or tofu salad with quinoa and mixed greens.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a biotin-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Baked chicken with sweet potatoes and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water throughout the day, and include herbal teas to support overall health and hair strength.</li>
        </ul>
      </section>
    </div>
  );
};

export default HairFallDiet;
