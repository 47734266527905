import React from 'react';
import '../../css/KidneyStoneDiet.css';  

const EpididymitisDiet = () => {
  return (
    <div className="kidney-stone-diet-page">
      <h1 className="diet-title">Epididymitis Diet Guide</h1>
      <p className="diet-description">A diet rich in anti-inflammatory and immune-boosting foods can help manage epididymitis by reducing inflammation, fighting infection, and promoting tissue healing. Below are dietary suggestions for foods to take and avoid for epididymitis management.</p>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Take</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Anti-Inflammatory Foods</h3>
          <p className="diet-text">Foods like turmeric, ginger, and green leafy vegetables reduce inflammation, which may help alleviate pain and swelling in epididymitis.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Omega-3 Fatty Acids</h3>
          <p className="diet-text">Omega-3s in fatty fish, chia seeds, and walnuts help reduce inflammation and support immune function, essential for recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Probiotic-Rich Foods</h3>
          <p className="diet-text">Probiotics found in yogurt, kefir, and other fermented foods support a healthy immune system and can help reduce infection-related symptoms.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Vitamin C-Rich Foods</h3>
          <p className="diet-text">Vitamin C helps boost immunity and supports healing. Include citrus fruits, bell peppers, and strawberries in your diet.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">5. Zinc-Rich Foods</h3>
          <p className="diet-text">Zinc plays a key role in immune health and tissue repair. Foods like pumpkin seeds, chickpeas, and almonds are good sources.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">6. Hydrating Foods</h3>
          <p className="diet-text">Staying well-hydrated is crucial for flushing out toxins. Water-rich fruits and vegetables like cucumbers and watermelon aid hydration.</p>
        </section>
      </section>

      <section className="diet-section">
        <h2 className="diet-subheading">What to Avoid</h2>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">1. Processed Foods</h3>
          <p className="diet-text">Processed foods contain additives and unhealthy fats that can increase inflammation. Focus on whole, unprocessed foods instead.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">2. Sugary Foods and Drinks</h3>
          <p className="diet-text">Excess sugar can promote inflammation and weaken immune function. Limit sugary snacks and beverages for better recovery.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">3. Trans Fats</h3>
          <p className="diet-text">Trans fats in fried and processed foods can worsen inflammation. Choose healthy fats from sources like olive oil and nuts.</p>
        </section>

        <section className="diet-subsection">
          <h3 className="diet-subsubheading">4. Excessive Caffeine and Alcohol</h3>
          <p className="diet-text">Excess caffeine and alcohol can irritate the body and impact immune response. Moderation is key for optimal recovery.</p>
        </section>
      </section>

      <section className="sample-diet">
        <h2 className="diet-sample-subheading">Sample Diet Plan</h2>
        <ul className="diet-list">
          <li className="diet-list-item"><strong>Breakfast:</strong> Smoothie with spinach, berries, ginger, and almond milk.</li>
          <li className="diet-list-item"><strong>Lunch:</strong> Grilled salmon with quinoa and steamed broccoli.</li>
          <li className="diet-list-item"><strong>Snack:</strong> A handful of walnuts or a probiotic-rich yogurt.</li>
          <li className="diet-list-item"><strong>Dinner:</strong> Stir-fried vegetables with tofu, brown rice, and a side of leafy greens.</li>
          <li className="diet-list-item"><strong>Hydration:</strong> Drink plenty of water and herbal teas like chamomile to support hydration and reduce inflammation.</li>
        </ul>
      </section>
    </div>
  );
};

export default EpididymitisDiet;
