import React from 'react';
import '../css/Kidney.css'; // Add custom styles here

const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/crf.webp')}alt="Urology Hero" />
        <h1>Crf Management Treatment</h1>
      </div>

      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Crf Management</h2>
          <img src={require('../images/kidney7.webp')}alt="Urology Hero" />
          <h3>Causes</h3>
          <ul>
            <li><h5>Diabetes Mellitus:</h5>High blood sugar levels over time can damage the kidneys’ blood vessels, leading to diabetic nephropathy and CKD.</li>
            <li><h5>Hypertension (High Blood Pressure): </h5>Uncontrolled high blood pressure can damage the kidneys’ blood vessels and impair their ability to filter blood effectively.</li>
            <li><h5>Glomerulonephritis:</h5> Inflammation of the kidney’s filtering units (glomeruli) can lead to CKD if untreated or chronic.</li>
            <li><h5>Polycystic Kidney Disease (PKD): </h5>A genetic disorder where numerous fluid-filled cysts develop in the kidneys, gradually replacing healthy tissue and impaired kidney function.</li>
            <li><h5>Urinary Tract Obstructions:</h5>Conditions like kidney stones, tumors, or enlarged prostate can block urine flow, leading to kidney damage over time.</li>
          </ul>
          <h3>Symptoms of CRF:</h3>
          <h4>Early Stages:</h4>
          <ul>
            <li><h5>Asymptomatic:</h5>In the early stages, chronic renal failure may not present with noticeable symptoms, and the condition may be detected through routine blood or urine tests.</li>
            <li><h5>Mild Symptoms:</h5>Some individuals may experience mild symptoms such as: Fatigue or tiredness, which can be due to decreased kidney function affecting energy levels.Fluid retention or swelling (edema), particularly in the feet, ankles, or hands, due to the kidneys’ reduced ability to remove excess fluid from the body.</li>
          </ul>
          <h4>Progressive Stages:</h4>
          <ul>
            <li><h5>Decreased Urine Output:</h5>As kidney function continues to decline, urine output may decrease or become more variable. This occurs because the kidneys are less able to filter and eliminate waste products and excess fluid from the body.</li>
            <li><h5>Edema (Swelling):</h5>Persistent fluid retention can lead to swelling in the extremities (legs, ankles, feet) or around the eyes, particularly in the morning.</li>
            <li><h5>Nausea and Vomiting:</h5> Buildup of waste products in the blood (uremia) can cause nausea and vomiting, especially after meals or in the morning.</li>
            <li><h5>Itching (Pruritus): </h5>Accumulation of toxins in the blood can lead to itching, often felt on the skin.</li>
            <li><h5>Muscle Cramps:</h5>Electrolyte imbalances and fluid retention can contribute to muscle cramps, especially in the legs.</li>
            <li><h5>Muscle Cramps: </h5>Accumulation of toxins in the blood can lead to itching, often felt on the skin.</li>
          </ul>
          <h3>Investigations and Diagnosis</h3>
          <h4>Medical History and Physical Examination:</h4>
          <ul>
            <li><h5>Medical History: </h5>The healthcare provider will review the patient’s medical history, including any symptoms suggestive of kidney dysfunction such as fatigue, swelling, changes in urine output or appearance, and risk factors like diabetes or hypertension.</li>
            <li><h5>Physical Examination: </h5>A thorough physical examination may reveal signs such as swelling (edema), high blood pressure, and other indicators of kidney function and overall health.</li>
          </ul>
          <h4>Laboratory Tests:</h4>
          <ul>
            <li><h5>Blood Tests:</h5>Blood tests are crucial for assessing kidney function.</li>
            <li><h5>Creatinine:</h5>Elevated levels indicate impaired kidney function, as creatinine is a waste product normally filtered out by the kidneys.</li>
            <li><h5>Blood Urea Nitrogen (BUN):</h5> Elevated levels can indicate impaired kidney function, but it’s also influenced by other factors such as protein intake.</li>
            <li><h5>Electrolytes (e.g., potassium, sodium):</h5>Imbalances can occur with kidney dysfunction.</li>
            <li><h5>Urine Tests:</h5>Urine tests help assess kidney function and detect abnormalities such as- </li>
            <li><h5>Proteinuria:</h5>Presence of protein in the urine, indicating kidney damage.</li>
            <li><h5>Hematuria:</h5>Presence of blood in the urine, which can indicate inflammation or damage to the kidneys.</li>
          </ul>
          <h4>Imaging Studies:</h4>
          <ul>
            <li><h5>Ultrasound:</h5>Ultrasound imaging is non-invasive and commonly used to assess kidney size, structure, and detect abnormalities such as cysts or tumors.</li>
            <li><h5>CT Scan (Computed Tomography): </h5>Provides detailed images of the kidneys and surrounding structures, helpful for evaluating kidney function and identifying structural abnormalities.
            </li>
          </ul>
          <h3>Complications and Risk Factors</h3>
          <h4>Complications:</h4>
          <ul>
            <li><h5>Anemia:</h5>Reduced kidney function can lead to decreased production of erythropoietin, a hormone that stimulates red blood cell production. This can result in anemia, characterized by fatigue, weakness, and pale skin.</li>
            <li><h5>Bone Disease:</h5>Chronic kidney disease can disrupt the balance of minerals in the body, leading to abnormalities in bone metabolism. This can result in bone pain, fractures, and an increased risk of osteoporosis.</li>
            <li><h5>Cardiovascular Disease:</h5>Individuals with chronic renal failure are at higher risk of developing cardiovascular complications such as heart disease, heart attacks, and strokes. Kidney dysfunction can lead to fluid retention, electrolyte imbalances, and hypertension, all of which contribute to cardiovascular stress.</li>
            <li><h5>Fluid and Electrolyte Imbalances:</h5>The kidneys play a crucial role in maintaining fluid balance and electrolyte levels in the body. In CRF, impaired kidney function can lead to fluid retention, electrolyte disturbances (such as high potassium levels), and acid-base imbalances.</li>
          </ul>
          <h4>Risk Factors:</h4>
          <ul>
            <li><h5>Diabetes:</h5>Diabetes mellitus is a leading cause of chronic kidney disease. Elevated blood sugar levels over time can damage the kidneys’ blood vessels and impair their function.</li>
            <li><h5>Hypertension (High Blood Pressure):</h5>Uncontrolled high blood pressure can damage the kidneys’ blood vessels and accelerate the progression of kidney disease.</li>
            <li><h5>Older Age:</h5>The risk of chronic kidney disease increases with age, as kidney function naturally declines over time.</li>
            <li><h5>Family History of Kidney Disease:</h5>A family history of kidney disease, especially among first-degree relatives, increases the risk of developing chronic renal failure.</li>
            <li><h5>Certain Ethnic Backgrounds:</h5>Some ethnic groups, such as African-Americans, Hispanics, and Native Americans, have a higher prevalence of chronic kidney disease. Genetic factors and higher rates of diabetes and hypertension in these populations contribute to increased risk.</li>
          </ul>
          <h3>Traditional Treatment of CRF:</h3>
          <h4>Medications:</h4>
          <ul>
            <li><h5>ACE Inhibitors (Angiotensin-Converting Enzyme Inhibitors) and ARBs (Angiotensin II Receptor Blockers):</h5>These medications help to lower blood pressure and protect the kidneys from further damage by dilating blood vessels and reducing pressure on the kidneys’ filtering units (glomeruli).</li>
            <li><h5>Erythropoiesis-Stimulating Agents: </h5>These agents stimulate the production of red blood cells in the bone marrow and are used to treat anemia associated with chronic kidney disease. They mimic the action of erythropoietin, a hormone normally produced by the kidneys.</li>
            <li><h5>Phosphate Binders:</h5>In CRF, the kidneys may have difficulty removing phosphate from the blood, leading to high levels (hyperphosphatemia). Phosphate binders are medications that bind to phosphate in the digestive tract, preventing its absorption into the bloodstream.</li>
            <li><h5>Vitamin D Supplements:</h5> Chronic kidney disease can lead to vitamin D deficiency, which is important for bone health and immune function. Vitamin D supplements are often prescribed to maintain adequate levels in the body.</li>
          </ul>
          <h4>Dietary Changes:</h4>
          <ul>
            <li><h5>Low-Protein Diet:</h5>A reduced intake of protein helps to lessen the workload on the kidneys, as protein metabolism produces waste products that the kidneys must filter.</li>
            <li><h5>Low-Sodium Diet:</h5>Limiting sodium helps to control blood pressure and manage fluid retention, which is common in kidney disease.</li>
            <li><h5>Fluid Restriction:</h5>Restricting fluid intake may be necessary to manage fluid balance and prevent swelling (edema).</li>
          </ul>
          <h3>Homeopathic and Complementary Treatments for CRF:</h3>
          <h4>Nutritional Supplements:</h4>
          <ul>
            <li><h5>Cranberry Extract:</h5>Known for its potential benefits in supporting urinary tract health and reducing the risk of urinary tract infections (UTIs).</li>
            <li><h5>Omega-3 Fatty Acids:</h5>Found in fish oil supplements, omega-3s have anti-inflammatory properties that may help reduce inflammation associated with kidney disease.</li>
          </ul>
          <h4>Herbal Medicine:</h4>
          <ul>
            <li><h5>Dandelion Root:</h5>Often used in herbal medicine to support kidney function and promote fluid balance.</li>
            <li><h5>Nettle Leaf:</h5>Has diuretic properties and may help support kidney health by promoting urine production and flushing out toxins.</li>
          </ul>
          <h4>Dietary Modifications:</h4>
          <ul>
            <li><h5>Low-Protein Diet:</h5>Similar to conventional treatment, a low-protein diet in complementary medicine aims to reduce the workload on the kidneys and minimize the production of waste products.</li>
            <li><h5>Herbal Teas: </h5>Some herbal teas like parsley tea or dandelion root tea are believed to have diuretic properties and may support kidney function.</li>
          </ul>
          <h3>Conclusion on CRF Management: </h3>
          <p>Chronic renal failure requires a multidisciplinary approach involving medical management, dietary modifications, and potentially dialysis or transplant. Homeopathic and complementary therapies may offer supportive benefits, but their use should be integrated with conventional treatments under medical supervision. Early detection and proactive management are essential to slow disease progression and improve quality of life for individuals with CRF.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Crf Management Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Crf Management conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/urology/crf-management/crf-management-diet">Diet for Crf Management</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention from Crf Management</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Crf Management</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on CRF Management Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/4tN2sAajql4?si=S9VSXMjYfP1_PTS3"
                    title="m"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
