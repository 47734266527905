import React from 'react';
import '../css/Kidney.css'; // Add custom styles here
const KidneyPage = () => {
  return (
    <div className="kidney-page">
      {/* Hero Image Section */}
      <div className="kidney-hero-image">
      <img src={require('../images/sh5.webp')}alt="Urology Hero" />
        <h1>Alopecia</h1>
      </div>
      {/* Main Content Section */}
      <div className="kidney-content-container">
        <div className="kidney-left-content">
          <h2>Alopecia</h2>
          <img src={require('../images/skin5.webp')}alt="Urology Hero" />
          <p>
          Alopecia, a condition characterized by hair loss, can manifest in various forms and affect individuals of all ages. From localized patches to complete loss of hair across the scalp (alopecia totalis) or the entire body (alopecia universalis), this condition can have profound effects on physical appearance and emotional well-being. Understanding its epidemiology, causes, symptoms, and treatment options is crucial for effective management and support for those affected.          </p>
          <h3>Epidemiology of Alopecia</h3>
          <p>Alopecia affects a significant portion of the population worldwide, with varying prevalence rates depending on the type and demographic factors. Here are some key epidemiological insights:</p>
          <ul>
            <li><h5>Androgenetic Alopecia:</h5>Most common form, affecting both men and women, with a prevalence increasing with age.</li>
            <li><h5>Alopecia Areata:</h5>Estimated prevalence of about 0.1% to 0.2%, affecting all age groups.</li>
            <li><h5>Alopecia Totalis and Universalis:</h5> Less common, with prevalence rates lower than alopecia areata.</li>
          </ul>
          <h3>Causes of Alopecia</h3>
          <p>The underlying causes of alopecia can be multifactorial and complex, involving genetic, autoimmune, hormonal, and environmental factors. Understanding these causes helps in determining appropriate treatment strategies:</p>
          <ul>
            <li><h5>Genetic Predisposition: </h5>Family history plays a significant role, especially in androgenetic alopecia.</li>
            <li><h5>Autoimmune Mechanisms: </h5>Thought to be involved in alopecia areata, where the immune system mistakenly attacks hair follicles.</li>
            <li><h5>Hormonal Factors:</h5>Imbalances, such as in thyroid hormones, can contribute to hair loss.</li>
            <li><h5>Environmental Triggers:</h5>Stress, trauma, medications, and nutritional deficiencies can exacerbate or trigger episodes of alopecia.</li>
          </ul>
          <h3>Signs and Symptoms of Alopecia</h3>
          <p>The symptoms of alopecia can vary widely depending on the type and severity of the condition:</p>
          <ul>
            <li><h5>Patchy Hair Loss:</h5> Common in alopecia areata, where round or oval patches of hair loss occur.</li>
            <li><h5>Thinning of Hair:</h5>Gradual thinning over the scalp in androgenetic alopecia.</li>
            <li><h5>Complete Baldness:</h5> In severe cases like alopecia totalis or universalis, where all scalp or body hair is lost.</li>
            <li><h5>Nail Changes:</h5>Sometimes accompanying alopecia areata, such as pitting or ridging of nails.</li>
          </ul>
          <h3>Allopathic Medicines and Treatments</h3>
          <p>Allopathic treatments focus on managing symptoms, reducing inflammation, and promoting hair regrowth:</p>
          <ul>
            <li><h5>Topical Treatments:</h5>Minoxidil (Rogaine) is commonly used to stimulate hair growth.</li>
            <li><h5>Oral Medications:</h5>Finasteride (Propecia) is prescribed for androgenetic alopecia in men.</li>
            <li><h5>Corticosteroid Injections: </h5>Used in localized alopecia areata to suppress immune response.</li>
            <li><h5>Immunosuppressants:</h5>Methotrexate or JAK inhibitors for more severe cases of alopecia areata.</li>
          </ul>
          <h3>Prognosis and Precautions</h3>
          <p>The prognosis for alopecia varies depending on the type and individual response to treatment:</p>
          <ul>
            <li><h5>Androgenetic Alopecia:</h5>Generally a progressive condition with variable response to treatment.</li>
            <li><h5>Alopecia Areata:</h5>Spontaneous regrowth can occur in some cases; however, recurrence is common.</li>
            <li><h5>Alopecia Totalis/Universalis:</h5>More challenging to treat, with less predictable outcomes.</li>
            </ul>
            <p>Precautions include:</p>
            <ul>
            <li><h5>Avoiding Stress:</h5>Stress management techniques can help mitigate exacerbations.</li>
            <li><h5>Healthy Diet:</h5>Ensuring adequate nutrition supports hair follicle health.</li>
            <li><h5>Regular Monitoring:</h5>Early intervention can improve outcomes.</li>
          </ul>
          <h3>Homeopathic Treatments for Alopecia </h3>
          <p>Homeopathy offers alternative treatments focusing on individualized remedies:</p>
          <ul>
            <li><h5>Silicea:</h5>For brittle hair and slow hair growth.</li>
            <li><h5>Phosphorus:</h5>Addressing hair loss due to dandruff or scalp infections.</li>
            <li><h5>Natrum Muriaticum: </h5>Especially for hair loss due to grief or emotional stress.</li>
          </ul>
          <p>Homeopathic treatments aim to stimulate the body’s self-healing abilities and address underlying imbalances contributing to alopecia.</p>
          <h3>Conclusion</h3>
          <p>Alopecia presents a diverse spectrum of manifestations, from benign to severe forms impacting quality of life. Understanding its epidemiology, causes, symptoms, and treatment options, including allopathic and homeopathic approaches, is crucial for comprehensive management. While allopathic treatments focus on symptom control and hair regrowth, homeopathy provides alternative avenues for individuals seeking holistic approaches. Continued research and personalized care are essential for improving outcomes and supporting those affected by alopecia.</p>
        </div>

        {/* Right Sidebar */}
        <div className="kidney-right-sidebar">
          <div className="kidney-treatment-section">
            <h3>Alopecia Treatment</h3>
            <p>We offer natural, safe, and effective treatment for Alopecia conditions.</p>
            <button className="appointments-btn">Book Appointment</button>
          </div>

          <div className="related-articles">
            <h3>Related Articles</h3>
            <ul>
              <li><a href="/treatments/skin/alopecia/alopecia-diet">Diet for Alopecia</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Prevention of Alopecia</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()}>Homeopathy for Alopecia</a></li>
            </ul>
          </div>
        </div>
      </div>
       {/* YouTube Video Section */}
       <div className="kidney-video-container">
                <h3>Watch Our Video on Alopecia Treatment</h3>
                <iframe
                    src="https://www.youtube.com/embed/fnw3m_WRu6o?si=O04QRBLkBKW0Ep4C"
                    title="Infertility Treatment Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '560px', height: '315px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}
                />
            </div>
    </div>
  );
};

export default KidneyPage;
